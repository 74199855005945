import React, { useEffect, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";

interface ImageSliderTwoProps {
  Images: { url: string }[];
  // width: string;
  // height: string;
  className?: string;
}

const ImageSlider2: React.FC<ImageSliderTwoProps> = ({
  Images,
  // width,
  // height,
  // className,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? Images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === Images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  return (
    // w-[356px]
    // m-auto my-5 bg-cover bg-center relative group w-[356px] h-[222px] md:w-[570px] md:h-[355px]  xl:w-[724px] xl:h-[451px] lg:px-0
    <div
      className="m-auto my-5 relative group w-[356px] h-[222px] md:w-[570px] md:h-[355px]  xl:w-[724px] xl:h-[451px] lg:px-0"
      // style={{ width: width, height: height }}
    >
      <div
        style={{ backgroundImage: `url(${Images[currentIndex].url})`,
        transition: 'background-image 0.9s ease'
      }}
        className="w-full h-full bg-center bg-cover"
      />

      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>

      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className="flex top-4 justify-center py-2">
        {Images.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`text-3xl cursor-pointer ${
              slideIndex === currentIndex ? "text-red-600" : "text-white"
            }`}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider2;
