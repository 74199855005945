// import React from "react";
// import WhiteHouseIcon from "../assets/icons/WhiteHouseIcon";
// import OfficeIcon from "../assets/icons/OfficeIcon";
// import BeliefIcon from "../assets/icons/BeliefIcon";
// import PartyIcon from "../assets/icons/PartyIcon";
// import LocalityIcon from "../assets/icons/LocalityIcon";
import whiteHouseIcon from "../assets/icons/whiteHouseIcon.svg";
import officeIcon from "../assets/icons/officeIcon.svg";
import beliefIcon from "../assets/icons/beliefIcon.svg";
import partyIcon from "../assets/icons/partyIcon.svg";
import localityIcon from "../assets/icons/localityIcon.svg";

import Button from "./Button";
// import { Link } from "react-router-dom";

const CandidateInfoCard = () => {
  return (
    <>
      {/* max-[435px]:w-[358px] */}
      {/* h-[450px] */}
      {/* flex flex-col w-[450px] min-w-[250px] rounded-[25px] h-[415px]  bg-black bg-opacity-70 p-8 max-[435px]:w-[340px] max-[425px]:h-[425px] lg:w-[554px] lg:h-[578px] */}
      <div className="flex flex-col w-[450px] min-w-[250px] rounded-[25px] h-[415px]  bg-black bg-opacity-50 py-10 px-5 md:p-10 max-[435px]:w-[340px] max-[425px]:h-[425px] lg:w-[554px] lg:h-[510px] xl:h-[578px] ">
        <div className="flex flex-row justify-between items-center px-4 xl:px-0">
          <div>
            {/* <WhiteHouseIcon /> */}
            <img
              className="w-[50px] h-[65px] lg:w-[78px] lg:h-[101px]"
              src={whiteHouseIcon}
              alt=""
            />
          </div>
          <div className="flex flex-col items-end ">
            <p className="text-2xl text-white font-bold lg:text-4xl xl:text-5xl">
              Candidates
            </p>
            <p className="text-white text-sm">For The Term 2024</p>
          </div>
        </div>
        <div className="text-white text-l pl-0 mt-5 lg:pl-14 lg:py-5 xl:pl-0 xl:text-xl ">
          Search through our powerful filters to select candidates standing for
          what you believe in
        </div>
        <div className="flex flex-row justify-center items-center mt-10 gap-x-8 lg:mb-10 lg:gap-x-16">
          <div className=" flex flex-col items-center cursor-pointer">
            {/* <OfficeIcon /> */}
            <img className="h-[35px] w-[35px] lg:h-[42px] lg:w-auto" src={officeIcon} alt="" />

            <p className="text-white text-center mt-3 ">Office</p>
          </div>
          <div className="flex flex-col cursor-pointer">
            {/* <BeliefIcon /> */}
            <img className="h-[35px] w-[35px] lg:h-[42px] lg:w-auto" src={beliefIcon} alt="" />
            <p className="text-white text-center mt-3">Belief</p>
          </div>
          <div className="flex flex-col cursor-pointer">
            {/* <PartyIcon /> */}
            <img className="h-[35px] w-[45px] lg:h-[42px] lg:w-auto" src={partyIcon} alt="" />

            <p className="text-white cursor-pointer text-center mt-3">Party</p>
          </div>
          <div className="flex flex-col cursor-pointer">
            {/* <LocalityIcon /> */}
            <img className="h-[35px] w-[35px] lg:h-[42px] lg:w-auto" src={localityIcon} alt="" />
            <p className="text-white text-center mt-3">Locality</p>
          </div>
        </div>
        <div className="flex justify-center items-center mt-10" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
          {/* <Link to="signup"> */}
            <Button label="Sign up" />
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export default CandidateInfoCard;
