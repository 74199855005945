// src/Layout.tsx
import React, { ReactNode } from 'react';
// import Sidebar from '../components/Sidebar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex-1 md:flex-1 lg:flex-1 xl:flex">
      {/* <Sidebar /> */}
      <div>
        {children}
      </div>
    </div>
  );
};

export default Layout;
