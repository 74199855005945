import React, { useState, useEffect } from "react";
import dollarWhite from "../../assets/icons/dollarWhiteIcon.png";
import chart from "../../assets/icons/chartIcon.png";
import top from "../../assets/icons/topIcon.png";
import verify from "../../assets/icons/verifyIcon.png";
import recentActivity from "../../assets/icons/recentActivityIcon.png";
import contribution from "../../assets/icons/contributionIcon.png";
import line from "../../assets/icons/lineIcon.png";
import dottedLine from "../../assets/icons/dottedLineIcon.png";
import upArrow from "../../assets/icons/upArrowIcon.png";
import calendar from "../../assets/icons/calendarWhiteIcon.png";
import add from "../../assets/icons/addIcon.png";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Navbar from "../../components/DashboardNavbar/Navbar";
import Footer from "../../components/Footer";

interface Notification {
  text: string;
  day: string;
  status: "read" | "unread";
}

const DonorDashboard: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([
    { text: "Notification 1", day: "today", status: "unread" },
    { text: "Notification 2", day: "today", status: "unread" },
    { text: "Notification 3", day: "today", status: "unread" },
    { text: "Notification 4", day: "yesterday", status: "read" },
    { text: "Notification 5", day: "yesterday", status: "read" },
  ]);
  useEffect(() => {
    setNotifications(notifications);
  }, []);

  const data = [
    {
      name: "August 2023",
      est: 4000,
      date: 4000,
      amt: 2400,
    },
    {
      name: "September 2023",
      est: 5550,
      date: 5550,
      amt: 2210,
    },
    {
      name: "October 2023",
      est: 4500,
      date: 4500,
      amt: 2290,
    },
    {
      name: "November 2023",
      est: 7000,
      date: 7000,
      amt: 2000,
    },
    {
      name: "December 2023",
      est: 12000,
      date: 1200,
      amt: 2181,
    },
    {
      name: "January 2023",
      est: 13500,
      // date: ``,
      amt: 2500,
    },
    {
      name: "febraury 2023",
      est: 15000,
      // date: ``,
      amt: 2100,
    },
  ];
  // notfications unread count function
  const unreadCount = notifications.filter(
    (notification) => notification.status === "unread"
  ).length;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Navbar />
      <div className="bg-neutral-50 px-12">
        {/* heading */}
        <div className="flex items-center gap-4 py-10">
          <div>
            <span className="text-zinc-800 text-[32px] font-semibold font-['Hind Guntur']">
              Dashboard
            </span>
            <span className="text-red-700 text-[32px] font-semibold font-['Hind Guntur']">
              {" "}
              Statistics
            </span>
          </div>
          <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
            Johnathon Smith Morgan’s Dashboard
          </div>
        </div>
        {/* main section */}
        <div className="flex gap-10">
          {/* left section */}
          <div className="w-[70%]">
            <div className="flex items-center justify-center h-[149px] bg-white rounded-[25px]">
              <div className="flex">
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-2">
                    Total Contributions
                  </div>
                  <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] leading-7">
                    $1,150,206
                  </div>
                </div>
                <div className="border-l-2 border-stone-300 h-auto w-px mx-10"></div>
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-2">
                    Supported Candidates
                  </div>
                  <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] leading-7">
                    6 Candidates
                  </div>
                </div>
                <div className="border-l-2 border-stone-300 h-auto w-px mx-10"></div>
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-2">
                    Supported Committees
                  </div>
                  <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] leading-7">
                    10 Committees
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center py-10 gap-5">
              <img src={chart} alt="" />
              <div className="text-center">
                <span className="text-zinc-800 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
                  Total{" "}
                </span>
                <span className="text-red-700 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
                  Contributions
                </span>
              </div>
            </div>
            {/* Line chart */}
            <div className=" h-[638px] bg-white rounded-[25px] p-5">
              <div className="flex justify-between items-center pb-5">
                <div className="flex items-center gap-5">
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px]">
                    Contributions amount
                  </div>
                  <div className="text-zinc-800 text-[22px] font-semibold font-['Hind Guntur'] leading-relaxed">
                    $1,150,206
                  </div>
                </div>
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                  This Year
                </div>
              </div>
              <hr className="bg-stone-300 h-[2px] mb-8" />
              <div>
                <LineChart
                  width={800}
                  height={450}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="0 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Line
                    type="monotone"
                    dataKey="date"
                    stroke="#CB000E"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    type="monotone"
                    dataKey="est"
                    stroke="#CB000E"
                    strokeDasharray="3 4 5 2"
                  />
                </LineChart>
              </div>
              <div className="pt-4 pr-5">
                <div className="flex justify-end gap-5">
                  <div className="flex items-center gap-3">
                    <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] leading-[27.05px]">
                      Up to date
                    </div>
                    <img src={line} alt="" />
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] leading-[27.05px]">
                      Estimated Expected{" "}
                    </div>
                    <img src={dottedLine} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* right section */}
          <div className="w-[30%]">
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered ? (
                <div className="h-[149px]  bg-white rounded-[25px]">
                  <div className="bg-red-700 rounded-t-[25px] p-2">
                    <div className=" text-white text-lg font-medium font-['Hind Guntur'] leading-snug">
                      January 26th @ 7:00pm Mar-o-Largo, Palm Beach
                    </div>
                  </div>
                  <div className="bg-white rounded-b-[25px] pt-1 px-2 pb-1">
                    <div>
                      <div className="text-slate-600 text-md font-normal font-['Hind Guntur'] leading-[30.06px]">
                        Sponsored by the Rothwell's
                      </div>
                      <div className="flex items-center">
                        <div className=" text-black text-lg font-medium font-['Hind Guntur'] leading-snug">
                          Meet Our Next Governor Holland Morgan
                        </div>
                        <img src={add} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" h-[149px] bg-red-700 rounded-[25px] p-5">
                  <div className="">
                    <div className="flex justify-between">
                      <div className="w-[104px] h-[31px] text-center bg-white rounded-[10px]">
                        <div>
                          <span className="text-zinc-800 text-lg font-semibold font-['Hind Guntur']">
                            Red
                          </span>
                          <span className="text-red-700 text-lg font-semibold font-['Hind Guntur']">
                            Wave
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                          ( 4 Tickets )
                        </div>
                        <div className="text-white text-[28px] font-semibold font-['Hind Guntur'] leading-[33.54px]">
                          $2,500
                        </div>
                      </div>
                    </div>
                    <div className="w-[306px] text-white text-lg font-medium font-['Hind Guntur'] leading-snug">
                      January 26th @ 7:00pm Mar-o-Largo, Palm Beach
                    </div>
                    <div className="flex items-center justify-end gap-3">
                      <img src={calendar} alt="" />
                      <div className="text-white text-lg font-medium font-['Hind Guntur'] leading-[27.05px]">
                        Upcoming Event
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center py-10 gap-5">
              <img src={top} alt="" />
              <div className="text-center">
                <span className="text-zinc-800 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
                  Top{" "}
                </span>
                <span className="text-red-700 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
                  Donations
                </span>
              </div>
            </div>
            {/* rankings */}
            {/* h-[638px] */}
            <div className="h-[638px]  bg-white rounded-[25px] px-5">
              <div className="flex justify-between py-5">
                <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-[30.06px]">
                  Contributed
                </div>
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[27.05px]">
                  6 / 16
                </div>
              </div>
              <hr className="bg-stone-300 h-[2px] mb-10" />

              {/* 1 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #1 Holland Morgan
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $50k Contribution
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      Top
                    </div>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #2 Mellisa Morgan
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $40k Contribution
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      2nd
                    </div>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #3 Emilly Jordan
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $35k Contribution
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      3rd
                    </div>
                  </div>
                </div>
              </div>
              {/* 4 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #4 Anna Hildton
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $25k Contribution
                    </div>
                  </div>
                </div>
              </div>
              {/* 5 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #5 Angelina Freeman
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $20k Contribution
                    </div>
                  </div>
                </div>
              </div>
              {/* 6 */}
              <div className="flex items-start justify-between pb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #6 Anna Hildton
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $10k Contribution
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* recent activity */}
        <div className="my-10 ">
          <div className="flex items-center gap-5 pb-10">
            <img src={recentActivity} alt="" />
            <div className="text-center">
              <span className="text-zinc-800 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
                Recent{" "}
              </span>
              <span className="text-red-700 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
                Activity
              </span>
            </div>
          </div>
          <div>
            <div className=" h-[400px] bg-white rounded-[25px] p-5">
              <div className="flex justify-between pb-5">
                <div className="flex items-center gap-5">
                  <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-[30.06px]">
                    All Notifications
                  </div>
                  <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-normal">
                    {unreadCount} New Notifications
                  </div>
                </div>
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                  Read All Notfications
                </div>
              </div>
              <hr className="bg-stone-300 h-[2px] mb-8" />
              <div className="h-[285px] overflow-y-scroll ">
                {notifications.map((notification, index) => (
                  <div
                    className={`h-24 notification ${
                      index % 2 === 0 ? "bg-neutral-50" : "bg-white"
                    }`}
                  >
                    <div className="flex justify-between px-[20px] pt-[20px] pb-[5px]">
                      <div className="flex items-center gap-3">
                        <img src={contribution} alt="" />
                        <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur']">
                          Contribution Received
                        </div>
                        {notification.status === "unread" && (
                          <div className="w-[9px] h-[9px] bg-red-700 rounded-full"></div>
                        )}
                      </div>
                      <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                        {notification.day}
                      </div>
                    </div>
                    <div
                      key={index}
                      className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal pl-[59px]"
                      // className={`notification ${
                      //   index % 2 === 0 ? "bg-neutral-50" : "bg-white"
                      // }`}
                    >
                      {notification.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* back to top */}
        <div className="flex items-center gap-5 py-20">
          <div>
            <img src={upArrow} alt="" onClick={scrollToTop} />
          </div>
          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
            Back To Top
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DonorDashboard;
