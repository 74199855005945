import React, { useState } from 'react'
import singleTickRed from "../assets/icons/singleTickRedIcon.png"
import doubleTickBlue from "../assets/icons/doubleTickBlueIcon.png"
import addIcon from "../assets/icons/addIcon.png"
import minIcon from "../assets/icons/minIcon.png"

interface TransactionInfo {
    transactionId: string;
  created: string;
  donorName: string;
  amount: string;
  complaint: "complaint" | "pending" | "inReview" ;
  source: string;
  bankStatus: "cleared" | "pending";
  fillingPeriod: string;
  notes: string
  }

const TransactionCard: React.FC<TransactionInfo> = ({transactionId, created, donorName,amount,complaint,source,bankStatus,fillingPeriod,notes}) => {
  const [details, setDetails] = useState(false)

  const handleShowDetails = () => {
    setDetails(!details)
  }

  return (
    <div>
        <div className="w-full h-auto bg-stone-50 rounded-[10px]  px-[5px] py-[10px] my-3">
                    <div className={`${details === true ? "hidden" : "false"}`}>

                 <div className="flex items-center justify-between">
                  <div className="flex gap-x-5">
                  <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">{donorName}</div>
                  <div className="flex items-center gap-x-1">
                    <img src={doubleTickBlue} alt="" />
                    <div className="text-center text-sky-800 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{bankStatus}</div>
                  </div>
                  </div>
                  <div className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{amount} </div>
            </div>

            <hr className="h-[2px] bg-stone-300 my-3" />

              <div className="flex items-center justify-between">
                  <div className="flex items-center">
                  <div className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">Created</div>
                  <div className="border-l-2 border-stone-300 h-[25px] w-px mx-3"></div>
                  <div className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{created}</div>
                  </div>
                  <div className="flex items-center gap-x-3" onClick={handleShowDetails}>
                  <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[18.20px]">More Details</div>
                  <img className="w-[23px] h-[23px]" src={addIcon} alt="" />
                  </div>
              </div>
              </div>
{/* .includes(data.transactionId) */}
              {details && 
               <div>
                <div className="flex flex-col items-start gap-y-3">
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Donor Name : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{donorName}</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Transaction ID : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{transactionId}</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Created : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{created}</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Amount : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{amount}</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Complaint : <span className="text-center text-red-700 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">Feature Coming Soon</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Source : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{source}</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Bank Status : <span className="text-center text-red-700 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{bankStatus}</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Filling Period : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{fillingPeriod}</span></div>
                <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Notes : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{notes}</span></div>

                </div>
            <hr className="h-[2px] bg-stone-300 my-3" />
                  <div className="flex items-center justify-end gap-x-3" onClick={handleShowDetails}>
                  <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[18.20px]">Less Details</div>
                  <img className="w-[23px] h-[23px]" src={minIcon} alt="" />
                  </div>
               </div>
               }
              </div>
    </div>
  )
}

export default TransactionCard