import React, { useState, useEffect } from "react";
import dollarWhite from "../../assets/icons/dollarWhiteIcon.png";
import chart from "../../assets/icons/chartIcon.png";
import top from "../../assets/icons/topIcon.png";
import verify from "../../assets/icons/verifyIcon.png";
import recentActivity from "../../assets/icons/recentActivityIcon.png";
import contribution from "../../assets/icons/contributionIcon.png";
import line from "../../assets/icons/lineIcon.png";
import dottedLine from "../../assets/icons/dottedLineIcon.png";
import upArrow from "../../assets/icons/upArrowIcon.png";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Navbar from "../../components/DashboardNavbar/Navbar";
import Footer from "../../components/Footer";

interface Notification {
  text: string;
  day: string;
  status: "read" | "unread";
}

const Dashboard: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([
    { text: "Notification 1", day: "today", status: "unread" },
    { text: "Notification 2", day: "today", status: "unread" },
    { text: "Notification 3", day: "today", status: "unread" },
    { text: "Notification 4", day: "yesterday", status: "read" },
    { text: "Notification 5", day: "yesterday", status: "read" },
  ]);
  useEffect(() => {
    setNotifications(notifications);
  }, []);

  const data = [
    {
      name: "August 2023",
      est: 4000,
      date: 4000,
      amt: 2400,
    },
    {
      name: "September 2023",
      est: 5550,
      date: 5550,
      amt: 2210,
    },
    {
      name: "October 2023",
      est: 4500,
      date: 4500,
      amt: 2290,
    },
    {
      name: "November 2023",
      est: 7000,
      date: 7000,
      amt: 2000,
    },
    {
      name: "December 2023",
      est: 12000,
      date: 1200,
      amt: 2181,
    },
    {
      name: "January 2023",
      est: 13500,
      // date: ``,
      amt: 2500,
    },
    {
      name: "febraury 2023",
      est: 15000,
      // date: ``,
      amt: 2100,
    },
  ];
  // notfications unread function
  const unreadCount = notifications.filter(
    (notification) => notification.status === "unread"
  ).length;

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setNotifications((prevNotifications) => {
  //       const [first, ...rest] = prevNotifications;
  //       return [...rest, first];
  //     });
  //   }, 3000); // Change 3000 to the desired scroll interval in milliseconds

  //   return () => clearInterval(intervalId);
  // }, []); // Ensure that the dependency array is properly set
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Navbar />
      <div className="bg-neutral-50 px-5 lg:px-12 md:px-12">
        {/* heading */}
        <div className="flex flex-col gap-4 py-10 lg:flex-row lg:items-center md:flex-row md:items-center">
          <div>
            <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] md:text-[32px] lg:text-[32px]">
              Dashboard
            </span>
            <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] md:text-[32px] lg:text-[32px]">
              {" "}
              Statistics
            </span>
          </div>
          <div className=" text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:text-base lg:leading-tight md:text-base md:leading-tight">
            Holland Morgan’s Dashboard
          </div>
        </div>
        {/* main section */}
        {/* <div className="flex gap-10"> */}
          {/* left section */}

          {/* w-[70%] */}
          <div className="lg:w-full">
          {/* first part */}
            <div className="flex flex-wrap gap-5 md:gap-10 lg:gap-10 xl:flex-nowrap">

             <div className="flex w-[100%] items-center justify-center h-[90px] bg-white rounded-[25px] lg:h-[149px] md:h-[149px] xl:w-[70%]">
              <div className="flex  m-3 overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none'] xl:m-0">
                <div>
                  <div className="text-slate-600 w-max text-[15px] font-normal font-['Hind Guntur'] leading-snug lg:text-xl lg:leading-[30.06px] md:text-xl md:leading-[30.06px]">
                    Total Raised
                  </div>
                  <div className="text-zinc-800 text-[17px] font-semibold font-['Hind Guntur'] leading-tight lg:text-2xl lg:leading-7 md:text-2xl md:leading-7">
                    $1,150,206
                  </div>
                </div>
                <div className="border-l-2 border-stone-300 h-auto w-px mx-5"></div>
                <div>
                  <div className="text-slate-600 w-max text-[15px] font-normal font-['Hind Guntur'] leading-snug lg:text-xl lg:leading-[30.06px] md:text-xl md:leading-[30.06px]">
                    Total Spent
                  </div>
                  <div className="text-zinc-800 text-[17px] font-semibold font-['Hind Guntur'] leading-tight lg:text-2xl lg:leading-7 md:text-2xl md:leading-7">
                    $1,020,008
                  </div>
                </div>
                <div className="border-l-2 border-stone-300 h-auto w-px mx-5"></div>
                <div>
                  <div className="text-slate-600 w-max text-[15px] font-normal font-['Hind Guntur'] leading-snug lg:text-xl lg:leading-[30.06px] md:text-xl md:leading-[30.06px]">
                    Pending In
                  </div>
                  <div className="text-zinc-800 text-[17px] font-semibold font-['Hind Guntur'] leading-tight lg:text-2xl lg:leading-7 md:text-2xl md:leading-7">
                    $3,005.00
                  </div>
                </div>
                <div className="border-l-2 border-stone-300 h-auto w-px mx-5"></div>
                <div>
                  <div className="text-slate-600 w-max text-[15px] font-normal font-['Hind Guntur'] leading-snug lg:text-xl lg:leading-[30.06px] md:text-xl md:leading-[30.06px]">
                    Pending Out
                  </div>
                  <div className="text-zinc-800 text-[17px] font-semibold font-['Hind Guntur'] leading-tight lg:text-2xl lg:leading-7 md:text-2xl md:leading-7">
                    $4,250.00
                  </div>
                </div>
                <div className="border-l-2 border-stone-300 h-auto w-px mx-5"></div>
                <div>
                  <div className="text-slate-600 w-max text-[15px] font-normal font-['Hind Guntur'] leading-snug lg:text-xl lg:leading-[30.06px] md:text-xl md:leading-[30.06px]">
                    Total Donors
                  </div>
                  <div className="text-zinc-800 text-[17px] font-semibold font-['Hind Guntur'] leading-tight lg:text-2xl lg:leading-7 md:text-2xl md:leading-7">
                    $1,807
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[100%] xl:w-[30%]">
              <div className=" h-[90px] flex bg-red-700 rounded-[25px] lg:h-[149px] md:h-[149px]">
                <div className="flex flex-col justify-between pl-5 py-5">
                  <div className="flex items-center gap-3">
                    <img src={dollarWhite} alt="" />
                    <div className="text-white text-base font-normal font-['Hind Guntur'] lg:text-xl lg:leading-[30.06px] md:text-xl md:leading-[30.06px]">
                      Cash On Hand
                    </div>
                  </div>
                  <div className="flex items-baseline gap-3">
                    <div className="text-white text-xl font-semibold font-['Hind Guntur'] leading-normal lg:text-[32px] lg:leading-[38.34px] md:text-[32px] md:leading-[38.34px]">
                      $130,198.00
                    </div>
                    <div className="text-white text-base font-medium font-['Hind Guntur'] lg:text-base lg:leading-normal md:text-base md:leading-normal">
                      Total Amount
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>
            
            {/* second part */}

            <div className="flex flex-wrap gap-5 md:gap-10 lg:gap-10 xl:flex-nowrap">
            
            <div className="w-[100%] xl:w-[70%]">

            <div className="flex items-center py-10 gap-5">
              <img src={chart} alt="" />
              <div className="text-center">
                <span className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-normal lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Total{" "}
                </span>
                <span className="text-red-700 text-xl font-semibold font-['Hind Guntur'] leading-normal lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Received
                </span>
              </div>
            </div>
            {/* Line chart */}
            <div className=" h-auto bg-white rounded-[25px] p-5 lg:h-[638px] md:h-[638px]">
              <div className="flex justify-between items-center pb-5">
                <div className="flex items-center gap-5">
                  <div className="text-slate-600 text-sm font-normal font-['Hind Guntur'] leading-[21.04px] lg:text-xl lg:leading-[30.06px] md:text-xl md:leading-[30.06px]">
                    Total contributions received
                  </div>
                  <div className="text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-none lg:text-[22px] lg:leading-relaxed md:text-[22px] md:leading-relaxed">
                    $1,150,206
                  </div>
                </div>
                <div className="hidden text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                  This Year
                </div>
              </div>
              <hr className="bg-stone-300 h-[2px] mb-8" />
              {/* width={800}
                  height={450} */}
              <div className="h-[330px] w-full lg:h-[450px]  md:h-[450px] ">
              <ResponsiveContainer height={"100%"} width={"100%"} >
                <LineChart
                  width={800}
                  height={450}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="0 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Line
                    type="monotone"
                    dataKey="date"
                    stroke="#CB000E"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    type="monotone"
                    dataKey="est"
                    stroke="#CB000E"
                    strokeDasharray="3 4 5 2"
                  />
                </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="pt-4 pr-5">
                <div className="flex justify-end gap-5">
                  <div className="flex items-center gap-3">
                    <div className="text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] lg:text-lg lg:leading-[27.05px] md:text-lg md:leading-[27.05px]">
                      Up to date
                    </div>
                    <img className="w-[20px] md:w-[50px]" src={line} alt="" />
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] lg:text-lg lg:leading-[27.05px] md:text-lg md:leading-[27.05px]">
                      Estimated Expected{" "}
                    </div>
                    <img className="w-[20px] md:w-[50px]" src={dottedLine} alt="" />
                  </div>
                </div>
              </div>
            </div>
            </div>
            
            <div className="w-[100%] xl:w-[30%]">
            <div className="flex items-center py-10 gap-5">
              <img src={top} alt="" />
              <div className="text-center">
                <span className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-normal lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Top{" "}
                </span>
                <span className="text-red-700 text-xl font-semibold font-['Hind Guntur'] leading-normal lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Contributors
                </span>
              </div>
            </div>
            {/* rankings */}

            <div className="flex gap-x-10 justify-between overflow-hidden overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none'] md:hidden lg:hidden">
              {/* contibutor card */}
              <div>
                <div className="w-40 p-3 h-[130px] bg-white rounded-[10px] border border-zinc-100 border-opacity-0">

                  <div className="flex justify-center items-center mb-2 gap-5">
                  <img className="w-12 h-12 rounded-[64.50px]" src="https://via.placeholder.com/48x48" alt="contributor img" />
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      Top
                    </div>
                  </div>
                  </div>

                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] leading-snug mb-2">#1 Holland Morgan</div>
                  <div className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-[17.97px]">$50k Contribution</div>

                </div>
              </div>
                
                <div>
                 <div className="w-40 p-3 h-[130px] bg-white rounded-[10px] border border-zinc-100 border-opacity-0">

                  <div className="flex justify-center items-center mb-2 gap-5">
                  <img className="w-12 h-12 rounded-[64.50px]" src="https://via.placeholder.com/48x48" alt="contributor img" />
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      Top
                    </div>
                  </div>
                  </div>

                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] leading-snug mb-2">#1 Holland Morgan</div>
                  <div className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-[17.97px]">$50k Contribution</div>

                </div> 
                </div>
                
                  <div>
                 <div className="w-40 p-3 h-[130px] bg-white rounded-[10px] border border-zinc-100 border-opacity-0">

                  <div className="flex justify-center items-center mb-2 gap-5">
                  <img className="w-12 h-12 rounded-[64.50px]" src="https://via.placeholder.com/48x48" alt="contributor img" />
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      Top
                    </div>
                  </div>
                  </div>

                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] leading-snug mb-2">#1 Holland Morgan</div>
                  <div className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-[17.97px]">$50k Contribution</div>

                </div>   
                  </div>
                
                  <div>
                   <div className="w-40 p-3 h-[130px] bg-white rounded-[10px] border border-zinc-100 border-opacity-0">

                  <div className="flex justify-center items-center mb-2 gap-5">
                  <img className="w-12 h-12 rounded-[64.50px]" src="https://via.placeholder.com/48x48" alt="contributor img" />
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      Top
                    </div>
                  </div>
                  </div>

                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] leading-snug mb-2">#1 Holland Morgan</div>
                  <div className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-[17.97px]">$50k Contribution</div>

                </div> 
                  </div>
                
                  <div>
                   <div className="w-40 p-3 h-[130px] bg-white rounded-[10px] border border-zinc-100 border-opacity-0">

                  <div className="flex justify-center items-center mb-2 gap-5">
                  <img className="w-12 h-12 rounded-[64.50px]" src="https://via.placeholder.com/48x48" alt="contributor img" />
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      Top
                    </div>
                  </div>
                  </div>

                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] leading-snug mb-2">#1 Holland Morgan</div>
                  <div className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-[17.97px]">$50k Contribution</div>

                </div> 
                  </div>
                

            </div>

            <div className="hidden h-[638px]  bg-white rounded-[25px] px-5 md:block lg:block">
              <div className="flex justify-between py-5">
                <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-[30.06px]">
                  Contributors
                </div>
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[27.05px]">
                  6 / 1807
                </div>
              </div>
              <hr className="bg-stone-300 h-[2px] mb-10" />

              {/* 1 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #1 Holland Morgan
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $50k Contribution
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      Top
                    </div>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #2 Mellisa Morgan
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $40k Contribution
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      2nd
                    </div>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #3 Emilly Jordan
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $35k Contribution
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
                    <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
                      3rd
                    </div>
                  </div>
                </div>
              </div>
              {/* 4 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #4 Anna Hildton
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $25k Contribution
                    </div>
                  </div>
                </div>
              </div>
              {/* 5 */}
              <div className="flex items-start justify-between mb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #5 Angelina Freeman
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $20k Contribution
                    </div>
                  </div>
                </div>
              </div>
              {/* 6 */}
              <div className="flex items-start justify-between pb-8">
                <div className="flex gap-5">
                  <div>
                    <img
                      className="w-12 h-12 rounded-[64.50px]"
                      src="https://via.placeholder.com/48x48"
                      alt="contributor img"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      #6 Anna Hildton
                    </div>
                    <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
                      $10k Contribution
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>

              
            </div>

            {/* third part */}

            <div className="flex flex-wrap gap-5 md:gap-10 lg:gap-10 xl:flex-nowrap">

            {/* recent activity */}
            <div className="w-[100%] my-10 xl:w-[70%]">
              <div className="flex items-center gap-5 pb-10">
                <img src={recentActivity} alt="" />
                <div className="text-center">
                  <span className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-normal lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                    Recent{" "}
                  </span>
                  <span className="text-red-700 text-xl font-semibold font-['Hind Guntur'] leading-normal lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                    Activity
                  </span>
                </div>
              </div>
              <div>
                <div className=" h-[325px] bg-white rounded-[25px] p-5 lg:h-[400px] md:h-[400px]">
                  <div className="hidden justify-between pb-5 lg:flex md:flex">
                    <div className="flex items-center gap-5">
                      <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-[30.06px]">
                        All Notifications
                      </div>
                      <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-normal">
                        {unreadCount} New Notifications
                      </div>
                    </div>
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
                      Read All Notfications
                    </div>
                  </div>
                  <hr className="hidden bg-stone-300 h-[2px] mb-8 lg:block md:block"/>
                  <div className="h-[285px] overflow-y-scroll lg:h-[285px] md:h-[285px] ">
                    {notifications.map((notification, index) => (
                      <div
                        className={`h-24 notification ${
                          index % 2 === 0 ? "bg-neutral-50" : "bg-white"
                        }`}
                      >
                        <div className="flex justify-between px-[20px] pt-[20px] pb-[5px]">
                          <div className="flex items-center gap-3">
                            <img src={contribution} alt="" />
                            <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] lg:text-lg md:text-lg">
                              Contribution Received
                            </div>
                            {notification.status === "unread" && (
                              <div className="w-[9px] h-[9px] bg-red-700 rounded-full"></div>
                            )}
                          </div>
                          <div className="text-slate-600 text-sm font-normal font-['Hind Guntur'] leading-[21.04px] lg:text-base lg:leading-normal md:text-base md:leading-normal">
                            {notification.day}
                          </div>
                        </div>
                        <div
                          key={index}
                          className="text-slate-600 text-sm font-normal font-['Hind Guntur'] leading-[21.04px] pl-[59px] lg:text-base lg:leading-normal md:text-base md:leading-normal"
                          // className={`notification ${
                          //   index % 2 === 0 ? "bg-neutral-50" : "bg-white"
                          // }`}
                        >
                          {notification.text}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* featured profile */}
            <div className="w-[100%] my-5 xl:w-[30%] lg:my-10 md:my-10">
              <div className=" h-auto bg-red-700 rounded-[25px] lg:h-[477px] md:h-[477px]">
                <div className="flex justify-center p-2">
                  {/* [370px] */}
                  <div className="w-full h-[39px]  bg-white rounded-[10px] md:rounded-tl-[17px] md:rounded-tr-[17px] md:rounded-bl-[10px] md:rounded-br-[10px] lg:h-[73px] md:h-[73px]">
                    <div className="flex items-baseline justify-start pl-8 gap-5 lg:items-center lg:pl-0 md:pl-0 md:items-center lg:pt-4 md:pt-4 lg:justify-center md:justify-center">
                      <div>
                        <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-4xl md:text-4xl">
                          Red
                        </span>
                        <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-4xl md:text-4xl">
                          Wave
                        </span>
                      </div>
                      <div className="text-center text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px] lg:text-base lg:leading-tight">
                        Featured Profile
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {/* w-[278px] */}
                  <div className="  text-start px-10 pb-2 lg:pb-5 md:pb-5">
                    <span className="text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px] lg:text-base lg:leading-tight md:text-base md:leading-tight">
                      Promote your Candidate Profile as a{" "}
                    </span>
                    <span className="text-white text-sm font-bold font-['Hind Guntur'] leading-[17.26px] lg:text-base lg:leading-tight md:text-base md:leading-tight">
                      RedWave Featured Profile
                    </span>
                    <span className="text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px] lg:text-base lg:leading-tight md:text-base md:leading-tight">
                      {" "}
                      to gain visibility and take in more donations{" "}
                    </span>
                  </div>
                  <div className=" hidden items-baseline justify-center gap-5 lg:flex md:flex">
                    <div className="text-white text-[64px] font-semibold font-['Hind Guntur']">
                      $19.99{" "}
                    </div>
                    <div className="text-center text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                      Monthly
                    </div>
                  </div>
                  <div className="px-10">
                    <div className="flex items-center gap-5 py-2 lg:py-6 md:py-6">
                      <img src={verify} alt="" />
                      <div className="text-white text-[13px] font-medium font-['Hind Guntur'] lg:text-base md:text-base">
                        Featured Profile For 1 Month
                      </div>
                    </div>

                    <div className="flex items-center gap-5 pb-8">
                      <img src={verify} alt="" />
                      <div className="text-white text-[13px] font-medium font-['Hind Guntur'] lg:text-base md:text-base">
                        No Commitments - Cancel Anytime
                      </div>
                    </div>
                  </div>

                  <div className="hidden lg:block md:block">
                    <button className="w-[183px] h-[39px] bg-white rounded-[19.50px]">
                      <span className="text-center text-red-700 text-base font-medium font-['Hind Guntur'] leading-tight">
                        Upgrade Now!
                      </span>
                    </button>
                  </div>
                  {/* for mobile */}
                  <div className="px-10 pb-5 md:hidden">
                    <div className="flex items-end justify-between ">
                      <div>
                      <div className="text-white text-5xl font-semibold font-['Hind Guntur']">$19.99 </div>
                      <div className="text-end text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">Monthly</div>
                      </div>
                    
                    
                    <div>
                    <button className="w-[106px] h-[31px] bg-white rounded-2xl">
                      <span className="text-red-700 text-sm font-medium font-['Hind Guntur']">
                        Upgrade Now!
                      </span>
                    </button>
                    </div>
                  </div>
                  </div>
                  
                </div>
              </div>
            </div>

            </div>
            

          {/* </div> */}

          

        </div>
        {/* back to top */}
        <div className="flex items-center gap-5 py-8  md:py-20 lg:py-20">
          <div>
            <img
              className="cursor-pointer"
              src={upArrow}
              alt=""
              onClick={scrollToTop}
            />
          </div>
          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
            Back To Top
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;


// {/* right section */}
//           <div className="w-[30%]">
//             <div>
//               <div className=" h-[149px] flex bg-red-700 rounded-[25px]">
//                 <div className="flex flex-col justify-between pl-5 py-5">
//                   <div className="flex items-center gap-3">
//                     <img src={dollarWhite} alt="" />
//                     <div className="text-white text-xl font-normal font-['Hind Guntur'] leading-[30.06px]">
//                       Cash On Hand
//                     </div>
//                   </div>
//                   <div className="flex items-baseline gap-3">
//                     <div className="text-white text-[32px] font-semibold font-['Hind Guntur'] leading-[38.34px]">
//                       $130,198.00
//                     </div>
//                     <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
//                       Total Amount
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

            // <div className="flex items-center py-10 gap-5">
            //   <img src={top} alt="" />
            //   <div className="text-center">
            //     <span className="text-zinc-800 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
            //       Top{" "}
            //     </span>
            //     <span className="text-red-700 text-[32px] font-semibold font-['Hind Guntur'] leading-10">
            //       Contributors
            //     </span>
            //   </div>
            // </div>
            // {/* rankings */}
            // <div className="h-[638px]  bg-white rounded-[25px] px-5">
            //   <div className="flex justify-between py-5">
            //     <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] leading-[30.06px]">
            //       Contributors
            //     </div>
            //     <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[27.05px]">
            //       6 / 1807
            //     </div>
            //   </div>
            //   <hr className="bg-stone-300 h-[2px] mb-10" />

            //   {/* 1 */}
            //   <div className="flex items-start justify-between mb-8">
            //     <div className="flex gap-5">
            //       <div>
            //         <img
            //           className="w-12 h-12 rounded-[64.50px]"
            //           src="https://via.placeholder.com/48x48"
            //           alt="contributor img"
            //         />
            //       </div>
            //       <div className="flex flex-col gap-1">
            //         <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
            //           #1 Holland Morgan
            //         </div>
            //         <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
            //           $50k Contribution
            //         </div>
            //       </div>
            //     </div>
            //     <div>
            //       <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
            //         <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
            //           Top
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   {/* 2 */}
            //   <div className="flex items-start justify-between mb-8">
            //     <div className="flex gap-5">
            //       <div>
            //         <img
            //           className="w-12 h-12 rounded-[64.50px]"
            //           src="https://via.placeholder.com/48x48"
            //           alt="contributor img"
            //         />
            //       </div>
            //       <div className="flex flex-col gap-1">
            //         <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
            //           #2 Mellisa Morgan
            //         </div>
            //         <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
            //           $40k Contribution
            //         </div>
            //       </div>
            //     </div>
            //     <div>
            //       <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
            //         <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
            //           2nd
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   {/* 3 */}
            //   <div className="flex items-start justify-between mb-8">
            //     <div className="flex gap-5">
            //       <div>
            //         <img
            //           className="w-12 h-12 rounded-[64.50px]"
            //           src="https://via.placeholder.com/48x48"
            //           alt="contributor img"
            //         />
            //       </div>
            //       <div className="flex flex-col gap-1">
            //         <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
            //           #3 Emilly Jordan
            //         </div>
            //         <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
            //           $35k Contribution
            //         </div>
            //       </div>
            //     </div>
            //     <div>
            //       <div className="w-[53px] h-8 bg-red-700 rounded-2xl">
            //         <div className="text-white text-center text-sm font-semibold font-['Hind Guntur'] leading-[21.04px] pt-[6px]">
            //           3rd
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   {/* 4 */}
            //   <div className="flex items-start justify-between mb-8">
            //     <div className="flex gap-5">
            //       <div>
            //         <img
            //           className="w-12 h-12 rounded-[64.50px]"
            //           src="https://via.placeholder.com/48x48"
            //           alt="contributor img"
            //         />
            //       </div>
            //       <div className="flex flex-col gap-1">
            //         <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
            //           #4 Anna Hildton
            //         </div>
            //         <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
            //           $25k Contribution
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   {/* 5 */}
            //   <div className="flex items-start justify-between mb-8">
            //     <div className="flex gap-5">
            //       <div>
            //         <img
            //           className="w-12 h-12 rounded-[64.50px]"
            //           src="https://via.placeholder.com/48x48"
            //           alt="contributor img"
            //         />
            //       </div>
            //       <div className="flex flex-col gap-1">
            //         <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
            //           #5 Angelina Freeman
            //         </div>
            //         <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
            //           $20k Contribution
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   {/* 6 */}
            //   <div className="flex items-start justify-between pb-8">
            //     <div className="flex gap-5">
            //       <div>
            //         <img
            //           className="w-12 h-12 rounded-[64.50px]"
            //           src="https://via.placeholder.com/48x48"
            //           alt="contributor img"
            //         />
            //       </div>
            //       <div className="flex flex-col gap-1">
            //         <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-normal">
            //           #6 Anna Hildton
            //         </div>
            //         <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur'] leading-[19.17px]">
            //           $10k Contribution
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            // {/* featured profile */}
            // <div className="my-10">
            //   <div className=" h-[477px] bg-red-700 rounded-[25px]">
            //     <div className="flex justify-center p-2">
            //       {/* [370px] */}
            //       <div className="w-full h-[73px] bg-white rounded-tl-[17px] rounded-tr-[17px] rounded-bl-[10px] rounded-br-[10px]">
            //         <div className="flex items-center justify-center gap-5 pt-4">
            //           <div>
            //             <span className="text-zinc-800 text-4xl font-semibold font-['Hind Guntur']">
            //               Red
            //             </span>
            //             <span className="text-red-700 text-4xl font-semibold font-['Hind Guntur']">
            //               Wave
            //             </span>
            //           </div>
            //           <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
            //             Featured Profile
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //     <div className="text-center">
            //       {/* w-[278px] */}
            //       <div className="  text-start px-10 pb-5">
            //         <span className="text-white text-base font-medium font-['Hind Guntur'] leading-tight">
            //           Promote your Candidate Profile as a{" "}
            //         </span>
            //         <span className="text-white text-base font-bold font-['Hind Guntur'] leading-tight">
            //           RedWave Featured Profile
            //         </span>
            //         <span className="text-white text-base font-medium font-['Hind Guntur'] leading-tight">
            //           {" "}
            //           to gain visibility and take in more donations{" "}
            //         </span>
            //       </div>
            //       <div className="flex items-baseline justify-center gap-5 ">
            //         <div className="text-white text-[64px] font-semibold font-['Hind Guntur']">
            //           $19.99{" "}
            //         </div>
            //         <div className="text-center text-white text-base font-medium font-['Hind Guntur'] leading-tight">
            //           Monthly
            //         </div>
            //       </div>
            //       <div className="px-10">
            //         <div className="flex items-center gap-5 py-6">
            //           <img src={verify} alt="" />
            //           <div className="text-white text-base font-medium font-['Hind Guntur']">
            //             Featured Profile For 1 Month
            //           </div>
            //         </div>

            //         <div className="flex items-center gap-5 pb-8">
            //           <img src={verify} alt="" />
            //           <div className="text-white text-base font-medium font-['Hind Guntur']">
            //             No Commitments - Cancel Anytime
            //           </div>
            //         </div>
            //       </div>

            //       <div>
            //         <button className="w-[183px] h-[39px] bg-white rounded-[19.50px]">
            //           <span className="text-center text-red-700 text-base font-medium font-['Hind Guntur'] leading-tight">
            //             Upgrade Now!
            //           </span>
            //         </button>
            //       </div>
            //     </div>
            //   </div>
            // </div>
//           </div>