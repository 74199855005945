import React, { useState } from 'react';
import MyInfo from './Onboarding/Candidate/CandidateForms/MyInfo';
import { Stepper } from 'react-form-stepper';
import CandidateInfo from './Onboarding/Candidate/CandidateForms/CandidateInfo';

type StepType = {
    label: any;
    // Add more properties if needed
};

const steps: StepType[] = [
    { label: 'Step 1' },
    { label: 'Step 2' },
    { label: 'Step 3' },
    // Add more steps if needed
];

const useSignupStepper = () => {
    const [activeStep, setActiveStep] = useState<any>(1);

    const handleStepChange = (step: StepType) => {
        setActiveStep(step.label);
    };

    const renderStepComponent = () => {
        switch (activeStep) {
            case 1:
                return <MyInfo />;
            case 2:
                return <CandidateInfo />;
            case 3:
            default:
                return null;
        }
    };

    return {
        activeStep,
        handleStepChange,
        renderStepComponent,
    };
};

const StepperComponent: React.FC = () => {
    const { activeStep, handleStepChange, renderStepComponent } = useSignupStepper();

    return (
        <>
            <div className="flex bg-[url('/src/assets/images/Signup.png')]">

                <div className="bg-black md:bg-black lg:bg-black xl:bg-black h-auto md:h-auto lg:h-auto lg:w-[548px] text-white w-[48px] p-6 flex-grow flex-col justify-start items-center">
                    <div className="text-center mt-20 md:mt-20 xl:mt-36 lg:mt-36">

                        <Stepper
                            steps={steps}
                            activeStep={activeStep}
                        // onChange={handleStepChange}
                        />
                    </div>
                </div>
                <div className="hidden md:hidden lg:block items-center justify-center">
                    {renderStepComponent()}
                </div>
            </div>
        </>
    );
};

export default StepperComponent;
