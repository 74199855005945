import React, { useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RedWave from "../../../../assets/images/RedWave.svg";
import Back from "../../../../../assets/images/Back.svg";
import FormFlagMobile from "../../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../../assets/images/RedDot.svg";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { UpdateCandidateForm } from "../../../../../redux/candidate/candidateSlice";
import { API_BASE_URL } from "../../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../../axios/index";
import { setAuthData } from "../../../../../redux/auth/authSlice";

type FormValues = {
  id: string | number;
  political_affiliation: string;
  level: string;
  office: string;
  state: string;
  district: string;
  city: string;
  incumbent: any;
};
type RequestData = {
  political_affiliation: string;
  level: string;
  office: string;
  incumbent: any;
  state?: string; // Optional if level is not "local"
  district?: string; // Optional if level is not "local"
  city?: string; // Optional if level is not "local"
};

function PoliticalAffiliation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);

  const [isInfoVisible, setIsInfoVisible] = useState<any>("no");

  const [UpdateNationalField, setUpdateNationalField] = useState<string | null>(
    null
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const handleRadioChange = (value: string) => {
    setIsInfoVisible(value === "yes" ? "yes" : "no");
  };

  const handleNationalChange = (value: string) => {
    setUpdateNationalField(value);
  };

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    const Isincumbent = data.incumbent === "yes" ? true : false;
    const requestData: RequestData = {
      political_affiliation: data.political_affiliation,
      level: data.level,
      office: data.office,
      incumbent: Isincumbent,
    };
    if (UpdateNationalField === "state") {
      requestData.state = data.state;
    }
    if (UpdateNationalField === "local") {
      requestData.state = data.state;
      requestData.district = data.district;
      requestData.city = data.city;
    }

    try {
      setloading(true);
      const res = await httpRequest.patch(
        `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/political_affiliation`,
        requestData
      );
      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data.candidate,
        };
        dispatch(UpdateCandidateForm("CampaignInfo"));
        dispatch(setAuthData(updatedAuthInfo));
        // toast.success("added successfully!");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (authinfo) {
      const {
        political_affiliation,
        level,
        office,
        state,
        district,
        city,
        incumbent,
      } = authinfo;

      if (political_affiliation !== undefined) {
        setValue("political_affiliation", political_affiliation);
      }
      if (level !== undefined) {
        setValue("level", level);
        setUpdateNationalField(level);
      }
      if (office !== undefined) {
        setValue("office", office);
      }
      if (state !== undefined) {
        setValue("state", state);
      }
      if (district !== undefined) {
        setValue("district", district);
      }
      if (city !== undefined) {
        setValue("city", city);
      }
      if (incumbent !== undefined) {
        setValue("incumbent", incumbent);
        const Iscandidancy = incumbent ? "yes" : "no";
        setIsInfoVisible(Iscandidancy);
      }
    }
  }, [authinfo]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Political Affiliation</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-2 md:py-4 lg:py">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                What is your political affiliation :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <select
                {...register("political_affiliation")}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] px-2 text-[#8C8C8C] font-['Hind Guntur']"
                defaultValue={watch("political_affiliation")}
              >
                <option value="default" disabled selected hidden>
                  Select Political Affiliation
                </option>
                <option value="republican">Republican</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-3 md:py-6 lg:py-6">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-sm md:text-base lg:text-xl font-normal font-['Hind Guntur']"
              >
                Are you running for National, State or Local levels :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="flex flex-wrap flex-row gap-14 mt-4">
                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="national"
                    value="national"
                    {...register("level")}
                    checked={UpdateNationalField === "national"}
                    onChange={() => handleNationalChange("national")}
                  />
                  <label htmlFor="national">National</label>
                </div>

                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="state"
                    value="state"
                    {...register("level")}
                    checked={UpdateNationalField === "state"}
                    onChange={() => handleNationalChange("state")}
                  />
                  <label htmlFor="state">State</label>
                </div>

                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="local"
                    value="local"
                    {...register("level")}
                    checked={UpdateNationalField === "local"}
                    onChange={() => handleNationalChange("local")}
                  />
                  <label htmlFor="local">Local</label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-sm md:text-base lg:text-xl font-normal font-['Hind Guntur']"
              >
                Office :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("office", {
                  required: "Office should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Office"
              />
              {errors.office && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.office.message}
                </span>
              )}
            </div>
            {(UpdateNationalField === "state" ||
              UpdateNationalField === "local") && (
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="field1"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  State :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  {...register("state", {
                    required: "State should not be empty",
                  })}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Enter the state"
                />
                {errors.state && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.state.message}
                  </span>
                )}
              </div>
            )}
          </div>
          {UpdateNationalField === "local" && (
            <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4">
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="field1"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  District/County :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  {...register("district", {
                    required: "District should not be empty",
                  })}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Enter your District or County"
                />
                {errors.district && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.district.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="field1"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  City/Town :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  {...register("city", {
                    required: "City should not be empty",
                  })}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Enter your City or Town"
                />
                {errors.city && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.city.message}
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-3 md:py-6 lg:py-6">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-sm md:text-base lg:text-xl font-normal font-['Hind Guntur']"
              >
                I am an incumbent and currently hold the position :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="flex flex-wrap flex-row gap-14 mt-12">
                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="yes"
                    value="yes"
                    {...register("incumbent")}
                    checked={isInfoVisible === "yes"}
                    onChange={() => handleRadioChange("yes")}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>

                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="no"
                    value="no"
                    {...register("incumbent")}
                    checked={isInfoVisible === "no"}
                    onChange={() => handleRadioChange("no")}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap py-2 md:py-6 lg:py-6 xl:py-6 justify-end gap-40 md:gap-32 lg:gap-40 xl:gap-40">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("CandidateInfo"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button type="button" className="hidden sm:inline-block">
                Back
              </button>
            </div>
            <div className="flex flex-nowrap justify-between gap-4 sm:gap-4 items-center">
              <p className="text-zinc-800 text-[18px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Political Affiliation
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default PoliticalAffiliation;
