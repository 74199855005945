import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UpdateDonorForm } from "../../../../redux/donor/donorSlice";

interface SidebarItemProps {
  title: string;
  icon: string;
  selectedIcon: string;
  path: string;
}

const DonorSidebarItem: React.FC<SidebarItemProps> = ({
  title,
  icon,
  selectedIcon,
  path,
}) => {
  const location = useLocation();
  const donorState = useAppSelector((state: any) => state.donor.DonorFlow);
  const dispatch = useAppDispatch();

  const getIcon = (title: string): any => {
    const formattedTitle = title.replace(/\s+/g, "");
    const filterText = formattedTitle === "MyInfo" ? "myinfo" : formattedTitle;
    const committee = donorState[filterText];
    if (committee && committee.fill) {
      //   return selectedIcon;
      return true;
    }

    // return icon;
    return false;
  };

  const isAvailable: any = getIcon(title);

  const handelform = (title: string) => {
    const formattedTitle = title.replace(/\s+/g, "");
    const filterText = formattedTitle === "MyInfo" ? "myinfo" : formattedTitle;
    const committee = donorState[filterText];
    if (committee && committee.fill) {
      dispatch(UpdateDonorForm(filterText));
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="flex items-center p-2">
      <div
        className="flex items-center gap-6 justify-start cursor-pointer"
        onClick={() => handelform(title)}
      >
        <img
          src={isAvailable ? selectedIcon : icon}
          alt="icon"
          className={`flex-shrink-0 `}
        />
        <div className={`text-justify `}>{title}</div>
      </div>
    </div>
  );
};

export default DonorSidebarItem;
