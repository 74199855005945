import React, { useState } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import RedWave from '../assets/images/RedWave.svg';
import RedwaveLogo from '../components/icons/RedwaveLogo';
import Campaigns from "../assets/icons/Campaigns.svg";
import Donors from "../assets/icons/Donors.svg";
import Banks from "../assets/icons/Banks.svg";
import Payments from "../assets/icons/Payments.svg";
import Reporting from '../assets/icons/Reporting.svg';
import RedWaveMobile from '../assets/icons/RedWaveMobile.svg';


type FormValues = {
    email: string
}

const iconsData = [
    { id: 1, text: 'Campaigns', icon: Campaigns },
    { id: 2, text: 'Donors', icon: Donors },
    { id: 3, text: 'Banks', icon: Banks },
    { id: 4, text: 'Payments', icon: Payments },
    { id: 5, text: 'Reporting', icon: Reporting },
];


function ForgotPassword() {
    const { register, handleSubmit } = useForm<FormValues>()
    const onSubmit: SubmitHandler<FormValues> = (data) => console.log(data)
    const navigate = useNavigate();

    return (
        <div className="flex bg-[url('/src/assets/images/AuthForm.png')] bg-center bg-cover">
            <div className="hidden md:hidden lg:block items-center justify-center  ">
                <div className="flex-1 w-[970px] px-20 py-32">
                    <RedwaveLogo />
                    <span className='text-white text-xl font-medium'>
                        America’s existing campaign and contribution management platforms are <br />
                        decades old and broken at best. Learn how to more effectively set-up your <br />
                        campaign, manage contributions, and offer an intuitive means for donors <br />
                        to find like minded candidates.
                    </span>
                    <div className='py-14'>
                        <span className='text-white text-2xl font-bold'>The RedWave Ecosystem Connects</span>
                    </div>
                    <div className="flex flex-row gap-14">
                        {iconsData.map((icon) => (
                            <div key={icon.id} className="flex flex-col items-center">
                                <div className={`p-3 rounded-full ${icon.icon} text-white`}>
                                    <img src={icon.icon} alt={icon.icon} className="w-16 h-16" />
                                </div>
                                <p className="text-center mt-2 text-white text-2xl">{icon.text}</p>
                            </div>
                        ))}
                    </div>
                    <div className='py-12'>
                        <div>
                            <span className='text-red-700 text-xl font-bold'>1000+ </span><span className='text-white text-xl font-bold'>Committees, Campaigns, Donors, Financial Institutions, and Regulators <br /> are already with </span><span className='text-red-700 text-xl font-bold'>RedWave.</span>
                        </div>
                    </div>
                    <div className='py-4'>
                        <span className='text-white text-xl font-medium'>Join Now to explore more about your favourite Candidates</span>
                    </div>
                </div>
            </div>
            <div className="bg-black bg-opacity-50 h-auto md:h-auto lg:h-auto text-white w-[48px] p-6 flex-grow flex-col justify-start items-center">
                <div className="text-center mt-4">
                    <div className="flex flex-wrap items-center justify-center">
                        <img
                            src={RedWave}
                            alt="RedWave"
                            className="w-64 h-56 hidden md:block lg:block"
                        />
                        <img
                            src={RedWaveMobile}
                            alt="RedWaveMobile"
                            className="block md:hidden lg:hidden"
                        />
                    </div>
                    <p className="text-white text-base mt-4 block md:block lg:hidden font-bold font-['DM Sans']">The RedWave Ecosystem Connects</p>
                    <div className="flex flex-row gap-2 justify-center mt-4 md:mt-4 lg:mt-0">
                        {iconsData.map((icon) => (
                            <div key={icon.id} className="flex flex-col items-center">
                                <div className={`p-3 rounded-full ${icon.icon} text-white block md:block lg:hidden`}>
                                    <img src={icon.icon} alt={icon.icon} className="w-16 h-16" />
                                </div>
                                <p className="text-center mt-2 text-white text-xs md:text-md lg:text-2xl block md:hidden lg:hidden">{icon.text}</p>
                            </div>
                        ))}
                    </div>
                    <p className="text-center text-white text-base mt-4 block font-medium font-['Hind Guntur'] block md:block lg:hidden">Join Now to explore more about your favourite Candidates</p>
                    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-center items-center gap-5 p-7'>
                        <input
                            {...register("email")}
                            className="w-80 h-12 p-4 bg-transparent focus:outline-none border text-white text-sm font-medium rounded-xl mt-14 md:mt-12 lg:mt-0"
                            placeholder="Enter your email address"
                        />
                        <p className='text-red-600 text-base font-medium'>The email associated with your account</p>
                        <div className="flex items-center justify-between pt-20 gap-6">
                            <button className="flex justify-center items-center bg-black-rgba text-white border border-blue-400 rounded-3xl p-2 gap-2 w-36 h-11">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0625 10.1562H12.3438V14.8438H19.2188C18.5938 18.125 15.625 20.3125 12.3438 20.1562C8.28125 20 5.15625 16.875 5 12.8125C4.84375 8.59375 8.125 5 12.3438 4.84375C14.0625 4.84375 15.7812 5.46875 17.1875 6.71875L20.7812 3.28125C18.5938 1.09375 15.4688 0 12.5 0C5.625 0 0 5.625 0 12.5C0 19.375 5.625 25 12.5 25C19.6875 25 24.5312 20 24.5312 12.8125C24.375 11.875 24.375 11.0938 24.0625 10.1562Z" fill="white" />
                                </svg>
                                <span className='text-sm font-medium'>Sign In</span>
                            </button>

                            <button className="flex justify-center items-center bg-black-rgba text-white border border-blue-400 rounded-3xl p-2 gap-2 w-36 h-11">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none">
                                    <path d="M17.5493 23.997C16.1883 25.3162 14.7024 25.1079 13.272 24.483C11.7583 23.8442 10.3696 23.8164 8.77261 24.483C6.77286 25.344 5.71744 25.0941 4.52315 23.997C-2.25377 17.0117 -1.2539 6.3742 6.43957 5.98536C8.31434 6.08257 9.61973 7.013 10.7168 7.09633C12.3555 6.76303 13.9247 5.80482 15.6745 5.92981C17.7715 6.09645 19.3546 6.92968 20.3961 8.42949C16.0634 11.0264 17.091 16.734 21.0627 18.331C20.2712 20.4141 19.2435 22.4833 17.5354 24.0109L17.5493 23.997ZM10.5779 5.90203C10.3696 2.8052 12.8832 0.249968 15.7717 0C16.1745 3.58288 12.5221 6.24921 10.5779 5.90203Z" fill="white" />
                                </svg>
                                <span className='text-sm font-medium'>Sign In</span>
                            </button>
                        </div>
                        <div className='pt-8'>
                            <input type="submit" value="Next" className='w-80 h-12 bg-red-600 rounded-3xl text-xl font-medium' />
                        </div>
                        <div className="flex flex-col md:flex-row lg-flex-row items-center justify-between gap-2">
                            <span className='text-base font-medium'>Don’t have an account yet?</span>
                            <button onClick={() => navigate('/signup')} className='text-xl font-semibold text-red-600'>Sign Up</button>
                        </div>
                        <div className="flex items-center justify-between py-5 gap-4">
                            <span className='text-sm font-normal'>Privacy Policy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
                            </svg>
                            <span className='text-sm font-norma'>Terms of use</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
