import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

const backendURL = 'http://redwavelb-1722554144.us-east-1.elb.amazonaws.com/api'

const userToken: string | null = localStorage.getItem('userToken');
let userSlug: any = null;
console.log("🚀 ~ file: candidateSlice.ts:93 ~ userSlug:", userSlug)
// const slug: string | null = localStorage.getItem('slug');

// console.log("slug", slug);

type CommitteeState = {
    committeeValue: string | null;
    // role: string,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    // data: [],
    error: string,
    // formData: string
    // user: string
}

const initialState: CommitteeState = {
    committeeValue: null,
    // role: '',
    loading: 'idle',
    // data: [],
    error: '',
    // formData: '',
    // user: ''
}


export const committeeUserInfo = createAsyncThunk(
    'committee/user-info', async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            }
            const response = await axios.get(`${backendURL}/users`, config)
            // console.log("asd", response?.data?.user?.candidate_details.map((item: { slug: any; }) => item.slug));
            // return response?.data?.user?.candidate_details.map((item: { slug: any; }) => item.slug);

            // Update the userSlug variable with the retrieved slug
            userSlug = response?.data?.user?.candidate_details.map((item: { slug: any; }) => item.slug);

            console.log("asd", userSlug);
            return userSlug;
        } catch (error) {
            console.log("error", error);
        }
    }
)


export const committeeUserRole = createAsyncThunk(
    'role/committee-role',
    async ({ role }: { role: string }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const response = await axios.patch(`${backendURL}/users`, { role }, config);

            // Assuming your API returns the updated role in the response
            const updatedCommitteeRole = response.data.role;

            // Return the updated role to be used as the payload in the 'fulfilled' action
            console.log("update committee role", updatedCommitteeRole);
            return updatedCommitteeRole;

        } catch (error) {
            console.log("error", error);

            // Handle errors and reject with a custom error message if needed
            // return rejectWithValue(error.message);
        }
    }
);

export const committeeMyInfo = createAsyncThunk(
    'committee/committee-my-info',
    async ({ first_name, last_name, phone_number, title, visible }:
        { first_name: string, last_name: string, phone_number: string, title: string, visible: boolean }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            }
            const response = await axios.post(
                `${backendURL}/onboarding/my_info`,
                { first_name, last_name, phone_number, title, visible },
                config
            )
            console.log("asd", response);

            return response.data;
        } catch (error) {
            // console.log("error", error);

            // // // return custom error message from backend if present
            // if (response && response.data.message) {
            //   return rejectWithValue(error.response.data.message)
            // } else {
            //   return rejectWithValue(error.message)
            // }
        }
    }
)


export const committeeInfo = createAsyncThunk(
    'committee/committee-info',
    async ({ id, type, name, email, phone_number, address, website }:
        { id: string | number, type: string, name: string, email: string, phone_number: string, address: string, website: string }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            }
            const response = await axios.post(
                `${backendURL}/onboarding/committee/0/info`,
                { id, type, name, email, phone_number, address, website },
                config
            )
            console.log("🚀 ~ file: candidateSlice.ts:82 ~ response:", response)
            return response.data;
        } catch (error) {
            // console.log("error", error);

            // // // return custom error message from backend if present
            // if (response && response.data.message) {
            //   return rejectWithValue(error.response.data.message)
            // } else {
            //   return rejectWithValue(error.message)
            // }
        }
    }
)


export const committeeComInfo = createAsyncThunk(
    'committee/committee-company-info',
    async ({ id, registered_business, business_name, business_type, business_tax_id }:
        { id: string | number, registered_business: string, business_name: string, business_type: string, business_tax_id: string }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            }
            const response = await axios.patch(
                `${backendURL}/onboarding/committee/${userSlug}/company_info`,
                { id, registered_business, business_name, business_type, business_tax_id },
                config
            )
            console.log("asd", response);

            return response.data;
        } catch (error) {
            // console.log("error", error);

            // // // return custom error message from backend if present
            // if (response && response.data.message) {
            //   return rejectWithValue(error.response.data.message)
            // } else {
            //   return rejectWithValue(error.message)
            // }
        }
    }
)

export const committeeBiography = createAsyncThunk(
    'committee/committee-biograpy',
    async ({ id, bio }:
        { id: string | number, bio: string }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            }
            const response = await axios.patch(
                `${backendURL}/onboarding/committee/${userSlug}/biography`,
                { id, bio },
                config
            )
            console.log("asd", response);

            return response.data;
        } catch (error) {
            // console.log("error", error);

            // // // return custom error message from backend if present
            // if (response && response.data.message) {
            //   return rejectWithValue(error.response.data.message)
            // } else {
            //   return rejectWithValue(error.message)
            // }
        }
    }
)



export const committeeIssues = createAsyncThunk(
    'committee/committee-issue-info',
    async ({ id, issues_description, issues }:
        { id: string | number, issues_description: string, issues: Array<string> }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            }
            const response = await axios.patch(
                `${backendURL}/onboarding/committee/${userSlug}/issues`,
                { id, issues_description, issues },
                config
            )
            console.log("asd", response);

            return response.data;
        } catch (error) {
            console.error("error", error);

            // // // return custom error message from backend if present
            // if (response && response.data.message) {
            //   return rejectWithValue(error.response.data.message)
            // } else {
            //   return rejectWithValue(error.message)
            // }
        }
    }
)

export const committeeMedia = createAsyncThunk(
    'committee/committee-social-media',
    async ({ id, social_media_links }:
        { id: string | number, social_media_links: Array<{ name: string, username: string }> }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            }
            const response = await axios.patch(
                `${backendURL}/onboarding/committee/${userSlug}/social_media`,
                { id, social_media_links },
                config
            )
            console.log("asd", response);

            return response;
        } catch (error) {
            console.error("error", error);
        }
    }
)

export const committeeSlice = createSlice({
    name: 'committee',
    initialState,
    reducers: {
        updateCommitteeRole: (state, action: PayloadAction<string>) => {
            state.committeeValue = action.payload;
        },
        setFormData: (state, action) => {
            // state.formData = action.payload;
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(myInfo.pending, (state) => {
        //     state.loading = 'pending';
        // })
        // builder.addCase(myInfo.fulfilled, (state, action: PayloadAction<any>) => {
        //     state.loading = 'succeeded'
        //     state.data = action.payload;
        //     // state.error = ''
        //     console.log('Data fetched successfully:', action.payload);
        // })
        // builder.addCase(myInfo.rejected, (state, action) => {
        //     state.loading = 'failed'
        //     // state.data = action.payload;
        //     // state.error = ''
        //     // console.log('Data fetched successfully:', action.payload);
        // });
        // builder.addCase(committeeUserRole.pending, (state) => {
        //     state.loading = 'pending';
        // })
        // builder.addCase(committeeUserRole.fulfilled, (state, action: PayloadAction<any>) => {
        //     state.loading = 'succeeded'
        //     state.committeeValue = action.payload;
        //     // state.error = ''
        //     console.log('Data fetched successfully:', action.payload);
        // })
        // builder.addCase(committeeUserRole.rejected, (state, action) => {
        //     state.loading = 'failed'
        //     // state.data = action.payload;
        //     // state.error = ''
        //     // console.log('Data fetched successfully:', action.payload);
        // });
    },
})

// Action creators are generated for each case reducer function
export const { updateCommitteeRole, setFormData } = committeeSlice.actions
export const selectFormData = (state: { committee: any; }) => state.committee;

export default committeeSlice.reducer