import React from 'react'

const Energy = ({color}) => {
  return (
    <svg width="40" height="47" viewBox="0 0 40 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.0639 15.7234H22.213L30.1532 0H17.4515L7.85156 23.5845H17.4615L9.34202 46.5936L32.0639 15.7234Z" fill={color}/>
<path d="M14.4961 41.582L16.0074 39.529C20.1012 40.4199 24.3792 39.798 28.05 37.7785C31.7208 35.7589 34.536 32.4784 35.975 28.5436C37.4139 24.6088 37.3792 20.2859 35.8773 16.3748C34.3753 12.4636 31.5077 9.22861 27.805 7.2683L28.8664 5.16602C33.1599 7.42651 36.4676 11.1926 38.155 15.7421C39.8423 20.2915 39.7904 25.3038 38.0093 29.8173C36.2282 34.3308 32.8433 38.0277 28.5039 40.1989C24.1645 42.3701 19.1763 42.8627 14.4961 41.582Z" fill={color}/>
<path d="M14.7437 3.53125L13.591 6.36321C10.498 7.5259 7.80164 9.54828 5.81968 12.1923C3.83771 14.8362 2.65267 17.9916 2.40415 21.2866C2.15563 24.5816 2.85399 27.8789 4.41696 30.7903C5.97992 33.7016 8.34239 36.1057 11.226 37.7192L10.432 39.9686C6.93286 38.1044 4.07801 35.2272 2.24104 31.7136C0.404065 28.2001 -0.329463 24.2139 0.136444 20.2766C0.602351 16.3393 2.24599 12.6344 4.85227 9.64659C7.45855 6.65883 10.9061 4.52736 14.7437 3.53125Z" fill={color}/>
</svg>
  )
}

export default Energy

