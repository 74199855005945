// Modal.tsx
import React, {useState } from "react";
import leftArrow from "../assets/icons/leftArrowIcon.png";
import speaker from "../assets/icons/speakerIcon.png";
import IconSound from "../components/icons/landing_page/IconSound";
import IconDonation from "../components/icons/landing_page/IconDonation";
import IconTracking from "../components/icons/landing_page/IconTracking";
import IconDemand from "../components/icons/landing_page/IconDemand";
import IconCollection from "../components/icons/landing_page/IconCollection";
import IconDollar from "../components/icons/landing_page/IconDollar"

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Features {
  content: {
    icon: string | any;
    text: string;
    description: string;
  };
}
// Committies and Candidate features/description

const features: Features[] = [
  {
    content: {
      text: "Exposure to Contributors and Voters",
      icon: IconSound,
      description: "Gain exposure to contributors and donors for your committees, PACs, and campaigns. RedWave helps match long lasting supporters that believe in the same values as your campaign.",
    },
  },
  {
    content: {
      text: "Custom Donation Collection Widget",
      icon: IconCollection,
      description: "Campaigns and committees can tailor the contribution dollar values, payment acceptance, and deploy a full-blown payment processing gateway directly onto your existing website.",
    },
  },
  {
    content: {
      text: "Real-time Donation Processing",
      icon: IconDonation,
      description: "Accept donations and offer the ability for donors to cover the credit card processing fees.Offer real time ACH, pay by card, or electronic check. RedWave supports the following for of payments:",
    },
  },
  {
    content: {
      text: "Contribution Limits Tracking",
      icon: IconTracking,
      description: "Taking in large contributions is always the goal of a successfully operated committee or campaign. RedWave helps let you know if a committee, PAC, or donor has reached their contribution limits.",
    },
  },
  {
    content: {
      text: "On-Demand Reporting",
      icon: IconDemand,
      description: "Taking in large contributions is always the goal of a successfully operated committee or campaign. RedWave helps let you know if a committee, PAC, or donor has reached their contribution limits.",
    },
  },
  {
    content: {
      text: "Keep 100 (%) of the Money That You Take In",
      icon: IconDollar,
      description: "RedWave is the only free ($0.00) platform for effectively operating your initiative, as well as enabling donors and contributors to pay for their payment  processing fees. Make certain you keep 100 percent (%) of each and every contribution.",
    },
  },
];

const FeatureModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [selectedFeature, setSelectedFeature] = useState<Features>(features[0]);
  // const [isMobile, setIsMobile] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  //choose the screen size
  // const handleResize = () => {
  //   if (window.innerWidth < 720) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // };

  // create an event listener
  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  // }, []);

  const handleFeatureClick = (feature: Features) => {
    setSelectedFeature(feature);
  };

  const handleClick = (index: number) => {
    if (selectedOption === index) {
      // Close the accordion if the same option is clicked again
      setSelectedOption(null);
    } else {
      // Open the accordion for the selected option
      setSelectedOption(index);
    }
  };

  // const reorderedOptions = selectedOption
  //   ? [
  //       features[selectedOption],
  //       ...features.slice(0, selectedOption),
  //       ...features.slice(selectedOption + 1),
  //     ]
  //   : features;

  return (
    <>
      {/*  bg-opacity-75 mix-blend bg-black */}
      {/* inset-0 bg-gradient-to-b from-black to-black */}
      {isOpen && (
        // fixed bg-opacity-75 mix-blend bg-black rounded-[25px] h-[578px] w-[20%] visible lg:mx-[100px] lg:fixed lg:w-auto
        <div className=" bg-opacity-75 mix-blend bg-black rounded-[25px] w-full h-[578px] visible xl:mx-[100px] xl:fixed xl:w-auto">
          {/* {isMobile ? ( */}
          {/* // for mobile */}
          <div className="block w-full  h-[578px] lg:w-[366px] xl:hidden">
            <div>
              <div
                className="flex items-center text-white gap-4 p-5 cursor-pointer"
                onClick={onClose}
              >
                <img src={`${leftArrow}`} alt="" />
                <span>Back</span>
              </div>
              <div className="flex flex-col items-center text-5xl text-white gap-4 p-5">
                <img className="w-[73px] h-[59px]" src={`${speaker}`} alt="" />
                <div className="py-[20px] text-center text-white text-[22px] font-bold font-['DM Sans'] tracking-wide">
                  Committies & Candidates
                </div>
              </div>
            </div>
            <div className="max-w-lg w-[90%] mx-auto pb-5">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className={` ${
                    selectedOption !== null && selectedOption !== index
                      ? "hidden"
                      : ""
                  }`}
                >
                  <button
                    className={` bg-black-500 text-white p-2 text-left ${
                      selectedOption === index ? "rounded-t-md" : ""
                    }`}
                    onClick={() => handleClick(index)}
                  >
                    {/* items-center  */}
                    <div className="flex items-start gap-x-5">
                      <feature.content.icon className="w-[15px]"/>

                      {feature.content.text}
                    </div>
                  </button>
                  {selectedOption === index && (
                    
                     <div className="bg-red-700 text-white p-4 h-[100%] rounded-[25px]">
                      {features[index].content.description}
                    </div> 
                  
                    
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* // ) : ( */}
          {/* //   <> */}
          {/* for laptop */}
          <div className="hidden w-[1216px] h-[578px] xl:block">
            <div className="flex  items-center justify-between px-8">
              <div
                className="flex items-center text-white gap-4 p-5 cursor-pointer"
                onClick={onClose}
              >
                <img src={`${leftArrow}`} alt="" />
                <span>Back</span>
              </div>
              <div className="flex items-center text-5xl text-white font-['DM Sans'] tracking-wide gap-4 p-5">
                <img src={`${speaker}`} alt="" />
                <div>Committies & Candidates</div>
              </div>
            </div>
            <div className="flex  items-center justify-center text-white px-8 py-12 max-w-fit w-full">
              <div className="flex justify-center gap-10">
                <div className=" bg-opacity-60 mix-blend bg-black rounded-[25px] w-[50%] ">
                  <div className="p-[15px]">
                    {features.map((feature, index) => (
                      <div
                        key={index}
                        className={`cursor-pointer py-2 pl-4 ${
                          selectedFeature.content.text === feature.content.text
                            ? "bg-red-700 rounded-[21px]"
                            : ""
                        }`}
                        onClick={() => handleFeatureClick(feature)}
                      >
                        <div className="flex items-center gap-4">
                          <feature.content.icon />
                          <div className="text-xl font-medium font-['Hind Guntur'] leading-relaxed">
                            {feature.content.text}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-red-700 rounded-[25px]  p-[15px] w-[50%]">
                  <div>
                    {selectedFeature !== null && (
                      <>
                        <div className="flex items-center mb-2">
                          <selectedFeature.content.icon />
                          <div className="ml-2 text-[22px] font-semibold font-['Hind Guntur'] leading-7 py-2">
                            {selectedFeature.content.text}
                          </div>
                        </div>
                        <div className="text-lg font-medium font-['Hind Guntur'] leading-[28.89px]">
                          {selectedFeature.content.description}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* // </> */}
          {/* )} */}
        </div>
      )}
    </>
  );
};

export default FeatureModal;
