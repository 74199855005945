import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../../assets/images/Back.svg";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { UpdateDonorForm } from "../../../../redux/donor/donorSlice";
import { API_BASE_URL } from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../axios/index";
import { setAuthData } from "../../../../redux/auth/authSlice";

type FormValues = {
  id: string | number;
  type: string;
  political_affiliation: string;
  address: string;
  occupation: string;
  title: string;
  employer: string;
  ssn_last4: string;
};

function ContributorInfo() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setloading] = useState<boolean>(false);
  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const DonerState = useAppSelector((state: any) => state.auth.DonerData);
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const handleRadioChange = (value: string) => {
    setIsInfoVisible(value === "yes");
  };

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    try {
      setloading(true);
      const res = await httpRequest.post(
        `${API_BASE_URL}/onboarding/doner/0/contributor_info`,
        {
          type: data.type,
          political_affiliation: data.political_affiliation,
          address: data.address,
          occupation: data.occupation,
          title: data.title,
          employer: data.employer,
          ssn_last4: data.ssn_last4,
        }
      );
      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data.doner,
        };
        dispatch(UpdateDonorForm("Issues"));
        dispatch(setAuthData(updatedAuthInfo));
        toast.success("added successfully!");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between w-full h-full px-12">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">My Info</p>
          </div>
        </div>
      </div>

      {/* <div className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden">
                <div className="flex md:flex md:justify-between lg:justify-between lg-flex items-center justify-between h-full px-12 lg:block xl:hidden">
                    <div className='block flex-row gap-4 pt-4 sm:block md:flex-row lg:flex-row lg-hidden xl:hidden'>
                        <img src={Back} alt="Back" />
                        <p className="text-slate-600 text-base font-medium">My Info</p>
                    </div>
                    <div className='flex flex-row gap-4 pt-3'>
                        <p className="text-slate-600 text-sm font-medium">Complete Profile</p>
                        <img src={RedDot} alt="RedDot" />
                    </div>
                </div>
            </div> */}

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex sm:flex-col flex-wrap gap-6 md:gap-8 lg:gap-12 md:flex-row md:flex-nowrap md:overflow-x-hidden md:justify-center lg:justify-start lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                What type of Donor/Campaign Contributor are you :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <select
                {...register("type", {
                  required: "Please select a Campaign Contributor",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] px-2 text-[#8C8C8C] font-['Hind Guntur']"
                value=""
              >
                <option value="default" disabled selected hidden>
                  Select Political Affiliation
                </option>
                <option value="republican">Republican</option>
              </select>
              {errors.type && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.type.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-col flex-wrap gap-6 md:gap-8 lg:gap-12 md:flex-row md:justify-start lg:flex-row mt-4">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                What is your political affiliation :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <select
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] px-2 text-[#8C8C8C] font-['Hind Guntur']"
                value=""
                {...register("political_affiliation", {
                  required: "Please select  Select Political Affiliation",
                })}
              >
                <option value="default" disabled selected hidden>
                  Select Political Affiliation
                </option>
                <option value="republican">Republican</option>
              </select>
              {errors.political_affiliation && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.political_affiliation.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-1 mt-2 md:mt-4 lg:mt-4 xl:mt-4">
            <label
              htmlFor="field1"
              className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
            >
              Home address :{" "}
              <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                *
              </span>
            </label>
            <input
              type="text"
              {...register("address", {
                required: "address should not be empty",
              })}
              className="mt-2 w-[688px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
              placeholder="Enter your home address"
            />
            {errors.address && (
              <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                {errors.address.message}
              </span>
            )}
          </div>

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-3 md:py-6 lg:py-6">
            <div className="flex sm:flex-col flex-wrap gap-6 md:gap-8 lg:gap-12 md:flex-row md:flex-nowrap md:overflow-x-hidden md:justify-center lg:justify-start lg:flex-row">
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="field1"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  Occupation :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  {...register("occupation", {
                    required: "Occupation should not be empty",
                  })}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Ex: Marketer"
                />
                {errors.occupation && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.occupation.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="field2"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  Your functional role or job title :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  {...register("title", {
                    required: "title should not be empty",
                  })}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Ex: VP Customer Success"
                />
                {errors.title && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.title.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-3 md:py-6 lg:py-6">
            <div className="flex sm:flex-col flex-wrap gap-6 md:gap-8 lg:gap-12 md:flex-row md:flex-nowrap md:overflow-x-hidden md:justify-center lg:justify-start lg:flex-row">
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="field1"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  Employer :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  {...register("employer", {
                    required: "employer should not be empty",
                  })}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Ex: Self Employed"
                />
                {errors.title && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.title.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="field2"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  Your SSN (Last 4 digits) :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  // {...register("ssn_last4", {
                  //   required: "ssn should not be empty",
                  // })}
                  {...register("ssn_last4", {
                    required: "SSN should not be empty",
                    pattern: {
                      value: /^\d{4}$/,
                      message: "Enter a valid 4-digit number",
                    },
                  })}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Enter your last 4 digits of SSN"
                />
                {errors.ssn_last4 && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.ssn_last4.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div className="flex flex-wrap gap-2 text-base font-medium cursor-pointer">
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button className="hidden sm:inline-block">Back</button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[24px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                My Info
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
              >
                {loading ? "loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContributorInfo;
