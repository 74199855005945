import React, { useEffect, useState } from "react";
import Navbar from "../components/DashboardNavbar/Navbar";
import searchIcon from "../assets/icons/navbar/searchIcon.png";
import rightArrow from "../assets/icons/rightArrowIcon.png";
import sortIcon from "../assets/icons/sortIcon.png";
import doubleTick from "../assets/icons/doubleTickIcon.png";
import doubleTickBlue from "../assets/icons/doubleTickBlueIcon.png"
import singleTick from "../assets/icons/singleTickIcon.png";
import singleTickRed from "../assets/icons/singleTickRedIcon.png"
import addIcon from "../assets/icons/addIcon.png"
import minIcon from "../assets/icons/minIcon.png"
import filterIcon from "../assets/icons/filterIcon.png"
import rightDropDown from "../assets/icons/navbar/rightDropIcon.png";
import csvIcon from "../assets/icons/csvIcon.png";
import xlsIcon from "../assets/icons/xlsIcon.png";
import pdfIcon from "../assets/icons/pdfIcon.png";
import Footer from "../components/Footer";

import ReactPaginate from 'react-paginate';
import upArrow from "../assets/icons/upArrowIcon.png";
import addWhite from "../assets/icons/addWhiteIcon.png"
import TransactionCard from "../components/TransactionCard";

interface TransactionInfo {
  transactionId: string;
  created: string;
  donorName: string;
  amount: string;
  complaint: "complaint" | "pending" | "inReview" ;
  source: string;
  bankStatus: "cleared" | "pending";
  fillingPeriod: string;
  notes: string

}

const transactionInfo: TransactionInfo[] = [
  {
    transactionId: "001010101",
    created: "Dec 12, 2023",
    donorName: "Holland Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "001010103",
    created: "jan 12, 2024",
    donorName: "john dow",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "001010104",
    created: "Dec 12, 2023",
    donorName: "Holland james",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "001010105",
    created: "jun 12, 2023",
    donorName: "james Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "001010106",
    created: "may 12, 2023",
    donorName: "Holland mark",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010105",
    created: "Dec 12, 2023",
    donorName: "Holland Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010107",
    created: "july 12, 2023",
    donorName: "june",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010108",
    created: "Dec 12, 2023",
    donorName: "usera",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "mark",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "Holland Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "Holland Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "Holland Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "Holland Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "Holland Morgan",
    amount: "$ 1000",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: " Morgan",
    amount: "$ 900",
    complaint: "complaint",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "john Morgan",
    amount: "$ 8000",
    complaint: "inReview",
    source: "ACH",
    bankStatus: "pending",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "Holland john",
    amount: "$ 1000",
    complaint: "inReview",
    source: "ACH",
    bankStatus: "cleared",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
  {
    transactionId: "RW : 001010101",
    created: "Dec 12, 2023",
    donorName: "Holland ",
    amount: "$ 1000",
    complaint: "pending",
    source: "ACH",
    bankStatus: "pending",
    fillingPeriod: "Feb 12, 2023",
  notes: "Good Luck"
  },
];

const Transactions = () => {

  const [transactions, setTransactions] = useState(transactionInfo)
  const [exportMenu, setExportMenu] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [details, setDetails] = useState(false)
  const [filters, setFilters] = useState(false)

  const itemsPerPage = 9;

  useEffect(()=>{
    setTransactions(transactionInfo)

    setTotalPages(Math.ceil(transactions.length / itemsPerPage));
}, [])

const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = transactions.slice(startIndex, endIndex);

const handlePageChange = (selectedPage: any) => {
  setCurrentPage(selectedPage.selected);
};

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  const handleExportToggle = () => {
    setExportMenu(!exportMenu);
  };

  const handleShowDetails = () => {
    setDetails(!details)
  }

  const handleShowFilters = () => {
    setFilters(!filters)
  }

  return (
    <>
      <Navbar />
      <div className="bg-neutral-50 px-4 pb-20 md:px-12">

        <div className="flex justify-between items-center pt-5 md:hidden">

        <div className="w-[70%] flex">
          {
            !filters ? 
            <div className="flex items-center p-1 bg-stone-500 bg-opacity-5 rounded-[20.50px]">
          <img className="h-[18px] w-[18px]" src={searchIcon} alt="" />
          <input
            className="<w-[268px] h-[35px] bg-white bg-opacity-0 rounded-[17.50px]  text-black outline-none pl-2"
            type="text"
            placeholder="Search For Anything"
          />
          <img src={rightArrow} alt="" />
        </div>
        : <div className="flex flex-wrap justify-between gap-y-3">
          <div className="w-[126px] bg-stone-100 rounded-[17.50px]">
            <div className="flex justify-center items-center gap-3 py-3">
            <div className="text-neutral-400 text-xs font-normal font-['Hind Guntur']">Transaction type</div>
            <img src={rightDropDown} alt="right dropdown icon" />
            </div>
          </div>

          <div className="w-[126px] bg-stone-100 rounded-[17.50px]">
            <div className="flex justify-center items-center gap-3 py-3">
            <div className="text-neutral-400 text-xs font-normal font-['Hind Guntur']">Amount</div>
            <img src={rightDropDown} alt="right dropdown icon" />
            </div>
          </div>
          <div className="w-[126px] bg-stone-100 rounded-[17.50px]">
            <div className="flex justify-center items-center gap-3 py-3">
            <div className="text-neutral-400 text-xs font-normal font-['Hind Guntur']">Bank Status</div>
            <img src={rightDropDown} alt="right dropdown icon" />
            </div>
          </div>
          <div className="w-[126px] bg-stone-100 rounded-[17.50px]">
            <div className="flex justify-center items-center gap-3 py-3">
            <div className="text-neutral-400 text-xs font-normal font-['Hind Guntur']">Source</div>
            <img src={rightDropDown} alt="right dropdown icon" />
            </div>
          </div>
        </div>
          }
        
      </div>

      <div className="flex items-center gap-x-3" onClick={handleShowFilters}>
      <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[18.20px]">Filters</div>
      <img src={filterIcon} alt="" />
      </div>
        </div>

        {/* search filters and heading */}
        <div className="flex flex-wrap items-center gap-5 py-5">
          <div className="hidden items-center p-1 bg-stone-50 bg-opacity-5 rounded-[20.50px] md:flex">
            <img className="h-[18px] w-[18px]" src={searchIcon} alt="" />
            <input
              className="<w-[268px] h-[35px] bg-white bg-opacity-0 rounded-[17.50px]  text-black outline-none pl-2"
              type="text"
              placeholder="Quick Find"
            />
            <img src={rightArrow} alt="" />
          </div>

          <div>
            <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] md:text-[32px]">
              Transactions
            </span>
            <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] md:text-[32px]">
              {" "}
              History
            </span>
          </div>
          <div className=" text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
            Showing 15 out of 100 Transactions
          </div>
        </div>

        {/* mobile sort */}
        <div className="flex justify-between items-center py-3 md:hidden">
<div className="flex items-center gap-5 md:hidden">
              <img className="w-[23px] h-[13.28px]" src={sortIcon} alt="" />
              <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                Sort By :
              </div>
              <div className="w-[126px] bg-stone-100 rounded-[17.50px]">
            <div className="flex justify-center items-center gap-3 py-3">
            <div className="text-neutral-400 text-xs font-normal font-['Hind Guntur']">Candidates Only</div>
            <img src={rightDropDown} alt="right dropdown icon" />
            </div> 
            </div> 

              </div>

              <div className="flex items-center gap-x-3">
              <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[18.20px]">Options</div>
              </div>
        </div>
        

        {/* sort */}
        <div className=" bg-white py-5 rounded-[25px] px-2">
          <div className=" flex items-center md:ustify-between">
            <div className="flex justify-between gap-x-10 items-center md:gap-5 md:justify-normal">
              <div className="text-center text-zinc-800 text-xl font-semibold font-['Hind Guntur'] md:text-[32px] md:leading-10">
                All Transactions
              </div>

              {/* for mobile */}
              <div>
              <div className="flex items-center px-2 py-1 bg-stone-300 bg-opacity-5 rounded-[20.50px] md:hidden">
            <img className="h-[18px] w-[18px]" src={searchIcon} alt="" />
            <input
              className="w-[125px] h-[35px] bg-white bg-opacity-0 rounded-[17.50px]  text-black outline-none pl-2"
              type="text"
              placeholder="Quick Find"
            />
            <img src={rightArrow} alt="" />
          </div>
              </div>
              
              {/* for laptop */}
              <div className="hidden  items-center gap-5 md:flex">
              <img src={sortIcon} alt="" />
              <div className="text-slate-600 text-base font-medium font-['Hind Guntur']">
                Sort By :
              </div>
              <div className="w-[171px] h-[35px] bg-stone-50 rounded-[17.50px] pl-[10px] pt-[5px]">
                <div className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">
                  Created
                </div>
              </div>  
              </div>
              
            </div>

            <div className="hidden items-center gap-5 md:flex">
              <div className="w-[83px] h-9 px-5 pt-3 pb-2.5 bg-stone-50 rounded-[100px] justify-center items-center gap-2.5 inline-flex">
                <div className="text-neutral-400 text-[15px] font-medium font-['Hind Guntur'] leading-none">
                  Today
                </div>
              </div>
              <div className="w-[114px] h-9 px-5 pt-3 pb-2.5 bg-stone-50 rounded-[100px] justify-center items-center gap-2.5 inline-flex">
                <div className="text-neutral-400 text-[15px] font-medium font-['Hind Guntur'] leading-none">
                  Last Week
                </div>
              </div>
              <div className="w-[120px] h-9 px-5 pt-3 pb-2.5 bg-stone-50 rounded-[100px] justify-center items-center gap-2.5 inline-flex">
                <div className="text-neutral-400 text-[15px] font-medium font-['Hind Guntur'] leading-none">
                  Last Month
                </div>
              </div>
              <div className="w-[133px] h-9 px-5 pt-3 pb-2.5 bg-stone-50 rounded-[100px] justify-center items-center gap-2.5 inline-flex">
                <div className="text-neutral-400 text-[15px] font-medium font-['Hind Guntur'] leading-none">
                  Custom Date
                </div>
              </div>
              <div className="bg-stone-300 h-5 w-[2px]"></div>
              <div
                className="relative text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight"
                onClick={handleExportToggle}
              >
                Export
                {exportMenu && (
                  <div className="absolute right-full w-[238px] h-auto bg-white rounded-bl-[10px] rounded-br-[10px] p-5 shadow-2xl z-50">
                    <div className="flex items-center gap-5 py-2">
                      <div>
                        <img src={pdfIcon} alt="" />
                      </div>
                      <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer">
                        Export as PDF
                      </div>
                    </div>

                    <div className="flex items-center gap-5 py-2">
                      <div>
                        <img src={xlsIcon} alt="" />
                      </div>
                      <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer">
                        Export as XLS
                      </div>
                    </div>

                    <div className="flex items-center gap-5 py-2">
                      <div>
                        <img src={csvIcon} alt="" />
                      </div>
                      <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer">
                        Export as CSV
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr className="bg-stone-300 h-[2px] my-5" />

          {/* for mobile */}
          <div className=" md:hidden">
          <div className="w-full h-auto bg-red-700 rounded-tl-[10px] rounded-tr-[10px] rounded-bl-[5px] rounded-br-[5px] px-[5px] py-[10px]">
            <div className="flex items-center justify-between">
            <div className="text-center text-white text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px]">Transaction Details</div>
<div className="flex items-center gap-x-3">
<div className="text-white text-sm font-medium font-['Hind Guntur'] leading-[18.20px]">Expand All</div>
<img src={addWhite} alt="" />
</div>
            </div>

          </div>
          
          {/* <div className="w-full h-auto bg-stone-50 rounded-[10px]  px-[5px] py-[10px] my-3">S */}

               <div>
                {subset.map((data,index) => (

                  
//                   <div key={data.transactionId} className="w-full h-auto bg-stone-50 rounded-[10px]  px-[5px] py-[10px] my-3">
//                     <div className={`${details === true ? "hidden" : "false"}`}>

//                  <div className="flex items-center justify-between">
//                   <div className="flex gap-x-5">
//                   <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">{data.donorName}</div>
//                   <div className="flex items-center gap-x-1">
//                     <img src={doubleTickBlue} alt="" />
//                     <div className="text-center text-sky-800 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.bankStatus}</div>
//                   </div>
//                   </div>
//                   <div className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.amount} </div>
//             </div>

//             <hr className="h-[2px] bg-stone-300 my-3" />

//               <div className="flex items-center justify-between">
//                   <div className="flex items-center">
//                   <div className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">Created</div>
//                   <div className="border-l-2 border-stone-300 h-[25px] w-px mx-3"></div>
//                   <div className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.created}</div>
//                   </div>
//                   <div className="flex items-center gap-x-3" onClick={handleShowDetails}>
//                   <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[18.20px]">More Details</div>
//                   <img className="w-[23px] h-[23px]" src={addIcon} alt="" />
//                   </div>
//               </div>
//               </div>
// {/* .includes(data.transactionId) */}
//               {details && 
//                <div>
//                 <div className="flex flex-col items-start gap-y-3">
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Donor Name : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.donorName}</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Transaction ID : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.transactionId}</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Created : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.created}</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Amount : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.amount}</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Complaint : <span className="text-center text-red-700 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">Feature Coming Soon</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Source : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.source}</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Bank Status : <span className="text-center text-red-700 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.bankStatus}</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Filling Period : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.fillingPeriod}</span></div>
//                 <div className="text-center text-zinc-800 text-sm font-semibold font-['Hind Guntur'] leading-[17.26px]">Notes : <span className="text-center text-zinc-600 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">{data.notes}</span></div>

//                 </div>
//             <hr className="h-[2px] bg-stone-300 my-3" />
//                   <div className="flex items-center justify-end gap-x-3" onClick={handleShowDetails}>
//                   <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[18.20px]">Less Details</div>
//                   <img className="w-[23px] h-[23px]" src={minIcon} alt="" />
//                   </div>
//                </div>
//                }
//               </div>

                  <TransactionCard key={data.transactionId} {...data} />

                ))}
                
               </div>
            
          {/* </div> */}

          </div>
          {/* table */}
          <div className="hidden w-full md:block">
            <table className="w-full rounded-tl-[10px] rounded-tr-[10px]">
              <thead className="bg-red-700">
                <tr className="text-center text-white text-lg font-medium font-['Hind Guntur'] leading-snug">
                  <th className="p-3">Transaction ID</th>
                  <th>Created</th>
                  <th>Donor Name</th>
                  <th>Amount ($)</th>
                  <th>Complaint</th>
                  <th>Source</th>
                  <th>Bank Status</th>
                  <th>Filling Period</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>

                {
                  subset.map((data,index) => (
                    <tr className={`text-center text-zinc-600 text-lg font-medium font-['Hind Guntur'] leading-snug ${
                      index % 2 === 0 ? "bg-neutral-50" : "bg-white"
                    }`}>
                  <td className="p-2">{data.transactionId}</td>
                  <td>{data.created}</td>
                  <td className="text-black">{data.donorName}</td>
                  <td>{data.amount}</td>
                  <td>
                    {
                      data.complaint === "complaint" &&
                      <div className="w-[106px] h-8 bg-sky-800 rounded-2xl">
                      <div className="flex items-center justify-center gap-2 pt-[7px]">
                        <img src={doubleTick} alt="" />
                        <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                          {data.complaint}
                        </div>
                      </div>
                    </div>
                    }
                    {
                      data.complaint === "inReview" &&
                      <div className="w-[106px] h-8 bg-white rounded-2xl border border-red-700">
                      <div className="flex items-center justify-center gap-2 pt-[7px]">
                        {/* <img src={singleTick} alt="" /> */}
                        <div className="w-2 h-2 bg-red-700 rounded-full"></div>
                        <div className="text-center text-red-700 text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                          {data.complaint}
                        </div>
                      </div>
                    </div>
                    }
                    {
                      data.complaint === "pending" &&
                      <div className="w-[106px] h-8 bg-red-700 rounded-2xl">
                      <div className="flex items-center justify-center gap-2 pt-[7px]">
                        <img src={singleTick} alt="" />
                        <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                          Pending
                        </div>
                      </div>
                    </div>
                    }
                    
                  </td>
                  <td>{data.source}</td>
                  <td>
                    {data.bankStatus === "cleared" && 
                    <div className="w-[106px] h-8 bg-sky-800 rounded-2xl">
                      <div className="flex items-center justify-center gap-2 pt-[7px]">
                        <img src={singleTick} alt="" />
                        <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                          {data.bankStatus}
                        </div>
                      </div>
                    </div>
                    }
                    {data.bankStatus === "pending" && 
                    <div className="w-[106px] h-8 bg-red-700 rounded-2xl">
                    <div className="flex items-center justify-center gap-2 pt-[7px]">
                      <img src={singleTick} alt="" />
                      <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                        Pending
                      </div>
                    </div>
                  </div>
                    }
                    
                  </td>
                  <td>{data.fillingPeriod}</td>
                  <td>{data.notes}</td>
                </tr>
                  ))
                }
                
              </tbody>
            </table>
          </div>

        </div>
        <div className='hidden items-center justify-between my-5 lg:flex md:flex'>
                    <div className=''>
                    <div className="flex items-center gap-5 ">
        <div>
          <img
            className="cursor-pointer"
            src={upArrow}
            alt=""
            onClick={scrollToTop}
          />
        </div>
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
          Back To Top
        </div>
                    </div>    
                    </div>
                          <div className=''>
                <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                activeClassName={"active-page"}
                className='flex gap-5 text-slate-600 text-lg font-normal'
                />
                </div>    
        </div>

{/* for mobile */}
        <div className='flex items-center justify-center my-5 lg:hidden md:hidden'>
                <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                activeClassName={"active-page"}
                className='flex gap-5 text-slate-600 text-lg font-normal'
                />
                </div>

                <div className="flex items-center gap-5 lg:hidden md:hidden">
        <div>
          <img
            className="cursor-pointer"
            src={upArrow}
            alt=""
            onClick={scrollToTop}
          />
        </div>
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
          Back To Top
        </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Transactions;
