import React, { useState } from "react";
import logo from "../assets/icons/logoWhiteIcon.png"
const DonationForm = () => {
  const [amount, setAmount] = useState("");
  const [isMonthly, setIsMonthly] = useState(false);

  const handleAmountChange = (event: any) => {
    setAmount(event.target.value);
  };

  const handleFrequencyChange = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <div className="bg-white  rounded-[25px] mt-6 shadow-lg flex flex-col w-[357px] lg:w-[400px] lg:mt-0 max-[435px]:w-full xl:w-[439px]">
      <div className="bg-[#00418C] flex justify-between rounded-3xl xl:rounded-[25px]">
        <div className="pl-5 py-5">
          <div className=" text-white text-lg font-bold rounded-t xl:text-3xl">
            Donate Now
          </div>
          <div className=" text-white xl:text-xl">Your Contribution Matters</div>
        </div>
        <div className="flex justify-center items-center w-[35%] bg-red-700 rounded-tr-3xl rounded-bl-[56.50px] rounded-br-3xl  xl:rounded-br-[25px]  xl:rounded-tr-[25px]">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="py-4 px-3 md:px-6">
        <p className="text-slate-600 p-2 font-semibold text-sm xl:text-lg ">
          Select Your Amount or Add Custom Amount
        </p>
        <div className="flex mt-4 col-span-1 gap-x-2 rounded-3xl">
          <button
            onClick={() => setAmount("25")}
            className={`flex-1 py-2 rounded-3xl ${
              amount === "25"
                ? "bg-red-700 text-white"
                : "bg-neutral-100 text-stone-400"
            }`}
          >
            $25
          </button>
          <button
            onClick={() => setAmount("50")}
            className={`flex-1 py-2 rounded-3xl ${
              amount === "50"
                ? "bg-red-700 text-white"
                : "bg-neutral-100 text-stone-400"
            }`}
          >
            $50
          </button>
          <button
            onClick={() => setAmount("100")}
            className={`flex-1 py-2 rounded-3xl ${
              amount === "100"
                ? "bg-red-700 text-white"
                : "bg-neutral-100 text-stone-400"
            }`}
          >
            $100
          </button>
        </div>
        <input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          className="border p-2 pl-5 mt-5 w-full rounded-3xl bg-neutral-100 border-none appearance-none focus:outline-none focus:shadow-outline placeholder:text-stone-400"
          placeholder="$ Custom Amount"
        />
        <p className="text-slate-600 py-4 font-semibold text-sm xl:text-lg">
          Select Your Donation Schedule
        </p>
        <div className="flex  col-span-1 bg-neutral-100 rounded-3xl">
          <button
            onClick={handleFrequencyChange}
            className={`flex-1 py-2 rounded-3xl text-xl ${
              isMonthly ? "bg-red-700 text-white" : "text-stone-400"
            }`}
          >
            One Time
          </button>
          <button
            onClick={handleFrequencyChange}
            className={`flex-1 py-2 rounded-3xl text-xl ${
              isMonthly ? "text-stone-400" : "bg-red-700 text-white"
            }`}
          >
            Monthly
          </button>
        </div>
        <div className="flex items-center mt-4">
          <input type="checkbox" className="mr-2 bg-[#F6F6F6]" />
          <p className="text-sm pt-4 text-[#CCC]">
            I’ll cover the 3.00% processing fee. Please add it to my donation
            amount.
          </p>
        </div>
        <div className="flex justify-center">
          <button className="w-36 h-9 mt-4 bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl">
            Donate Now
          </button>
        </div>
        <div className="flex justify-center mt-5">
          <p className="text-[#CCC] p-2  text-sm ">Powered by Cliq</p>
        </div>
      </div>
    </div>
  );
};

export default DonationForm;
