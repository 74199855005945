import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../../../assets/images/Back.svg";
import Domestic from "../../../../../assets/icons/Domestic";
import Education from "../../../../../assets/icons/Education";
import Electorial from "../../../../../assets/icons/Electorial";
import Energy from "../../../../../assets/icons/Energy";
import HealthCare from "../../../../../assets/icons/HealthCare";
import Economy from "../../../../../assets/icons/Economy";
import Immigration from "../../../../../assets/icons/Immigration";
import Criminal from "../../../../../assets/icons/Criminal";
import Conservation from "../../../../../assets/icons/Conservation";
import Foreign from "../../../../../assets/icons/Foreign";
import Housing from "../../../../../assets/icons/Housing";
import Transportation from "../../../../../assets/icons/Transportation";
import National from "../../../../../assets/icons/National";
import Tax from "../../../../../assets/icons/Tax";
import Science from "../../../../../assets/icons/Science";
import Plus from "../../../../../assets/icons/Plus.svg";
import FormFlagMobile from "../../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../../assets/images/RedDot.svg";
import {
  UpdateCandidateForm,
  UpdateCandidateissues,
} from "../../../../../redux/candidate/candidateSlice";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { toast } from "react-hot-toast";
import "../../../Donor/DonorForms/styles.css";

export interface CategoryItem {
  id: number;
  title: string;
  icon: React.ReactNode;
  selectedIcon: string;
  path: string;
}

const categories: CategoryItem[] = [
  {
    id: 1,
    title: "Energy",
    icon: <Energy color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 2,
    title: "Domestic Policy",
    icon: <Domestic color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 3,
    title: "Education",
    icon: <Education color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 4,
    title: "Healthcare",
    icon: <HealthCare color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 5,
    title: "Electorial",
    icon: <Electorial color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 6,
    title: "Economy",
    icon: <Economy color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 7,
    title: "Immigration",
    icon: <Immigration color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 8,
    title: "Criminal",
    icon: <Criminal color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 9,
    title: "Conservation",
    icon: <Conservation color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 10,
    title: "Science",
    icon: <Science color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 11,
    title: "Foreign",
    icon: <Foreign color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 12,
    title: "Housing",
    icon: <Housing color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 13,
    title: "Transportation",
    icon: <Transportation color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 14,
    title: "National",
    icon: <National color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
  {
    id: 15,
    title: "Tax",
    icon: <Tax color="#CB000E" />,
    selectedIcon: RedDot,
    path: "",
  },
];

type FormValues = {
  id: string | number;
  issues_description: string;
  issues: Array<string>;
};

interface ShowIssuesField {
  issues: string;
}

function Issues() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const authinfo = useAppSelector((state: any) => state.auth.userData);

  const [issueslist, setIssuesList] = useState<any[]>([]);
  const [showIssuesFields, setShowIssuesFields] = useState<ShowIssuesField[]>([
    { issues: "" },
  ]);

  const handleShowIssuesFields = (e: any) => {
    if (issueslist.length + showIssuesFields.length < 6) {
      setShowIssuesFields([...showIssuesFields, { issues: "" }]);
    } else {
      toast.error("You can select up to 6 issues.");
    }
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const handleShowIssues = (title: string) => {
    const updatedList = [...issueslist];
    // Check if the title is already in the array
    const index = updatedList.indexOf(title);

    if (index === -1) {
      if (updatedList.length + showIssuesFields.length >= 7) {
        toast.error("You can select up to 6 issues!");
        return;
      }
      updatedList.push(title);
    } else {
      updatedList.splice(index, 1);
    }

    setIssuesList(updatedList);
  };

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    if (issueslist.length === 0 || showIssuesFields.length === 0) {
      toast.error("please select the issues");
      return;
    }

    const hasEmptyIssues = data.issues.some((field) => field.trim() === "");

    const Count: number = !hasEmptyIssues ? 6 : 7;

    if (issueslist.length + showIssuesFields.length !== Count) {
      toast.error("Issues should not be less than 6");
      return;
    }

    let FinalIssue;
    if (!hasEmptyIssues) {
      FinalIssue = [...issueslist, ...data.issues];
    } else {
      FinalIssue = [...issueslist];
    }

    dispatch(UpdateCandidateissues(FinalIssue));
    dispatch(UpdateCandidateForm("ShowIssues"));
    // try {
    //   setloading(true);
    //   const res = await httpRequest.patch(
    //     `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/issues`,
    //     {
    //       issues: FinalIssue,
    //       //   issues_description: "description"
    //     }
    //   );
    //   if (res?.status === 200 || res?.status === 201) {
    //     const updatedAuthInfo = {
    //       ...authinfo,
    //       ...res?.data.candidate,
    //     };
    //     dispatch(UpdateCandidateForm("ShowIssues"));
    //     dispatch(setAuthData(updatedAuthInfo));
    //     toast.success("added successfully!");
    //   } else {
    //     toast.error(res?.data?.message || "Something went wrong");
    //   }
    // } catch (error: any) {
    //   toast.error(
    //     (error.response?.data?.message as string) || "Something went wrong"
    //   );
    // } finally {
    //   setloading(false);
    // }
  };

  useEffect(() => {
    if (authinfo) {
      const { issues } = authinfo;
      if (issues) {
        const MatchedIssues = issues.filter((issue: string) =>
          categories.some((category) => category.title === issue)
        );
        const notMatchedIssues = issues.filter(
          (issue: string) =>
            !categories.some((category) => category.title === issue)
        );
        setIssuesList(MatchedIssues);

        setShowIssuesFields(
          notMatchedIssues.map((value: any) => ({ issues: value }))
        );
        notMatchedIssues.forEach((certification: any, index: number) => {
          setValue(`issues.${index}`, certification);
        });
      }
    }
  }, [authinfo]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Issues</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-20 xl:px-20 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <p className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
              Please choose your top six (6) Political issues in order of
              importance :{" "}
            </p>
          </div>
          <div className="flex flex-wrap py-12 gap-14">
            {categories.map((category, index) => (
              <div
                key={index}
                className={`relative  flex flex-col gap-3 justify-center items-center `}
                // ${
                //   issueslist.includes(category.title) ? "active_cat" : ""
                // }
                onClick={() => {
                  handleShowIssues(category.title);
                }}
              >
                {/* <img
                  src={category.icon}
                  alt={category.title}
                  style={{ width: "45px",
                  height: "45px" , 
                  // fill: issueslist.includes(category.title) ? 'red' : 'black'
                  // filter: issueslist.includes(category.title) ? 'brightness(0) invert(1) sepia(100%) saturate(10000%) hue-rotate(0deg) contrast(100%) drop-shadow(0 0 5px red)' : 'none'
                }}
                /> */}
                {React.cloneElement(category.icon as React.ReactElement, {
                  color: issueslist.includes(category.title)
                    ? "#CB000E"
                    : "#282828", // adjust 'fill' to the appropriate prop
                  style: { width: "45px", height: "45px" },
                })}
                {issueslist.includes(category.title) ? (
                  <span className="selected_count">
                    {issueslist.indexOf(category.title) + 1}
                  </span>
                ) : (
                  ""
                )}
                <p>{category.title}</p>
              </div>
            ))}
          </div>

          <div className="flex flex-row flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                What are you fighting for :
              </label>
              <div className="flex flex-wrap flex-row gap-8 mt-2">
                {/* {showIssuesFields.map((field, index) => (
                  <div className="flex flex-row gap-2">
                    <input
                      {...register("issues_description")}
                      type="text"
                      // id={`field${index + 1}`}
                      // name={`field${index + 1}`}
                      className="mt-2 p-2 w-[317px] h-[50px] bg-stone-50 rounded-[10px]"
                      placeholder="Enter your own custom issues"
                    />
                  </div>
                ))} */}

                {showIssuesFields.map((field, index) => (
                  <div className="flex flex-row gap-2" key={index}>
                    <input
                      // {...register(`issues.${index}` as const, {
                      //   required: "issues should not be empty",
                      // })}
                      {...register(`issues.${index}` as const, {
                        validate: (value) =>
                          issueslist.length === 0
                            ? value.trim().length > 0 ||
                              "Issues should not be empty"
                            : true,
                      })}
                      type="text"
                      className="mt-2 p-2 w-[317px] h-[50px] bg-stone-50 rounded-[10px]"
                      placeholder="Enter your own custom issues"
                    />
                    {errors.issues?.[index] && (
                      <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                        {errors.issues[index]?.message}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-8 mt-6">
            <div
              className="flex flex-nowrap gap-2 text-base font-medium cursor-pointer"
              onClick={handleShowIssuesFields}
            >
              <img src={Plus} alt="Plus" className="w-8 h-8" />
              <button
                className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur']"
                type="button"
              >
                Add an additional custom issue
              </button>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("Education"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button className="hidden sm:inline-block">Back</button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Issues
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Issues;
