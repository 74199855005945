import React from "react";
import { cn } from "../utils/utils";

interface ButtonProps {
  backgroundColor?: string;
  label: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  backgroundColor,
  label,
  disabled,
}) => {
  return (
    <>
      <div
        className={cn(
          "w-36 h-9 bg-[#CB000E] flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold"
        )}
      >
        {label}
      </div>
    </>
  );
};

export default Button;
