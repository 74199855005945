import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../../../assets/images/Back.svg";
import Fire from "../../../../../assets/icons/Fire.svg";
import Minus from "../../../../../assets/icons/Minus.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { API_BASE_URL } from "../../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../../axios/index";
import { setAuthData } from "../../../../../redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../../redux/candidate/candidateSlice";

type FormValues = {
  issues_description: string;
};

function ShowIssue() {
  const navigate = useNavigate();
  const [loading, setloading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const authinfo = useAppSelector((state: any) => state.auth.userData);

  const CandidateIssueState = useAppSelector(
    (state: any) => state.candidate.Candidateissues
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    try {
      setloading(true);
      const res = await httpRequest.patch(
        `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/issues`,
        {
          issues: CandidateIssueState,
          issues_description: data.issues_description || "",
        }
      );
      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data.candidate,
        };
        dispatch(UpdateCandidateForm("CreativeAssets"));
        dispatch(setAuthData(updatedAuthInfo));
        // toast.success("added successfully!");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (authinfo) {
      const { issues_description } = authinfo;
      if (issues_description) {
        setValue("issues_description", issues_description);
      }
    }
  }, [authinfo, setValue]);

  return (
    <div className="flex-grow px-24 pt-24">
      <p className="text-5xl font-bold mb-4">
        Welcome to <span className="text-red-600">Red</span>Wave
      </p>
      <p className="text-xl font-normal text-[#585C7B]">
        Please Provide The Details Below To Complete Your Profile
      </p>

      <div className="mt-14">
        <div className="flex flex-row flex-nowrap gap-4">
          <img src={Fire} alt="Back" className="w-8 h-8" />
          <p className="mt-1 text-zinc-800 text-base font-medium font-['Hind Guntur']">
            Top Issue
          </p>
        </div>
        <div className="flex flex-row gap-6">
          {CandidateIssueState &&
            CandidateIssueState?.map((value: any) => {
              return (
                <>
                  <div
                    className="flex items-center w-[98px] h-7 bg-red-700 rounded-[14px] relative mt-6"
                    key={value}
                  >
                    <span className=" text-white text-base font-medium font-['Hind Guntur'] p-4">
                      {value}
                    </span>
                    <div className="absolute right-0 h-full flex items-center">
                      <div className="w-7 h-7 bg-white rounded-full flex items-center justify-center">
                        <img src={Minus} alt="Minus" />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className="flex flex-row flex-nowrap gap-4 mt-14">
          <p className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
            Tell us why you are passionate about these issues :
          </p>
        </div>
        <div className=" p-4 rounded-[14px] mt-6">
          <form onSubmit={handleSubmit(submitForm)}>
            <textarea
              {...register("issues_description", {
                // required: "bio should not be empty",
                maxLength: {
                  value: 2000,
                  message: "description not exceed 2000  characters",
                },
              })}
              className="mt-2 p-2 w-[320px] h-[110px] md:w-[533px] md:h-[110px] lg:w-[733px] lg:h-[110px] xl:w-[733px] xl:h-[110px] bg-stone-50 rounded-[10px] resize-none bg-stone-50"
              placeholder="Write about what makes you a great candidate"
            />
            {errors.issues_description && (
              <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                {errors.issues_description.message}
              </span>
            )}
            {/* <span className="text-neutral-400 text-[15px] font-medium font-['Hind Guntur']">
              My leadership has resulted in improving: <br />
              Government Transparency including a weekly newsletter and an
              active social media presence. <br />
              Public Safety including safer streets, sidewalks, crosswalks, and
              intersections, a new GEMS Station 4, additional officers posted in{" "}
              <br />
              our schools, increased police presence on Greenwich Avenue and an
              Active Transportation Task Force.
              <br />
              Housing and Zoning Protection, including testifying in Hartford
              against harmful 8-30g rules and advocating for smart affordable
              housing solutions like the additional housing completed at
              Armstrong Court.
              <br />
              Public Private Partnerships including the building of the Eastern
              Greenwich Civic Center, acquiring 80 plus acres of unspoiled
              conservation land and the planting of tens of thousands of bulbs
              along the Post Road, GEMS Station Four, and several other
              initiatives.
              <br />
              Proposed Budgets that fund capital projects at historic levels
              while maintaining the lowest mill rate in Connecticut, which has
              us again making Greenwich's mill rate the most affordable in the
              state.
              <br />
              Economic Vitality, including the development of a plan allowing
              restaurants to offer outdoor dining, leading to increased activity
              which benefits all local businesses.
            </span> */}
          </form>
        </div>
      </div>

      <div className="flex flex-nowrap py-14 justify-between gap-4 sm:gap-2">
        <div
          className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
          onClick={() => dispatch(UpdateCandidateForm("Issues"))}
        >
          <img
            src={Back}
            alt="Back"
            className="w-8 h-8 pt-2 hidden sm:inline-block"
          />
          <button
            className="hidden sm:inline-block"
            // onClick={() => navigate("/issues")}
          >
            Back
          </button>
        </div>
        <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
          <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
            Issues Explained
          </p>
          <button
            className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
            disabled={loading}
            type="button"
            onClick={() => handleSubmit(submitForm)()}
          >
            {loading ? "Loading..." : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShowIssue;
