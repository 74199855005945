import React, { useState } from "react";
import Navbar from "../../components/DashboardNavbar/Navbar";
import min from "../../assets/icons/minIcon.png";
import max from "../../assets/icons/addIcon.png";
import tick from "../../assets/icons/singleTickIcon.png";
import upArrow from "../../assets/icons/upArrowIcon.png";
import key from "../../assets/icons/keyIcon.png";
import pen from "../../assets/icons/penRoundedIcon.png";
import cancel from "../../assets/icons/cancelIcon.png";
import Footer from "../../components/Footer";

const DonorSettings = () => {

  const [headingOne, setHeadingOne] = useState<boolean>(true);
  const [headingTwo, setHeadingTwo] = useState<boolean>(true);
  const [headingThree, setHeadingThree] = useState<boolean>(true);
  const [headingFour, setHeadingFour] = useState<boolean>(true);
  const [headingFive, setHeadingFive] = useState<boolean>(true);
  const [headingSix, setHeadingSix] = useState<boolean>(true);
  const [headingSeven, setHeadingSeven] = useState<boolean>(true);
  // information
  const [isInformation, setIsInformation] = useState<string>("");
  // notifications
  const [isNotification, setIsNotification] = useState<string>("")
  // alerts
  const [isAlert, setIsAlert] = useState<string>("")
  // promotional emails
  const [isNewsToggle, setIsNewsToggle] = useState(true);
  const [isEmailsToggle, setIsEmailsToggle] = useState(false);
  const [isNewsletterToggle, setIsNewsletterToggle] = useState(false);
  // 2 factor authentication
  const [isAuthentication, setIsAuthentiation] = useState<string>("")
  // bank
  const [isBank, setIsBank] = useState<string>("")
  // payment
  const [isPayment, setIsPayment] = useState<string>("")


  const toggleNewsSwitch = () => {
    setIsNewsToggle((prevIsOn) => !prevIsOn);
  };

  const toggleEmailsSwitch = () => {
    setIsEmailsToggle((prevIsOn) => !prevIsOn);
  };

  const toggleNewsletterSwitch = () => {
    setIsNewsletterToggle((prevIsOn) => !prevIsOn);
  };

  const handleInfoChange = (infoValue: string) => {
    setIsInformation(
      infoValue === isInformation ? "" : infoValue
    );
  };

  const handleNotificationChange =(notificationValue: string) => {
    setIsNotification(
      notificationValue === isNotification ? "" : notificationValue
    )
  }

  const handleAlertChange = (alertValue: string) => {
    setIsAlert(
      alertValue === isAlert ? "" : alertValue
    )
  }

  const handleAuthenticationChange = (authValue: string) => {
    setIsAuthentiation(
      authValue === isAuthentication ? "" : authValue
    )
  }

  const handleBankChange = (bankValue: string) => {
    setIsBank(
        bankValue === isBank ? "" : bankValue
    )
  }

  const handlePaymentChange= (paymentValue: string) => {
    setIsPayment(
      paymentValue === isPayment ? "" : paymentValue
    )
  }

  const handleClickOne = () => {
    setHeadingOne(!headingOne);
  };

  const handleClickTwo = () => {
    setHeadingTwo(!headingTwo);
  };

  const handleClickThree = () => {
    setHeadingThree(!headingThree);
  };

  const handleClickFour = () => {
    setHeadingFour(!headingFour);
  };

  const handleClickFive = () => {
    setHeadingFive(!headingFive);
  };

  const handleClickSix = () => {
    setHeadingSix(!headingSix);
  };

  const handleClickSeven = () => {
    setHeadingSeven(!headingSeven);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Navbar />

      <div className="px-24 pt-8 pb-12 bg-stone-50">
        <div className="flex items-center gap-5">
          <div>
            <span className="text-zinc-800 text-[32px] font-semibold font-['Hind Guntur']">
              Account </span>
            <span className="text-red-700 text-[32px] font-semibold font-['Hind Guntur']">
              Settings
            </span>
          </div>
          <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
            Manage Your Account Settings
          </div>
        </div>
        {/* one */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-5">
              <div className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                My Info
              </div>
              <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                Basic Profile Info
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingOne ? min : max}
                alt="min icon"
                onClick={handleClickOne}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingOne ? (
            <div className="pt-5 transition-transform translate-y-px duration-1000 ease-in-out">
              <div className="flex justify-between flex-wrap gap-5">
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Your first name :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Enter your first name"
                  />
                </div>
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Your last name :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Enter your last name"
                  />
                </div>
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Your phone number :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Enter your phone number"
                  />
                </div>

                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Your email address :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="email"
                    placeholder="Enter your email address"
                  />
                </div>

                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Your SSN ( Last 4 digits) :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="number"
                    placeholder="Enter your SSN number"
                  />
                </div>

                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Employer :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Enter your employer name"
                  />
                </div>

                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Occupation :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Enter your occupation"
                  />
                </div>

                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Job title :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Ex: Vice President"
                  />
                </div>


              </div>
              <div className="pt-5">
                <div>
                  <span className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Do you want your information visible to others :{" "}
                  </span>
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    *
                  </span>
                </div>

                <div className="flex gap-10">
                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isInformation === "yes"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isInformation === "yes"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={isInformation === "yes"}
                      value="yes"
                      onChange={() => handleInfoChange("yes")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Yes
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isInformation === "no"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isInformation === "no"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={isInformation === "no"}
                      value="no"
                      onChange={() => handleInfoChange("no")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      No
                    </span>
                  </label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* two */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-5">
              <div className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                Email & Notifications
              </div>
              <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                Manage notifications & alerts
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingTwo ? min : max}
                alt="min icon"
                onClick={handleClickTwo}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingTwo ? (
            <div className="pt-5">
              <div>
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose py-5">
                  How do you want to receive your notifications and alerts :
                </div>
                <div className="flex gap-10 py-2">
                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isNotification === "push notification"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isNotification === "push notification"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={isNotification === "push notification"}
                      onChange={() => handleNotificationChange("push notification")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Push Notifications
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isNotification === "email"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isNotification === "email"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={isNotification === "email"}
                      onChange={() => handleNotificationChange("email")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Email
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isNotification === "both"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isNotification === "both"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={ isNotification === "both"}
                      onChange={() => handleNotificationChange("both")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Both
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isNotification === "no alerts"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isNotification === "no alerts"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={isNotification === "no alerts"}
                      onChange={() => handleNotificationChange("no alerts")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      No alerts & notifications
                    </span>
                  </label>
                  
                </div>
              </div>

              <div>
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose py-5">
                  Which alerts you want to receive :
                </div>
                <div className="flex gap-10 py-2">
                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isAlert === "donation"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="w-[21px] h-[21px] accent-red-700 text-red-700 bg-zinc-300 rounded-full"
                      checked={isAlert === "donation"}
                      onChange={() => handleAlertChange("donation")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Donation Alerts
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isAlert === "campaign"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="w-[21px] h-[21px] accent-red-700 text-red-700 bg-zinc-300 rounded-full"
                      checked={isAlert === "campaign"}
                      onChange={() => handleAlertChange("campaign")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      New local Campaign alerts
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isAlert === "promotion"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="w-[21px] h-[21px] accent-red-700 text-red-700 bg-zinc-300 rounded-full"
                      checked={isAlert === "promotion"}
                      onChange={() => handleAlertChange("promotion")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Promotions alerts
                    </span>
                  </label>
                </div>
              </div>

              <div>
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose py-5">
                  Do you want to receive promotional emails :
                </div>
                <div className="flex items-center gap-5">
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300 rounded-full p-1 cursor-pointer ${
                        isNewsToggle ? "bg-red-700" : ""
                      }`}
                      onClick={toggleNewsSwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                          isNewsToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      News & announcements from RedWave
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-5">
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300 rounded-full p-1 cursor-pointer ${
                        isEmailsToggle ? "bg-red-700" : ""
                      }`}
                      onClick={toggleEmailsSwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                          isEmailsToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Marketing emails
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-5">
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300 rounded-full p-1 cursor-pointer ${
                        isNewsletterToggle ? "bg-red-700" : ""
                      }`}
                      onClick={toggleNewsletterSwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                          isNewsletterToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Monthly newsletter
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* three */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-5">
              <div className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                Account Security
              </div>
              <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                Manage your passwords
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingThree ? min : max}
                alt="min icon"
                onClick={handleClickThree}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingThree ? (
            <div className="pt-5">
              <div className="flex justify-between items-end">
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Current Password :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="password"
                    placeholder="Enter your current password"
                  />
                </div>
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    New Password :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="password"
                    placeholder="Enter new password"
                  />
                </div>
                <div>
                  <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                    Confirm Password :
                  </div>
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="password"
                    placeholder="Enter new password"
                  />
                </div>

                <div>
                  <div className=" bg-red-700 rounded-[17.50px] p-2">
                    <div className="flex items-center gap-2">
                      <img src={key} alt="" />
                      <div className="text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                        Change password
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* four */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-5">
              <div className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                Two Factor Authentication
              </div>
              <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                Add an extra security layer
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingFour ? min : max}
                alt="min icon"
                onClick={handleClickFour}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingFour ? (
            <div className="pt-5">
              <div className="flex flex-col gap-10">
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                  Do you want to enable Two-Factor Authentication :
                </div>
                <div className="flex gap-10">
                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isAuthentication === "sms"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isAuthentication === "sms"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={isAuthentication === "sms"}
                      onChange={() => handleAuthenticationChange("sms")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      SMS
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isAuthentication === "email"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isAuthentication === "email"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={ isAuthentication === "email"}
                      onChange={() => handleAuthenticationChange("email")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Email
                    </span>
                  </label>

                  <label
                    className={`inline-flex items-center space-x-2 ${
                      isAuthentication === "none"
                        ? "text-red-700"
                        : "text-neutral-400"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                        isAuthentication === "none"
                        ? "bg-red-700"
                        : "bg-zinc-300"
                      }`}
                      checked={isAuthentication === "none"}
                      onChange={() => handleAuthenticationChange("none")}
                    />
                    <span className=" text-xl font-normal font-['Hind Guntur'] leading-loose">
                      None
                    </span>
                  </label>
                </div>
                <div className="flex items-center gap-10">
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Enter your phone number"
                  />
                  <div>
                    <div className=" bg-red-700 rounded-[17.50px] p-2 w-40">
                      <div className="flex items-center gap-2">
                        <img src={key} alt="" />
                        <div className="text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                          Request Code
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-10">
                  <input
                    className="bg-stone-100 rounded-[10px] p-3"
                    type="text"
                    placeholder="Enter your 2FA Code"
                  />
                  <div>
                    <div className=" bg-red-700 rounded-[17.50px] p-2 w-40">
                      <div className="flex items-center gap-2">
                        <img src={key} alt="" />
                        <div className="text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                          Enable 2FA
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* five */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-5">
              <div className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                Connect Bank
              </div>
              <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                Manage your connected banks
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingFive ? min : max}
                alt="min icon"
                onClick={handleClickFive}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingFive ? (
            <div className="pt-5">
              <div>
                <div>
                  <div className={` rounded-[15px] shadow ${
                    isBank === "12345" ? "bg-white" : "bg-neutral-50"
                  } `}>
                    <div className="flex items-center justify-between p-8 mb-5">
                      <div className="flex items-center gap-10">
                        <div>
                          <input
                            type="checkbox"
                            className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                                isBank === "12345"
                                ? "bg-red-700"
                                : "bg-zinc-300"
                              }`}
                            checked={isBank === "12345"}
                      value="12345"
                      onChange={() => handleBankChange("12345")}
                          />
                        </div>

                        <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">Bank Of America</div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Routing
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                            021*****21
                          </div>
                        </div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Account
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                            498*****85
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-10">
                        <div className=" bg-red-700 rounded-2xl">
                          <div className="flex items-center gap-1 p-2">
                            <img src={tick} alt="" />
                            <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                              Active
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Edit
                          </div>
                          <img src={pen} alt="" />
                        </div>
                        <div className="border-l-2 border-stone-300 h-10 w-px"></div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Remove
                          </div>
                          <img src={cancel} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`rounded-[15px] shadow  ${
                    isBank === "123456" ? "bg-white" : "bg-neutral-50"
                  }`}>
                    <div className="flex items-center justify-between p-8 mb-5">
                      <div className="flex items-center gap-10">
                        <div>
                          <input
                            type="checkbox"
                            className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                                isBank === "123456"
                                ? "bg-red-700"
                                : "bg-zinc-300"
                              }`}
                            checked={isBank === "123456"}
                            value="123456"
                            onChange={() => handleBankChange("123456")}
                          />
                        </div>

                        <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">Capital One</div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Routing
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                            021*****21
                          </div>
                        </div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Account
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                            498*****85
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-10">
                        <div className=" bg-red-700 rounded-2xl">
                          <div className="flex items-center gap-1 p-2">
                            <img src={tick} alt="" />
                            <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                              Active
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Edit
                          </div>
                          <img src={pen} alt="" />
                        </div>
                        <div className="border-l-2 border-stone-300 h-10 w-px"></div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Remove
                          </div>
                          <img src={cancel} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Bank Name :
                    </div>
                    <input
                      className="bg-stone-100 rounded-[10px] p-3"
                      type="text"
                      placeholder="Enter your bank name"
                    />
                  </div>

                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Routing Number :
                    </div>
                    <input
                      className="bg-stone-100 rounded-[10px] p-3"
                      type="text"
                      placeholder="Enter your routing number"
                    />
                  </div>
                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Account Number :
                    </div>
                    <input
                      className="bg-stone-100 rounded-[10px] p-3"
                      type="text"
                      placeholder="Enter your bank account number"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-5 pt-10">
                  <img src={max} alt="" />
                  <div className=" text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-snug">
                    Add an additional payment method
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/* six */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-5">
              <div className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                Payment Method
              </div>
              <div className="text-center text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                Manage your connected cards
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingSix ? min : max}
                alt="min icon"
                onClick={handleClickSix}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingSix ? (
            <div className="pt-5">
              <div>
              <div>
                  <div className={`rounded-[15px] shadow ${
                    isPayment === "123" ? "bg-white" : "bg-neutral-50"
                  }`}>
                    <div className="flex items-center justify-between p-8 mb-5">
                      <div className="flex items-center gap-10">
                        <div>
                          <input
                            type="checkbox"
                            className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                                isPayment === "123"
                                ? "bg-red-700"
                                : "bg-zinc-300"
                              }`}
                            checked={isPayment === "123"}
                            value="123"
                            onChange={() => handlePaymentChange("123")}
                          />
                        </div>

                        <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">Master Card</div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Card number
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                          498********85
                          </div>
                        </div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Validity
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                            12/24
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-10">
                        <div className=" bg-red-700 rounded-2xl">
                          <div className="flex items-center gap-1 p-2">
                            <img src={tick} alt="" />
                            <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                              Active
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Edit
                          </div>
                          <img src={pen} alt="" />
                        </div>
                        <div className="border-l-2 border-stone-300 h-10 w-px"></div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Remove
                          </div>
                          <img src={cancel} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`rounded-[15px] shadow ${
                    isPayment === "1234" ? "bg-white" : "bg-neutral-50"
                  }`}>
                    <div className="flex items-center justify-between p-8 mb-5">
                      <div className="flex items-center gap-10">
                        <div>
                          <input
                            type="checkbox"
                            className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                                isPayment === "1234"
                                ? "bg-red-700"
                                : "bg-zinc-300"
                              }`}
                            checked={isPayment === "1234"}
                            value="1234"
                            onChange={() => handlePaymentChange("1234")}
                          />
                        </div>

                        <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">Visa Card</div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Card number
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                          498********85
                          </div>
                        </div>
                        <div>
                          <div className="text-zinc-800 text-xl font-normal font-['Hind Guntur'] leading-loose">
                            Validity
                          </div>
                          <div className="w-[76px] h-[18px] text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                            12/24
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-10">
                        <div className=" bg-red-700 rounded-2xl">
                          <div className="flex items-center gap-1 p-2">
                            <img src={tick} alt="" />
                            <div className="text-center text-white text-sm font-medium font-['Hind Guntur'] leading-[17.26px]">
                              Active
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Edit
                          </div>
                          <img src={pen} alt="" />
                        </div>
                        <div className="border-l-2 border-stone-300 h-10 w-px"></div>
                        <div className="flex items-center gap-2">
                          <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
                            Remove
                          </div>
                          <img src={cancel} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="flex items-center justify-between">

                <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Card holder name :
                    </div>
                    <input
                      className="bg-stone-100 rounded-[10px] p-3"
                      type="text"
                      placeholder="Enter card hold name"
                    />
                  </div>


                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Card number :
                    </div>
                    <input
                      className="bg-stone-100 rounded-[10px] p-3"
                      type="text"
                      placeholder="Enter your card number"
                    />
                  </div>

                  

                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Card validity :
                    </div>
                    <input
                      className="bg-stone-100 rounded-[10px] p-3"
                      type="text"
                      placeholder="Enter your card validity"
                    />
                  </div>

                  <div>
                    <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-loose">
                      Enter your card CVV :
                    </div>
                    <input
                      className="bg-stone-100 rounded-[10px] p-3"
                      type="text"
                      placeholder="Enter your card CVV"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-5 pt-10">
                  <img src={max} alt="" />
                  <div className=" text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-snug">
                    Add an additional payment method
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center gap-5 pl-24 py-20">
        <div>
          <img
            className="cursor-pointer"
            src={upArrow}
            alt=""
            onClick={scrollToTop}
          />
        </div>
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
          Back To Top
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DonorSettings;
