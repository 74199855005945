import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import RedWave from "../assets/images/RedWave.svg";
import RedwaveLogo from "../components/icons/RedwaveLogo";
import Campaigns from "../assets/icons/Campaigns.svg";
import Donors from "../assets/icons/Donors.svg";
import Banks from "../assets/icons/Banks.svg";
import Payments from "../assets/icons/Payments.svg";
import Reporting from "../assets/icons/Reporting.svg";
import Eye from "../assets/icons/Eye.svg";
import RedWaveMobile from "../assets/icons/RedWaveMobile.svg";
import { setAuthData } from "../redux/auth/authSlice";
import { useAppDispatch } from "../redux/hooks";
import { DevTool } from "@hookform/devtools";
import httpRequest from "../axios/index";
import { toast } from "react-hot-toast";
import { API_BASE_URL, REGISTER } from "../constants/apiEndPoints";

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

const iconsData = [
  { id: 1, text: "Campaigns", icon: Campaigns },
  { id: 2, text: "Donors", icon: Donors },
  { id: 3, text: "Banks", icon: Banks },
  { id: 4, text: "Payments", icon: Payments },
  { id: 5, text: "Reporting", icon: Reporting },
];

function Register() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [showConfrimPassowrd, setShowConfirmPassword] =
    useState<boolean>(false);
  const [passwordMatchError, setPasswordMatchError] = useState<string | null>(
    null
  );

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordChange = () => {
    // Clear the error when the confirm password field changes
    setPasswordMatchError(null);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    const { email, password } = data;

    if (data.password !== data.confirmPassword) {
      setPasswordMatchError("Passwords do not match");
    } else {
      setPasswordMatchError(null);
      setloading(true);
      try {
        const res = await httpRequest.post(`${API_BASE_URL}${REGISTER}`, {
          email,
          password,
        });
        if (res?.status === 200 || res?.status === 201) {
          dispatch(setAuthData(res?.data));
          localStorage.setItem("userToken", res?.data?.access_token);
          navigate("/onboarding");
        } else {
          toast.error(res?.data?.message || "signup failed");
        }
      } catch (error: any) {
        toast.error(
          (error.response?.data?.message as string) || "Something went wrong"
        );
      } finally {
        setloading(false);
      }
    }
  };

  return (
    <div className="flex bg-[url('/src/assets/images/Signup.png')] bg-cover bg-center ">
      <div className="hidden md:hidden lg:block items-center justify-center">
        <div className="flex-1 lg:w-[892px] lg:hidden xl:block px-20 py-32">
          <RedwaveLogo />
          <span className="text-white text-xl font-medium">
            America’s existing campaign and contribution management platforms
            are <br />
            decades old and broken at best. Learn how to more effectively set-up
            your <br />
            campaign, manage contributions, and offer an intuitive means for
            donors <br />
            to find like minded candidates.
          </span>
          <div className="flex flex-wrap mt-9 flex-col gap-9">
            <p className="text-white text-2xl font-bold">Create Account</p>
            <p className="text-white text-xl font-medium font-['Hind Guntur']">
              Sign up to get access to your Payment, Campaign, and Compliance{" "}
              <br />
              management that takes the busy work out of fundraising and
              reporting{" "}
            </p>
          </div>
          <div className="py-14">
            <span className="text-white text-2xl font-bold">
              The RedWave Ecosystem Connects
            </span>
          </div>
          <div className="flex flex-row gap-14">
            {iconsData.map((icon) => (
              <div key={icon.id} className="flex flex-col items-center">
                <div className={`p-3 rounded-full ${icon.icon} text-white`}>
                  <img src={icon.icon} alt={icon.icon} className="w-16 h-16" />
                </div>
                <p className="text-center mt-2 text-white text-2xl">
                  {icon.text}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="py-12">
            <div>
              <span className="text-red-700 text-xl font-bold">1000+ </span>
              <span className="text-white text-xl font-bold">
                Committees, Campaigns, Donors, Financial Institutions, and
                Regulators <br /> are already with{" "}
              </span>
              <span className="text-red-700 text-xl font-bold">RedWave.</span>
            </div>
          </div> */}
          {/* <div className="py-4">
            <span className="text-white text-xl font-medium">
              Join Now to explore more about your favourite Candidates
            </span>
          </div> */}
        </div>
      </div>
      <div className="bg-black bg-opacity-50 md:bg-black md:bg-opacity-50 lg:bg-black lg:bg-opacity-50 xl:bg-black xl:bg-opacity-50 h-auto md:h-auto lg:h-auto lg:w-[548px] text-white w-[48px] p-6 flex-grow flex-col justify-start items-center">
        <div className="text-center mt-20 md:mt-20 xl:mt-36 lg:mt-36">
          <div className="flex flex-wrap items-center justify-center">
            <img
              src={RedWave}
              alt="RedWave"
              className="w-64 h-56 hidden md:block lg:block"
            />
            <img
              src={RedWaveMobile}
              alt="RedWaveMobile"
              className="block md:hidden lg:hidden"
            />
          </div>
          <p className="text-white text-base mt-4 block md:block lg:hidden font-bold font-['DM Sans']">
            The RedWave Ecosystem Connects
          </p>
          <div className="flex flex-row gap-2 justify-center mt-4 md:mt-4 lg:mt-0">
            {iconsData.map((icon) => (
              <div key={icon.id} className="flex flex-col items-center">
                <div
                  className={`p-3 rounded-full ${icon.icon} text-white block md:block lg:hidden`}
                >
                  <img src={icon.icon} alt={icon.icon} className="w-16 h-16" />
                </div>
                <p className="text-center mt-2 text-white text-xs md:text-md lg:text-2xl block md:hidden lg:hidden">
                  {icon.text}
                </p>
              </div>
            ))}
          </div>
          <p className="text-center text-white text-base mt-4 block font-medium font-['Hind Guntur'] block md:block lg:hidden">
            Join Now to explore more about your favourite Candidates
          </p>
          <form
            onSubmit={handleSubmit(submitForm)}
            className="flex flex-col justify-start items-center gap-5 p-7"
          >
            <input
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Enter a valid email address",
                },
              })}
              className="w-[350px] h-[50px] p-4 bg-transparent focus:outline-none border text-white text-sm font-medium rounded-xl"
              placeholder="Enter your email address"
            />
            {errors.email && (
              <span className="-mt-3 flex flex-row justify-start text-start text-red-600">
                {errors.email.message}
              </span>
            )}
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: "Passowrd is required",
                })}
                className="w-[350px] h-[50px] p-4 bg-transparent focus:outline-none border text-white text-sm font-medium rounded-xl"
                placeholder="Enter your password"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
              >
                {showPassword ? (
                  <img src={Eye} alt="Eye" />
                ) : (
                  <img src={Eye} alt="Eye" />
                )}
              </button>
            </div>
            {errors.password && (
              <span className="-mt-3 flex flex-row justify-start text-start text-red-600">
                {errors.password.message}
              </span>
            )}
            <div className="relative">
              <input
                type={showConfrimPassowrd ? "text" : "password"}
                {...register("confirmPassword", {
                  required: "Confirm Passowrd is required",
                })}
                className="w-[350px] h-[50px] p-4 bg-transparent focus:outline-none border text-white text-sm font-medium rounded-xl"
                placeholder="Confirm your password"
              />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
              >
                {showConfrimPassowrd ? (
                  <img src={Eye} alt="Eye" />
                ) : (
                  <img src={Eye} alt="Eye" />
                )}
              </button>
            </div>
            {errors.confirmPassword && (
              <span className="-mt-3 flex flex-row justify-start text-start text-red-600">
                {errors.confirmPassword.message}
              </span>
            )}
            {passwordMatchError && (
              <span className="-mt-3 flex flex-row justify-start text-start text-red-600">
                {passwordMatchError}
              </span>
            )}
            <div className="flex flex-nowrap items-center gap-6 md:gap-5 lg:gap-5 xl:gap-5 py-6">
              <input
                type="checkbox"
                id="termsCheckbox"
                className="mr-2 w-5 h-5"
              />
              <label
                htmlFor="termsCheckbox"
                className="text-[13px] md:text-[15px] lg:text-[15px] xl:text-[15px] font-medium"
              >
                I agree to{" "}
                <span className="text-red-600">Terms of service</span> &{" "}
                <span className="text-red-600">Privacy policy</span>
              </label>
            </div>
            <div className="px-2">
              <input
                type="submit"
                value={loading ? "Loading..." : "Sign Up"}
                className="w-80 h-12 bg-red-600 rounded-3xl text-xl font-medium"
                disabled={loading}
              />
            </div>
            <div className="flex items-center justify-between gap-2">
              <span className="text-base font-medium">
                Already have an account?
              </span>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => navigate("/login")}
                className="text-red-600 text-xl font-semibold bg-none outline-none border border-none"
              >
                Login
              </button>
            </div>
            <div className="flex items-center justify-between py-5 gap-4">
              <span className="text-sm font-normal">Privacy Policy</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
                fill="none"
              >
                <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
              </svg>
              <span className="text-sm font-norma">Terms of use</span>
            </div>
          </form>
          <DevTool control={control} /> {/* set up the dev tool */}
        </div>
      </div>
    </div>
  );
}

export default Register;
