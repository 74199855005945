import React from 'react'

const Electorial = ({color}) => {
  return (
    <svg width="37" height="45" viewBox="0 0 37 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.8316 7.81689H22.7042C22.5117 7.81689 22.3271 7.74042 22.191 7.6043C22.0549 7.46819 21.9784 7.28358 21.9784 7.09108V0H6.86719V16.3813H29.8316V7.81689ZM12.7897 11.758L10.2131 9.18139L11.2365 8.15801L12.7897 9.70397L17.355 5.13868L18.3494 6.16206L12.7897 11.758Z" fill={color}/>
<path d="M1.89062 44.9994H34.813V22.6084H1.89062V44.9994ZM15.1946 30.2003L17.8728 26.7818L20.5292 30.2003L23.9405 31.2091L21.48 34.5914L21.6252 38.351L17.8728 37.0156L14.1204 38.351L14.2583 34.5914L11.8196 31.2091L15.1946 30.2003Z" fill={color}/>
<path d="M26.1352 3.70262L23.457 1.02441V6.36632H28.8062L26.1352 3.70262Z" fill={color}/>
<path d="M0 17.8398V21.1568H0.435481H36.2611H36.6965V17.8398H0Z" fill={color}/>
</svg>
  )
}

export default Electorial


