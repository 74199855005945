import RedDot from '../../../../assets/images/RedDot.svg';
import WhiteDot from '../../../../assets/icons/WhiteDot.svg';



export interface SidebarItem {
    id: number;
    title: string;
    icon: string;
    selectedIcon: string;
    path: string;
}

export const CandidateSidebarData: SidebarItem[] = [
    {
        id: 1,
        title: 'My Info',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/my-info',
    },
    {
        id: 2,
        title: 'Candidate Info',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/candidate-info',
    },
    {
        id: 3,
        title: 'Political Affiliation',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/political-affiliation',
    },
    {
        id: 4,
        title: 'Campaign Info',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/campaign-info',
    },
    {
        id: 5,
        title: 'Company Info',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/company-info',
    },
    {
        id: 6,
        title: 'Biography',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/biography',
    },
    {
        id: 7,
        title: 'Education',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/education',
    },
    {
        id: 8,
        title: 'Issues',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/issues',
    },
    {
        id: 9,
        title: 'Creative Assets',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/creative-assets',
    },
    {
        id: 10,
        title: 'Social Media',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/social-media',
    },
];
