import React from 'react'

const Education = ({color}) => {
  return (
    <svg width="45" height="37" viewBox="0 0 45 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.3084 22.8133L29.7224 24.7998C27.5175 26.0202 25.019 26.6667 22.4975 26.6667C19.978 26.6667 17.4801 26.0207 15.2731 24.7998L10.8751 22.3633C10.9301 24.4048 10.8441 26.5737 10.0121 28.3042C9.78264 28.7767 9.32365 29.2042 9.33215 29.7722C9.34215 30.5602 10.2381 30.6932 10.8831 30.5732C10.8831 30.5732 14.6851 29.8442 19.946 35.0002C20.4685 35.5127 20.984 35.9946 21.692 36.2451C23.0775 36.7366 24.1075 35.8877 25.0595 34.9912C25.7075 34.3817 26.43 33.8527 27.156 33.3382C28.899 32.1067 31.1109 30.5582 33.3314 30.5207C33.3314 30.5207 36.1959 31.1457 34.5294 28.4897C34.5294 28.4897 33.7659 26.0292 33.3084 22.8133Z" fill={color}/>
<path d="M4.99755 20.8333L4.16406 25.8333V32.5002H7.49753V25.8333L6.66404 20.8333V16.2204L4.99755 15.2969V20.8333Z" fill={color}/>
<path d="M44.2493 10.3903L28.11 1.45086C24.617 -0.48362 20.3821 -0.48362 16.8911 1.45086L0.749243 10.3903C-0.249748 10.9443 -0.249748 12.3883 0.749243 12.9433L4.9992 15.2962V11.6668H24.166V13.3332H6.66568V16.2197L16.8906 21.8832C20.3815 23.8166 24.6165 23.8166 28.1095 21.8832L44.2488 12.9438C45.2503 12.3883 45.2503 10.9448 44.2493 10.3903Z" fill={color}/>
</svg>
  )
}

export default Education


