import { useRef, useState } from "react";
import speaker from "../assets/icons/speakerIconLg.svg";
import donation from "../assets/icons/donationIconLg.svg";
import FeatureModal from "./FeatureModal";
import FeatureModal2 from "./FeatureModal2";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SliderSettings {
  dots: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  infinite: boolean;
  autoplay: boolean;
  autoplaySpeed: number;
  arrows?: boolean;
}

const FeatureSection: React.FC = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [featureModal, setIsFeatureModal] = useState(false);
  const [featureModal2, setIsFeatureModal2] = useState(false);
  const [isCandidateHover, setIsCandidateHover] = useState(false)
  const [isCommitteeHover, setIsCommitteeHover] = useState(false)

  const [startX, setStartX] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (startX !== null && containerRef.current) {
      const touchDelta = startX - e.touches[0].clientX;
      containerRef.current.scrollLeft += touchDelta;
      setStartX(e.touches[0].clientX);
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
    if (containerRef.current) {
      const cardWidth = containerRef.current.offsetWidth;
      const newActiveIndex = Math.round(
        containerRef.current.scrollLeft / cardWidth
      );
      setActiveCardIndex(newActiveIndex);
    }
  };

  const handleDotClick = (index: number) => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: index * containerRef.current.offsetWidth,
        behavior: "smooth",
      });
      setActiveCardIndex(index);
    }
  };

  const settings: SliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <>
      {/* for big screens */}
      {/* "hidden max-w-full h-[90%] overflow-hidden relative pt-24 lg:block lg:pt-36" */}
      <div
        className="hidden max-w-full overflow-hidden relative lg:flex justify-center "
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={containerRef}
      >
        {/* "flex  transition-transform ease-in-out duration-300 h-[70vh]  lg:h-full lg:justify-center lg:gap-x-20 */}
        <div
          className="flex  transition-transform ease-in-out duration-300 lg:h-full lg:justify-center lg:gap-x-20"
          style={{ transform: `translateX(-${activeCardIndex * 100}%)` }}
          
        >
          {/* featureModal && !featureModal2 */}
          {featureModal ? (
            <FeatureModal
              isOpen={true}
              onClose={() => setIsFeatureModal(false)}
            />
          ) : (
            <div
              // className="px-[20px] lg:px-0"
              className={`px-[20px] lg:px-0 ${
                featureModal2 ? "xl:invisible" : "visible"
              }`}
              onClick={() => setIsFeatureModal(true)}
              
            >
              {/* cursor-pointer flex flex-col items-center w-[350px] min-w-[250px] rounded-[25px] h-[578px] bg-[#000000] bg-opacity-80 hover:bg-opacity-95 lg:w-[380px] xl:w-[554px] xl:h-[578px] */}
              <div className="group cursor-pointer flex flex-col items-center w-[350px] min-w-[250px] rounded-[25px] h-[450px] bg-[#000000] bg-opacity-80 hover:bg-opacity-95 lg:pt-16 lg:w-[366px] lg:h-[578px] xl:pt-0 xl:w-[554px] xl:h-[578px]">
                <div className="mt-12 group-hover:scale-125 transition-transform duration-500 ease-in-out">
                  <img
                    className="h-[70px] xl:h-[119.62px] xl:w-[148.29px] xl:mb-5"
                    src={`${speaker}`}
                    alt=""
                  />
                </div>
                <div className="text-white text-2xl font-bold mt-10 px-5 text-center xl:text-4xl">
                  Committees & Candidates
                </div>
                <p className="text-white text-sm text-center mt-10 px-5 xl:px-0 xl:w-[480px] xl:h-[90px] xl:text-xl ">
                  Candidates and active campaigns come to RedWave to promote
                  their message and get support from contributors and donors.
                </p>
                <div className="mt-10" onClick={() => setIsFeatureModal(true)}>
                  <button className="w-36 h-9 bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl">
                    Features
                  </button>
                </div>
              </div>
            </div>
          )}

{/* featureModal2 && !featureModal */}
          {featureModal2 ? (
            <FeatureModal2
              isOpen={true}
              onClose={() => setIsFeatureModal2(false)}
            />
          ) : (
            <div
              // className="px-[20px] lg:px-0"
              className={`px-[20px] lg:px-0 ${
                featureModal ? "xl:invisible" : "visible"
              }`}
            >
              <div
                className="group cursor-pointer flex flex-col items-center w-[350px] min-w-[250px] rounded-[25px] h-[450px] bg-[#000000] bg-opacity-80 hover:bg-opacity-95 lg:pt-16 lg:w-[366px] lg:h-[578px] xl:pt-0 xl:w-[554px] xl:h-[578px]"
                onClick={() => setIsFeatureModal2(true)}
              >
                <div className="mt-12 group-hover:scale-125 transition-transform duration-500 ease-in-out">
                  <img
                    className="h-[70px] xl:h-[119.62px] xl:w-[148.29px] xl:mb-5"
                    src={`${donation}`}
                    alt=""
                  />
                </div>
                <div className="text-white text-2xl font-bold mt-10 px-5 text-center xl:text-4xl">
                  Contributors & Donors
                </div>
                <p className="text-white text-sm text-center mt-10 px-5 xl:px-0 xl:w-[450px] xl:h-[90px] xl:text-xl">
                  Contributors come to RedWave to track political profiles and
                  learn more about candidates at the state and local levels.
                </p>
                <div className="mt-10" onClick={() => setIsFeatureModal2(true)}>
                  {/* <Button label="Features"></Button> */}
                  <button className="w-36 h-9 bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl">
                    Features
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Navigation Dots */}
        <div className="hidden md:flex absolute bottom-4 left-1/2 transform -translate-x-1/2 space-x-2 lg:hidden ">
          {[0, 1].map((index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              className={`h-3 w-3 rounded-full ${
                index === activeCardIndex ? "bg-red-500" : "bg-gray-300"
              }`}
            ></div>
          ))}
        </div>
      </div>

      {/* for mobile */}
      {/* block max-w-full h-[90%] relative pt-24 lg:hidden */}
      <div className="block max-w-full relative lg:hidden">
        <Slider {...settings}>
          <div className="flex transition-transform ease-in-out duration-300 h-[60vh]">
            {featureModal && !featureModal2 ? (
              <FeatureModal
                isOpen={true}
                onClose={() => setIsFeatureModal(false)}
              />
            ) : (
              <div
                className="px-2 lg:px-0"
                // className={`px-2 lg:px-0 ${
                //   featureModal2 ? "invisible" : "visible"
                // }`}
                onClick={() => setIsFeatureModal(true)}
              >
                {/* h-[450px] */}
                <div className="cursor-pointer flex flex-col items-center w-full min-w-[250px] rounded-[25px] h-[578px] bg-[#000000] bg-opacity-80 hover:bg-opacity-95 lg:w-[380px] xl:w-[554px] xl:h-[578px]">
                  <div className="mt-12 hover:scale-125 transition-transform duration-500 ease-in-out">
                    <img
                      className="h-[96px] xl:h-[119.62px] xl:w-[148.29px] xl:mb-5"
                      src={`${speaker}`}
                      alt=""
                    />
                  </div>
                  <div className="text-white text-[32px] font-bold mt-10 px-5 text-center xl:text-4xl">
                    Committees & Candidates
                  </div>
                  <p className="text-white text-lg text-center mt-10 px-5 xl:px-0 xl:w-[480px] xl:h-[90px] xl:text-xl ">
                    Candidates and active campaigns come to RedWave to promote
                    their message and get support from contributors and donors.
                  </p>
                  <div
                    className="mt-10"
                    onClick={() => setIsFeatureModal(true)}
                  >
                    <button className="w-36 h-9 bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl">
                      Features
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex  transition-transform ease-in-out duration-300 h-[70vh]">
            {featureModal2 && !featureModal ? (
              <FeatureModal2
                isOpen={true}
                onClose={() => setIsFeatureModal2(false)}
              />
            ) : (
              <div
                className="px-2 lg:px-0"
                // className={`px-2 lg:px-0 visible ${
                //   featureModal ? "invisible" : "visible"
                // }`}
              >
                <div
                  className="cursor-pointer flex flex-col items-center w-full min-w-[250px] rounded-[25px] h-[578px] bg-[#000000] bg-opacity-80 hover:bg-opacity-95 lg:w-[380px] xl:w-[554px] xl:h-[578px]"
                  onClick={() => setIsFeatureModal2(true)}
                >
                  <div className="mt-12 hover:scale-125 transition-transform duration-500 ease-in-out">
                    <img
                      className="h-[87px] xl:h-[119.62px] xl:w-[148.29px] xl:mb-5"
                      src={`${donation}`}
                      alt=""
                    />
                  </div>
                  <div className="text-white text-[32px] font-bold mt-10 px-5 text-center xl:text-4xl">
                    Contributors & Donors
                  </div>
                  <p className="text-white text-lg text-center mt-10 px-5 xl:px-0 xl:w-[450px] xl:h-[90px] xl:text-xl">
                    Contributors come to RedWave to track political profiles and
                    learn more about candidates at the state and local levels.
                  </p>
                  <div
                    className="mt-10"
                    onClick={() => setIsFeatureModal2(true)}
                  >
                    {/* <Button label="Features"></Button> */}
                    <button className="w-36 h-9 bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl">
                      Features
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Slider>
      </div>
    </>
  );
};

export default FeatureSection;
