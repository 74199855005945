import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import Back from "../../../../assets/images/Back.svg";
import { fetchPlacementData } from "../../../../redux/candidate/googleApiSlice";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { committeeInfo } from "../../../../redux/committee/committeeSlice";
import httpRequest from "../../../../axios/index";
import {
  API_BASE_URL,
  COMMITTEE_INFORMATION,
} from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import { setAuthData } from "../../../../redux/auth/authSlice";
import { UpdateCommitteeForm } from "../../../../redux/committee/CommitteeNewSlice";

type FormValues = {
  id: string | number;
  type: string;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  website: string;
};

function CommiteeInfo() {
  const data = useAppSelector((state: any) => state.google.data);
  // console.log("placement", data);
  const [loading, setloading] = useState<boolean>(false);
  const authinfo = useAppSelector((state: any) => state.auth.userData);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    try {
      setloading(true);
      const res = await httpRequest.post(
        `${API_BASE_URL}/onboarding/committee/0/info`,
        {
          type: data.type,
          name: data.name,
          email: data.email,
          phone_number: data.phone_number,
          address: data.address,
          website: data.website,
        }
      );
      console.log(
        "🚀 ~ constsubmitForm:SubmitHandler<FormValues>= ~ res:",
        res
      );
      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data?.committee,
        };
        toast.success("added successfully!");
        dispatch(setAuthData(updatedAuthInfo));
        dispatch(UpdateCommitteeForm("PoliticalAffiliation"));
      } else {
        toast.error(res?.data?.message || "signup failed");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Candidate Info</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                What type of PAC or Committee are you :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <select
                {...register("type", {
                  required: "Type is required",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] px-2 text-[#8C8C8C] font-['Hind Guntur']"
                value=""
              >
                <option value="default" disabled selected hidden>
                  Select Political Affiliation
                </option>
                <option value="National">
                  Additional Party Committee ( National )
                </option>
              </select>
              {errors.email && (
                <span className="-mt-3 flex flex-row justify-start text-start text-red-600">
                  {errors.email.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4 md:py-6 lg-py-6">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Committee name :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("name", {
                  required: "Name is required",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter your Committee name"
              />
              <div className="mt-2">
                {errors.name && (
                  <span className="-mt-3 flex flex-row justify-start text-start text-red-600">
                    {errors.name.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Committee website address :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("website")}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter Committee website address"
              />
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Committee phone number :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("phone_number")}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter your Committee phone number"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Committee email address :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("email")}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter Committee email address"
              />
            </div>
          </div>

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4 md:py-10 lg:py-10">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Committee business address :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="flex flex-col gap-1">
                <input
                  type="text"
                  {...register("address")}
                  className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                  placeholder="Enter your Committee business address"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div className="flex flex-wrap gap-2 text-base font-medium cursor-pointer">
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button className="hidden sm:inline-block">Back</button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[24px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Committee Info
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CommiteeInfo;
