import React from "react";
import Footer from "../../components/Footer";
import MainContainer from "./MainContainer";
import Header from "../../components/Header";

const Home = () => {
  return (
    <>
    <Header />
      <MainContainer />
      {/* <Footer /> */}
    </>
  );
};

export default Home;
