import React from 'react'

const Speaker = ({color}) => {
  return (
    <svg width="149" height="120" viewBox="0 0 149 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M104.852 109.976H99.2055C95.4701 109.976 92.4297 107.023 92.4297 103.287V6.68891C92.4297 2.95355 95.4701 0 99.2055 0H104.852C108.587 0 111.541 2.95355 111.541 6.68891V103.287C111.541 107.023 108.587 109.976 104.852 109.976Z" fill={color}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.016 42.5654C119.967 44.5634 123.355 49.4281 123.355 54.9877C123.355 60.6342 119.967 65.412 115.016 67.4968V42.5654Z" fill={color === "#FFFFFF" ? "#FFFFFF" : "#CB000E" }/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M88.9522 95.2081C86.5199 94.3394 83.7401 93.4707 80.6997 92.602V65.2383C80.6997 64.2827 79.9179 63.5009 78.9623 63.5009C78.0067 63.5009 77.2249 64.2827 77.2249 65.2383V91.5596C67.7562 89.0404 55.942 86.5212 42.043 84.8707C42.3036 84.1757 42.5642 83.4808 42.5642 82.6121V37.8745C42.5642 37.0927 42.3904 36.3109 42.043 35.6159C44.9096 35.0078 47.6026 34.3129 50.2955 33.5311V53.5109C50.2955 54.4665 51.0773 55.2483 52.0329 55.2483C52.9885 55.2483 53.7703 54.4665 53.7703 53.5109V32.5755C68.6249 27.9715 80.4391 21.7169 88.9522 16.2441V95.2081ZM78.9623 58.8968C78.0067 58.8968 77.2249 58.2019 77.2249 57.1594V50.2099C77.2249 49.2544 78.0067 48.4725 78.9623 48.4725C79.9179 48.4725 80.6997 49.2544 80.6997 50.2099V57.1594C80.6997 58.2019 80.0047 58.8968 78.9623 58.8968ZM52.0329 66.9756C51.0773 66.9756 50.2955 66.1938 50.2955 65.2383V60.4605C50.2955 59.5049 51.0773 58.7231 52.0329 58.7231C52.9885 58.7231 53.7703 59.5049 53.7703 60.4605V65.2383C53.7703 66.1938 52.9885 66.9756 52.0329 66.9756Z" fill={color}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.3537 84.3493H19.5455C8.77377 84.3493 0 75.6624 0 64.8906V55.6825C0 44.9107 8.77377 36.137 19.5455 36.137H37.3537C38.3092 36.137 39.0911 36.9188 39.0911 37.8743V82.6119C39.0911 83.5674 38.3092 84.3493 37.3537 84.3493ZM13.4647 70.1896C12.5091 70.1896 11.7273 69.4078 11.7273 68.4522V52.034C11.7273 51.0784 12.5091 50.2966 13.4647 50.2966C14.5071 50.2966 15.2021 51.0784 15.2021 52.034V68.4522C15.2021 69.4078 14.5071 70.1896 13.4647 70.1896ZM25.5395 70.1896C24.5839 70.1896 23.8021 69.4078 23.8021 68.4522V52.034C23.8021 51.0784 24.5839 50.2966 25.5395 50.2966C26.4951 50.2966 27.2769 51.0784 27.2769 52.034V68.4522C27.2769 69.4078 26.4951 70.1896 25.5395 70.1896Z" fill={color}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.27 119.619H27.2781C23.1953 119.619 19.8942 116.405 19.1993 111.714L18.5043 107.457H42.8277L43.4357 111.192C43.6963 113.19 43.0883 115.102 41.7852 116.665C40.1347 118.489 37.7892 119.619 35.27 119.619ZM17.9831 103.982L15.5508 87.4772C16.8538 87.7378 18.1569 87.8246 19.5468 87.8246H37.3549C38.2236 87.8246 39.0054 87.6509 39.7872 87.2166L42.3064 103.982H17.9831Z" fill={color}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M146.55 56.2043H129.437C128.481 56.2043 127.699 55.4224 127.699 54.4669C127.699 53.5113 128.481 52.7295 129.437 52.7295H146.55C147.505 52.7295 148.287 53.5113 148.287 54.4669C148.287 55.4224 147.505 56.2043 146.55 56.2043Z" fill={color === "#FFFFFF" ? "#FFFFFF" : "#CB000E" }/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.564 44.1293C130.043 44.1293 129.522 43.8687 129.174 43.3475C128.566 42.6526 128.74 41.5233 129.522 41.0021L144.376 30.2303C145.158 29.7091 146.201 29.8828 146.809 30.6647C147.33 31.4465 147.156 32.4889 146.374 33.0101L131.607 43.7819C131.259 44.0425 130.912 44.1293 130.564 44.1293Z" fill={color === "#FFFFFF" ? "#FFFFFF" : "#CB000E" }/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M145.334 79.137C144.986 79.137 144.639 78.9633 144.378 78.7895L129.524 68.0178C128.742 67.4966 128.655 66.3673 129.176 65.5854C129.697 64.8036 130.827 64.6299 131.609 65.238L146.376 75.9229C147.158 76.5309 147.332 77.5734 146.811 78.3552C146.463 78.8764 145.942 79.137 145.334 79.137Z" fill={color === "#FFFFFF" ? "#FFFFFF" : "#CB000E" }/>
<path d="M45.1809 90.7778H38.3945V100.221H45.1809V90.7778Z" fill={color}/>
</svg>
  )
}

export default Speaker


