import React from 'react'

const Conservation = ({color}) => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.9181 19.2051C44.436 19.2051 44.0342 19.2051 43.5521 19.2051C22.4985 19.2051 23.5431 39.5354 24.1056 44.0354C26.5967 38.8926 31.4181 30.2944 36.8824 28.6069C36.6414 28.8479 29.7306 37.1247 26.9985 44.9997C47.3289 44.7586 44.9181 19.2051 44.9181 19.2051Z" fill={color}/>
<path d="M0.000691768 16.0723C0.000691768 16.0723 -0.401094 39.6973 18.1614 40.2598C17.6793 39.1348 17.1971 38.0098 16.6346 36.8848C13.4203 30.6973 9.00069 25.3937 8.83998 25.1526C12.0543 26.1973 15.0275 29.5723 17.4382 33.1883C18.8043 35.2776 20.0096 37.4473 20.9739 39.3758C21.2953 35.5187 21.456 17.5187 0.000691768 16.0723Z" fill={color}/>
<path d="M22.5788 0C22.5788 0 7.39129 10.4464 21.1324 24.1875C21.3734 22.7411 21.5341 21.375 21.6949 20.0089C22.177 15.1071 22.0163 11.1696 22.0163 11.1696C23.3824 13.1786 23.8645 16.1518 23.9449 19.2054C23.9449 20.9732 23.8645 22.7411 23.6234 24.5089C26.9984 21.2946 35.8377 10.6875 22.5788 0Z" fill={color}/>
</svg>
  )
}

export default Conservation

