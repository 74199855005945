import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

type GoogleState = {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    data: [],
    error: string,
}

const initialState: GoogleState = {
    loading: 'idle',
    data: [],
    error: '',
}

export const fetchPlacementData = createAsyncThunk('placement/fetchData', async () => {
    try {
        const response = await axios.get('https://jsonplaceholder.typicode.com/posts');
        return response.data;
    } catch (error) {
        console.log("error", error);
        return error;
    }
}
);



export const authSlice = createSlice({
    name: 'google',
    initialState,
    reducers: {
        google(state, action: PayloadAction<[]>) {
            console.log('google');
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPlacementData.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(fetchPlacementData.fulfilled, (state, action) => {
            state.loading = 'succeeded'
            state.data = action.payload;
            // state.error = ''
            console.log('Data fetched successfully:', action.payload);
        })
        builder.addCase(fetchPlacementData.rejected, (state, action) => {
            state.loading = 'failed'
            // state.data = action.payload;
            // state.error = ''
            // console.log('Data fetched successfully:', action.payload);
        });
    },
})

// Action creators are generated for each case reducer function
export const { google } = authSlice.actions

export default authSlice.reducer