export default function IconCollection({
  className='w-[1.31rem] w-[1.31rem]',
}) {
  return (
    <svg
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.4755C0 2.55374 0.366168 1.66973 1.01795 1.01795C1.66973 0.366168 2.55374 0 3.4755 0C4.39726 0 5.28127 0.366168 5.93305 1.01795C6.58483 1.66973 6.951 2.55374 6.951 3.4755C6.951 4.39726 6.58483 5.28127 5.93305 5.93305C5.28127 6.58483 4.39726 6.951 3.4755 6.951C2.55374 6.951 1.66973 6.58483 1.01795 5.93305C0.366168 5.28127 0 4.39726 0 3.4755ZM8.049 11.5245C8.049 11.0681 8.1389 10.6162 8.31356 10.1945C8.48822 9.77282 8.74422 9.38968 9.06695 9.06695C9.38968 8.74422 9.77282 8.48822 10.1945 8.31356C10.6162 8.1389 11.0681 8.049 11.5245 8.049C11.9809 8.049 12.4328 8.1389 12.8545 8.31356C13.2762 8.48822 13.6593 8.74422 13.982 9.06695C14.3048 9.38968 14.5608 9.77282 14.7354 10.1945C14.9101 10.6162 15 11.0681 15 11.5245C15 12.4463 14.6338 13.3303 13.982 13.982C13.3303 14.6338 12.4463 15 11.5245 15C10.6027 15 9.71873 14.6338 9.06695 13.982C8.41517 13.3303 8.049 12.4463 8.049 11.5245Z" fill="white"/>
      <path d="M0 11.625C0 10.0343 -4.47035e-08 9.2385 0.49425 8.74425C0.9885 8.25 1.78425 8.25 3.375 8.25C4.96575 8.25 5.7615 8.25 6.25575 8.74425C6.75 9.2385 6.75 10.0343 6.75 11.625C6.75 13.2157 6.75 14.0115 6.25575 14.5057C5.7615 15 4.96575 15 3.375 15C1.78425 15 0.9885 15 0.49425 14.5057C-4.47035e-08 14.0115 0 13.2157 0 11.625ZM8.25 3.375C8.25 1.78425 8.25 0.9885 8.74425 0.49425C9.2385 -4.47035e-08 10.0343 0 11.625 0C13.2157 0 14.0115 -4.47035e-08 14.5057 0.49425C15 0.9885 15 1.78425 15 3.375C15 4.96575 15 5.7615 14.5057 6.25575C14.0115 6.75 13.2157 6.75 11.625 6.75C10.0343 6.75 9.2385 6.75 8.74425 6.25575C8.25 5.7615 8.25 4.96575 8.25 3.375Z" fill="white"/>
    </svg>
  )
}
