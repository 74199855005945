import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../../assets/images/Back.svg";
import Plus from "../../../../assets/icons/Plus.svg";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { committeeBiography } from "../../../../redux/committee/committeeSlice";
// import { biograpyInfo, setFormData } from '../../../../../redux/candidate/candidateSlice';

import { API_BASE_URL } from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import { setAuthData } from "../../../../redux/auth/authSlice";
import { UpdateCommitteeForm } from "../../../../redux/committee/CommitteeNewSlice";
import httpRequest from "../../../../axios/index";

interface PositionField {
  positionField: string;
}

type FormValues = {
  id: string | number;
  bio: string;
};

function CommitteeBiography() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<FormValues>();
  const submitForm: SubmitHandler<FormValues> = async (data) => {
    try {
      setloading(true);
      const res = await httpRequest.patch(
        `${API_BASE_URL}/onboarding/committee/${authinfo.slug}/biography`,
        { bio: data.bio }
      );

      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data?.committee,
        };
        dispatch(UpdateCommitteeForm("CreativeAssets"));
        dispatch(setAuthData(updatedAuthInfo));
        toast.success("added successfully!");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Biography</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Biography :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <textarea
                {...register("bio")}
                className="mt-2 p-2 w-[320px] h-[310px] md:w-[533px] md:h-[310px] lg:w-[733px] lg:h-[310px] xl:w-[733px] xl:h-[310px] bg-stone-50 rounded-[10px]"
                placeholder="Write a few sentences about your Committee"
              />
              <div className="flex flex-wrap justify-start text-slate-600">
                <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">
                  Max 200 Characters
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div className="flex flex-wrap gap-2 text-base font-medium cursor-pointer">
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button className="hidden sm:inline-block">Back</button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[24px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Biography
              </p>
              <button className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium">
                {loading ? "loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CommitteeBiography;
