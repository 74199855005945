import card1 from "../../src/assets/images/credit_card_1.png";
import card2 from "../../src/assets/images/credit_card_2.png";
import card3 from "../../src/assets/images/credit_card_3.png";
import card4 from "../../src/assets/images/creditCard.svg";
// import creditcard1 from "../../src/assets/images/creditCard1.svg";
// import creditcard2 from "../../src/assets/images/creditCard2.svg";
// import creditcard3 from "../../src/assets/images/creditCard3.svg";
// import creditcard4 from "../../src/assets/images/creditCard4.svg";

import creditcard1 from "../../src/assets/images/creditCardRed.svg";
import creditcard2 from "../../src/assets/images/creditCardBlack.svg";
import creditcard3 from "../../src/assets/images/creditCardYellow.svg";
import creditcard4 from "../../src/assets/images/creditCardPurple.svg";
import creditcard5 from "../../src/assets/images/creditCardWhite.svg";
import creditcard6 from "../../src/assets/images/creditCardGreen.svg";


import creditcardsm1 from "../../src/assets/images/creditCardRedSm.svg";
import creditcardsm2 from "../../src/assets/images/creditCardBlackSm.svg";
import creditcardsm3 from "../../src/assets/images/creditCardYellowSm.svg";
import creditcardsm4 from "../../src/assets/images/creditCardPurpleSm.svg";
import creditcardsm5 from "../../src/assets/images/creditCardWhiteSm.svg";
import creditcardsm6 from "../../src/assets/images/creditCardGreenSm.svg";


import flag from "../../src/assets/images/american_flag.svg";
// import profileCard1 from "../../src/assets/images/profile_card_1.png";
// import profileCard2 from "../../src/assets/images/profile_card_2.png";
// import profileCard3 from "../../src/assets/images/profile_card_3.png";
// import profileCard1 from "../../src/assets/images/profileCardOne.svg";
// import profileCard2 from "../../src/assets/images/profileCardTwo.svg";
// import profileCard3 from "../../src/assets/images/profileCardThree.svg";

import profileCard1 from "../../src/assets/images/profileCardOne.png";
import profileCard2 from "../../src/assets/images/profileCardTwo.png";
import profileCard3 from "../../src/assets/images/profileCardThree.png";

import profileCardSm1 from "../../src/assets/images/profileCardSm1.svg";
import profileCardSm2 from "../../src/assets/images/profileCardSm2.svg";
import profileCardSm3 from "../../src/assets/images/profileCardSm3.svg";

import whiteHouseNight from "../../src/assets/images/whiteHouseNight.png";
import americanmap from "../../src/assets/images/american_map.png";
import haroldmandoza from "../../src/assets/images/harold_mendoza.png";
import flowerimg from "../../src/assets/images/flowers.png";

import whiteHouselateNight from "../../src/assets/images/whiteHouseLateNight.png";
import redwave from "../../src/assets/images/logo.png";
import women from "../../src/assets/images/buisness_women.png";
import footer from "../../src/assets/images/footer_image.png";

export const slidesCreditCards = [
  {
    url: `${creditcard1}`,
  },
  {
    url: `${creditcard2}`,
  },
  {
    url: `${creditcard3}`,
  },
  {
    url: `${creditcard4}`,
  },
  {
    url: `${creditcard5}`,
  },
  {
    url: `${creditcard6}`,
  },
];

export const slidesCreditCardSm = [
  {
    url: `${creditcardsm1}`,
  },
  {
    url: `${creditcardsm2}`,
  },
  {
    url: `${creditcardsm3}`,
  },
  {
    url: `${creditcardsm4}`,
  },
  {
    url: `${creditcardsm5}`,
  },
  {
    url: `${creditcardsm6}`,
  },
];

export const slidesProfileCards = [
  {
    url: `${profileCard1}`,
  },
  {
    url: `${profileCard2}`,
  },
  {
    url: `${profileCard3}`,
  },
];

export const slidesProfileCardsSm = [
  {
    url: `${profileCardSm1}`,
  },
  {
    url: `${profileCardSm2}`,
  },
  {
    url: `${profileCardSm3}`,
  },
];

export const whiteHouse =
  "https://s3-alpha-sig.figma.com/img/df51/1863/79ce692e5c474dd6ea07631a4e529786?Expires=1702252800&Signature=ExgRgDuHTpMNJ1g4HLchYZC5EMIWdZu1~~1tvJrHZxHPFVw~LUj8W2LM-7wUR97ftz6a8bfiaHGUWqYldMi5xNu1dDXQOiBQ68Q-P66pEnAzzEhnpuv3lX2jPNC94-LKIfzok96ZT~EW9bvbd~cILhliRQx5PYFwGgwOTINwZi9sbFzrrL92H753CgFFS1jE-oG9JU~8zzRshuIFgLl~ZP6gKupU0X46j-UXDtYqFNi1Il523egF4XTjjfzAlz5BL9NIeEa~EsZJuRb3jJ04ccjADgcM3hlUrQHTSNnoXXvrHvf1gWZVdEkOeMPT5bvtvJDyhI-qiZJZHsvt8NAfrw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4";

export const rome =
  "https://s3-alpha-sig.figma.com/img/49ea/c313/d93bf8bc0addc6c057f96ed6b0a1f7fb?Expires=1702252800&Signature=qTGE96BcsUKkQx1ziDJTQmb0rCuDueTwHf~7pRdVN-BU2EZVx53~Ihr0dBegyWfAfKG7Rn3CRekL86Altw-Ghe3RA-KT3plVPF5TrBHOrXukohTNsoYVO4vTynJCVSOYyXgiByjwbnYQR7lL0pDoqhJNnpKUF-F-TY0~LUy4JiJVU4sYc2nGMW6UT~a6qEa1NlQn2Sx6rOfGTa0E~KGkrzafS5VGRrLA9L7Nq5bZpI4zXFUOE6ZzWJLQPnivKYP2tkr5W4bDEnjLDwSgNsiCsa5vqZEPjWqLFf5a7Lv-r~-VPHtfaaFK0ZMBNvt5IWsBA9TyBGkh3EIQwUIMlWPcLA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4";

export const americanFlag = flag;

export const whiteHouseNoon = whiteHouseNight;

export const whiteHouseLateNoon = whiteHouselateNight;
export const haroldMandoza = haroldmandoza;
export const americanMap = americanmap;
export const flowerImg = flowerimg;

export const logo = redwave;

export const blonde_women = women;

export const footer_image = footer;
