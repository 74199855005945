import React, { useState } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import RedWave from '../assets/images/RedWave.svg';
import RedwaveLogo from '../components/icons/RedwaveLogo';
import Campaigns from "../assets/icons/Campaigns.svg";
import Donors from "../assets/icons/Donors.svg";
import Banks from "../assets/icons/Banks.svg";
import Payments from "../assets/icons/Payments.svg";
import Reporting from '../assets/icons/Reporting.svg';
import RedWaveMobile from '../assets/icons/RedWaveMobile.svg';


type FormValues = {
    email: string
    password: string
}

const iconsData = [
    { id: 1, text: 'Campaigns', icon: Campaigns },
    { id: 2, text: 'Donors', icon: Donors },
    { id: 3, text: 'Banks', icon: Banks },
    { id: 4, text: 'Payments', icon: Payments },
    { id: 5, text: 'Reporting', icon: Reporting },
];


function ChangePassword() {
    const { register, handleSubmit } = useForm<FormValues>()
  const [loading, setloading] = useState<boolean>(false);

    const onSubmit: SubmitHandler<FormValues> = (data) => console.log(data)
    const navigate = useNavigate();

    return (
        <div className="flex bg-[url('/src/assets/images/AuthForm.png')] bg-center bg-cover">
            <div className="hidden md:hidden lg:block items-center justify-center  ">
                <div className="flex-1 lg:w-[970px] lg:hidden xl:block px-20 py-32">
                    <RedwaveLogo />
                    <span className='text-white text-xl font-medium'>
                        America’s existing campaign and contribution management platforms are <br />
                        decades old and broken at best. Learn how to more effectively set-up your <br />
                        campaign, manage contributions, and offer an intuitive means for donors <br />
                        to find like minded candidates.
                    </span>
                    <div className='py-14'>
                        <span className='text-white text-2xl font-bold'>The RedWave Ecosystem Connects</span>
                    </div>
                    <div className="flex flex-row gap-14">
                        {iconsData.map((icon) => (
                            <div key={icon.id} className="flex flex-col items-center">
                                <div className={`p-3 rounded-full ${icon.icon} text-white`}>
                                    <img src={icon.icon} alt={icon.icon} className="w-16 h-16" />
                                </div>
                                <p className="text-center mt-2 text-white text-2xl">{icon.text}</p>
                            </div>
                        ))}
                    </div>
                    <div className='py-12'>
                        <div>
                            <span className='text-red-700 text-xl font-bold'>1000+ </span><span className='text-white text-xl font-bold'>Committees, Campaigns, Donors, Financial Institutions, and Regulators <br /> are already with </span><span className='text-red-700 text-xl font-bold'>RedWave.</span>
                        </div>
                    </div>
                    <div className='py-4'>
                        <span className='text-white text-xl font-medium'>Join Now to explore more about your favourite Candidates</span>
                    </div>
                </div>
            </div>
            <div className="bg-black bg-opacity-50 h-auto md:h-auto lg:h-auto lg:w-[480px] text-white w-[48px] p-6 flex-grow flex-col justify-start items-center">
                <div className="text-center mt-20 md:mt-20 xl:mt-36 lg:mt-20">
                    <div className="flex flex-wrap items-center justify-center">
                        <img
                            src={RedWave}
                            alt="RedWave"
                            className="w-64 h-56 hidden md:block lg:block"
                        />
                        <img
                            src={RedWaveMobile}
                            alt="RedWaveMobile"
                            className="block md:hidden lg:hidden"
                        />
                    </div>
                    <p className="text-white text-base mt-4 block md:block lg:hidden font-bold font-['DM Sans']">The RedWave Ecosystem Connects</p>

                    <div className="flex flex-row gap-2 justify-center mt-4 md:mt-4 lg:mt-0">
                        {iconsData.map((icon) => (
                            <div key={icon.id} className="flex flex-col items-center">
                                <div className={`p-3 rounded-full ${icon.icon} text-white block md:block lg:hidden`}>
                                    <img src={icon.icon} alt={icon.icon} className="w-16 h-16" />
                                </div>
                                <p className="text-center mt-2 text-white text-xs md:text-md lg:text-2xl block md:hidden lg:hidden">{icon.text}</p>
                            </div>
                        ))}
                    </div>
                    <p className="text-center text-white text-base mt-4 block font-medium font-['Hind Guntur'] block md:block lg:hidden">Join Now to explore more about your favourite Candidates</p>
                    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-center items-center gap-5 p-7'>
                        <input
                            {...register("email")}
                            className="w-80 h-12 p-4 bg-transparent focus:outline-none border text-white text-sm font-medium rounded-xl mt-14 md:mt-12 lg:mt-0"
                            placeholder="Enter new password"
                        />
                        <input
                            type='password'
                            {...register("password")}
                            className="w-80 h-12 p-4 bg-transparent focus:outline-none border text-white text-sm font-medium rounded-xl"
                            placeholder="Confirm your new password"
                        />

                        
                        <div className="px-2 pt-20">
                        <input
                            type="submit"
                            value={loading ? "Loading..." : "Change password"}
                            className="w-80 h-12 bg-red-600 rounded-3xl text-xl font-medium"
                            disabled={loading}
                        />
                        </div>

                        <div className="flex flex-col md:flex-row lg-flex-row items-center justify-between gap-2">
                            <span className='text-base font-medium'>Don’t have an account yet?</span>
                            <button onClick={() => navigate('/signup')} className='text-xl font-semibold text-red-600'>Sign Up</button>
                        </div>
                        <div className="flex items-center justify-between py-5 gap-4">
                            <span className='text-sm font-normal'>Privacy Policy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
                            </svg>
                            <span className='text-sm font-norma'>Terms of use</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
