import React from 'react';
import RedWave from '../../../../assets/images/RedWave.svg';
// import FormflagMobile from '../../../../assets/images/FormflagMobile.svg';
import { DonorSidebarData, SidebarItem as SidebarItemType } from './DonorSidebarData';
import DonorSidebarItem from './DonorSidebarItem';

const CandidateSidebar: React.FC = () => {
    return (
        <div className="flex sm:hidden md:hidden lg:hidden xl:block h-auto bg-[url('/src/assets/images/SidebarFlag.svg')]">
            <div className="block sm:hidden md:hidden lg:hidden xl:hidden bg-[url('/src/assets/images/SidebarFlag.svg')]">
            </div>
            <div className="hidden text-white w-[443px] p-6 md:flex lg:flex flex-col justify-start items-center">
                <div className="flex items-center">
                    <img src={RedWave} alt="Logo" className="w-66 h-40 mt-24" />
                </div>
                <div className='flex flex-col gap-2 p-2 pt-6'>
                    {DonorSidebarData.map((item: SidebarItemType) => (
                        <DonorSidebarItem key={item.id} {...item} />
                    ))}
                </div>
                <div className="flex items-center justify-between gap-4 mt-14 mb-14">
                    <span className='text-base font-normal'>Privacy Policy</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                        <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
                    </svg>
                    <span className='text-sm font-norma'>Terms of use</span>
                </div>
            </div>
        </div>
    );
};


export default CandidateSidebar;