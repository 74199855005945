import React, { ReactNode } from 'react';
import DonorSidebar from '../DonorSidebar/DonorSidebar';
// import CommitteeSidebar from '../CommitteeSidebar/CommitteeSidebar';

interface LayoutProps {
    children: ReactNode;
}

const DonorLayout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className="flex">
            <DonorSidebar />
            <div>
                {children}
            </div>
        </div>
    );
};

export default DonorLayout;
