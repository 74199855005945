export default function IconDonation({
  className='w-[1.31rem] w-[1.31rem]',
}) {
  return (
    <svg
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path d="M13.105 8.35L10 10.8333H5.83333V10H9.22083C9.2962 9.99996 9.37015 9.97948 9.4348 9.94074C9.49945 9.902 9.55238 9.84645 9.58795 9.78C9.62352 9.71356 9.64041 9.63871 9.63681 9.56342C9.63321 9.48814 9.60925 9.41525 9.5675 9.3525L8.8275 8.2425C8.67569 8.01401 8.4697 7.82663 8.2279 7.69708C7.9861 7.56752 7.71599 7.49982 7.44167 7.5H0.833333C0.61232 7.5 0.400358 7.5878 0.244078 7.74408C0.0877973 7.90036 0 8.11232 0 8.33333V13.3333C0 13.7754 0.175595 14.1993 0.488155 14.5118C0.800716 14.8244 1.22464 15 1.66667 15H9.69917C10.0555 15.0001 10.4077 14.924 10.7323 14.7768C11.0568 14.6296 11.3461 14.4148 11.5808 14.1467L16.6667 8.33333L15.4567 7.93C15.0608 7.79804 14.6382 7.76734 14.2274 7.84071C13.8166 7.91407 13.4307 8.08917 13.105 8.35ZM14.3817 3.65833C14.7575 3.27083 14.99 2.735 14.99 2.14333C14.99 1.55167 14.7575 1.01583 14.3817 0.628333C14.1916 0.429982 13.9634 0.27208 13.7108 0.164107C13.4582 0.0561329 13.1864 0.000313864 12.9117 5.36803e-08C12.9117 5.36803e-08 11.875 -0.00250012 10.8333 1.07167C9.79167 -0.00250012 8.755 5.36803e-08 8.755 5.36803e-08C8.48037 0.000349304 8.20863 0.0561025 7.95604 0.163923C7.70346 0.271743 7.47522 0.429415 7.285 0.6275C6.90917 1.01583 6.67667 1.55083 6.67667 2.1425C6.67667 2.73417 6.90917 3.27083 7.285 3.6575L10.8333 7.5L14.3817 3.65833Z" fill="white"/>
    </svg>
  )
}
