import { Navigate } from "react-router-dom";
import { PublicRouteProps, ProtectedRouteProps } from "./types";
import { useAppSelector } from "../redux/hooks";

const ProtectedRedirectTo: string = "/login";

const AuthRedirectTo: string = "/";
const AdminRedirectTo: string = "/403";

// // pages access before login
const PublicRoute = ({ children }: PublicRouteProps) => {
  // const userinfo: any = useAppSelector((state: any) => state.auth);
  const token = localStorage.getItem("userToken");

  return !token ? children : <Navigate to={AuthRedirectTo} replace={true} />;
};

// pages access after login successfully
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  // const userinfo: any = useAppSelector((state: any) => state.auth);
  const token = localStorage.getItem("userToken");
  return token ? (
    children
  ) : (
    <Navigate to={ProtectedRedirectTo} replace={true} />
  );
};

export { ProtectedRoute, PublicRoute };
