import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import menuIcon from "../assets/icons/menuIcon.svg";
import leftArrow from "../assets/icons/leftArrowIcon.svg";
import { Tooltip } from "react-tooltip";
import { logo } from "../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { clearStore } from "../redux/auth/authSlice";

// const Header: React.FC = () => {
//   const navigate = useNavigate();

interface HeaderProps {
  zincBackground?: boolean;
}

const Header: React.FC<HeaderProps> = ({ zincBackground = false }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const UserState = useAppSelector(
    (state: any) => state?.auth?.isAuthenticated
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClasses = `fixed top-0 w-full ${
    scrolling
      ? "bg-zinc-950 bg-opacity-100"
      : zincBackground
      ? "bg-zinc-950 bg-opacity-100"
      : "bg-transparent"
  } text-white z-50`;

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    // console.log("menu open");
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const menuRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    // Check if the user has scrolled below the navbar height
    if (window.scrollY > (menuRef.current?.offsetHeight || 0)) {
      closeMenu();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener("click", handleOutsideClick);
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen]);

  // useEffect(() => {
  //   const handleOutsideClick = (event: MouseEvent) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
  //       closeMenu();
  //     }
  //   };

  //   if (isMenuOpen) {
  //     document.addEventListener("click", handleOutsideClick);
  //   }

  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, [isMenuOpen]);

  return (
    // <div className="w-full fixed top-0 bg-zinc-900 bg-opacity-100 z-50">
    <div className={headerClasses}>
      {/* text-white py-0 max-w-6xl mx-auto lg:py-8
     fixed top-0 left-0 w-full text-white py-0 bg-zinc-950 bg-opacity-90  */}
      <header className="text-white max-w-7xl mx-auto z-50 py-0 lg:py-4 ">
        <div className="container mx-auto flex items-center justify-between px-[20px] relative z-50 xl:px-0">
          {/* Logo for large screens */}
          <div className="hidden lg:flex items-center w-[10%] lg:w-[60%] lg:justify-between xl:w-[50%]">
            <Link to="/">
              <img src={`${logo}`} alt="Logo" className="h-14 w-20 md:ml-2" />

              {/* <div
            className="block md:inline-block px-4 py-2"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Coming Soon!"
          >
            Home
          </div> */}
              {/* <div
            className="block md:inline-block px-4 py-2"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Coming Soon!"
          > */}
            </Link>
            {/* <div className="block md:inline-block px-4 py-2" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
            Home
          </div> */}
            <div
              className="block text-white md:inline-block px-4 py-2"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Coming Soon!"
            >
              Campaigns
            </div>
            <div
              className="block md:inline-block px-4 py-2"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Coming Soon!"
            >
              Contibuters
            </div>
            <Link to="/about" className="block md:inline-block px-4 py-2">
              About
            </Link>
            <div
              className="block md:inline-block px-4 py-2"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Coming Soon!"
            >
              Contact Us
            </div>
          </div>
          {!UserState && (
            <div className="hidden lg:flex gap-x-10">
              {/* <Link to="/signup"> */}
              <div className="w-36 h-[35px] bg-opacity-5 bg-white text-center flex justify-center items-center rounded-[20px]">
                <div
                  className=" text-white text-sm font-medium font-['Hind Guntur']"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Coming Soon!"
                  // onClick={() => navigate("/signup")}
                >
                  Sign Up
                </div>
                {/* <Tooltip id="my-tooltip" style={{backgroundColor: "#CB000E", zIndex:"1"}} place="bottom"/> */}
              </div>
              {/* </Link> */}
              {/* <Link to="/login"> */}
              {/* <Button label="Login"/> */}
              {/* remove link */}
              <div
                className="w-36 h-9 bg-[#CB000E] flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Coming Soon!"
                // onClick={() => navigate("/login")}
              >
                Login
              </div>
              {/* </Link> */}
            </div>
          )}{" "}
          {UserState && (
            <div className="hidden lg:flex gap-x-10">
              <div
                className="w-36 h-9 bg-[#CB000E] flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold"
                onClick={() => dispatch(clearStore())}
              >
                log out
              </div>
            </div>
          )}
          {/* Menu Icon for mobile */}
          <div className="py-[30px] lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              {/* {isMenuOpen ? (
              <span className="text-xl">
                <img src={leftArrow} alt="" />
              </span>
            ) : ( */}
              <span ref={menuRef} className="text-xl">
                <img src={menuIcon} alt="" />
              </span>
              {/* )} */}
            </button>
          </div>
          <div className="lg:hidden flex items-center">
            <img src={`${logo}`} alt="Logo" className="w-[62px] h-[46px]" />
          </div>
          {/* side navbar */}
          <div
            className={` bg-black pl-5 pr-10 pt-10 h-screen text-white z-50 w-64 fixed inset-y-0 left-0 transform ${
              isMenuOpen ? "translate-x-0 z-50 h-screen  " : "-translate-x-full"
            } transition-transform ease-in-out duration-300`}
            style={{ zIndex: isMenuOpen ? 1000 : -1 }}
          >
            {/* <div className=" pl-5 pr-10 pt-10 "> */}
            {/* <div className="flex items-center gap-x-3" onClick={toggleMenu}>
            <img src={leftArrow} alt="" />
            <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
              Back
            </div>
          </div>

          <div className="">
            <img
              src={`${logo}`}
              alt="Logo"
              className="w-[136px] h-[99px] my-7 md:ml-2"
            />
          </div> */}

            <div
              className="flex flex-col justify-between"
              style={{ height: "90vh" }}
            >
              <div className="flex flex-col gap-y-7">
                {/*  */}
                <div className="flex items-center gap-x-3" onClick={toggleMenu}>
                  <img src={leftArrow} alt="" />
                  <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                    Back
                  </div>
                </div>

                <div className="">
                  <img
                    src={`${logo}`}
                    alt="Logo"
                    className="w-[136px] h-[99px] my-7 md:ml-2"
                  />
                </div>
                {/*  */}

                <div className="text-white text-sm font-medium font-['DM Sans']">
                  {/* <Link to="/login"> */}
                  Log In
                  {/* </Link> */}
                </div>

                <div className="text-white text-sm font-medium font-['DM Sans']">
                  {/* <Link to="/"> */}
                  Home
                  {/* </Link> */}
                </div>

                {/* <div className="text-white text-sm font-medium font-['DM Sans']">
                  Home
              </div> */}

                <div className="text-white text-sm font-medium font-['DM Sans']">
                  Campaigns
                </div>

                <div className="text-white text-sm font-medium font-['DM Sans']">
                  Contributors
                </div>

                <div className="text-white text-sm font-medium font-['DM Sans']">
                  <Link to="/about">About</Link>
                </div>

                <div className="text-white text-sm font-medium font-['DM Sans']">
                  Contact Us
                </div>
              </div>

              <div className=" flex justify-center items-center ">
                <button className="w-[155px] h-[33px] bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-base font-semibold">
                  {/* <Link to="/signup"> */}
                  Sign up
                  {/* </Link> */}
                </button>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
