import React, { useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../../../assets/images/Back.svg";
import FormFlagMobile from "../../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../../assets/images/RedDot.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../../redux/candidate/candidateSlice";
import { API_BASE_URL } from "../../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../../axios/index";
import { setAuthData } from "../../../../../redux/auth/authSlice";

type FormValues = {
  id: string | number;
  registered_business: any;
  business_name: string;
  business_type: string;
  business_tax_id: string;
};
type RequestData = {
  registered_business?: any;
  business_name?: string;
  business_type?: string;
  business_tax_id?: string;
};

function CompanyInfo() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [showComapanyInfo, setShowCompanyInfo] = useState<any>("no");
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);

  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowCompanyInfo(event.target.value === "yes" ? "yes" : "no");
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const submitForm: SubmitHandler<FormValues> = async (data) => {
    const requestData: RequestData = {
      registered_business: showComapanyInfo === "yes" ? true : false,
    };
    if (showComapanyInfo === "yes") {
      requestData.business_name = data.business_name;
      requestData.business_type = data.business_type;
      requestData.business_tax_id = data.business_tax_id;
    }

    try {
      setloading(true);
      const res = await httpRequest.patch(
        `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/company_info`,
        requestData
      );

      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data.candidate,
        };
        dispatch(UpdateCandidateForm("BiographyStepOne"));
        dispatch(setAuthData(updatedAuthInfo));
        toast.success("added successfully!");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (authinfo) {
      const {
        registered_business,
        business_name,
        business_type,
        business_tax_id,
      } = authinfo;

      if (registered_business) {
        setValue("registered_business", registered_business);
        const RegisteredBusiness = registered_business ? "yes" : "no";
        setShowCompanyInfo(RegisteredBusiness);
      }
      if (business_name) {
        setValue("business_name", business_name);
      }
      if (business_type) {
        setValue("business_type", business_type);
      }
      if (business_tax_id) {
        setValue("business_tax_id", business_tax_id);
      }
    }
  }, [authinfo]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Company Info</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-8">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-3 md:py-6 lg:py-6">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Are you Incorporated{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="flex flex-wrap flex-row gap-14 mt-4">
                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="yes"
                    {...register("registered_business")}
                    value="yes"
                    checked={showComapanyInfo === "yes"}
                    onChange={handleLocalChange}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>

                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="no"
                    name="no"
                    value="no"
                    checked={showComapanyInfo === "no"}
                    onChange={handleLocalChange}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>
          </div>

          {showComapanyInfo === "yes" && (
            <>
              <div className="py-2 md:py-4 lg:py-4">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="field2"
                    className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                  >
                    What is the name of your Corporation/Non-profit :{" "}
                    <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    {...register("business_name", {
                      required: "Business Name should not be empty",
                    })}
                    className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                    placeholder="Enter name of your corporation"
                  />
                  {errors.business_name && (
                    <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                      {errors.business_name.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="py-2 md:py-4 lg:py-4">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="field2"
                    className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                  >
                    Entity type :{" "}
                    <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    {...register("business_type", {
                      required: "Business type should not be empty",
                    })}
                    className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                    placeholder="Ex: Campaign Manager"
                  />
                  {errors.business_type && (
                    <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                      {errors.business_type.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="py-2 md:py-4 lg:py-4">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="field2"
                    className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                  >
                    Company Federal Tax ID/EIN :{" "}
                    <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    {...register("business_tax_id", {
                      required: "Company Federal Tax should not be empty",
                    })}
                    className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                    placeholder="Ex: Campaign Manager"
                  />
                  {errors.business_tax_id && (
                    <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                      {errors.business_tax_id.message}
                    </span>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="flex flex-wrap py-2 md:py-24 lg:py-24 xl:py-24 justify-end gap-40">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("CampaignInfo"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button
                className="hidden sm:inline-block"
                type="button"
                // onClick={() => navigate("/campaign-info")}
              >
                Back
              </button>
            </div>
            <div className="flex flex-nowrap justify-between gap-4 sm:gap-4 items-center">
              <p className="text-zinc-800 text-[18px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Company Info
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CompanyInfo;
