export default function IconKey({
  className='w-[1.31rem] w-[1.31rem]',
}) {
  return (
    <svg
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path d="M4.17149 6.25724C4.75087 6.25724 5.24334 6.05446 5.6489 5.6489C6.05446 5.24334 6.25724 4.75087 6.25724 4.17149C6.25724 3.59212 6.05446 3.09965 5.6489 2.69409C5.24334 2.28853 4.75087 2.08575 4.17149 2.08575C3.59212 2.08575 3.09965 2.28853 2.69409 2.69409C2.28853 3.09965 2.08575 3.59212 2.08575 4.17149C2.08575 4.75087 2.28853 5.24334 2.69409 5.6489C3.09965 6.05446 3.59212 6.25724 4.17149 6.25724ZM4.17149 8.34299C3.01275 8.34299 2.02781 7.93743 1.21669 7.1263C0.405562 6.31518 0 5.33024 0 4.17149C0 3.01275 0.405562 2.02781 1.21669 1.21669C2.02781 0.405562 3.01275 0 4.17149 0C5.11008 0 5.93001 0.266512 6.63129 0.799537C7.33256 1.33256 7.82202 1.99305 8.09965 2.781H13.6269C13.7196 2.781 13.8095 2.79838 13.8966 2.83314C13.9838 2.8679 14.0561 2.91425 14.1136 2.97219L14.7914 3.65006C14.8609 3.71958 14.9131 3.79768 14.9479 3.88436C14.9826 3.97103 15 4.06095 15 4.15411C15 4.24681 14.9856 4.33372 14.9569 4.41483C14.9282 4.49594 14.8788 4.57126 14.8088 4.64079L13.0012 6.44843C12.9316 6.51796 12.8563 6.5701 12.7752 6.60487C12.6941 6.63963 12.6072 6.65701 12.5145 6.65701C12.4218 6.65701 12.3349 6.64241 12.2538 6.61321C12.1727 6.58401 12.0973 6.53488 12.0278 6.46582L11.124 5.56199L10.2202 6.46582C10.1506 6.53534 10.0753 6.58447 9.99421 6.61321C9.91309 6.64195 9.82619 6.65655 9.73349 6.65701C9.64079 6.65701 9.55388 6.64241 9.47277 6.61321C9.39166 6.58401 9.31634 6.53488 9.24681 6.46582L8.34299 5.56199H8.09965C7.80996 6.39629 7.30591 7.06837 6.58749 7.57821C5.86906 8.08806 5.06373 8.34299 4.17149 8.34299Z" fill="white"/>
    </svg>
  )
}
