import React, { useEffect, useState } from 'react'
import Navbar from '../components/DashboardNavbar/Navbar'
import Footer from '../components/Footer'
import sort from "../assets/icons/sortIcon.png"
import dropDown from "../assets/icons/dropDownIcon.png"
import darkIcon from "../assets/icons/heartDarkIcon.png"
import rightIcon from "../assets/icons/rightArrowIcon.png"
import cardImg from "../assets/images/modalCardImg.png";
import ProfileCard from '../components/ProfileCard'
import ReactPaginate from 'react-paginate';
import upArrow from "../assets/icons/upArrowIcon.png";

interface UserInfo {
    image: string;
    name: string;
    state: string;
    status: string;
    party: string;
    cardColor: string;
  }
  
  const userInfo: UserInfo[] = [
    {
      image: cardImg,
      name: "Holland Morgan Reagan",
      state: "North Carolina",
      status: "Running For Governor",
      party: "Republican Party",
      cardColor: "bg-red-800",
    },
    {
      image: cardImg,
      name: "Republican Party Florida",
      state: "North Carolina",
      status: "Running For Governor",
      party: "Democrat Party",
      cardColor: "bg-sky-800",
    },
    {
      image: cardImg,
      name: "Elizabeth Martin Parkinson",
      state: "North Carolina",
      status: "Running For Governor",
      party: "Independent",
      cardColor: "bg-red-800",
    },
    {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
      {
        image: cardImg,
        name: "Elizabeth Martin Parkinson",
        state: "North Carolina",
        status: "Running For Governor",
        party: "Independent",
        cardColor: "bg-red-800",
      },
  ];

const FavouriteProfiles = () => {
    const [data,setData] = useState(userInfo)

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 9;

    useEffect(()=>{
        setData(userInfo)

        setTotalPages(Math.ceil(data.length / itemsPerPage));
    }, [])

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = data.slice(startIndex, endIndex);
  
    const handlePageChange = (selectedPage: any) => {
      setCurrentPage(selectedPage.selected);
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };

  return (
    <>
    <Navbar />
    <div className='bg-neutral-50 px-[20px] py-5 lg:px-16 md:px-16'>
        <div className='flex items-center gap-5 mt-5'>
          <div className='hidden items-center gap-5 lg:flex md:flex'>

        <img src={sort} alt="" />
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur']">Sort By :</div>
        <div className="w-[171px] flex items-center justify-between bg-stone-100 rounded-[17.50px] p-2">
        <div className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">All Profiles</div>
        <img src={dropDown} alt="" />
        </div>
        </div>

        <div className='flex items-center gap-5 lg:gap-5 md:gap-5'>

       <div><span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] md:text-[32px]">Favourite</span><span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] md:text-[32px]"> Profiles  </span></div>
        <div className=" text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:leading-tight lg:text-base md:leading-tight md:text-base">Total 40 Favourite Profiles </div>

        
        {/* <div><span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] md:text-[32px]">Favourite</span><span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] md:text-[32px]"> Profiles  </span></div>
        <div className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:leading-tight lg:text-base md:leading-tight md:text-base">Total 40 Favourite Profiles </div> */}

</div>

        </div>

        {/* for mobile screen */}
        <div className='flex items-center gap-5 mt-10 lg:hidden md:hidden'>

        <img src={sort} alt="" />
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur']">Sort By :</div>
        <div className="w-[171px] flex items-center justify-between bg-stone-100 rounded-[17.50px] p-2">
        <div className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">All Profiles</div>
        <img src={dropDown} alt="" />
        </div>
        </div>

        <div className='flex items-center  my-6 gap-5 lg:my-16 md:my-16'>
            <div className='flex items-center gap-2 lg:gap-5 md:gap-5'>
                <img src={darkIcon} alt="" />
                <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] lg:text-[28px] md:text-[28px]">Favourites</div>
            </div>
            <div className='w-[30%] lg:w-[70%] md:w-[70%]'>
                <hr className="h-[2px] bg-stone-300" />
            </div>
            <div className='flex items-center gap-2 lg:gap-5 md:gap-5'>
            <div className="text-slate-600 text-sm font-medium font-['Hind Guntur'] leading-[18.20px] lg:text-base lg:leading-tight md:text-base md:leading-tight">Next Page</div>
            <img src={rightIcon} alt="" />
            </div>
        </div>

        <div className="flex justify-between flex-wrap ">
                  {subset.map((info, index) => (
                    <div className='pb-5' key={index}>
                      <ProfileCard
                        name={info.name}
                        image={info.image}
                        state={info.state}
                        status={info.state}
                        party={info.party}
                        cardColor={info.cardColor}
                      />
                    </div>
                  ))}
                </div>

                <div className='hidden items-center justify-between my-5 lg:flex md:flex'>
                    <div className=''>
                    <div className="flex items-center gap-5 ">
        <div>
          <img
            className="cursor-pointer"
            src={upArrow}
            alt=""
            onClick={scrollToTop}
          />
        </div>
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
          Back To Top
        </div>
                    </div>    
                    </div>
                          <div className=''>
                <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                activeClassName={"active-page"}
                className='flex gap-5 text-slate-600 text-lg font-normal'
                />
                </div>    
                </div>

                <div className='flex items-center justify-center my-5 lg:hidden md:hidden'>
                <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                activeClassName={"active-page"}
                className='flex gap-5 text-slate-600 text-lg font-normal'
                />
                </div>

                <div className="flex items-center gap-5 lg:hidden md:hidden">
        <div>
          <img
            className="cursor-pointer"
            src={upArrow}
            alt=""
            onClick={scrollToTop}
          />
        </div>
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
          Back To Top
        </div>
        </div>

                
                
    </div>

<Footer />
    </>
  )
}

export default FavouriteProfiles