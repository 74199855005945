import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../../../assets/images/Back.svg";
import Plus from "../../../../../assets/icons/Plus.svg";
import FormFlagMobile from "../../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../../assets/images/RedDot.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../../redux/candidate/candidateSlice";
import { API_BASE_URL } from "../../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../../axios/index";
import { setAuthData } from "../../../../../redux/auth/authSlice";

interface PositionField {
  positionField: string;
}

type FormValues = {
  id: string | number;
  bio: string;
  reason_for_running: string;
  great_candidate: string;
  family_life: string;
  current_occupation: string;
  still_active: any;
  past_occupation: Array<string>;
};

function Biography() {
  const dispatch = useAppDispatch();
  const CandidateBioState = useAppSelector(
    (state: any) => state.candidate.CandidateBio
  );
  const [isInfoVisible, setIsInfoVisible] = useState<any>("no");

  const [showPositionFields, setShowPositionFields] = useState<PositionField[]>(
    [{ positionField: "" }]
  );

  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);

  const handleRadioChange = (value: string) => {
    setIsInfoVisible(value === "yes" ? "yes" : "no");
  };

  const handlePositionFields = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Check if the number of existing input fields is less than 3
    if (showPositionFields.length < 6) {
      setShowPositionFields([...showPositionFields, { positionField: "" }]);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const submitForm: SubmitHandler<FormValues> = async (data) => {
    try {
      setloading(true);
      const res = await httpRequest.patch(
        `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/biography`,
        {
          bio: CandidateBioState.bio,
          reason_for_running: CandidateBioState.reason_for_running,
          great_candidate: CandidateBioState.great_candidate,
          family_life: data.family_life,
          current_occupation: data.current_occupation,
          still_active: isInfoVisible === "yes" ? true : false,
          past_occupation: data.past_occupation,
        }
      );
      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data.candidate,
        };
        dispatch(UpdateCandidateForm("Education"));
        dispatch(setAuthData(updatedAuthInfo));
        // toast.success("added successfully!");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (authinfo) {
      const {
        bio,
        reason_for_running,
        great_candidate,
        family_life,
        current_occupation,
        still_active,
        past_occupation,
      } = authinfo;

      if (bio !== undefined) {
        setValue("bio", bio);
      }
      if (reason_for_running !== undefined) {
        setValue("reason_for_running", reason_for_running);
      }
      if (great_candidate !== undefined) {
        setValue("great_candidate", great_candidate);
      }
      if (family_life !== undefined) {
        setValue("family_life", family_life);
      }
      if (current_occupation !== undefined) {
        setValue("current_occupation", current_occupation);
      }
      if (still_active !== undefined) {
        setValue("still_active", still_active);
        setIsInfoVisible(still_active ? "yes" : "no");
      }
      // Handling dynamic past_occupation fields
      if (past_occupation !== undefined && Array.isArray(past_occupation)) {
        // Update existing values
        past_occupation.forEach((field, index) => {
          setValue(`past_occupation.${index}`, field);
        });

        // Remove extra fields if they exist
        for (
          let i = past_occupation.length;
          i < showPositionFields.length;
          i++
        ) {
          setValue(`past_occupation.${i}`, ""); // Set extra fields to empty
        }
        setShowPositionFields(
          Array.from({ length: past_occupation.length }, (_, index) => ({
            positionField: `field${index + 1}`,
          }))
        );
      }
    }
  }, [authinfo, setValue]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Biography</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-20 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Tell us about your Family life :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <textarea
                {...register("family_life", {
                  required: "Family life should not be empty",
                  maxLength: {
                    value: 200,
                    message: "Campaign slogan should not exceed 200 characters",
                  },
                })}
                className="mt-2 p-2 w-[320px] h-[110px] md:w-[533px] md:h-[110px] lg:w-[733px] lg:h-[110px] xl:w-[733px] xl:h-[110px] bg-stone-50 rounded-[10px] resize-none"
                placeholder="Write about your Family life"
              />
              {errors.family_life && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.family_life.message}
                </span>
              )}
              <div className="flex flex-wrap justify-start text-slate-600">
                <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">
                  Max 200 Characters
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4 md:py-7 lg:py-7">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                What is your current Occupation :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("current_occupation", {
                  required: "Current occupation should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Ex. Accountant"
              />
              {errors.current_occupation && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.current_occupation.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Are you still active :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="flex flex-nowrap flex-row gap-14 mt-4">
                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600 mt-1"
                    type="radio"
                    id="yes"
                    {...register("still_active")}
                    checked={isInfoVisible === "yes"}
                    onChange={() => handleRadioChange("yes")}
                    value="yes"
                  />
                  <label
                    className="text-neutral-400 text-xl font-normal font-['Hind Guntur']"
                    htmlFor="yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600 mt-1"
                    type="radio"
                    id="no"
                    checked={isInfoVisible === "no"}
                    onChange={() => handleRadioChange("no")}
                    value="no"
                  />
                  <label
                    className="text-neutral-400 text-xl font-normal font-['Hind Guntur']"
                    htmlFor="no"
                  >
                    No, I am Retired
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Past held positions :
              </label>
              <div className="flex flex-wrap flex-row gap-8 mt-2">
                {showPositionFields.map((field, index) => (
                  <div className="flex flex-row gap-2" key={index}>
                    <input
                      {...register(`past_occupation.${index}` as const, {
                        required: "Past occupation should not be empty",
                      })}
                      type="text"
                      // id={`field${index + 1}`}
                      // name={`field${index + 1}`}
                      className="mt-2 p-2 w-[317px] h-[50px] bg-stone-50 rounded-[10px]"
                      placeholder="Enter your past held position"
                    />
                    {errors.past_occupation && (
                      <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                        {errors.past_occupation.message}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-8 mt-6">
            <div className="flex flex-nowrap gap-2 text-base font-medium cursor-pointer">
              <img src={Plus} alt="Plus" className="w-8 h-8" />
              <button
                className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur']"
                onClick={handlePositionFields}
              >
                Add additional past held position
              </button>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("BiographyStepOne"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button className="hidden sm:inline-block" type="button">
                Back
              </button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Biography
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
                type="submit"
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Biography;
