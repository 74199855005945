import React, { useState } from "react";
import { Link } from "react-router-dom";
import redWave from "../../assets/images/redWaveIcon.svg";
import leftArrow from "../../assets/icons/leftArrowIcon.svg";
import rightArrow from "../../assets/icons/rightArrowIcon.png";
import connectBank from "../../assets/icons/connectBankIcon.svg";
import sandClock from "../../assets/icons/sandClockIcon.svg";
import emailIcon from "../../assets/icons/emailIcon.svg";

const ConnectBank = () => {
  const [isAuthorized, setIsAuthorized] = useState("yes");
  const [isBankConnect, setIsBankConnect] = useState<Boolean>(false);
  const [isSendEmail, setIsSendEmail] = useState<Boolean>(false);

  const [flying, setFlying] = useState(false);

  const flyEmailIcon = () => {
    setFlying(true);
    setTimeout(() => {
      setFlying(false);
    }, 500); // Adjust the timeout to match the transition duration in CSS
  };

  const handleAuthorizedChange = (authorizeValue: any) => {
    setIsAuthorized(authorizeValue === isAuthorized ? "yes" : authorizeValue);
  };

  const handleBankConnect = () => {
    setIsBankConnect(true);
  };

  const handleSendEmail = () => {
    setIsSendEmail(true);
  };

  return (
    <div className="bg-cover bg-center relative bg-[url('/src/assets/images/whitehouseInside.svg')] lg:h-full">
      <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-70"></div>

      <div className="relative z-10 px-8 py-8 lg:px-20 lg:py-20">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-white text-[64px] font-bold font-['DM Sans'] leading-[74.37px]">
              Connect Your Bank
            </div>
            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Connect your bank to manage your transactions and all your
              financial needs
            </div>
          </div>
          <img
            className="w-[92px] h-[67px] lg:w-[194px] lg:h-[141px]"
            src={redWave}
            alt=""
          />
        </div>

        <div className="flex justify-between pt-8">
          {/* left */}
          <div className="w-[474px] h-[616px] px-10 py-10 relative bg-opacity-60 mix-blend-multiply bg-black rounded-[25px]">
            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Are you an authorized signer :
            </div>

            <div className="flex gap-10 pt-5">
              <label
                className={`inline-flex items-center space-x-2 ${
                  isAuthorized === "yes" ? "text-red-700" : "text-neutral-400"
                }`}
              >
                <input
                  type="checkbox"
                  className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                    isAuthorized === "yes" ? "bg-red-700" : "bg-zinc-300"
                  }`}
                  checked={isAuthorized === "yes"}
                  value="yes"
                  onChange={() => handleAuthorizedChange("yes")}
                />
                <span className=" text-white text-xl font-normal font-['Hind Guntur'] leading-loose">
                  Yes
                </span>
              </label>

              <label
                className={`inline-flex items-center space-x-2 ${
                  isAuthorized === "no" ? "text-red-700" : "text-neutral-400"
                }`}
              >
                <input
                  type="checkbox"
                  className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                    isAuthorized === "no" ? "bg-red-700" : "bg-zinc-300"
                  }`}
                  checked={isAuthorized === "no"}
                  value="no"
                  onChange={() => handleAuthorizedChange("no")}
                />
                <span className="text-white text-xl font-normal font-['Hind Guntur'] leading-loose">
                  No
                </span>
              </label>
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-5">
              {isAuthorized === "yes"
                ? "Your name :"
                : "Authorized signer's name :"}
            </div>

            <div>
              <input
                className="w-[317px] h-[50px] bg-white text-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                type="text"
                placeholder={
                  isAuthorized === "yes"
                    ? "Holland Morgan Reagan"
                    : "Enter authorized signer's name"
                }
              />
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-5">
              {isAuthorized === "yes"
                ? "Your email :"
                : "Authorized signer's email :"}
            </div>

            <div>
              <input
                className="w-[317px] h-[50px] text-white bg-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                type="email"
                placeholder={
                  isAuthorized === "yes"
                    ? "hollandmorgan@gmail.com"
                    : "Enter authorized signer's email address"
                }
              />
            </div>

            <div className="flex gap-x-3 pb-5 pt-10">
              <input
                type="checkbox"
                className="w-[18px] h-[18px] accent-red-700 text-red-700 bg-zinc-300 rounded-full"
              />
              <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                I agree to the Terms & Conditions of RedWave
              </div>
            </div>

            <div className=" space-x-3 pb-5">
              <input
                type="checkbox"
                className="w-[18px] h-[18px] accent-red-700 text-red-700 bg-zinc-300 rounded-full"
              />
              <span className=" text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                {isAuthorized === "yes"
                  ? "I , Holland Morgan Reagan confirms that on behalf of Candidates for Freedom, LLC 300 Ocean Blvd Palm Beach, FL 33480, I am the Authorized signer."
                  : "I , john miller confirms that on behalf of Candidates for Freedom, LLC 300 Ocean Blvd Palm Beach, FL 33480, this person is the Authorized signer."}
              </span>
            </div>

            {isAuthorized === "no" ? (
              <div className="flex justify-end">
                <div
                  className="flex cursor-pointer items-center gap-x-3"
                  onClick={handleSendEmail}
                >
                  <div
                    className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight"
                    onClick={flyEmailIcon}
                  >
                    Send Email
                  </div>
                  <img src={rightArrow} alt="" />
                </div>
              </div>
            ) : null}
          </div>
          {/* right */}

          <div>
            <div className="w-[633px] h-[476px] px-10 py-10 relative bg-opacity-60 mix-blend-multiply bg-black rounded-[25px]">
              {isAuthorized === "yes" && (
                <>
                  <div className={` ${!isBankConnect ? "block" : "hidden"}`}>
                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Enter your bank name :
                    </div>

                    <div className="py-6">
                      <input
                        className="w-[317px] h-[50px] text-white bg-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                        type="text"
                        placeholder="Enter your bank name"
                      />
                    </div>

                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Enter your bank routing number :
                    </div>

                    <div className="py-6">
                      <input
                        className="w-[317px] h-[50px] text-white bg-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                        type="text"
                        placeholder="Enter your bank routing number"
                      />
                    </div>

                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Enter your bank account number :
                    </div>

                    <div className="py-6">
                      <input
                        className="w-[317px] h-[50px] text-white bg-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                        type="text"
                        placeholder="Enter your bank account number"
                      />
                    </div>

                    <div className="flex justify-end">
                      <div
                        className="cursor-pointer flex items-center gap-x-3"
                        onClick={handleBankConnect}
                      >
                        <div className=" cursor-pointer text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                          Connect Bank
                        </div>
                        <img src={rightArrow} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className={` flex-col items-center justify-center ${
                      isBankConnect ? "flex" : "hidden"
                    }`}
                  >
                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Your bank has been connected with the following details
                    </div>

                    <div className="py-10">
                      <img
                        className="w-[95px] lg:h-[115px]"
                        src={connectBank}
                        alt=""
                      />
                    </div>

                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-2">
                      Bank name : Bank of America
                    </div>

                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-2">
                      Routing number : 021000123
                    </div>

                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-2">
                      Account number : 0123456789123
                    </div>
                  </div>
                </>
              )}

              {isAuthorized === "no" && (
                <>
                  <div
                    className={` flex-col items-center justify-center ${
                      !isSendEmail ? "flex" : "hidden"
                    }`}
                  >
                    <div className="text-center text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Waiting for an authorized signer to connect the bank
                    </div>

                    <div className="py-20">
                      <img
                        className="w-[95px] lg:h-[115px]"
                        src={sandClock}
                        alt=""
                      />
                    </div>

                    <div className="text-center text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Enter the email address of the authorized signer to
                      complete connecting the bank
                    </div>
                  </div>

                  <div
                    className={` flex-col items-center justify-center ${
                      isSendEmail ? "flex" : "hidden"
                    }`}
                  >
                    <div className="text-center text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Your email has been sent to the following address <br />
                      hollandmorgan@gmail.com
                    </div>

                    <div className="py-20">
                      <img
                        // "w-[95px] lg:h-[115px]"
                        className={`cursor-pointer transition-transform ${
                          flying ? "transform translate-y-[-100%]" : ""
                        }`}
                        src={emailIcon}
                        alt=""
                      />
                    </div>

                    <div className="text-center text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      We will notify you when the authorized signer has
                      connected the bank
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex justify-between items-center pt-16">
              <Link to="/customize-donations">
                <div className="cursor-pointer flex items-center gap-x-3">
                  <img src={leftArrow} alt="" />
                  <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                    Back
                  </div>
                </div>
              </Link>

              <Link to="/verify-identity">
                <button className="cursor-pointer w-[183px] h-[42px] bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-base font-semibold ">
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectBank;
