import React from 'react'

const Science = ({color}) => {
  return (
    <svg width="45" height="38" viewBox="0 0 45 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.0419 22.1872C20.8942 22.1872 20.7414 22.1311 20.6243 22.0242C20.3696 21.7899 20.3544 21.3978 20.5835 21.1482L28.7319 12.2512C28.9662 11.9966 29.3583 11.9813 29.6079 12.2105C29.8625 12.4448 29.8778 12.8369 29.6486 13.0864L21.5002 21.9835C21.378 22.121 21.2099 22.1872 21.0419 22.1872Z" fill={color}/>
<path d="M20.9937 22.2635C20.7798 22.2635 20.571 22.1514 20.459 21.9579L12.7486 8.76773C12.5754 8.47235 12.6773 8.09039 12.9726 7.91724C13.268 7.74409 13.65 7.84594 13.8231 8.14132L21.5335 21.3264C21.7067 21.6218 21.6048 22.0037 21.3095 22.1769C21.2076 22.2329 21.1007 22.2635 20.9937 22.2635ZM30.3949 14.0998C28.4597 14.0998 26.886 12.5262 26.886 10.5909C26.886 8.65569 28.4597 7.08203 30.3949 7.08203C32.3301 7.08203 33.9038 8.65569 33.9038 10.5909C33.9038 12.5262 32.3301 14.0998 30.3949 14.0998ZM30.3949 8.94597C29.4884 8.94597 28.7499 9.68442 28.7499 10.5909C28.7499 11.4974 29.4884 12.2359 30.3949 12.2359C31.3014 12.2359 32.0398 11.4974 32.0398 10.5909C32.0398 9.68442 31.3014 8.94597 30.3949 8.94597Z" fill={color}/>
<path d="M12.0183 10.6578C9.22238 10.6578 6.86954 8.47301 6.70148 5.64654C6.61491 4.22567 7.08853 2.85573 8.03578 1.79134C8.97793 0.726962 10.2817 0.0903705 11.7076 0.00888675C14.6461 -0.159173 17.1721 2.07654 17.3504 5.00995C17.5286 7.94337 15.2827 10.4745 12.3493 10.6527C12.2322 10.6578 12.1252 10.6578 12.0183 10.6578ZM12.0234 3.10527C11.9776 3.10527 11.9317 3.10527 11.8859 3.11036C11.2951 3.14601 10.7502 3.41083 10.353 3.8539C9.96083 4.29697 9.76222 4.86735 9.79786 5.4632C9.87426 6.68546 10.9335 7.62762 12.1507 7.55123C12.7415 7.51558 13.2864 7.25076 13.6836 6.80769C14.0758 6.36462 14.2744 5.79423 14.2387 5.19838C14.2031 4.60763 13.9383 4.0627 13.4952 3.66547C13.0827 3.29879 12.5683 3.10527 12.0234 3.10527ZM8.3006 30.2444C8.09689 30.2444 7.89827 30.1426 7.77605 29.9593C7.58762 29.669 7.67419 29.287 7.95939 29.0986L20.6454 20.9146C20.9357 20.7312 21.3176 20.8127 21.5061 21.0979C21.6945 21.3882 21.6079 21.7701 21.3227 21.9586L8.63672 30.1426C8.53487 30.2088 8.41773 30.2444 8.3006 30.2444ZM24.9742 33.6871C24.7094 33.6871 24.4598 33.514 24.3784 33.2492L21.6436 24.286C21.5417 23.96 21.7301 23.6086 22.0561 23.5119C22.382 23.41 22.7334 23.5984 22.8302 23.9244L25.565 32.8876C25.6668 33.2135 25.4784 33.5649 25.1525 33.6617C25.0964 33.682 25.0353 33.6871 24.9742 33.6871Z" fill={color}/>
<path d="M24.9648 37.6781C25.2563 37.8279 25.5744 37.9188 25.9011 37.9455C26.2277 37.9723 26.5564 37.9345 26.8684 37.8343C27.1804 37.734 27.4696 37.5733 27.7195 37.3613C27.9694 37.1493 28.1751 36.8901 28.3248 36.5986C28.4745 36.3071 28.5654 35.989 28.5922 35.6623C28.6189 35.3357 28.5811 35.007 28.4809 34.695C28.3806 34.383 28.2199 34.0938 28.0078 33.8439C27.7958 33.594 27.5366 33.3884 27.2451 33.2387C26.9536 33.0889 26.6355 32.998 26.3088 32.9712C25.9822 32.9445 25.6535 32.9823 25.3415 33.0825C25.0295 33.1827 24.7403 33.3435 24.4904 33.5555C24.2405 33.7675 24.0348 34.0267 23.8851 34.3182C23.7353 34.6097 23.6445 34.9278 23.6177 35.2545C23.5909 35.5811 23.6288 35.9098 23.729 36.2218C23.8293 36.5338 23.99 36.823 24.2021 37.0729C24.4141 37.3228 24.6733 37.5284 24.9648 37.6781Z" fill={color}/>
<path d="M36.1244 26.3229C36.0684 26.3229 36.0124 26.3178 35.9614 26.3026L20.3267 22.0145C19.9957 21.9228 19.8022 21.5816 19.8938 21.2506C19.9855 20.9195 20.3267 20.726 20.6578 20.8177L36.2924 25.1058C36.6235 25.1974 36.817 25.5386 36.7253 25.8697C36.6489 26.1447 36.3943 26.3229 36.1244 26.3229Z" fill={color}/>
<path d="M15.4531 21.6676C15.4531 22.4006 15.5975 23.1264 15.878 23.8036C16.1585 24.4808 16.5696 25.0961 17.0879 25.6144C17.6063 26.1327 18.2216 26.5438 18.8988 26.8243C19.576 27.1048 20.3018 27.2492 21.0348 27.2492C21.7678 27.2492 22.4936 27.1048 23.1708 26.8243C23.848 26.5438 24.4633 26.1327 24.9816 25.6144C25.4999 25.0961 25.911 24.4808 26.1915 23.8036C26.472 23.1264 26.6164 22.4006 26.6164 21.6676C26.6164 20.1872 26.0283 18.7675 24.9816 17.7208C23.9348 16.674 22.5151 16.0859 21.0348 16.0859C19.5544 16.0859 18.1347 16.674 17.0879 17.7208C16.0412 18.7675 15.4531 20.1872 15.4531 21.6676Z" fill={color}/>
<path d="M39.4978 31.656C38.0973 31.656 36.7681 31.1315 35.7394 30.1689C34.6648 29.1657 34.0486 27.8008 34.0028 26.3341C33.9569 24.8674 34.4815 23.4669 35.4848 22.3923C36.488 21.3178 37.8529 20.7015 39.3196 20.6557C40.7965 20.6048 42.1868 21.1344 43.2614 22.1377C44.3359 23.141 44.9521 24.5058 44.998 25.9725C45.0947 29.0078 42.7113 31.5542 39.6761 31.6509C39.6201 31.6509 39.5589 31.656 39.4978 31.656ZM39.5029 23.7521H39.4214C38.7798 23.7725 38.189 24.0424 37.751 24.5109C37.313 24.9794 37.0839 25.5855 37.1042 26.2272C37.1246 26.8688 37.3945 27.4596 37.8631 27.8976C38.3316 28.3355 38.9376 28.5647 39.5793 28.5443C40.8983 28.4985 41.9372 27.3934 41.8965 26.0693C41.8761 25.4276 41.6062 24.8368 41.1377 24.3989C40.6895 23.9813 40.114 23.7521 39.5029 23.7521Z" fill={color}/>
<path d="M5.49783 37.247C4.09733 37.247 2.76812 36.7225 1.73939 35.7599C0.664824 34.7567 0.0486029 33.3918 0.00276833 31.9251C-0.0430663 30.4584 0.481485 29.0579 1.48475 27.9833C2.48802 26.9088 3.85288 26.2925 5.31958 26.2467C8.35995 26.155 10.9012 28.5333 10.998 31.5686C11.0947 34.6039 8.71134 37.1502 5.67607 37.247H5.49783ZM5.50801 29.3482H5.42653C4.78485 29.3685 4.19409 29.6385 3.75611 30.107C3.31814 30.5755 3.08896 31.1816 3.10934 31.8232C3.12971 32.4649 3.39962 33.0557 3.86815 33.4937C4.33669 33.9316 4.94781 34.1608 5.5844 34.1404C6.90342 34.0946 7.94234 32.9895 7.9016 31.6654C7.88123 31.0237 7.61131 30.4329 7.14278 29.995C6.69462 29.5774 6.11405 29.3482 5.50801 29.3482Z" fill={color}/>
</svg>
  )
}

export default Science


