import RedDot from '../../../../assets/images/RedDot.svg';
import WhiteDot from '../../../../assets/icons/WhiteDot.svg';



export interface SidebarItem {
    id: number;
    title: string;
    icon: string;
    selectedIcon: string;
    path: string;
}

export const DonorSidebarData: SidebarItem[] = [
    {
        id: 1,
        title: 'My Info',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/donor-info',
    },
    {
        id: 2,
        title: 'Contributor Info',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/contributor-info',
    },
    {
        id: 3,
        title: 'Issues',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/donor-issues',
    },
    {
        id: 4,
        title: 'Biography',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/donor-biography',
    },
    {
        id: 5,
        title: 'Social Media',
        icon: WhiteDot,
        selectedIcon: RedDot,
        path: '/donor-social-media',
    },
];
