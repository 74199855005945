import React from "react";
import { footer_image, logo } from "../utils/constants";
import Button from "./Button";
import pinterest from "../assets/icons/pinterestIcon.svg";
import twitter from "../assets/icons/twitterIcon.svg";
import insta from "../assets/icons/instaIcon.svg";
import linkedin from "../assets/icons/linkedinIcon.svg";
import discord from "../assets/icons/discordIcon.svg";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip'

const Footer = () => {
  return (
    <>
<div className="bg-cover bg-center relative" style={{ backgroundImage: `url("${footer_image}")` }}>

<div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-70"></div>
      <div
        className="flex flex-wrap justify-around pt-20 pb-20 items-center relative z-10 flex-col gap-[38px] lg:flex-row lg:items-start"
      >
      
        <div className="flex flex-col gap-10 items-center  lg:items-start">
          <img className="w-[232px] h-[169px] " src={`${logo}`} alt="logo" />
          <div className="text-white  flex-col hidden lg:flex ">
            <div className="text-xl font-normal font-['Hind Guntur'] leading-[30px]">Copyright © 2023 Red Wave.</div>
            <div className="text-xl font-normal font-['Hind Guntur'] leading-[30px]">All Rights Reserved.</div>
          </div>
          <div className="gap-20 hidden lg:flex" >
            <Link to="/terms-and-condition" className="text-white text-lg font-normal font-['Hind Guntur'] leading-[30px]" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
              Terms & Condition
            </Link>
            <Link to="/privacy-policy" className="text-white text-lg font-normal font-['Hind Guntur'] leading-[30px]" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
              Privacy Policy
            </Link>
          </div>
        </div>

        <div className="flex items-center flex-col gap-10 lg:items-start">
          <Link  to="/about" className="text-white text-lg font-bold font-['DM Sans'] leading-normal lg:text-xl lg:leading-relaxed">
            About RedWave
          </Link>
          <Link to="/careers" className="text-white text-lg font-bold font-['DM Sans'] leading-normal lg:text-xl lg:leading-relaxed" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
            Careers
          </Link>
          <Link to="/support" className="text-white text-lg font-bold font-['DM Sans'] leading-normal lg:text-xl lg:leading-relaxed" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
            Support
          </Link>
          <Link to="/faqs" className="text-white text-lg font-bold font-['DM Sans'] leading-normal lg:text-xl lg:leading-relaxed" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
            FAQs
          </Link>
          <Link to="/contact-us" className="text-white text-lg font-bold font-['DM Sans'] leading-normal lg:text-xl lg:leading-relaxed" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
            Contact Us!
          </Link>
        </div>
        <div className="flex flex-col gap-10 items-center lg:items-start  ">
          <p className="text-white text-lg font-bold font-['DM Sans'] lg:text-xl">Sign Up For Newsletter</p>
    
          <div className="flex justify-around items-center w-[358px] h-[35px] lg:justify-start">
            <div className="flex items-center p-1 bg-white bg-opacity-20 rounded-[20.50px]">
              <input
                className=" bg-white bg-opacity-0 outline-none pl-2 text-white px-4 "
                type="email"
                placeholder="Enter Your Email"
              />
              <button className="w-[93px] h-[31px] bg-[#CB000E] flex flex-row justify-center items-center  text-white text-sm font-semibold rounded-[17px]">
                subscribe
              </button>
            </div>

          </div>
          <p className="text-white text-lg font-bold font-['DM Sans'] lg:text-xl">Social Media</p>
          <div className="flex gap-6 lg:gap-6">
            <img src={insta} alt="" />
            <img src={pinterest} alt="" />
            <img src={linkedin} alt="" />
            <img src={discord} alt="" />
            <img src={twitter} alt="" />
          </div>
        </div>
        <div>
          <div className="text-white text-base font-normal font-['Hind Guntur'] leading-[30px] flex-col lg:hidden">
            Copyright © 2023 Red Wave.All Rights Reserved.
          </div>
          <div className="flex justify-around pt-4 lg:hidden">
            <Link to="/terms-and-condition" className="text-white text-base font-normal font-['Hind Guntur'] leading-[30px]">
              Terms & Condition
            </Link>
            <Link to="/privacy-policy" className="text-white text-base font-normal font-['Hind Guntur'] leading-[30px]">
              Privacy Policy
            </Link>
          </div>
        </div>
      
      </div>
      </div>
    </>
  );
};

export default Footer;
