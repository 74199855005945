import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import DonorInfo from "./DonorInfo";
import ContributorInfo from "./ContributorInfo";
import DonorIssues from "./DonorIssues";
import DonorBiography from "./DonorBiography";
import DonorSocial from "./DonorSocial";

const DonorMain = () => {
  const donorState = useAppSelector((state: any) => state.donor.DonorFlow);
  // console.log("🚀 ~ DonorMain ~ donorState:", donorState);

  if (donorState.myinfo.isvisible) {
    return <DonorInfo />;
  }

  if (donorState.ContributorInfo.isvisible) {
    return <ContributorInfo />;
  }
  if (donorState.Biography.isvisible) {
    return <DonorBiography />;
  }

  if (donorState.Issues.isvisible) {
    return <DonorIssues />;
  }

  if (donorState.SocialMedia.isvisible) {
    return <DonorSocial />;
  }
  return null;
};

export default DonorMain;
