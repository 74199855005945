import React from "react";
import img from "../../assets/images/committeProfile.png";
import imgTag from "../../assets/images/profileTag.png";
import heart from "../../assets/icons/heartIcon.png";
import shareProfile from "../../assets/icons/shareProfileIcon.png";
import stats from "../../assets/icons/statsIcon.png";
import contribution from "../../assets/icons/contributionIcon.png";
import activity from "../../assets/icons/activityIcon.png";
import view from "../../assets/icons/viewIcon.png";
import share from "../../assets/icons/shareProfileIcon.png";
import print from "../../assets/icons/printIcon.png";
import feature from "../../assets/icons/featureIcon.png";
import governor from "../../assets/icons/governorDarkIcon.png";
import republican from "../../assets/icons/republicanDarkIcon.png";
import building from "../../assets/icons/buildingIcon.png";
import northCarolina from "../../assets/icons/northDarkIcon.png";
import florida from "../../assets/icons/floridaDark.png";
import calendar from "../../assets/icons/calendarIcon.png";
import cake from "../../assets/icons/cakeIcon.png";
import mapLocation from "../../assets/icons/mapLocationIcon.png";
import pen from "../../assets/icons/penIcon.png";
import camera from "../../assets/icons/cameraIcon.png";
import speaker from "../../assets/icons/speakerDarkIcon.png";
import family from "../../assets/icons/familyIcon.png";
import committee from "../../assets/icons/committeDarkIcon.png";
import education from "../../assets/icons/educationIcon.png";
import advisorBag from "../../assets/icons/advisorBagIcon.png";
import contributionWhite from "../../assets/icons/contributionWhiteIcon.png";
import location from "../../assets/icons/locationDarkIcon.png";
import website from "../../assets/icons/websiteIcon.png";
import certificate from "../../assets/icons/certificateIcon.png";
import office from "../../assets/icons/officeDarkIcon.png";
import files from "../../assets/icons/filesIcon.png";
import book from "../../assets/icons/bookIcon.png";
import editProfile from "../../assets/icons/editProfileIcon.png";
import fire from "../../assets/icons/fireIcon.png";
import energy from "../../assets/icons/energyIcon.png";
import security from "../../assets/icons/securityIcon.png";
import immigration from "../../assets/icons/immigrationIcon.png";
import electorial from "../../assets/icons/electorialIcon.png";
import policy from "../../assets/icons/policyIcon.png";
import tax from "../../assets/icons/taxIcon.png";
import issues from "../../assets/icons/issuesIcon.png";
import social from "../../assets/icons/socialIcon.png";
import insta from "../../assets/icons/instaDarkIcon.png";
import telegram from "../../assets/icons/telegramDarkIcon.png";
import linkedin from "../../assets/icons/linkedinDarkIcon.png";
import facebook from "../../assets/icons/facebookDarkIcon.png";
import twitter from "../../assets/icons/twitterDarkIcon.png";
import youtube from "../../assets/icons/youtubeDarkIcon.png";
import scan from "../../assets/icons/scanIcon.png";
import contact from "../../assets/icons/contactDarkIcon.png";
import mail from "../../assets/icons/mailIcon.png";
import phone from "../../assets/icons/phoneIcon.png";
import profileTag from "../../assets/icons/profileTagIcon.png";
import tick from "../../assets/icons/tickDarkIcon.png";

import QRCode from "react-qr-code";
import { Line } from "rc-progress";
import Navbar from "../../components/DashboardNavbar/Navbar";
import Footer from "../../components/Footer";

const DonorPublicProfile = () => {
  return (
    <>
      <Navbar />
      <div className="flex">
        {/* left side */}
        <div className=" w-[33%] pl-12 pr-10 pt-10">
          <div className="">
            <img
              className=" bg-black w-[377px] h-[508px] rounded-[10px]"
              src={img}
              alt=""
            />
          </div>
          <div className="flex justify-between items-center mt-10">
            <div className="flex items-center gap-3">
              <img src={heart} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Favorite Donor
              </div>
            </div>
            <div className="flex items-center gap-3">
              <img src={shareProfile} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Share Profile
              </div>
            </div>
          </div>
          <hr className="bg-stone-300 my-10" />
          <div>
            <div className="flex items-center gap-2">
              <img src={stats} alt="" />
              <div>
                <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                  RED
                </span>
                <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                  WAVE Statistics
                </span>
              </div>
            </div>
            <div>
              <div className="text-zinc-800 text-4xl font-semibold font-['Hind Guntur'] leading-[43.13px] py-5">
                Johnathon Smith Morgan
              </div>
              <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                Donor and Contributor
              </div>
              <div className="flex items-center gap-2 pb-5">
                <img src={contribution} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Contributed
                </div>
              </div>
              {/* bar code */}
              <div>
                <div>
                  {/* <ProgressBar
                  completed={3206450}
                  maxCompleted={10000000}
                  customLabel=""
                /> */}
                  <Line
                    percent={100}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $3,206,450
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Total
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={60}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $2,634,028
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Year to Date
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={30}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $267,230
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      December
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center py-5 gap-2">
                <img src={activity} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Profile Activity
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <img src={view} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    1,084
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={heart} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    264
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={share} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    68
                  </div>
                </div>
              </div>
              <hr className="bg-stone-300 my-10" />
              <div>
                <div className="flex items-center gap-2 pb-5">
                  <img src={scan} alt="" />
                  <div>
                    <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                      Share{" "}
                    </span>
                    <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                      Profile
                    </span>
                  </div>
                </div>
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                  Share,Print or Copy Profile
                </div>
                {/* Qr Code */}
                <div className="w-[329px] h-[329px] bg-white rounded-[10px] border border-stone-300 p-5">
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value="hello"
                    viewBox={`0 0 256 256`}
                  />
                </div>

                <div className="flex items-center justify-between py-10">
                  <div className="flex items-center gap-2">
                    <img src={share} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Share Profile
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={print} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Print Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-l-2 border-stone-300 h-auto w-px my-10"></div>
        {/* right side */}
        <div className="w-[67%] pl-12 pr-24 py-10">
          <div className="flex justify-between">
            {/* left */}
            <div className="w-[65%]">
              <div className="text-zinc-800 text-[40px] font-bold font-['DM Sans']">
                Johnathon Smith Morgan
              </div>
              <div className="flex justify-end items-center">
                <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur']">
                  ( Donor )
                </div>
              </div>
              <div className="flex flex-wrap justify-between gap-8">
                <div className="flex items-center gap-3">
                  <img src={republican} alt="" />
                  <div className="text-slate-600 text-lg font-normal font-['Hind Guntur']">
                    Republican Party
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <img src={florida} alt="" />
                  <div className="text-slate-600 text-lg font-normal font-['Hind Guntur']">
                    North Carolina
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <img src={building} alt="" />
                  <div className="text-slate-600 text-lg font-normal font-['Hind Guntur']">
                    Accenture
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <img src={advisorBag} alt="" />
                  <div className="text-slate-600 text-lg font-normal font-['Hind Guntur']">
                    Corporate Advisor
                  </div>
                </div>
              </div>
            </div>
            {/* right */}
            <div className="w-[197px] h-[197px] bg-white rounded-[10px] border border-stone-300">
              <div className="bg-red-700 rounded-t-[10px]">
                <div className="flex items-center gap-2 p-2">
                  <img src={contributionWhite} alt="" />
                  <div className="text-white text-base font-semibold font-['Hind Guntur']">
                    Last Contribution
                  </div>
                </div>
              </div>
              <div className=" flex flex-col gap-3 pt-4 px-2">
                <div className="text-red-700 text-[15px] font-bold font-['Hind Guntur']">
                  Republican
                </div>
                <div className="w-[175px] text-zinc-800 text-lg font-bold font-['DM Sans']">
                  Holland Morgan Reagan
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-slate-600 text-sm font-normal font-['Hind Guntur']">
                    Dec 18, 2023
                  </div>
                  <div className="text-zinc-800 text-base font-semibold font-['Hind Guntur']">
                    $2,500.00
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className=" my-10" />
          <div>
            <div className="flex items-center gap-2">
              <img src={pen} alt="" />
              <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                Biography
              </div>
            </div>
            <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.94px] pt-5">
              I am Johnathon Smith, honored to serve as your current Governor in
              North Carolina. My journey in public service is rooted in a deep
              commitment to conservative principles, with a special emphasis on
              family values and the unyielding defense of our Constitution and
              the Bill of Rights. As a mother of two and a devoted wife to an
              accomplished electrical engineer, my personal life mirrors the
              values I champion in my role as Governor. My academic journey led
              me to the University of North Carolina, Chapel Hill, where I
              earned a Master of Business degree. This educational background
              has equipped me with the skills needed for a strategic and
              informed approach to governance, ensuring that the decisions I
              make are in the best interest of the people of North Carolina.
              Throughout my tenure as Governor, I've prioritized policies that
              directly impact the lives of our citizens. From initiatives that
              strengthen families to a focus on improving educational
              opportunities and promoting community well-being, my commitment is
              to create a North Carolina where every resident can thrive. My
              unwavering dedication to constitutional values drives me to be a
              vigilant defender against any threats, be they foreign or
              domestic. I firmly believe that safeguarding our foundational
              principles is crucial for the prosperity of our state. Engagement
              with the community is at the heart of my service. Actively
              participating in volunteer initiatives and local organizations
              keeps me connected with your concerns and aspirations. Your
              feedback is invaluable, shaping the policies and decisions that
              impact our great state. As I seek re-election, I extend an
              invitation to the citizens of North Carolina to join me in shaping
              a future that upholds our time-honored traditions while embracing
              the opportunities that lie ahead. Together, we can continue to
              make North Carolina a beacon of prosperity, unity, and unwavering
              commitment to our shared values as Americans.
            </div>
          </div>
          <hr className="my-8" />

          <div>
            <div className="flex items-center gap-2">
              <img src={location} alt="" />
              <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                Resides
              </div>
            </div>

            <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.94px] py-5">
              Chapel Hill,NC
            </div>
          </div>

          <hr className="my-8" />

          <div>
            <div className="flex items-center gap-2 ">
              <img src={fire} alt="" />
              <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                Top Issues
              </div>
            </div>
            <div className="flex justify-between py-5">
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={energy} alt="" />
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                  Energy
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={security} alt="" />
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                  National Security
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={immigration} alt="" />
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                  Immigration
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={electorial} alt="" />
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                  Electorial
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={policy} alt="" />
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                  Foreign Policy
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img className="" src={tax} alt="" />
                <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.80px]">
                  Tax Reforms
                </div>
              </div>
            </div>
          </div>

          <hr className="my-8" />

          <div>
            <div className="flex items-center gap-2 ">
              <img src={social} alt="" />
              <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                Social Media
              </div>
            </div>
            <div className="flex gap-3 flex-wrap py-5">
              <div className="flex items-center">
                <img src={insta} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  @JohnathonSM
                </a>
              </div>
              <div className="flex items-center">
                <img src={telegram} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  @JohnathonSM
                </a>
              </div>
              <div className="flex items-center">
                <img src={linkedin} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  @JohnathonSM
                </a>
              </div>
              <div className="flex items-center">
                <img src={facebook} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  JohnathonSM
                </a>
              </div>
              <div className="flex items-center">
                <img src={twitter} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  @JohnathonSM
                </a>
              </div>
              <div className="flex items-center">
                <img src={youtube} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  /JohnathonSM
                </a>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={contact} alt="" />
              <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                Contact Us
              </div>
            </div>
            <div className="flex gap-14 flex-wrap py-5">
              <div className="flex items-center gap-2">
                <img src={mail} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  JohnathonSM@gmail.com
                </a>
              </div>
              <div className="flex items-center gap-2">
                <img src={phone} alt="" />
                <a
                  className="text-red-700 text-lg font-normal font-['Hind Guntur'] underline leading-[28.94px]"
                  href=""
                >
                  +1123 1234 1234
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DonorPublicProfile;
