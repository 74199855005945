import React from "react";
import Login from "./Login";

function AuthContainer() {
  return (
    <>
      <Login />
    </>
  );
}

export default AuthContainer;
