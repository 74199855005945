import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RedWave from "../../../../assets/images/RedWave.svg";
import Back from "../../../../assets/images/Back.svg";
import Preview from "../../../../assets/icons/Preview.svg";
import Plus from "../../../../assets/icons/Plus.svg";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../redux/candidate/candidateSlice";
import { API_BASE_URL } from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../axios/index";
import { setAuthData } from "../../../../redux/auth/authSlice";

type FormValues = {
  id: string | number;
  profile_image: string;
  logo_image: string;
  compaign_slogan: string;
  video: string;
};

function CreativeAssets() {
  const dispatch = useAppDispatch();
  const creativeAssetsData = useAppSelector(
    (state) => state.candidate.creativeAssetsData
  );

  const navigate = useNavigate();
  const authinfo = useAppSelector((state: any) => state.auth.userData);

  const [loading, setloading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const [profileImagePreview, setProfileImagePreview] = useState<any | null>(
    null
  );
  const [logoImagePreview, setLogoImagePreview] = useState<any | null>(null);
  const [videoPreview, setVideoPreview] = useState<any | null>(null);
  const [SelectedImage, setSelectedImage] = useState<any | null>(null);

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setPreview: any
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleLogoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setPreview: any
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVideoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setPreview: any
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const previewUrl = URL.createObjectURL(file);

      setVideoPreview(previewUrl);
    }
  };

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    try {
      setloading(true);

      const formData = new FormData();
      formData.append("profile_image", data.profile_image[0]);
      formData.append("logo_image", data.logo_image[0]);
      formData.append("compaign_slogan", data.compaign_slogan);
      formData.append("video", data.video[0]);
      const res = await httpRequest.patch(
        `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/creative_assets`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data.candidate,
        };
        dispatch(UpdateCandidateForm("SocialMedia"));
        dispatch(setAuthData(updatedAuthInfo));
        // toast.success("added successfully!");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (authinfo) {
      const { profile_image, logo_image, compaign_slogan, video } = authinfo;
      if (profile_image) {
        setValue("profile_image", profile_image);
        setProfileImagePreview(profile_image);
      }
      if (logo_image) {
        setValue("logo_image", logo_image);
        setLogoImagePreview(logo_image);
      }
      if (compaign_slogan) {
        setValue("compaign_slogan", compaign_slogan);
      }
      if (video) {
        setValue("video", video);
        setVideoPreview(video);
      }
    }
  }, [authinfo, setValue]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Creative Assets</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1 relative">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Upload your photo :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="relative">
                <input
                  type="file"
                  className="mt-2 w-full md:w-[317px] h-[50px] bg-stone-100 p-3 rounded-[25px] text-[#8C8C8C] font-['Hind Guntur'] pr-16"
                  placeholder="Upload A Photo"
                  accept="image/*"
                  {...register("profile_image", {
                    required: "Please upload a photo",
                    onChange: (e) => {
                      handleImageChange(e, handleImageChange);
                      return e.target.value;
                    },
                  })}
                />
                {errors.profile_image && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.profile_image.message}
                  </span>
                )}
                <span className="absolute right-4 top-8 transform -translate-y-1/2">
                  <img src={Plus} alt="Plus" />
                </span>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="w-[98px] h-[85px] relative">
                {profileImagePreview ? (
                  <img
                    className="w-full h-full object-cover rounded-[11px]"
                    src={profileImagePreview}
                    alt="Preview"
                  />
                ) : (
                  <img
                    className="w-[98px] h-[85px] left-0 top-0 absolute bg-stone-50 rounded-[11px]"
                    src={Preview}
                    alt="Preview"
                  />
                )}
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                  Image preview
                </p>
                <div className="flex flex-col justify-start">
                  <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur'] leading-snug">
                    800*800 Dimensions
                  </p>
                  <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur'] leading-snug">
                    JPG , PNG Formats{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 md:py-4 lg:py-4">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Campaign slogan :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <textarea
                {...register("compaign_slogan", {
                  required: "Campaign slogan should not be empty",
                  maxLength: {
                    value: 200,
                    message: "Campaign slogan should not exceed 200 characters",
                  },
                })}
                className="mt-2 p-2 w-[320px] h-[110px] md:w-[533px] md:h-[110px] lg:w-[733px] lg:h-[110px] xl:w-[733px] xl:h-[110px] bg-stone-50 rounded-[10px]"
                placeholder="Write Your Campaign Slogan"
              />
              {errors.compaign_slogan && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.compaign_slogan.message}
                </span>
              )}
              <div className="flex flex-wrap justify-start text-slate-600">
                <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">
                  Max 200 Characters
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4 md:py-7 lg:py-7">
            <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
              <div className="flex flex-col gap-1 relative">
                <label
                  htmlFor="field1"
                  className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                >
                  Upload your logo :{" "}
                  <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                    *
                  </span>
                </label>
                <div className="relative">
                  <input
                    type="file"
                    className="mt-2 w-full md:w-[317px] h-[50px] bg-stone-100 p-3 rounded-[25px] text-[#8C8C8C] font-['Hind Guntur'] pr-16"
                    placeholder="Upload A Photo"
                    {...register("logo_image", {
                      required: "Logo image should not be empty",
                      onChange: (e) => {
                        handleLogoChange(e, setLogoImagePreview);
                        return e.target.value;
                      },
                    })}
                    accept="image/*"
                  />
                  {errors.logo_image && (
                    <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                      {errors.logo_image.message}
                    </span>
                  )}
                  <span className="absolute right-4 top-8 transform -translate-y-1/2">
                    <img src={Plus} alt="Plus" />
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="w-[98px] h-[85px] relative">
                {logoImagePreview ? (
                  <img
                    className="w-full h-full object-cover rounded-[11px]"
                    src={logoImagePreview}
                    alt="Preview"
                  />
                ) : (
                  <img
                    className="w-[98px] h-[85px] left-0 top-0 absolute bg-stone-50 rounded-[11px]"
                    src={Preview}
                    alt="Preview"
                  />
                )}
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                  Image preview
                </p>
                <div className="flex flex-col justify-start">
                  <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur'] leading-snug">
                    800*800 Dimensions
                  </p>
                  <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur'] leading-snug">
                    JPG , PNG Formats{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-3 md:py-6 lg:py-6">
            <div className="flex flex-col gap-1 relative">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Upload your video :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="relative">
                <input
                  type="file"
                  className="mt-2 w-full md:w-[317px] h-[50px] bg-stone-100 p-3 rounded-[25px] text-[#8C8C8C] font-['Hind Guntur'] pr-16"
                  placeholder="Upload A Photo"
                  {...register("video", {
                    required: "Video should not be empty",
                    onChange: (e) => {
                      handleVideoChange(e, handleVideoChange);
                      return e.target.value;
                    },
                  })}
                  accept="video/*"
                />
                {errors.video && (
                  <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                    {errors.video.message}
                  </span>
                )}
                <span className="absolute right-4 top-8 transform -translate-y-1/2">
                  <img src={Plus} alt="Plus" />
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="w-[98px] h-[85px] relative">
                {videoPreview ? (
                  <video
                    className="w-full h-full object-cover rounded-[11px]"
                    controls
                  >
                    <source src={videoPreview} type="video/mp4" />
                    <source src={videoPreview} type="video/webm" />
                    <source src={videoPreview} type="video/ogg" />
                  </video>
                ) : (
                  <img
                    className="w-[98px] h-[85px] left-0 top-0 absolute bg-stone-50 rounded-[11px]"
                    src={Preview}
                    alt="Preview"
                  />
                )}
              </div>

              <div className="flex flex-col gap-4">
                <p className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                  Video preview
                </p>
                <div className="flex flex-col justify-start">
                  <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur'] leading-snug">
                    1920 * 1080 Dimensions
                  </p>
                  <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur'] leading-snug">
                    MP4, Avi, Flv, MOV, Wmv Formats
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("ShowIssues"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button
                className="hidden sm:inline-block"
                // onClick={() => navigate("/education")}
              >
                Back
              </button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Creative Assets
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreativeAssets;
