import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import Back from "../../../../assets/images/Back.svg";
// import { fetchPlacementData } from "../../../../redux/candidate/googleApiSlice";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
// import {
//   candidateInfo,
//   userInfoSlug,
// } from "../../../../redux/candidate/candidateSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { API_BASE_URL } from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../axios/index";
import { setAuthData } from "../../../../redux/auth/authSlice";
import { UpdateCandidateForm } from "../../../../redux/candidate/candidateSlice";

type FormValues = {
  id: string | number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  address: string;
  dob: string;
  us_citizen: any;
  candidancy: any;
};

function CandidateInfo() {
  const data = useAppSelector((state: any) => state.google.data);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isInfoVisible, setIsInfoVisible] = useState<any>(false);
  const [isCandidateVisible, setIsCandidateVisible] = useState<any>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);
  const handleRadioChange = (value: string) => {
    setIsInfoVisible(value === "yes" ? "yes" : "no");
  };

  const handleCandidateChange = (value: string) => {
    setIsCandidateVisible(value === "yes" ? "yes" : "no");
  };

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    if (data) {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, "0");
      let day = String(currentDate.getDate()).padStart(2, "0");

      const Iscandidancy = data.candidancy === "yes" ? true : false;
      const Isitizen = data.us_citizen === "yes" ? true : false;

      let formattedDate = `${year}-${month}-${day}`;

      const slug =
        authinfo &&
        authinfo.first_name &&
        authinfo.last_name &&
        authinfo.us_citizen !== undefined &&
        authinfo.candidancy !== undefined
          ? authinfo.id
          : 0;

      try {
        setloading(true);
        const res = await httpRequest.post(
          `${API_BASE_URL}/onboarding/candidate/${slug}/info`,
          {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            address: data.address,
            dob: data.dob || formattedDate,
            us_citizen: Isitizen,
            candidancy: Iscandidancy,
          }
        );
        if (res?.status === 200 || res?.status === 201) {
          const updatedAuthInfo = {
            ...authinfo,
            ...res?.data.candidate,
          };
          dispatch(UpdateCandidateForm("PoliticalAffiliation"));
          dispatch(setAuthData(updatedAuthInfo));
          // toast.success("added successfully!");
        } else {
          toast.error(res?.data?.message || "Something went wrong");
        }
      } catch (error: any) {
        toast.error(
          (error.response?.data?.message as string) || "Something went wrong"
        );
      } finally {
        setloading(false);
      }
    }
  };
  useEffect(() => {
    if (authinfo) {
      const {
        first_name,
        last_name,
        email,
        phone_number,
        address,
        dob,
        us_citizen,
        candidancy,
      } = authinfo;

      if (first_name) {
        setValue("first_name", first_name);
      }
      if (last_name) {
        setValue("last_name", last_name);
      }
      if (email) {
        setValue("email", email);
      }
      if (phone_number) {
        setValue("phone_number", phone_number);
      }
      if (address) {
        setValue("address", address);
      }
      if (dob) {
        const formattedDate = new Date(dob).toISOString().split("T")[0];
        setValue("dob", formattedDate);
      }
      if (us_citizen !== undefined) {
        setValue("us_citizen", us_citizen);
        const Iscitizen = us_citizen ? "yes" : "no";
        setIsInfoVisible(Iscitizen);
      }
      if (candidancy !== undefined) {
        setValue("candidancy", candidancy);
        const Iscandidancy = candidancy ? "yes" : "no";
        setIsCandidateVisible(Iscandidancy);
      }
    }
  }, [authinfo, setValue]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Candidate Info</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:pl-20 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Candidate First Name :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("first_name", {
                  required: "First Name should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter Candidates first name"
              />
              {errors.first_name && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.first_name.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Candidate Last Name :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("last_name", {
                  required: "Last Name should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter Candidates last name"
              />
              {errors.last_name && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.last_name.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4 md:py-6 lg-py-6">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Email Address :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="email"
                {...register("email", {
                  required: "Email should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter Candidate's email address"
                value={authinfo.email}
              />
              {errors.email && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Mobile Number :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="tel"
                {...register("phone_number", {
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Phone number should be exactly 10 digits",
                  },
                  required: "Add 10 digits phone no and it should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter Candidate's Mobile Number"
              />
              {errors.phone_number && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.phone_number.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Business Address :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("address", {
                  required: "Address should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter Candidates Business Address"
              />
              {errors.address && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.address.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Candidate Date of Birth :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="date"
                {...register("dob", {
                  required: "Date of Birth should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="DD/MM/YYYY"
              />
              {errors.dob && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.dob.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4 md:py-10 lg:py-10">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Is the Candidate a US Citizen :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="flex flex-wrap flex-row gap-14 mt-4">
                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="yes"
                    value="yes"
                    {...register("us_citizen")}
                    checked={isInfoVisible === "yes"}
                    onChange={() => handleRadioChange("yes")}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>

                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="no"
                    value="no"
                    {...register("us_citizen")}
                    checked={isInfoVisible === "no"}
                    onChange={() => handleRadioChange("no")}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Have you filed a statement of Candidacy{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <div className="flex flex-wrap flex-row gap-14 mt-4">
                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="yes"
                    value="yes"
                    {...register("candidancy")}
                    checked={isCandidateVisible === "yes"}
                    onChange={() => handleCandidateChange("yes")}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>

                <div className="flex flex-wrap gap-2">
                  <input
                    className="w-[21px] h-[21px] rounded-full accent-red-600"
                    type="radio"
                    id="no"
                    value="no"
                    {...register("candidancy")}
                    checked={isCandidateVisible === "no"}
                    onChange={() => handleCandidateChange("no")}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-nowrap py-14 justify-between gap-4 sm:gap-2">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("myinfo"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button type="button" className="hidden sm:inline-block">
                Back
              </button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Candidate Info
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CandidateInfo;
