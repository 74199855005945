// SidebarItem.tsx

import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../redux/candidate/candidateSlice";

interface SidebarItemProps {
  title: string;
  icon: string;
  selectedIcon: string;
  path: string;
}

const CandidateSidebarItem: React.FC<SidebarItemProps> = ({
  title,
  icon,
  selectedIcon,
  path,
}) => {
  const location = useLocation();
  const CandidateState = useAppSelector(
    (state: any) => state.candidate.CandidateFlow
  );

  const dispatch = useAppDispatch();

  const getIcon = (title: string): any => {
    const formattedTitle = title.replace(/\s+/g, "");
    const filterText =
      formattedTitle === "MyInfo"
        ? "myinfo"
        : formattedTitle === "Biography"
        ? "BiographyStepOne"
        : formattedTitle;
    const committee = CandidateState[filterText];
    if (committee && committee.fill) {
      //   return selectedIcon;
      return true;
    }

    // return icon;
    return false;
  };

  const isAvailable: any = getIcon(title);

  const handelform = (title: string) => {
    const formattedTitle = title.replace(/\s+/g, "");
    const filterText =
      formattedTitle === "MyInfo"
        ? "myinfo"
        : formattedTitle === "Biography"
        ? "BiographyStepOne"
        : formattedTitle;
    const committee = CandidateState[filterText];
    if (committee && committee.fill) {
      dispatch(UpdateCandidateForm(filterText));
      return true;
    } else {
      return false;
    }
  };
  console.log("🚀 ~ CandidateState:", CandidateState);

  return (
    <div className="flex items-center p-2">
      <div className="flex items-center gap-6 justify-start">
        <img
          src={isAvailable ? selectedIcon : icon}
          alt="icon"
          className={`flex-shrink-0 ${location.pathname === path}`}
        />
        <div
          className={`text-justify ${
            title === "Biography" || "Issues"
              ? "flex justify-center items-center gap-3"
              : null
          }`}
        >
          <div className="cursor-pointer" onClick={() => handelform(title)}>
            {title}
          </div>
          {CandidateState.BiographyStepOne.fill && title === "Biography" && (
            <>
              <div className="flex justify-center items-center gap-4">
                <div
                  className={`h-3 w-3 ${
                    CandidateState.BiographyStepOne.fill
                      ? "bg-red-600 cursor-pointer"
                      : "bg-white-600 "
                  }  rounded-full`}
                  onClick={() => handelform("Biography")}
                ></div>
                <div
                  className={`h-3 w-3 ${
                    CandidateState.BiographyStepTwo.fill
                      ? "bg-red-600 cursor-pointer"
                      : "bg-white "
                  }  rounded-full`}
                  onClick={() => handelform("BiographyStepTwo")}
                ></div>
              </div>
            </>
          )}
          {CandidateState.Issues.fill && title === "Issues" && (
            <>
              <div className="flex justify-center items-center gap-4">
                <div
                  className={`h-3 w-3 ${
                    CandidateState.Issues.fill
                      ? "bg-red-600 cursor-pointer"
                      : "bg-white-600 "
                  }  rounded-full`}
                  onClick={() => handelform("Issues")}
                ></div>
                <div
                  className={`h-3 w-3 ${
                    CandidateState.ShowIssues.fill
                      ? "bg-red-600 cursor-pointer"
                      : "bg-white "
                  }  rounded-full`}
                  onClick={() => handelform("ShowIssues")}
                ></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateSidebarItem;
