import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import AuthReducer from "../redux/auth/authSlice";
import GoogleReducer from "../redux/candidate/googleApiSlice";
import CandidateReducer from "../redux/candidate/candidateSlice";
import CommitteeReducer from "../redux/committee/committeeSlice";
import DonorReducer from "../redux/donor/donorSlice";
import CommitteeNewSlice from "./committee/CommitteeNewSlice";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "committeenew", "donor", "candidate"],
};

// Combine your reducers with the persistConfig
const rootReducer = combineReducers({
  auth: AuthReducer,
  google: GoogleReducer,
  candidate: CandidateReducer,
  committee: CommitteeReducer,
  donor: DonorReducer,
  committeenew: CommitteeNewSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
// Create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
});

// Create a persisted version of the store
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
