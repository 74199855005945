import React, { useEffect, useState } from "react";
import RedWave from "../../assets/images/RedWave.svg";
import Speaker from "../../../src/assets/images/Speaker";
import Hand from "../../../src/assets/images/Hand";
import Committee from "../../../src/assets/images/Committee.svg";
import Candidate from "../../../src/assets/images/Candidate.svg";
import FormFlagMobile from "../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../assets/images/MobileFormLogo.svg";
import RedDot from "../../assets/images/RedDot.svg";
import Back from "../../../src/assets/images/Back.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
// import { userRole } from "../../redux/candidate/candidateSlice";
import { useNavigate } from "react-router-dom";
// import { committeeUserRole } from "../../redux/committee/committeeSlice";
import httpRequest from "../../axios/index";
import { API_BASE_URL, USER } from "../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import { setAuthData } from "../../redux/auth/authSlice";
import { UpdateCommitteeForm } from "../../redux/committee/CommitteeNewSlice";
import { UpdateDonorForm } from "../../redux/donor/donorSlice";
import { UpdateCandidateForm } from "../../redux/candidate/candidateSlice";

function Onboarding() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isSpeakerHover, setIsSpeakerHover] = useState(false)
  const [isHandHover, setIsHandHover] = useState(false)
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const authinfo = useAppSelector((state: any) => state.auth.userData);

  useEffect(() => {
    if (authinfo.role.toLowerCase() === "doner") {
      navigate("/donor-forms");
    } else if (authinfo.role.toLowerCase() === "committee") {
      navigate("/committee-forms");
    } else if (authinfo.role.toLowerCase() === "candidate") {
      navigate("/candidate-forms");
    }
  }, [authinfo]);

  const toggleView = (cardName: string) => {
    setSelectedCard((prevSelectedCard) =>
      prevSelectedCard === cardName ? null : cardName
    );
  };

  const resetView = () => {
    setSelectedCard(null);
  };

  const handleCommitteeRole = async (role: string) => {
    const Role = role.toLocaleLowerCase();
    try {
      const res = await httpRequest.patch(`${API_BASE_URL}${USER}`, {
        role: Role,
      });
      if (res?.status === 200 || res?.status === 201) {
        // toast.success("Role updated successfully!");
        dispatch(setAuthData(res?.data?.user));
        if (Role === "doner") {
          navigate("/donor-forms");
          dispatch(UpdateDonorForm("myinfo"));
        } else if (Role === "committee") {
          navigate("/committee-forms");
          dispatch(UpdateCommitteeForm("myinfo"));
        } else if (Role === "candidate") {
          navigate("/candidate-forms");
          dispatch(UpdateCandidateForm("myinfo"));
        }
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    }
  };

  const handleCandidateRole = () => {
    // Update role in the Redux store (this is just a local update, not an API call)
    // dispatch(userRole({ role: "candidate" }));
    // navigate("/my-info");
  };

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between w-full h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />

          <div className="flex flex-row gap-4">
            {/* <img src={RedDot} alt="RedDot" /> */}
            <p className="text-white">Select Role</p>
          </div>
        </div>
      </div>
      <div className="flex h-auto">
        <div className="bg-[url('/src/assets/images/SidebarFlag.svg')] text-white w-[443px] p-6 hidden md:hidden lg:hidden xl:block lg:flex-col lg:justify-start items-center">
          <div className="flex items-center justify-center">
            <img src={RedWave} alt="Logo" className="w-66 h-40 mt-24" />
          </div>

          <div className="flex flex-wrap justify-center items-center text-center mt-24 gap-4">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              className="text-red-500"
            >
              <circle cx="15.5" cy="15.5" r="7.5" fill="#CB000E" />
            </svg> */}
            <span className="relative flex h-4 w-4">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 "></span>
              <span className="relative inline-flex rounded-full h-4 w-4 bg-red-600"></span>
            </span>
            <p className="text-lg font-medium">Select Role</p>
          </div>
          <div className="flex items-center justify-center py gap-4 mt-96">
            <span className="text-base font-normal">Privacy Policy</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="5"
              viewBox="0 0 5 5"
              fill="none"
            >
              <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
            </svg>
            <span className="text-sm font-norma">Terms of use</span>
          </div>
        </div>
        {selectedCard === "Committees & Candidates" ? (
          <div>
            <div className="flex flex-row px-10 gap-6 mt-12 text-base font-medium cursor-pointer">
              <img
                src={Back}
                alt="Back"
                onClick={resetView}
                className="w-8 h-8"
              />
              <button>Back</button>
            </div>
            <div className="flex-grow px-6 md:px-8 lg:px-12 xl:px-12 pt-24">
              <p className="text-3xl text-center md:text-center lg:text-start xl:text-start md:text-2xl lg:text-5xl xl:text-5xl font-bold mb-4">
                Welcome to <span className="text-red-600">Red</span>Wave
              </p>
              <p className="text-sm text-center md:text-center lg:text-start xl:text-start md:text-base lg:text-xl xl:text-xl font-normal text-[#585C7B]">
                Please Provide The Details Below To Complete Your Profile
              </p>
            </div>
            <div className="flex justify-center items-center flex-col md:flex-row lg:flex-row xl:flex-row px-10 pt-24">
              <div className="flex flex-nowrap flex-col md:flex-row lg:flex-row xl:flex-row py-24 gap-12">
                
                <div
                  className="flex flex-wrap flex-col justify-start items-center w-72 h-[480px] md:w-96 p-6 cursor-pointer"
                  // onClick={() => toggleView("Committees & Candidates")}
                  onClick={() => handleCommitteeRole("Committee")}
                >
                  <img
                    src={Committee}
                    alt="speaker"
                    className="w-36 h-28 mt-6"
                    // onClick={() => handleCommitteeRole("Committee")}
                  />
                  <p className="text-2xl font-bold mt-20">Committee</p>
                  <p className="text-base font-normal mt-7 text-center text-[#585C7B]">
                    Committees come to RedWave to promote their message and gain
                    support from contributors and donors for their candidates.
                  </p>
                </div>

                {/* Card 2 */}
                <div
                  className="flex flex-wrap flex-col justify-start items-center w-72 h-[480px] p-6 cursor-pointer"
                  // onClick={() => toggleView("Contributors & Donors")}
                  onClick={() => handleCommitteeRole("Candidate")}
                >
                  <img
                    src={Candidate}
                    alt="speaker"
                    className="w-36 h-28 mt-6"
                    onClick={() => handleCandidateRole()}
                  />
                  <p className="text-2xl font-bold mt-20">Candidate</p>
                  <p className="text-base font-normal mt-7 text-center text-[#585C7B]">
                    Candidates come to RedWave to <br /> promote their message
                    and gain support from contributors and donors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : selectedCard === "Contributors & Donors" ? (
          <div>
            <div className="flex flex-row px-10 gap-6 mt-12 text-base font-medium cursor-pointer">
              <img
                src={Back}
                alt="Back"
                onClick={resetView}
                className="w-8 h-8"
              />
              <button>Back</button>
            </div>
            <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row px-10 pt-24">
              <div className="flex flex-nowrap flex-col md:flex-row lg:flex-row xl:flex-row py-24 gap-12">
                <div
                  className="flex flex-wrap flex-col justify-start items-center w-72 h-[480px] md:w-96 p-6"
                  // onClick={() => toggleView("Committees & Candidates")}
                  onClick={() => handleCommitteeRole("doner")}
                >
                  <img
                    src={Committee}
                    alt="speaker"
                    className="w-36 h-28 mt-6"
                    // onClick={() => handleCommitteeRole("Donor")}
                  />
                  <p className="text-2xl font-bold mt-20">Donor</p>
                  <p className="text-base font-normal mt-7 text-center text-[#585C7B]">
                    Committees come to RedWave to promote their message and gain
                    support from contributors and donors for their candidates.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-grow px-6 md:px-8 lg:px-12 xl:px-12 pt-24">
            <p className="text-3xl text-center md:text-center lg:text-start xl:text-start md:text-2xl lg:text-5xl xl:text-5xl font-bold mb-4">
              Welcome to <span className="text-red-600">Red</span>Wave
            </p>
            <p className="text-sm text-center md:text-center lg:text-start xl:text-start md:text-base lg:text-xl xl:text-xl font-normal text-[#585C7B]">
              Please Provide The Details Below To Complete Your Profile
            </p>
            <div className="flex flex-col pt-10 mb-16 md:flex-row lg:flex-row xl:flex-row gap-8">

            {/* committes & candidates card (onboarding) */}
              <div
                className="flex flex-wrap flex-col justify-start items-center w-96 h-[450px] p-6 cursor-pointer rounded-[25px] group hover:bg-[#000000] hover:bg-opacity-90 "
                onClick={() => toggleView("Committees & Candidates")}
                onMouseEnter={()=> setIsSpeakerHover(true)}
                onMouseLeave={()=> setIsSpeakerHover(false)}
              >
                <div className="pt-5 group-hover:scale-125 transition-transform duration-500 ease-in-out">
                 {/* <img src={Speaker} alt="speaker" className="w-36 h-28 mt-6" /> */}
                 <Speaker color={isSpeakerHover ? "#FFFFFF" : "#282828"} />
                 
                </div>
                
                <p className="text-2xl font-bold mt-20 group-hover:text-white">
                  Committees & Candidates
                </p>
                <p className="text-base font-normal mt-7 text-center text-[#585C7B] group-hover:text-white">
                  Candidates and active committees come to RedWave to promote
                  their message and gain support from contributors and donors.
                </p>
              </div>

              {/* contributors & donors (onboarding) */}
              <div
                className="flex flex-wrap flex-col justify-start items-center w-96 h-[450px] p-6 cursor-pointer rounded-[25px] group hover:bg-[#000000] hover:bg-opacity-90"
                onClick={() => toggleView("Contributors & Donors")}
                onMouseEnter={()=> setIsHandHover(true)}
                onMouseLeave={()=> setIsHandHover(false)}
              >
                <div className="pt-5 group-hover:scale-125 transition-transform duration-500 ease-in-out">
                 <Hand color={isHandHover ? "#FFFFFF" : "#282828"} />
                 
                </div>

                <p className="text-2xl font-bold mt-20 group-hover:text-white">
                  Contributors & Donors
                </p>
                <p className="text-base font-normal mt-7 text-center text-[#585C7B] group-hover:text-white">
                  Contributors come to RedWave to track political profiles and
                  learn more about candidates at the state and local levels.
                </p>
              </div>
            </div>
            <div className="flex flex-nowrap md:flex md:flex-wrap lg:flex lg:flex-wrap justify-end gap-4 md:gap-8 lg:gap-8 xl:gap-8 -mt-6">
              <p className="text-zinc-800 text-[20px] font-bold font-['DM Sans'] leading-[37.18px]">
                Choose Your Option
              </p>
              <button className="w-[163px] h-[42px] md:w-[183px] md:h-[42px] lg:w-[183px] lg:h-[42px] xl:w-[183px] xl:h-[42px] bg-red-700 rounded-[21px] text-white text-xl font-medium">
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Onboarding;
