import React, { useEffect, useState } from "react";
import Back from "../../../../assets/images/Back.svg";
import { useNavigate } from "react-router-dom";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../redux/candidate/candidateSlice";
import { API_BASE_URL } from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../axios/index";
import { setAuthData } from "../../../../redux/auth/authSlice";

type FormValues = {
  id: string | number;
  compaign_website: string;
  date_of_election: string;
};

function CampaignInfo() {
  const dispatch = useAppDispatch();
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const submitForm: SubmitHandler<FormValues> = async (data) => {
    if (data) {
      try {
        setloading(true);
        const res = await httpRequest.patch(
          `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/compaign_info`,
          {
            compaign_website: data.compaign_website,
            date_of_election: data.date_of_election,
          }
        );

        if (res?.status === 200 || res?.status === 201) {
          const updatedAuthInfo = {
            ...authinfo,
            ...res?.data.candidate,
          };
          dispatch(UpdateCandidateForm("CompanyInfo"));
          dispatch(setAuthData(updatedAuthInfo));
          // toast.success("added successfully!");
        } else {
          toast.error(res?.data?.message || "Something went wrong");
        }
      } catch (error: any) {
        toast.error(
          (error.response?.data?.message as string) || "Something went wrong"
        );
      } finally {
        setloading(false);
      }
    }
  };

  useEffect(() => {
    if (authinfo) {
      const { date_of_election, compaign_website } = authinfo;
      if (date_of_election) {
        const formattedDate = new Date(date_of_election)
          .toISOString()
          .split("T")[0];
        setValue("date_of_election", formattedDate);
      }
      if (compaign_website) {
        setValue("compaign_website", compaign_website);
      }
    }
  }, [authinfo]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Campaign Info</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Date of election :
              </label>

              <input
                type="date"
                {...register("date_of_election", {
                  required: "Date of election should not be empty",
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="DD/MM/YYYY"
              />
              {errors.date_of_election && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.date_of_election.message}
                </span>
              )}
            </div>
          </div>
          <div className="py-2 md:py-4 lg:py-4">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Campaign’s website address :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <input
                type="text"
                {...register("compaign_website", {
                  required:
                    "Compaign website should not be empty and it must be a website Url",
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: "Enter a valid URL for the Campaign website",
                  },
                })}
                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                placeholder="Enter campaign’s website address"
              />
              {errors.compaign_website && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.compaign_website.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-wrap py-2 md:py-6 lg:py-6 xl:py-6 justify-end gap-40">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() =>
                dispatch(UpdateCandidateForm("PoliticalAffiliation"))
              }
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button className="hidden sm:inline-block" type="button">
                Back
              </button>
            </div>
            <div className="flex flex-nowrap justify-between gap-4 sm:gap-4 items-center">
              <p className="text-zinc-800 text-[18px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Campaign Info
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CampaignInfo;
