import React, { useState } from "react";
import img from "../../assets/images/modalCardImg.png";
import imgTag from "../../assets/images/profileTag.png";
import profileTagSm from "../../assets/images/profileTagSm.png";
import heart from "../../assets/icons/heartIcon.png";
import shareProfile from "../../assets/icons/shareProfileIcon.png";
import stats from "../../assets/icons/statsIcon.png";
import contribution from "../../assets/icons/contributionIcon.png";
import activity from "../../assets/icons/activityIcon.png";
import view from "../../assets/icons/viewIcon.png";
import share from "../../assets/icons/shareProfileIcon.png";
import print from "../../assets/icons/printIcon.png";
import feature from "../../assets/icons/featureIcon.png";
import governor from "../../assets/icons/governorDarkIcon.png";
import republican from "../../assets/icons/republicanDarkIcon.png";
import northCarolina from "../../assets/icons/northDarkIcon.png";
import calendar from "../../assets/icons/calendarIcon.png";
import cake from "../../assets/icons/cakeIcon.png";
import pen from "../../assets/icons/penIcon.png";
import camera from "../../assets/icons/cameraIcon.png";
import speaker from "../../assets/icons/speakerDarkIcon.png";
import family from "../../assets/icons/familyIcon.png";
import education from "../../assets/icons/educationIcon.png";
import bag from "../../assets/icons/bagIcon.png";
import location from "../../assets/icons/locationDarkIcon.png";
import website from "../../assets/icons/websiteIcon.png";
import certificate from "../../assets/icons/certificateIcon.png";
import office from "../../assets/icons/officeDarkIcon.png";
import files from "../../assets/icons/filesIcon.png";
import book from "../../assets/icons/bookIcon.png";

import fire from "../../assets/icons/fireIcon.png";
import energy from "../../assets/icons/energyIcon.png";
import security from "../../assets/icons/securityIcon.png";
import immigration from "../../assets/icons/immigrationIcon.png";
import electorial from "../../assets/icons/electorialIcon.png";
import policy from "../../assets/icons/policyIcon.png";
import tax from "../../assets/icons/taxIcon.png";
import issues from "../../assets/icons/issuesIcon.png";
import social from "../../assets/icons/socialIcon.png";
import insta from "../../assets/icons/instaDarkIcon.png";
import telegram from "../../assets/icons/telegramDarkIcon.png";
import linkedin from "../../assets/icons/linkedinDarkIcon.png";
import facebook from "../../assets/icons/facebookDarkIcon.png";
import twitter from "../../assets/icons/twitterDarkIcon.png";
import youtube from "../../assets/icons/youtubeDarkIcon.png";
import scan from "../../assets/icons/scanIcon.png";
import contact from "../../assets/icons/contactDarkIcon.png";
import mail from "../../assets/icons/mailIcon.png";
import phone from "../../assets/icons/phoneIcon.png";
import profileTag from "../../assets/icons/profileTagIcon.png";
import tick from "../../assets/icons/tickDarkIcon.png";

import rightDropDown from "../../assets/icons/navbar/rightDropIcon.png";
import dropDown from "../../assets/icons/dropDownIcon.png";

import QRCode from "react-qr-code";
import ProgressBar from "@ramonak/react-progress-bar";
import { Line } from "rc-progress";
import Navbar from "../../components/DashboardNavbar/Navbar";
import Footer from "../../components/Footer";

const UserProfile = () => {

  const [isStats, setIsStats] = useState(false)
  const [isProfile, setIsProfile] = useState(false)

  const handleClickStats = () => {
    setIsStats(!isStats)
    setIsProfile(false)
  }

  const handleClickProfile = () => {
    setIsProfile(!isProfile)
    setIsStats(false)
    }


  return (
    <>
      <Navbar />
      <div className="flex">
        {/* left side */}
        <div className=" hidden w-[33%] pl-12 pr-10 pt-10 lg:block md:block">
          <div className="">
            <img
              className=" bg-black w-[397px] h-[453px] rounded-t-[10px]"
              src={img}
              alt=""
            />
            <img className="w-full rounded-b-[10px]" src={imgTag} alt="" />
          </div>
          <div className="flex justify-between items-center mt-10">
            <div className="flex items-center gap-3">
              <img src={heart} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Favorite Candidate
              </div>
            </div>
            <div className="flex items-center gap-3">
              <img src={shareProfile} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Share Profile
              </div>
            </div>
          </div>
          <hr className="bg-stone-300 my-10" />
          <div>
            <div className="flex items-center gap-2">
              <img src={stats} alt="" />
              <div>
                <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                  RED
                </span>
                <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                  WAVE Statistics
                </span>
              </div>
            </div>
            <div>
              <div className="text-zinc-800 text-4xl font-semibold font-['Hind Guntur'] leading-[43.13px] py-5">
                Holland Morgan Reagan
              </div>
              <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                Republican Candidate For Governor
              </div>
              <div className="flex items-center gap-2 pb-5">
                <img src={contribution} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Contributions
                </div>
              </div>
              {/* bar code */}
              <div>
                <div>
                  {/* <ProgressBar
                  completed={3206450}
                  maxCompleted={10000000}
                  customLabel=""
                /> */}
                  <Line
                    percent={100}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $3,206,450
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Total
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={60}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $2,634,028
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Year to Date
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={30}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $267,230
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      December
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center py-5 gap-2">
                <img src={activity} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Profile Activity
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <img src={view} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    1,084
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={heart} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    264
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={share} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    68
                  </div>
                </div>
              </div>

              <hr className="bg-stone-300 my-10" />
              <div>
                <div className="flex items-center gap-2 pb-5">
                  <img src={scan} alt="" />
                  <div>
                    <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                      Share{" "}
                    </span>
                    <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                      Profile
                    </span>
                  </div>
                </div>
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                  Share,Print or Copy Profile
                </div>
                {/* Qr Code */}
                <div className="w-[329px] h-[329px] bg-white rounded-[10px] border border-stone-300 p-5">
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value="hello"
                    viewBox={`0 0 256 256`}
                  />
                </div>

                <div className="flex items-center justify-between py-10">
                  <div className="flex items-center gap-2">
                    <img src={share} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Share Profile
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={print} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Print Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-l-2 border-stone-300 h-auto w-px my-10 hidden lg:block md:block"></div>
        {/* right side */}
        <div className="w-full mx-[20px] py-10 lg:w-[67%] lg:pl-12 lg:pr-24">
          <div className="flex justify-between gap-5">
            {/* left */}
            <div className="w-full lg:w-[58%] md:w-[58%]">

              {/* for mobile screen */}
              <div className="flex items-center justify-between mb-10 gap-x-3 lg:hidden md:hidden">
              <div className="lg:hidden md:hidden">
              <img className="" src={profileTagSm} alt="" />
            </div>
            <div>
            <div className=" text-zinc-800 text-2xl font-bold font-['DM Sans']">Holland Morgan Reagan
            {/* <span className="flex items-center text-red-700 text-base font-semibold font-['Hind Guntur']">
            {" "}
            <img src={feature} alt="" />
            Candidate
            </span> */}
            </div>
            <div className="flex justify-end gap-x-2 items-center text-red-700 text-base font-semibold font-['Hind Guntur'] py-2">
            <img src={feature} alt="" />
            Candidate
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-3" onClick={handleClickStats}>
              <div className="text-slate-600 text-[15px] font-medium font-['Hind Guntur']">Statistics</div>
              <img src={isStats ? dropDown : rightDropDown} alt="" />
              </div>

              <div className="flex items-center gap-3" onClick={handleClickProfile}>
            <div className="text-slate-600 text-[15px] font-medium font-['Hind Guntur']">Share Profile</div>
            <img src={isProfile ? dropDown : rightDropDown} alt="" />
              </div>
            
            </div>

            </div>
              </div>
              {
                isStats ? 
                <div className="mb-5">
                  <div className="flex items-center gap-2">
              <img src={stats} alt="" />
              <div>
                <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                  RED
                </span>
                <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                  WAVE Statistics
                </span>
              </div>
                  </div>
                  <div>
                  <div className="text-zinc-800 text-3xl font-bold font-['Hind Guntur'] py-5">
                Holland Morgan Reagan
              </div>
              <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                Republican Candidate For Governor
              </div>
              <div className="flex items-center gap-2 pb-5">
                <img src={contribution} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Contributions
                </div>
              </div>
              {/* bar code */}
              <div>
                <div>
                  {/* <ProgressBar
                  completed={3206450}
                  maxCompleted={10000000}
                  customLabel=""
                /> */}
                  <Line
                    percent={100}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $3,206,450
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Total
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={60}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $2,634,028
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Year to Date
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={30}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                    //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $267,230
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      December
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center py-5 gap-2">
                <img src={activity} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Profile Activity
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <img src={view} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    1,084
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={heart} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    264
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={share} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    68
                  </div>
                </div>
              </div>
                  </div>
                </div> : null
              }
              {
                isProfile ? 
                <div className="mb-5">
                   <div>
                <div className="flex items-center gap-2 pb-5">
                  <img src={scan} alt="" />
                  <div>
                    <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                      Share{" "}
                    </span>
                    <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                      Profile
                    </span>
                  </div>
                </div>
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                  Share,Print or Copy Profile
                </div>
                {/* Qr Code */}
                <div className="flex items-center gap-x-5">
<div className="w-[230px] h-auto bg-white rounded-[10px] border border-stone-300 p-5"
                 style={{ height: "auto", margin: "0 auto", maxWidth: "100%", width: "100%" }}
>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value="hello"
                    viewBox={`0 0 256 256`}
                  />
                </div>

                <div className="flex flex-col items-center gap-y-5 py-10">
                  <div className="flex items-center gap-2">
                    <img src={share} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Share Profile
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={print} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Print Profile
                    </div>
                  </div>
                </div>
                </div>
                

                
              </div>
                </div> : null
              }

              <div className="hidden text-zinc-800 text-[40px] font-bold font-['DM Sans'] lg:block md:block">
                Holland Morgan Reagan
              </div>
              <div className="hidden justify-end items-center lg:flex md:flex">
                <img src={feature} alt="" />
                <div className="text-red-700 text-xl font-semibold font-['Hind Guntur']">
                  Featured Candidate
                </div>
              </div>

              <div className="flex flex-wrap justify-between gap-4 lg:gap-8 md:gap-8">
                <div className="flex items-center gap-5">
                  <img src={governor} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    Running For The Governor Office (Incumbent)
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <img src={republican} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    Republican Party
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <img src={northCarolina} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    North Carolina
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <img src={calendar} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    November,8 2024
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <img src={cake} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    Dec 12,1984
                  </div>
                </div>
              </div>
            </div>
            {/* right */}
            {/* h-[271px] */}
            <div className="hidden w-[250px] h-auto bg-white rounded-[10px] border border-stone-300 lg:block md:block">
              <img className="pt-14 pl-5" src={profileTag} alt="" />
            </div>
          </div>
            {/* for mobile screen user img */}
          <div className="mt-10 lg:hidden md:hidden">
          <div className="">
            <img
              className=" bg-black w-[397px] h-[453px] rounded-t-[10px]"
              src={img}
              alt=""
            />
            <img className="w-full rounded-b-[10px]" src={imgTag} alt="" />
          </div>
          <div className="flex justify-between items-center mt-10">
            <div className="flex items-center gap-3">
              <img src={heart} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Favorite Candidate
              </div>
            </div>
            <div className="flex items-center gap-3">
              <img src={shareProfile} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Share Profile
              </div>
            </div>
          </div>
          </div>
          
          <hr className=" my-10" />
          <div>
            <div className="flex items-center gap-2">
              <img src={pen} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Biography
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px] ">
              I am Holland Reagan, honored to serve as your current Governor in
              North Carolina. My journey in public service is rooted in a deep
              commitment to conservative principles, with a special emphasis on
              family values and the unyielding defense of our Constitution and
              the Bill of Rights. As a mother of two and a devoted wife to an
              accomplished electrical engineer, my personal life mirrors the
              values I champion in my role as Governor. My academic journey led
              me to the University of North Carolina, Chapel Hill, where I
              earned a Master of Business degree. This educational background
              has equipped me with the skills needed for a strategic and
              informed approach to governance, ensuring that the decisions I
              make are in the best interest of the people of North Carolina.
              Throughout my tenure as Governor, I've prioritized policies that
              directly impact the lives of our citizens. From initiatives that
              strengthen families to a focus on improving educational
              opportunities and promoting community well-being, my commitment is
              to create a North Carolina where every resident can thrive. My
              unwavering dedication to constitutional values drives me to be a
              vigilant defender against any threats, be they foreign or
              domestic. I firmly believe that safeguarding our foundational
              principles is crucial for the prosperity of our state. Engagement
              with the community is at the heart of my service. Actively
              participating in volunteer initiatives and local organizations
              keeps me connected with your concerns and aspirations. Your
              feedback is invaluable, shaping the policies and decisions that
              impact our great state. As I seek re-election, I extend an
              invitation to the citizens of North Carolina to join me in shaping
              a future that upholds our time-honored traditions while embracing
              the opportunities that lie ahead. Together, we can continue to
              make North Carolina a beacon of prosperity, unity, and unwavering
              commitment to our shared values as Americans.
            </div>
          </div>
          <hr className="my-8" />
          <div>
            <div className="flex items-center gap-2">
              <img src={camera} alt="" />
              <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Introductory Video
              </div>
            </div>
            {/* video */}
            <div className="pt-5">
              <video className="w-full rounded-[25px]" controls>
                <source src="../../assets/dummyVideo.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <hr className="my-8" />
          <div>
            <div className="flex items-center gap-2">
              <img src={speaker} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Campaign Slogan
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 pb-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              Charting a Course for North Carolina's Tomorrow: A Vision of
              Strong Families, Unwavering Security, and Shared Prosperity –
              Together We Thrive!
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={family} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Family Life
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              My family life, as a devoted mother of two and a loving wife to an
              accomplished electrical engineer, serves as the bedrock of my
              values, grounding my commitment to building a future where
              families can flourish.
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={education} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Education
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between py-5 lg:flex-nowrap md:flex-nowrap">
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
                University of North Carolina, Business Admin, 1994.
              </div>
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
                University of North Carolina, MBA, 1996.
              </div>
            </div>
          </div>

          <div className="flex flex-wrap gap-x-20 lg:gap-60">
            <div>
              <div className="flex items-center gap-2">
                <img src={bag} alt="" />
                <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                  Occupation
                </div>
              </div>
              <div className="flex py-5">
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
                  Accountant
                </div>
                <div className="flex items-center gap-2 pl-5">
                  <img src={tick} alt="" />
                  <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.94px]">
                    Active
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <img src={location} alt="" />
                <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                  Resides
                </div>
              </div>

              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-[28.94px] py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
                Chapel Hill,NC
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={website} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Website
              </div>
            </div>
            <div className="py-5">
              <a
                className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                href=""
              >
                https://www.holandmorgan.com
              </a>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={certificate} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Certifications & Licenses
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              Chartered Professional Accountant, Chartered Global Management
              Accountant
            </div>
          </div>

          <hr className="my-8" />

          <div>
            <div className="flex items-center gap-2">
              <img src={office} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Why I Am Running For The Office
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              My re-election bid as your Governor is fueled by a profound
              commitment to North Carolina's prosperity. Drawing on my
              background with a Master of Business degree from UNC, Chapel Hill,
              I am dedicated to preserving our values, ensuring community
              security, and fostering economic growth. As a mother and wife, I
              advocate for policies that strengthen families. My focus on
              national security, the economy, immigration reform, and criminal
              justice reflects my strategic and informed approach to governance.
              Join me in building a resilient North Carolina, where every
              resident can thrive and opportunities abound for all.
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={files} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                What Makes Me A Great Candidate
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              I stand as an exemplary candidate for re-election as your Governor
              due to a unique combination of strategic expertise, family values,
              and proven results. I bring an informed approach to governance. As
              a devoted mother and wife, I prioritize policies that strengthen
              families and communities. My commitment to constitutional values,
              national security, economic prosperity, immigration reform, and
              criminal justice showcases a well-rounded and forward-thinking
              leadership style. I am dedicated to the well-being of North
              Carolina, embodying a genuine connection to the people and a
              passion for building a thriving and inclusive future for all.
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={book} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Past Held Positions
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              City Council Member, Governor For North Carolina , Mayor for North
              Carolina , Clerk for North Carolina , District Attorney for North
              Carolina
            </div>
          </div>

          <hr className="my-8" />
          <div>
            <div className="flex items-center gap-2 ">
              <img src={fire} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Top Issues
              </div>
            </div>
            <div className="flex flex-wrap gap-6 justify-between py-5 lg:gap-0">
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={energy} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                  Energy
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={security} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                  National Security
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={immigration} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                  Immigration
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={electorial} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                  Electorial
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img src={policy} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                  Foreign Policy
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <img className="" src={tax} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                  Tax Reforms
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={issues} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Issues Explained
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              I am Holland Reagan, and I am running for re-election as your
              Governor with a steadfast focus on key issues critical to the
              well-being and prosperity of North Carolina. First and foremost,
              national security is a paramount concern. In an increasingly
              complex world, I am committed to ensuring the safety and security
              of our state, standing resolute against both foreign and domestic
              threats to preserve the peace and tranquility we hold dear.
              Economic vitality is at the heart of my vision for North Carolina.
              Leveraging my business background, I aim to implement policies
              that foster economic growth, create job opportunities, and
              stimulate innovation. By championing a robust economy, we can
              provide a foundation for the prosperity of all citizens and ensure
              a bright future for generations to come. Addressing immigration
              reform is another cornerstone of my campaign. I advocate for a
              comprehensive and compassionate approach that secures our borders
              while recognizing the contributions of immigrants to our diverse
              society. It's essential to strike a balance that upholds the rule
              of law while respecting the humanity and dignity of individuals
              seeking a better life in our great state. Lastly, tackling
              criminal issues is a top priority. I am committed to enhancing
              public safety, investing in law enforcement, and promoting
              community policing strategies. By addressing the root causes of
              crime and fostering cooperation between law enforcement and
              communities, we can build a safer and more secure North Carolina
              for everyone. In summary, my re-election campaign is centered
              around securing our state, promoting economic prosperity,
              advocating for sensible immigration reform, and addressing
              criminal issues to ensure a safe and thriving future for all North
              Carolinians. Together, let's build a stronger and more resilient
              North Carolina.
            </div>
          </div>

          <hr className="my-8" />

          <div>
            <div className="flex items-center gap-2 ">
              <img src={social} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Social Media
              </div>
            </div>
            <div className="flex gap-1 flex-wrap py-5 lg:gap-3 md:gap-3">
              <div className="flex items-center">
                <img src={insta} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={telegram} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={linkedin} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={facebook} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={twitter} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={youtube} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  /HollandMorgan
                </a>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={contact} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Contact Us
              </div>
            </div>
            <div className="flex gap-6 flex-wrap py-5 lg:gap-14 md:gap-14">
              <div className="flex items-center gap-2">
                <img src={mail} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  holandmorgan@gmail.com
                </a>
              </div>
              <div className="flex items-center gap-2">
                <img src={phone} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  +1123 1234 1234
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserProfile;
