import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const userToken: string | null = localStorage.getItem("userToken");
let userSlug: any = null;

const initialState: any = {
  DonorFlow: {
    myinfo: {
      fill: false,
      isvisible: true,
    },
    ContributorInfo: {
      fill: false,
      isvisible: false,
    },
    Biography: {
      fill: false,
      isvisible: false,
    },
    Issues: {
      fill: false,
      isvisible: false,
    },
    SocialMedia: {
      fill: false,
      isvisible: false,
    },
  },
};

export const donorSlice = createSlice({
  name: "donor",
  initialState,
  reducers: {
    UpdateDonorForm: (state, action: PayloadAction<string>) => {
      // Set all values to false initially
      Object.keys(state.DonorFlow).forEach((key) => {
        // state.CommitteeFlow[key].fill = false;
        state.DonorFlow[key].isvisible = false;
      });

      // Set the specified key values to true
      state.DonorFlow[action.payload].fill = true;
      state.DonorFlow[action.payload].isvisible = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { UpdateDonorForm } = donorSlice.actions;
export const selectFormData = (state: { donor: any }) => state.donor;

export default donorSlice.reducer;
