
import heroLogo from "../../assets/images/heroLogo.png";
// import cardImg from "../../assets/images/modalCardImg.png";
import DonationForm from "../../components/DonationForm";
// import Button from "../../components/Button";
import ImageSlider from "../../components/ImageSlider";
import { Tooltip } from 'react-tooltip'
import { PopupButton, PopupWidget,InlineWidget } from "react-calendly";


import {
  americanFlag,
  slidesCreditCards,
  slidesProfileCards,
  whiteHouseNoon,
  whiteHouseLateNoon,
  blonde_women,
  americanMap,
  haroldMandoza,
  flowerImg,
  slidesCreditCardSm,
} from "../../utils/constants";
import CandidateInfoCard from "../../components/Candidate-Info-Card";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import BookingForm from "../../components/BookingForm";
// import FilterDrawer from "../../components/FilterDrawer";
import FeatureSection from "../../components/FeatureSection";
// import ProfileCard from "../../components/ProfileCard";
// import belief from "../../assets/icons/beliefIcon.png";
// import candidate from "../../assets/icons/candidateIcon.png";
// import location from "../../assets/icons/locationIcon.png";
// import office from "../../assets/icons/officeIcon.png";
// import down from "../../assets/icons/downIcon.png";
// import { Link } from "react-router-dom";
import ImageSlider2 from "../../components/ImageSlider2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// interface UserInfo {
//   image: string;
//   name: string;
//   state: string;
//   status: string;
//   party: string;
//   cardColor: string;
// }

// const userInfo: UserInfo[] = [
//   {
//     image: cardImg,
//     name: "Holland Morgan Reagan",
//     state: "North Carolina",
//     status: "Running For Governor",
//     party: "Republican Party",
//     cardColor: "bg-red-800",
//   },
//   {
//     image: cardImg,
//     name: "Republican Party Florida",
//     state: "North Carolina",
//     status: "Running For Governor",
//     party: "Democrat Party",
//     cardColor: "bg-sky-800",
//   },
//   {
//     image: cardImg,
//     name: "Elizabeth Martin Parkinson",
//     state: "North Carolina",
//     status: "Running For Governor",
//     party: "Independent",
//     cardColor: "bg-red-800",
//   },
// ];

interface SliderSettings {
  dots: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  infinite: boolean;
  autoplay: boolean;
  autoplaySpeed: number;
  arrows?: boolean;
}

const MainContainer = () => {
  // const [showModal, setShowModal] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [showForm, setShowForm] = useState(false);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [hover, setHover] = useState(false);
  // const [isFieldsVisible, setIsFieldsVisible] = useState(false);

  // const toggleFields = () => {
  //   setIsFieldsVisible(!isFieldsVisible);
  // };

  // const toggleDrawer = () => {
  //   setIsDrawerOpen(!isDrawerOpen);
  // };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowLogo(false);
  //     setShowHeader(true);
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position and update showLogo state
      if (window.scrollY > 0) {
        setShowLogo(false);
      } else {
        setShowLogo(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const rootElement = document.getElementById("root") as HTMLElement;

  const settings: SliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };


  return (
    <div>
      {/* lg:h-[891px] */}
      <div
        className="w-full bg-cover bg-center px-5 pt-14 relative h-[691px] lg:h-[785px] "
        style={{
          backgroundImage: `url("${whiteHouseLateNoon}")`,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-40"></div>
        <div className="relative z-10">
          {/* fixed top-0 */}
          {/* <div className="hidden  z-10 lg:block">
            {showHeader ? <Header /> : <div className="py-[60px]"></div>}
          </div>
          <div className="block lg:hidden">
            <Header />
          </div> */}

          {/* Modal and filter row */}
          {/* {showModal ? null : (
          <div className="max-w-6xl mx-auto flex justify-center items-center lg:justify-between lg:pt-4 lg:px-[20px] xl:px-0">
            
            <div
              className="flex items-center p-1 bg-white bg-opacity-5 rounded-[20.50px]"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img className="h-[18px] w-[18px]" src={searchIcon} alt="" />
              <input
                className="<w-[268px] h-[35px] bg-white bg-opacity-0 rounded-[17.50px]  text-white outline-none pl-2"
                type="text"
                placeholder="Search For Anything"
              />
              <img src={rightArrow} alt="" />
            </div>

            <div className="flex w-[75%] items-center justify-end" >
             
            <div className="hidden lg:flex">
              <img
                className={`cursor-pointer overflow-hidden  ${isDrawerOpen ? "hidden" : "flex"}`}
                src={filterIcon}
                alt="filtericon"
                onClick={toggleDrawer}
              /> 
              <FilterDrawer isOpen={isDrawerOpen} onClose={toggleDrawer}/>
            </div>
              
            

            </div>
          </div>
        )} */}

          {/*  */}
          {/*SECTION:HERO  */}

          <section>
            {/* <div className="max-w-5xl mx-auto flex  items-center gap-x-20 h-3/5"> */}
            {/* {showModal ? ( */}
              <div className="h-[30px]"></div>
            {/* // ) : ( */}
              {/* max-w-6xl mx-auto flex flex-row flex-wrap justify-center items-center gap-x-20 h-[75vh] px-[20px] lg:px-0 lg:h-[90vh] lg:justify-start xl:h-full */}
              <div className="max-w-7xl mx-auto flex flex-row flex-wrap justify-center items-center gap-x-60 h-[60vh] px-[20px] md:h-[40vh] lg:h-[40vh] lg:justify-start xl:px-0 xl:h-full">
                <div className="w-[550px] xl:flex xl:flex-col xl:gap-y-5 xl:pb-20 xl:pt-10">
                  <div className="text-white text-4xl font-bold font-['DM Sans'] leading-[41.83px] lg:text-[54px] lg:leading-[74.37px] xl:text-[64px] xl:w-[620px] xl:leading-[74.37px]">
                    Running For Office Has Never Been More Rewarding
                  </div>
                  <div className="w-[279px] text-lg mt-10  leading-[27.09px] text-white lg:text-xl lg:leading-[30.10px] xl:text-xl lg:w-[607px]">
                    America’s existing campaign and contribution management
                    platforms are decades old and broken at best. Learn how to
                    more effectively set-up your campaign or committee, manage
                    contributions, and offer an intuitive means for donors to
                    find like minded candidates.
                  </div>
                  {/* mt-10 justify-center flex lg:justify-start */}
                  <div className="mt-20 justify-center flex lg:justify-start">
                    {/* <Link to="signup"> */}
                    <div
            className="w-36 h-9 bg-[#CB000E] flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
            Sign Up
            </div>
                    {/* </Link> */}
                  </div>
              <Tooltip id="my-tooltip" style={{backgroundColor: "#CB000E", zIndex:"1"}} place="bottom"/>

                </div>
                {/* <div className=" flex justify-center lg:hidden">
                  <Link to="signup">
                    <Button label="Sign Up" />
                  </Link>
                </div> */}
                {showLogo ? (
                  <div className="hidden xl:block">
                    <img
                      className="invisible w-[410px] h-[298px] transition-transform duration-500 ease-in-out lg:visible"
                      src={heroLogo}
                      alt=""
                    />
                  </div>
                ) : null}
              </div>
             {/* )} */}
          </section>
        </div>
      </div>

      {/* SECTION: FEATURES */}
      <div
        className="w-full h-[789px] px-5 bg-cover bg-center relative flex items-center justify-center"
        style={{
          backgroundImage: `url("${haroldMandoza}")`,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-30"></div>
        {/* className="w-full h-full pb-36 relative z-10" */}
        <section className="w-full relative z-10 ">
          <FeatureSection />
        </section>
      </div>

      {/* SECTION: DONATE NOW */}
      <div
        className="w-full bg-cover px-5 bg-center bg-opacity-70 "
        style={{
          backgroundImage: `url("${americanMap}")`,
        }}
      >
        <section>
          {/* <div className=" mx-auto flex flex-row justify-between items-center gap-x-20 h-full py-14 mobile:flex-wrap laptop:flex-nowrap"> */}
          {/* max-w-6xl */}
          <div className=" mx-auto flex flex-row flex-wrap justify-center items-center gap-x-20 h-full px-[20px] pt-[20px] lg:px-0 lg:py-20 ">
            <div className="w-[587px] lg:w-[650px] xl:w-[687px]">
              <div className="text-[32px] font-bold text-zinc-800 leading-[41px] lg:text-5xl lg:leading-[62px] xl:text-5xl xl:leading-[62.40px]">
                Campaigns Can Immediately Start Accepting Donations Through Our
                Embedded Donations{" "}
                <span className="text-[#CB000E]">Processing</span>{" "}
                <span className="text-[#00418C]">Widget</span>
              </div>
              <div className="my-6 text-slate-600 leading-relaxed w-[357px] lg:my-6 lg:leading-8 lg:w-[650px] xl:w-[687px] xl:my-16 xl:leading-loose xl:text-xl">
                The RedWave donations plugin empowers committees and candidates
                with a payment processing gateway needed to quickly start
                accepting payments and manage successful campaigns. Connecting
                campaigns and donors, financial institutions, and regulators
                within a secure and transparent application.
              </div>
              <div className=" hidden lg:block">
                {/* <Link to="signup"> */}
                  {/* <Button label="Sign Up" /> */}
                  <button className="w-36 h-9 bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
                    Sign up
                  </button>
                  
                {/* </Link> */}
              </div>
            </div>
            <div className="lg:pt-[40px] xl:pt-0">
              <DonationForm />
              <div className=" flex justify-center my-10  lg:hidden">
                {/* <Link to="signup">
                  <Button label="Sign Up" />
                </Link> */}
                <button className="w-36 h-9 bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
                    Sign up
                  </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* SECTION: PAYMENT GATEWAY */}
      <div
        className="bg-cover bg-center relative"
        style={{
          backgroundImage: `url("${flowerImg}")`,
        }}
      >
        <div className="w-full px-5 relative">
          <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-70"></div>
          <section className="relative z-10">
            <div className="max-w-6xl px-5 mx-auto flex flex-row flex-wrap justify-center items-center gap-x-20 h-[899px] lg:h-full lg:py-20 xl:h-[676px] xl:flex-nowrap xl:max-w-full xl:py-20">
              
              <div className="hidden xl:block">
                <ImageSlider
                  Images={slidesCreditCards}
                />
              </div>
              <div className="block px-0 my-10 xl:hidden">
                <ImageSlider
                  Images={slidesCreditCardSm}
                />
              </div>

            {/* <div className="w-full pt-10 md:w-[550px] xl:pt-0 xl:w-[553px] xl:h-[337px]">
              <Carousel showArrows={false}
              showStatus={false}
              showThumbs={false}
                infiniteLoop
                autoPlay
                interval={3000}>
                {slidesCreditCards.map((slide, index) => (
                <div key={index} className="">
                <img src={slide.url} alt={`Profile Card ${index + 1}`} />
                </div>
                ))}
                </Carousel>
            </div> */}

              <div className="w-[550px]  lg:px-0 xl:w-[558px]">
                <div className="text-white font-bold leading-[42px] text-[32px] lg:text-5xl xl:text-5xl xl:leading-[62.40px]">
                  Our Secure Payment Gateway For Public Donations
                </div>
                <div className="my-10 text-white leading-[28.94px] lg:my-14 lg:leading-8 xl:text-xl">
                  RedWave provides a secure payment gateway, enabling finance
                  teams to seamlessly track, reconcile, and report monetary
                  donations. With RedWave, campaigns can manage their financial
                  transactions hassle-free, ensuring transparency and
                  accountability across the end to end donation process.
                </div>

                <div className="my-10 z-10 flex justify-center lg:block lg:mb-0">
                  {/* <Link to="signup"> */}
                    <button className="w-36 h-9 bg-red-700  flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold xl:w-[183px] xl:h-[42px] xl:text-xl" data-tooltip-id="my-tooltip" data-tooltip-content="Coming Soon!">
                      Sign up
                    </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* SECTION: SUPPORT */}

      <div
        className="w-full py-10 px-5 bg-cover bg-center relative"
        style={{
          backgroundImage: `url("${americanFlag}")`,
        }}
      >
        <div className="absolute inset-0 rotate-180 bg-gradient-to-l from-white to-white opacity-50"></div>
        <section className="relative z-10">
          {/* <div className="max-w-6xl mx-auto flex flex-row flex-wrap justify-center items-center gap-x-20 h-full"> */}
          <div className="max-w-6xl mx-auto flex flex-row flex-wrap justify-center items-center gap-x-20 px-[20px] pt-[20px] h-[727px] lg:h-full  lg:p-0 xl:flex-nowrap xl:py-20 xl:h-[659px] xl:max-w-full">
            <div className="w-[550px] xl:w-[406px]">
              <div className="text-[32px] font-bold text-[#282828]  leading-[41.60px] lg:text-5xl lg:leading-[62px]">
                Support Your Candidate Today With{" "}
                <span className="text-[#D43D21]">Red</span>
                <span className="text-[#00418C]">Wave!</span>
              </div>
              <div className="mt-10 text-[#585C7B] text-lg leading-[28.80px] lg:leading-8 xl:text-xl xl:leading-loose">
                RedWave makes certain even dollar and cent ($) you donate goes
                directly to the candidate and issues you support. Don’t let the
                other platforms charge fees and subscriptions that cuts down
                your donor’s monetary support. Donate on RedWave, today!
              </div>
            </div>
            {/* bg-cover bg-center w-full lg:w-auto */}
            {/* <div className="w-[356px] h-[222px] px-5 xl:px-0 xl:w-[724px] xl:h-[451px]"> */}
            {/* w-auto md:w-[550px] xl:pt-0 xl:w-[724px] xl:h-[451px] */}
            <div className="">
              {/* <Carousel showArrows={false}
              showStatus={false}
              infiniteLoop
              autoPlay
              interval={3000}>
        {slidesProfileCards.map((slide, index) => (
          <div key={index} className="">
            <img src={slide.url} alt={`Profile Card ${index + 1}`} />
          </div>
        ))}
      </Carousel> */}
      {/* change images */}
              <ImageSlider2
                Images={slidesProfileCards}
              />
            </div>
          </div>
          
        </section>
      </div>

      {/* SECTION: CANDIDATE  */}
      <div
        className="w-full px-5 bg-cover bg-center relative"
        style={{
          backgroundImage: `url("${whiteHouseNoon}")`,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-70"></div>

        <section className="relative z-10">
          <div className=" mx-auto py-[40px] flex flex-row justify-center items-center flex-wrap gap-x-20 h-[1009px] lg:h-full xl:h-[789px] px-[20px] lg:px-0 xl:py-20">
            <div className="mb-10 lg:mb-0">
              <CandidateInfoCard />
            </div>
            <div className="w-[550px]">
              <div className="text-[2rem] font-bold text-white  leading-[41.60px] lg:text-5xl lg:leading-[62px]">
                Political Donors Are Offered Candidate Insights Based On Party,
                Political Race, Locality, Donations & Beliefs
              </div>
              <div className="mt-10 leading-8 text-white xl:text-xl">
                RedWave empowers donors to concentrate on contributing to
                campaigns and campaigns the ability to focus on running with the
                best candidates possible.
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* SECTION: BOOK A DEMO  */}
      <div
        className="w-full px-5 bg-cover bg-center"
        style={{
          backgroundImage: `url("${americanFlag}")`,
        }}
      >
        <section>
          {/* max-w-6xl mx-auto flex flex-row flex-wrap justify-center items-center gap-x-20 h-full px-[20px] pt-[20px] lg:p-0 */}
          <div className="max-w-6xl mx-auto flex flex-row flex-wrap justify-center items-end gap-x-20 h-[1050px] px-[20px] pt-0 md:pt-[10px] lg:h-full lg:pt-[20px] lg:p-0 xl:h-[700px]">
            <div className="w-[550px] pb-0 sm:flex-col sm:justify-center lg:pb-[100px]">
              <div className="text-[2rem] font-bold text-[#282828] leading-[41.60px] lg:text-5xl lg:leading-[62px] ">
                Book A Live Demo For Our Donation{" "}
                <span className="text-[#D43D21]">Processing </span>
                <span className="text-[#00418C]">Widget</span>
              </div>
              <div className="my-10  leading-8 text-[#585C7B] xl:text-xl xl:leading-loose ">
                RedWave donations processing gateway empowers campaigns a
                committees with a seamless and no-cost solution for accepting
                contributions. RedWave makes certain that every dollar ($) given
                to a campaign reflects the exact amount that was donated.
              </div>
              {/* <Button label="Book a demo" /> */}
              <div className="flex justify-center lg:justify-start ">
                <button
                  className={`w-36 h-9  flex flex-row justify-center items-center rounded-[21px] text-white text-sm font-semibold mobile:justify-center ${
                    showForm
                      ? "bg-[#CB000E] cursor-not-allowed hidden lg:block"
                      : "bg-[#CB000E] "
                  }`}
                  // onClick={() => setShowForm(true)}
                >
                  <PopupButton url="https://calendly.com/james-y6c/30min?text_color=282828&primary_color=cb000e&hide_gdpr_banner=1" 
              // styles={{width: "487px", height:"540px", borderRadius: "25px", overflowY: "hidden"}}
              rootElement={rootElement}
              text="Book A Demo"
              />
                  
                </button>
                

              </div>
            </div>
            {showForm ? (
              <div className="flex w-[358px] h-[508px] bg-white overflow-hidden rounded-[25px] mb-5 lg:py-4 lg:my-8 lg:h-[588px] lg:w-[512px] xl:h-[599px]  ">
              {/* <BookingForm isOpen={true} onClose={() => setShowForm(false)} /> */}
              {/* <PopupWidget url="https://calendly.com/james-y6c/redwave-product-demo-1??text_color=282828&primary_color=cb000e&hide_gdpr_banner=1" 
              styles={{width: "487px", height:"540px", borderRadius: "25px", overflowY: "hidden"}}
              rootElement={rootElement}
              text="Click here to schedule!"
              /> */}
              </div>
            ) : (
              <div className="flex object-cover w-[386px] h-[492px] lg:w-[512px] lg:h-[652px]">
                <img className="object-cover" src={blonde_women} alt="buisness_women" />
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default MainContainer;
