import { useEffect } from "react";
// import { useLocation } from 'react-router-dom';
import "./App.css";
import "react-day-picker/dist/style.css";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Error from "./components/Error";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import About from "./pages/about/About";
import UserProfile from "./pages/profile/UserProfile";
import CommitteeProfile from "./pages/profile/CommitteeProfile";
import Layout from "./layout/Layout";
import AuthContainer from "./auth/AuthContainer";
import Register from "./auth/Register";
import TwoFactorAuth from "./auth/TwoFactorAuth";
import ForgotPassword from "./auth/ForgotPassword";
import Verification from "./auth/Verification";
import ChangePassword from "./auth/ChangePassword";
import Candidate from "./components/Onboarding/Candidate/Candidate";
import CandidateInfo from "./components/Onboarding/Candidate/CandidateForms/CandidateInfo";
import MyInfo from "./components/Onboarding/Candidate/CandidateForms/MyInfo";
import PoliticalAffiliation from "./components/Onboarding/Candidate/CandidateForms/Political/PoliticalAffiliation";
import CampaignInfo from "./components/Onboarding/Candidate/CandidateForms/CampaignInfo";
import CompanyInfo from "./components/Onboarding/Candidate/CandidateForms/Company/CompanyInfo";
import Education from "./components/Onboarding/Candidate/CandidateForms/Education";
import Issues from "./components/Onboarding/Candidate/CandidateForms/Issues/Issues";
import CreativeAssets from "./components/Onboarding/Candidate/CandidateForms/CreativeAssets";
import SocialMedia from "./components/Onboarding/Candidate/CandidateForms/SocialMedia";
import ShowIssue from "./components/Onboarding/Candidate/CandidateForms/Issues/ShowIssue";
import Biography from "./components/Onboarding/Candidate/CandidateForms/Biography/Biography";
import BiographyInfo from "./components/Onboarding/Candidate/CandidateForms/Biography/BiographyInfo";
import DonorProfile from "./pages/profile/DonorProfile";
import DonorPublicProfile from "./pages/profile/DonorPublicProfile";
import Dashboard from "./pages/dashboard/Dashboard";
import DonorDashboard from "./pages/dashboard/DonorDashboard";
import CandidateProfile from "./pages/profile/CandidateProfile";
import Transactions from "./pages/Transactions";
import Settings from "./pages/setting/Settings";
import FavouriteProfiles from "./pages/FavouriteProfiles";
import DonorSettings from "./pages/setting/DonorSettings";
import WelcomeProfile from "./pages/paymentWidget/WelcomeProfile";
import CustomizeDonation from "./pages/paymentWidget/CustomizeDonation";
import ConnectBank from "./pages/paymentWidget/ConnectBank";
import VerifyIdentity from "./pages/paymentWidget/VerifyIdentity";
import CandidateLayout from "./components/Onboarding/Candidate/CandidateLayout/CandidateLayout";
import Onboarding from "./components/Onboarding/Onboarding";
import CommitteeLayout from "./components/Onboarding/Committee/CommitteeLayout/CommitteeLayout";
import CommitteeMyInfo from "./components/Onboarding/Committee/CommitteeForms/CommitteeMyInfo";
import CommiteeInfo from "./components/Onboarding/Committee/CommitteeForms/CommiteeInfo";
import CommittePolAffiliation from "./components/Onboarding/Committee/CommitteeForms/CommittePolAffiliation";
import CommitteeComInfo from "./components/Onboarding/Committee/CommitteeForms/CommitteeComInfo";
import CommitteeBiography from "./components/Onboarding/Committee/CommitteeForms/CommitteeBiography";
import CommiteeAssets from "./components/Onboarding/Committee/CommitteeForms/CommiteeAssets";
import CommiteeIssues from "./components/Onboarding/Committee/CommitteeForms/CommiteeIssues";
import CommitteeMedia from "./components/Onboarding/Committee/CommitteeForms/CommitteeMedia";
import DonorLayout from "./components/Onboarding/Donor/DonorLayout/DonorLayout";
import DonorInfo from "./components/Onboarding/Donor/DonorForms/DonorInfo";
import DonorMain from "./components/Onboarding/Donor/DonorForms/DonorMain";
import ContributorInfo from "./components/Onboarding/Donor/DonorForms/ContributorInfo";
import DonorIssues from "./components/Onboarding/Donor/DonorForms/DonorIssues";
import DonorBiography from "./components/Onboarding/Donor/DonorForms/DonorBiography";
import DonorSocial from "./components/Onboarding/Donor/DonorForms/DonorSocial";
import StepperComponennt from "./components/StepperComponent";
import CandidateMain from "./components/Onboarding/Candidate/CandidateForms/CandidateMain";
import CommitteeMain from "./components/Onboarding/Committee/CommitteeForms/CommitteeMain";
import Footer from "./components/Footer";
import { ProtectedRoute, PublicRoute } from "./routes/routes";

function ScrollToTop() {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [navigate]);

  return null;
}

// preline
function App() {
  // const location = useLocation();

  useEffect(() => {
    require("preline/preline");
  }, []);

  // useEffect(() => {
  //   // @ts-ignore
  //   HSStaticMethods.autoInit();
  // }, [location.pathname]);

  return (
    <>
      <RouterProvider router={appRouter} />
      <Toaster />
    </>
  );
}

const appRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <>
          <Home />
          <Footer />
        </>
      ),
      errorElement: <Error />,
    },
    {
      path: "/login",
      element: (
        <PublicRoute>
          <AuthContainer />
        </PublicRoute>
      ),
      errorElement: <Error />,
    },
    {
      path: "/signup",
      element: (
        <PublicRoute>
          <Register />
        </PublicRoute>
      ),
      errorElement: <Error />,
    },
    {
      path: "/about",
      element: (
        <>
          <ScrollToTop />
          <About />
          <Footer />
        </>
      ),
      errorElement: <Error />,
    },
    {
      path: "/settings",
      element: <Settings />,
      errorElement: <Error />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
      errorElement: <Error />,
    },
    {
      path: "/donor-dashboard",
      element: (
        <ProtectedRoute>
          <DonorDashboard />
        </ProtectedRoute>
      ),
      errorElement: <Error />,
    },
    {
      path: "/profile",
      element: <Profile />,
      errorElement: <Error />,
    },
    {
      path: "/user-profile",
      element: <UserProfile />,
      errorElement: <Error />,
    },
    {
      path: "/candidate-profile",
      element: <CandidateProfile />,
      errorElement: <Error />,
    },
    {
      path: "/committee-profile",
      element: <CommitteeProfile />,
      errorElement: <Error />,
    },
    {
      path: "/donor-profile",
      element: <DonorProfile />,
      errorElement: <Error />,
    },
    {
      path: "/donor-public-profile",
      element: <DonorPublicProfile />,
      errorElement: <Error />,
    },
    {
      path: "/favourite-profiles",
      element: <FavouriteProfiles />,
      errorElement: <Error />,
    },
    {
      path: "/donor-settings",
      element: <DonorSettings />,
      errorElement: <Error />,
    },
    {
      path: "/transactions-history",
      element: <Transactions />,
      errorElement: <Error />,
    },
    // payment widget pages routes
    {
      path: "/welcome-profile",
      element: <WelcomeProfile />,
      errorElement: <Error />,
    },
    {
      path: "/customize-donations",
      element: <CustomizeDonation />,
      errorElement: <Error />,
    },
    {
      path: "/connect-bank",
      element: <ConnectBank />,
      errorElement: <Error />,
    },
    {
      path: "/verify-identity",
      element: <VerifyIdentity />,
      errorElement: <Error />,
    },
    // onbaording pages routes
    {
      path: "/onboarding",
      element: (
        <ProtectedRoute>
          <Layout>
            <Onboarding />
          </Layout>
        </ProtectedRoute>
      ),
      errorElement: <Error />,
    },

    {
      path: "/two-factor",
      element: <TwoFactorAuth />,
      errorElement: <Error />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
      errorElement: <Error />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
      errorElement: <Error />,
    },
    {
      path: "/verify",
      element: <Verification />,
      errorElement: <Error />,
    },
    {
      path: "/candidate",
      element: <Candidate />,
      errorElement: <Error />,
    },
    {
      path: "/candidate-forms",
      element: (
        <ProtectedRoute>
          <CandidateLayout>
            <CandidateMain />
            {/* <MyInfo />, */}
          </CandidateLayout>
        </ProtectedRoute>
      ),
      errorElement: <Error />,
    },
    {
      path: "/candidate-info",
      element: (
        <CandidateLayout>
          <CandidateInfo />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "political-affiliation",
      element: (
        <CandidateLayout>
          <PoliticalAffiliation />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/campaign-info",
      element: (
        <CandidateLayout>
          <CampaignInfo />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/company-info",
      element: (
        <CandidateLayout>
          <CompanyInfo />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/biography-info",
      element: (
        <CandidateLayout>
          <BiographyInfo />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/biography",
      element: (
        <CandidateLayout>
          <Biography />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/education",
      element: (
        <CandidateLayout>
          <Education />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/issues",
      element: (
        <CandidateLayout>
          <Issues />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/show-issue",
      element: (
        <CandidateLayout>
          <ShowIssue />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/creative-assets",
      element: (
        <CandidateLayout>
          <CreativeAssets />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/social-media",
      element: (
        <CandidateLayout>
          <SocialMedia />,
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
    // Commitee Routes
    {
      path: "/committee-forms",
      element: (
        <CommitteeLayout>
          <CommitteeMain />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },

    {
      path: "/committee-my-info",
      element: (
        <CommitteeLayout>
          <CommitteeMyInfo />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/committee-info",
      element: (
        <CommitteeLayout>
          <CommiteeInfo />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/committee-political-affiliation",
      element: (
        <CommitteeLayout>
          <CommittePolAffiliation />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/committee-company-info",
      element: (
        <CommitteeLayout>
          <CommitteeComInfo />
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/committee-biography",
      element: (
        <CommitteeLayout>
          <CommitteeBiography />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/committee-creative-assets",
      element: (
        <CommitteeLayout>
          <CommiteeAssets />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/committee-issues",
      element: (
        <CommitteeLayout>
          <CommiteeIssues />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/committee-social-media",
      element: (
        <CommitteeLayout>
          <CommitteeMedia />,
        </CommitteeLayout>
      ),
      errorElement: <Error />,
    },
    // Donor Routes
    {
      path: "/donor-forms",
      element: (
        <DonorLayout>
          {/* <DonorInfo />, */}
          <DonorMain />,
        </DonorLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/welcome-donor",
      element: (
        <DonorLayout>
          <h1>Welcome </h1>
        </DonorLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/contributor-info",
      element: (
        <DonorLayout>
          <ContributorInfo />,
        </DonorLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/donor-issues",
      element: (
        <DonorLayout>
          <DonorIssues />,
        </DonorLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/donor-biography",
      element: (
        <DonorLayout>
          <DonorBiography />,
        </DonorLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/donor-social-media",
      element: (
        <DonorLayout>
          <DonorSocial />,
        </DonorLayout>
      ),
      errorElement: <Error />,
    },
    {
      path: "/stepper-component",
      element: (
        <CandidateLayout>
          <StepperComponennt />
        </CandidateLayout>
      ),
      errorElement: <Error />,
    },
  ]
  // {
  //   ScrollRestoration: "auto",
  // }
);

export default App;
