import React, { useState } from "react";
import governorHouse from "../assets/icons/governorHouseIcon.png";
import northMap from "../assets/icons/northMapIcon.png";
import republican from "../assets/icons/republicanIcon.png";
import whiteRight from "../assets/icons/whiteRightArrow.png";
import cardImg from "../assets/images/modalCardImg.png";
import mapIcon from "../assets/icons/mapIcon.png";

interface CardProps {
  image: string;
  name: string;
  state: string;
  status: string;
  party: string;
  cardColor: string;
}

// interface UserInfo {
//   image: string;
//   name: string;
//   state: string;
//   status: string;
//   party: string;
// }

// const userInfo: UserInfo[] = [
//   {
//     image: cardImg,
//     name: "Holland Morgan Reagan",
//     state: "North Carolina",
//     status: "Running For Governor",
//     party: "Republican Party",
//   },
//   {
//     image: cardImg,
//     name: "Republican Party Florida",
//     state: "North Carolina",
//     status: "Running For Governor",
//     party: "Democrat Party",
//   },
//   {
//     image: cardImg,
//     name: "Elizabeth Martin Parkinson",
//     state: "North Carolina",
//     status: "Running For Governor",
//     party: "Independent",
//   },
// ];

const ProfileCard: React.FC<CardProps> = ({
  image,
  name,
  state,
  status,
  party,
  cardColor,
}) => {
  const [hover, setHover] = useState(false);
  const color = cardColor;

  return (
    <div
      className="flex rounded-[20px] h-[130.30px] hover:bg-red-700 lg:h-[219px] md:h-[219px]"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={`w-[111px] ${hover ? "transition-transform ease-in-out duration-1000" : "" }  lg:w-[186px] md:w-[186px]`}>
        {hover ? (
          <div className="pl-[10px] pr-[5px] pt-2 transition-transform ease-in-out duration-700 lg:px-5 md:px-5">
            <div className="text-white text-[11px] font-bold font-['Hind Guntur'] leading-relaxed pb-2 lg:text-lg md:text-lg">
              {name}
            </div>

            <div className="flex items-center gap-2 mb-2">
              <img src={governorHouse} alt="" />
              <div className="text-white text-[8px] font-bold font-['DM Sans'] lg:text-sm md:text-sm">
                {status}
              </div>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <img src={northMap} alt="" />
              <div className="text-white text-[8px] font-bold font-['DM Sans'] lg:text-sm md:text-sm">
                {state}
              </div>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <img src={republican} alt="" />
              <div className="text-white text-[8px] font-bold font-['DM Sans'] lg:text-sm md:text-sm">
                {party}
              </div>
            </div>
          </div>
        ) : (
          <img src={image} alt="user" className="w-[111px] h-[130.30px] hover:hidden lg:h-auto  md:h-auto lg:w-full md:w-full" />
        )}
      </div>
{/* w-[110px] */}
      <div className="w-[76px] px-[5px] py-4 bg-red-700 rounded-r-[20px] flex flex-col justify-between lg:w-[121px] lg:px-4 lg:py-4 md:py-4 md:px-4">
        {hover ? null : (
          <>
            <h2 className="text-center text-white text-[11px] font-bold font-['Hind Guntur'] leading-normal lg:text-lg md:text-lg">
              {name}
            </h2>
            <p className="text-center text-white text-[8px] font-bold font-['DM Sans'] lg:text-xs md:text-xs">
              {status}
            </p>
          </>
        )}

        <div className="flex items-center">
          <img src={mapIcon} alt="Icon" className="mr-2" />
        </div>
        {hover ? (
          <div className="flex items-center gap-2 text-white font-['DM Sans']">
            <div className="text-[12px]">Profile</div>
            <img className="h-[20px]" src={whiteRight} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileCard;
