import React, { useState } from "react";
import redWave from "../../assets/images/redWaveIcon.svg";
import leftArrow from "../../assets/icons/leftArrowIcon.svg";
import { Link } from "react-router-dom";
import imagePreview from "../../assets/icons/imagePreviewIcon.svg";
import rightArrow from "../../assets/icons/rightArrowIcon.png";
import addIcon from "../../assets/icons/addIcon.svg";
import sandClock from "../../assets/icons/sandClockIcon.svg";

const VerifyIdentity = () => {
  const [isIdentity, setIsIdentity] = useState("license");
  const [frontImagePreview, setFrontImagePreview] = useState<File | null>(null);
  const [backImagePreview, setBackImagePreview] = useState<File | null>(null);
  const [isNext, setIsNext] = useState(true);
  const [isBack, setIsBack] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleIdentityChange = (identityValue: any) => {
    setIsIdentity(identityValue === isIdentity ? "license" : identityValue);
  };

  const handleFrontImageUpload = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      // Update state with the selected image
      setFrontImagePreview(file);
      // const file = e.target.files[0];
      // if (file) {

      //   setFrontImagePreview(file);

      //   console.log("Image uploaded:", file);
    }
  };

  const handleBackImageUpload = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      // Update state with the selected image
      setBackImagePreview(file);
    }
  };

  const handleNext = () => {
    setIsNext(false);
    setIsBack(true);
    setIsSubmit(false);
  };

  const handleBack = () => {
    setIsBack(false);
    setIsNext(true);
    setIsSubmit(false);
  };

  const handleSubmit = () => {
    setIsSubmit(!isSubmit);
    setIsBack(false);
    setIsNext(false);
  };

  return (
    <div className="bg-cover bg-center relative bg-[url('/src/assets/images/bridge.svg')] lg:h-full">
      <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-70"></div>

      <div className="relative z-10 px-8 py-8 lg:px-20 lg:py-20">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-white text-[64px] font-bold font-['DM Sans'] leading-[74.37px]">
              Verify Your Identity
            </div>
            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Verify your identity to helps us keep your account secure
            </div>
          </div>
          <img
            className="w-[92px] h-[67px] lg:w-[194px] lg:h-[141px]"
            src={redWave}
            alt=""
          />
        </div>

        <div className="flex justify-between pt-8">
          {/* left */}
          <div className="w-[474px] h-[616px] px-10 py-10 relative bg-opacity-60 mix-blend-multiply bg-black rounded-[25px]">
            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Verify your identity by :
            </div>

            <div className="flex gap-10 py-5">
              <label
                className={`inline-flex items-center space-x-2 ${
                  isIdentity === "license" ? "text-red-700" : "text-neutral-400"
                }`}
              >
                <input
                  type="checkbox"
                  className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                    isIdentity === "license" ? "bg-red-700" : "bg-zinc-300"
                  }`}
                  checked={isIdentity === "license"}
                  value="license"
                  onChange={() => handleIdentityChange("license")}
                />
                <span className=" text-white text-xl font-normal font-['Hind Guntur'] leading-loose">
                  Driver's License
                </span>
              </label>

              <label
                className={`inline-flex items-center space-x-2 ${
                  isIdentity === "passport"
                    ? "text-red-700"
                    : "text-neutral-400"
                }`}
              >
                <input
                  type="checkbox"
                  className={`appearance-none  w-[21px] h-[21px] rounded-full ${
                    isIdentity === "passport" ? "bg-red-700" : "bg-zinc-300"
                  }`}
                  checked={isIdentity === "passport"}
                  value="passport"
                  onChange={() => handleIdentityChange("passport")}
                />
                <span className="text-white text-xl font-normal font-['Hind Guntur'] leading-loose">
                  Passport
                </span>
              </label>
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              First and last name :
            </div>

            <div className="py-4">
              <input
                className="w-[317px] h-[50px] bg-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                type="text"
                placeholder="Enter your bank name"
              />
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Expiration :
            </div>

            <div className="py-4">
              <input
                className="w-[317px] h-[50px] bg-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                type="text"
                placeholder="Morgan reagan"
              />
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Upload front image :
            </div>

            <div className="flex justify-between items-center w-[317px] h-[50px] bg-white bg-opacity-5 rounded-[25px] my-4 px-3">
              {/* <input
                className="outline-none opacity-0 placeholder:text-white"
                type="file"
                accept="image/*"
                onChange={handleFrontImageUpload}
              /> */}

              <label className="block relative">
                <input
                  className="absolute opacity-0 w-full h-full cursor-pointer"
                  type="file"
                  accept="image/*"
                  onChange={handleFrontImageUpload}
                />
                <div className="text-white">
                  {frontImagePreview
                    ? frontImagePreview.name
                    : "Upload an image"}
                </div>
              </label>

              <img src={addIcon} alt="" />
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Upload back image :
            </div>

            <div className="flex justify-between items-center w-[317px] h-[50px] bg-white bg-opacity-5 rounded-[25px] my-4 px-3">
              <label className="block relative">
                <input
                  className="absolute opacity-0 w-full h-full cursor-pointer"
                  type="file"
                  accept="image/*"
                  onChange={handleBackImageUpload}
                />
                <div className="text-white">
                  {backImagePreview ? backImagePreview.name : "Upload an image"}
                </div>
              </label>

              <img src={addIcon} alt="" />
            </div>
          </div>
          {/* right */}

          <div>
            <div className="w-[633px] h-[476px] px-10 py-10 relative bg-opacity-60 mix-blend-multiply bg-black rounded-[25px]">
              <div>
                <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                  {isNext
                    ? "Front image preview :"
                    : isBack
                    ? "Back image preview :"
                    : isSubmit
                    ? null
                    : null}
                </div>

                {isNext && (
                  <div>
                    {frontImagePreview ? (
                      <div className="w-[547px] h-[303px] my-5">
                        <img
                          src={URL.createObjectURL(frontImagePreview)}
                          alt="Uploaded"
                          className="bg-cover bg-center w-[547px] h-[303px]"
                        />
                      </div>
                    ) : (
                      <div className="w-[547px] h-[303px] bg-white bg-opacity-5 rounded-[10px] border border-dashed border-white my-5 flex flex-col justify-center items-center">
                        {/* <div className="flex flex-col justify-center items-center"> */}
                        <div className="flex items-center gap-x-5">
                          <img src={imagePreview} alt="" />
                          <div>
                            <div className="text-white text-xl font-normal font-['Hind Guntur'] leading-loose">
                              Image Preview
                            </div>
                            <div className="text-center text-white text-sm font-normal font-['Hind Guntur'] leading-snug">
                              1200*800 Dimensions
                            </div>
                            <div className="text-center text-white text-sm font-normal font-['Hind Guntur'] leading-snug">
                              JPG , PNG Formats{" "}
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                )}

                {isBack && (
                  <div>
                    {backImagePreview ? (
                      <div className="w-[547px] h-[303px] my-5">
                        <img
                          src={URL.createObjectURL(backImagePreview)}
                          alt="Uploaded"
                          className="bg-cover bg-center w-[547px] h-[303px]"
                        />
                      </div>
                    ) : (
                      <div className="w-[547px] h-[303px] bg-white bg-opacity-5 rounded-[10px] border border-dashed border-white my-5 flex flex-col justify-center items-center">
                        {/* <div className="flex flex-col justify-center items-center"> */}
                        <div className="flex items-center gap-x-5">
                          <img src={imagePreview} alt="" />
                          <div>
                            <div className="text-white text-xl font-normal font-['Hind Guntur'] leading-loose">
                              Image Preview
                            </div>
                            <div className="text-center text-white text-sm font-normal font-['Hind Guntur'] leading-snug">
                              1200*800 Dimensions
                            </div>
                            <div className="text-center text-white text-sm font-normal font-['Hind Guntur'] leading-snug">
                              JPG , PNG Formats{" "}
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                )}

                {isNext ? (
                  <div className="flex justify-end">
                    <div
                      className="cursor-pointer flex items-center gap-x-3"
                      onClick={handleNext}
                    >
                      <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                        Next
                      </div>
                      <img src={rightArrow} alt="" />
                    </div>
                  </div>
                ) : isBack ? (
                  <div className="flex justify-between">
                    <div
                      className="cursor-pointer flex items-center gap-x-3"
                      onClick={handleBack}
                    >
                      <img src={leftArrow} alt="" />
                      <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                        Back
                      </div>
                    </div>

                    <div
                      className="cursor-pointer flex items-center gap-x-3"
                      onClick={handleSubmit}
                    >
                      <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                        Submit
                      </div>
                      <img src={rightArrow} alt="" />
                    </div>
                  </div>
                ) : isSubmit ? null : null}

                {isSubmit && (
                  <div className="flex flex-col items-center justify-center">
                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Your picture has been submitted for verification
                    </div>

                    <div className="py-20">
                      <img
                        className="w-[95px] lg:h-[115px]"
                        src={sandClock}
                        alt=""
                      />
                    </div>

                    <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                      Our team will review your picture and you will be notified
                      in the next 2 business days
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-between items-center pt-16">
              <Link to="/connect-bank">
                <div className="cursor-pointer flex items-center gap-x-3">
                  <img src={leftArrow} alt="" />
                  <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                    Back
                  </div>
                </div>
              </Link>

              <Link to="/dashboard">
                <button className="w-[183px] h-[42px] bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-base font-semibold ">
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyIdentity;
