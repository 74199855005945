import React, { ReactNode } from 'react';
import CommitteeSidebar from '../CommitteeSidebar/CommitteeSidebar';

interface LayoutProps {
    children: ReactNode;
}

const CommitteeLayout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className="flex-1 md:flex-1 lg:flex-1 xl:flex">
            <CommitteeSidebar />
            <div>
                {children}
            </div>
        </div>
    );
};

export default CommitteeLayout;
