import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  userData: null;
  data: [];
  error: null;
  slug: "";
  isAuthenticated: boolean;
};

const initialState: AuthState = {
  userData: null,
  data: [],
  error: null,
  slug: "",
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<any>) => {
      state.isAuthenticated = true;
      state.userData = action.payload;
    },

    clearStore: (state) => {
      state.userData = null;
      state.isAuthenticated = false;
      localStorage.clear();
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthData, clearStore } = authSlice.actions;

export default authSlice.reducer;
