import React, { useState } from "react";
import redWave from "../../assets/images/redWaveIcon.svg";
import leftArrow from "../../assets/icons/leftArrowIcon.svg";
import { Link } from "react-router-dom";

const CustomizeDonation = () => {
  const [isTodayToggle, setIsTodayToggle] = useState(false);
  const [isMonthToggle, setIsMonthToggle] = useState(true);

  const [isApplePayToggle, setIsApplePayToggle] = useState(true);
  const [isCreditCardToggle, setIsCreditCardToggle] = useState(true);
  const [isDirectBankToggle, setIsDirectBankToggle] = useState(true);
  const [isECheckToggle, setIsECheckToggle] = useState(true);
  const [isPaypalToggle, setIsPaypalToggle] = useState(true);

  const [isCustomDonation, setIsCustomDonation] = useState(false);

  const toggleTodaySwitch = () => {
    setIsTodayToggle((prevIsOn) => !prevIsOn);
  };

  const toggleMonthSwitch = () => {
    setIsMonthToggle((prevIsOn) => !prevIsOn);
  };

  //   payments toggle

  const toggleApplePaySwitch = () => {
    setIsApplePayToggle((prevIsOn) => !prevIsOn);
  };

  const toggleCreditCardSwitch = () => {
    setIsCreditCardToggle((prevIsOn) => !prevIsOn);
  };

  const toggleDirectBankSwitch = () => {
    setIsDirectBankToggle((prevIsOn) => !prevIsOn);
  };

  const toggleECheckSwitch = () => {
    setIsECheckToggle((prevIsOn) => !prevIsOn);
  };

  const togglePaypalSwitch = () => {
    setIsPaypalToggle((prevIsOn) => !prevIsOn);
  };

  const handleCustomDonation = () => {
    setIsCustomDonation(true);
  };

  return (
    <div className="bg-cover bg-center relative bg-[url('/src/assets/images/whitehouseSideViewTwo.svg')] lg:h-full">
      <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-70"></div>

      <div className="relative z-10 px-8 py-8 lg:px-20 lg:py-20">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-white text-[64px] font-bold font-['DM Sans'] leading-[74.37px]">
              Customize Your Donations
            </div>
            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Customize your donation amounts to your specific requirements
            </div>
          </div>
          <img
            className="w-[92px] h-[67px] lg:w-[194px] lg:h-[141px]"
            src={redWave}
            alt=""
          />
        </div>

        <div className="flex justify-between pt-8">
          {/* left */}
          <div className="w-[474px] h-[616px] px-10 py-10 relative bg-opacity-60 mix-blend-multiply bg-black rounded-[25px]">
            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
              Edit & Customize donations USD only :
            </div>

            <div className="flex gap-x-3 gap-y-5 py-5 flex-wrap">
              <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                  $25
                </div>
              </div>

              <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                  $50
                </div>
              </div>

              <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                  $100
                </div>
              </div>

              <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                  $500
                </div>
              </div>

              <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                  $1000
                </div>
              </div>

              <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                  $2000
                </div>
              </div>

              <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                  $3000
                </div>
              </div>

              {isCustomDonation ? (
                <input
                  className="w-[220px] text-white h-[42px] text-xl bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center outline-none placeholder:text-white"
                  type="text"
                  placeholder="Custom Amount $"
                />
              ) : (
                <>
                  <div className="w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center">
                    <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                      $5000
                    </div>
                  </div>

                  <div
                    className="cursor-pointer w-[110px] h-[42px] bg-opacity-5 bg-white rounded-[21px] text-center flex flex-col justify-center items-center"
                    onClick={handleCustomDonation}
                  >
                    <div className=" text-white text-xl font-normal font-['Hind Guntur']">
                      Custom
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-5">
              Select the schedule for donations :
            </div>

            <div className="space-y-5">
              <div className="flex justify-between items-center">
                <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                  Donate Today Only
                </div>
                <div>
                  <div
                    className={`relative w-12 h-6 bg-gray-300  rounded-full p-1 cursor-pointer ${
                      isTodayToggle ? "bg-green-600 " : "bg-opacity-25"
                    }`}
                    onClick={toggleTodaySwitch}
                  >
                    <div
                      className={`absolute w-4 h-4 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                        isTodayToggle ? "translate-x-6" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                  Donate Every Month
                </div>

                <div>
                  <div
                    className={`relative w-12 h-6 bg-gray-300  rounded-full p-1 cursor-pointer ${
                      isMonthToggle ? "bg-green-600 " : "bg-opacity-25"
                    }`}
                    onClick={toggleMonthSwitch}
                  >
                    <div
                      className={`absolute w-4 h-4 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                        isMonthToggle ? "translate-x-6" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-8">
              Do you want your Donors to cover fees :
            </div>

            <div className="flex gap-x-3">
              <input
                type="checkbox"
                className="w-[21px] h-[21px] accent-red-700 text-red-700 bg-zinc-300 rounded-full"
                //  checked={isAlert === "campaign"}
                //  onChange={() => handleAlertChange("campaign")}
              />
              <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                Yes, please add a 3.00% processing fee to credit card donations.
              </div>
            </div>
          </div>
          {/* right */}

          <div>
            <div className="w-[633px] h-[476px] px-10 py-10 relative bg-opacity-60 mix-blend-multiply bg-black rounded-[25px]">
              <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">
                What’s your total donation goal :
              </div>

              <div className="py-5">
                <input
                  className="w-[317px] h-[50px] text-white bg-white bg-opacity-5 rounded-[10px] outline-none p-3 placeholder:text-white"
                  type="text"
                  placeholder="Enter your amount"
                />
              </div>

              <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px] py-5">
                Supported payment methods for donations :
              </div>

              <div className="space-y-5">
                <div className="flex justify-between items-center">
                  <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                    Apple Pay Payments
                  </div>
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300  rounded-full p-1 cursor-pointer ${
                        isApplePayToggle ? "bg-green-600 " : "bg-opacity-25"
                      }`}
                      onClick={toggleApplePaySwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                          isApplePayToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                    Debit / Credit Card Payments
                  </div>
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300  rounded-full p-1 cursor-pointer ${
                        isCreditCardToggle ? "bg-green-600 " : "bg-opacity-25"
                      }`}
                      onClick={toggleCreditCardSwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                          isCreditCardToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                    Direct Bank Transfers
                  </div>
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300  rounded-full p-1 cursor-pointer ${
                        isDirectBankToggle ? "bg-green-600 " : "bg-opacity-25"
                      }`}
                      onClick={toggleDirectBankSwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                          isDirectBankToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                    E-Check Payments
                  </div>
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300  rounded-full p-1 cursor-pointer ${
                        isECheckToggle ? "bg-green-600 " : "bg-opacity-25"
                      }`}
                      onClick={toggleECheckSwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                          isECheckToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <div className="text-white text-base font-medium font-['Hind Guntur'] leading-normal">
                    PayPal Direct Payments
                  </div>
                  <div>
                    <div
                      className={`relative w-12 h-6 bg-gray-300  rounded-full p-1 cursor-pointer ${
                        isPaypalToggle ? "bg-green-600 " : "bg-opacity-25"
                      }`}
                      onClick={togglePaypalSwitch}
                    >
                      <div
                        className={`absolute w-4 h-4 bg-white  rounded-full shadow-md transform transition-transform duration-300 ${
                          isPaypalToggle ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center pt-16">
              <Link to="/welcome-profile">
                <div className="cursor-pointer flex items-center gap-x-3">
                  <img src={leftArrow} alt="" />
                  <div className=" text-white text-base font-medium font-['Hind Guntur'] leading-tight">
                    Back
                  </div>
                </div>
              </Link>

              <Link to="/connect-bank">
                <button className="w-[183px] h-[42px] bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-base font-semibold ">
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizeDonation;
