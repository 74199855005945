import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import MyInfo from "./MyInfo";
import CandidateInfo from "./CandidateInfo";
import PoliticalAffiliation from "./Political/PoliticalAffiliation";
import CampaignInfo from "./CampaignInfo";
import CompanyInfo from "./Company/CompanyInfo";
import Biography from "./Biography/Biography";
import Education from "./Education";
import Issues from "./Issues/Issues";
import CreativeAssets from "./CreativeAssets";
import SocialMedia from "./SocialMedia";
import BiographyInfo from "./Biography/BiographyInfo";
import ShowIssue from "./Issues/ShowIssue";

const CandidateMain = () => {
  const CandidateState = useAppSelector(
    (state: any) => state.candidate.CandidateFlow
  );

  if (CandidateState.myinfo.isvisible) {
    return <MyInfo />;
  }
  if (CandidateState.CandidateInfo.isvisible) {
    return <CandidateInfo />;
  }
  if (CandidateState.PoliticalAffiliation.isvisible) {
    return <PoliticalAffiliation />;
  }
  if (CandidateState.CampaignInfo.isvisible) {
    return <CampaignInfo />;
  }
  if (CandidateState.CompanyInfo.isvisible) {
    return <CompanyInfo />;
  }
  if (CandidateState.BiographyStepOne.isvisible) {
    return <BiographyInfo />;
  }
  if (CandidateState.BiographyStepTwo.isvisible) {
    return <Biography />;
  }
  if (CandidateState.Education.isvisible) {
    return <Education />;
  }
  if (CandidateState.Issues.isvisible) {
    return <Issues />;
  }
  if (CandidateState.ShowIssues.isvisible) {
    return <ShowIssue />;
  }
  if (CandidateState.CreativeAssets.isvisible) {
    return <CreativeAssets />;
  }
  if (CandidateState.SocialMedia.isvisible) {
    return <SocialMedia />;
  }

  return null;
};

export default CandidateMain;
