// SidebarItem.tsx

import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UpdateCommitteeForm } from "../../../../redux/committee/CommitteeNewSlice";
import "./styles.css";

interface SidebarItemProps {
  title: string;
  icon: string;
  selectedIcon: string;
  path: string;
}

const CommitteeSidebarItem: React.FC<SidebarItemProps> = ({
  title,
  icon,
  selectedIcon,
  path,
}) => {
  const location = useLocation();
  const CommitteeFlow = useAppSelector(
    (state: any) => state.committeenew.CommitteeFlow
  );
  const dispatch = useAppDispatch();

  const getIcon = (title: string): any => {
    const formattedTitle = title.replace(/\s+/g, "");
    const filterText = formattedTitle === "MyInfo" ? "myinfo" : formattedTitle;
    const committee = CommitteeFlow[filterText];
    if (committee && committee.fill) {
      //   return selectedIcon;
      return true;
    }

    // return icon;
    return false;
  };

  const isAvailable: any = getIcon(title);

  const handelform = (title: string) => {
    const formattedTitle = title.replace(/\s+/g, "");
    const filterText = formattedTitle === "MyInfo" ? "myinfo" : formattedTitle;
    const committee = CommitteeFlow[filterText];
    if (committee && committee.fill) {
      dispatch(UpdateCommitteeForm(filterText));
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="flex items-center p-2 relative add_sidebar_line">
      <div
        className="flex items-center gap-6 justify-start cursor-pointer "
        onClick={() => handelform(title)}
      >
        <img
          src={isAvailable ? selectedIcon : icon}
          alt="icon"
          className={`flex-shrink-0 ${location.pathname === path}`}
        />
        {/* <div className={`text-justify ${isAvailable ? "text-red-600" : ""}`}> */}
        <div className={`text-justify `}>{title}</div>
      </div>
    </div>
  );
};

export default CommitteeSidebarItem;
