import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import CommitteeMyInfo from "./CommitteeMyInfo";
import CommiteeInfo from "./CommiteeInfo";
import CommittePolAffiliation from "./CommittePolAffiliation";
import CommitteeComInfo from "./CommitteeComInfo";
import CommitteeBiography from "./CommitteeBiography";
import CommiteeIssues from "./CommiteeIssues";
import CommiteeAssets from "./CommiteeAssets";
import CommitteeMedia from "./CommitteeMedia";

const CommitteeMain = () => {
  const CommitteeFlow = useAppSelector(
    (state: any) => state.committeenew.CommitteeFlow
  );
  // const authinfo = useAppSelector((state: any) => state.auth.userData);

  if (CommitteeFlow.myinfo.isvisible) {
    return <CommitteeMyInfo />;
  }
  if (CommitteeFlow.CommitteeInfo.isvisible) {
    return <CommiteeInfo />;
  }
  if (CommitteeFlow.PoliticalAffiliation.isvisible) {
    return <CommittePolAffiliation />;
  }
  if (CommitteeFlow.CompanyInfo.isvisible) {
    return <CommitteeComInfo />;
  }
  if (CommitteeFlow.Biography.isvisible) {
    return <CommitteeBiography />;
  }
  if (CommitteeFlow.CreativeAssets.isvisible) {
    return <CommiteeAssets />;
  }
  if (CommitteeFlow.Issues.isvisible) {
    return <CommiteeIssues />;
  }

  if (CommitteeFlow.SocialMedia.isvisible) {
    return <CommitteeMedia />;
  }
  return null;
};

export default CommitteeMain;
