import React from 'react'

const National = ({color}) => {
  return (
    <svg width="41" height="45" viewBox="0 0 41 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.6644 11.022C40.6644 8.59498 38.8963 6.56979 36.4693 6.21623C32.0492 5.58937 27.5489 3.77313 23.0807 0.831772C21.409 -0.277257 19.2553 -0.277257 17.5837 0.831772C13.1155 3.77313 8.61512 5.58937 4.19509 6.21623C1.76807 6.56979 0 8.59498 0 11.0381V18.7691C0 30.1004 7.12035 40.4353 17.6801 44.5017C18.532 44.8392 19.4321 45 20.3322 45C21.2323 45 22.1323 44.8392 22.9681 44.5017L24.5593 43.891C29.4615 42.0105 33.4797 38.7155 36.2764 34.5205C39.0892 30.3255 40.6644 25.2304 40.6644 19.7978V11.022ZM30.5545 23.3178L27.9829 25.8251C27.9346 25.8733 27.9185 25.9376 27.9346 26.0019L28.5293 29.5218C28.7543 30.8077 28.24 32.0935 27.1792 32.8489C26.5845 33.2828 25.8773 33.5079 25.1862 33.5079C24.6397 33.5079 24.0933 33.3793 23.595 33.1221L20.4286 31.4506C20.3644 31.4184 20.3 31.4184 20.2357 31.4506L17.0693 33.1221C15.9121 33.7329 14.546 33.6204 13.4851 32.8489C12.4244 32.0935 11.91 30.8077 12.1351 29.5218L12.7298 26.0019C12.7458 25.9376 12.7298 25.8733 12.6815 25.8251L10.1099 23.3178C9.17763 22.4177 8.84006 21.0675 9.25797 19.8299C9.65979 18.5923 10.7046 17.6923 12.0065 17.5154L15.5425 17.001C15.6068 16.9851 15.655 16.9529 15.6871 16.8886L17.2783 13.6741C17.857 12.5007 19.0303 11.7774 20.3322 11.7774C21.6341 11.7774 22.8074 12.5007 23.3861 13.6741L24.9773 16.8886C25.0093 16.9529 25.0576 16.9851 25.1219 17.001L28.6579 17.5154C29.9598 17.6923 31.0046 18.5923 31.4064 19.8299C31.8243 21.0675 31.4867 22.4177 30.5545 23.3178Z" fill={color}/>
<path d="M28.2064 20.6982L24.6543 20.1678C23.5452 20.0071 22.5809 19.3159 22.0826 18.3034L20.5074 15.1048C20.4754 15.0406 20.186 15.0406 20.1539 15.1048L18.5787 18.3034C18.0804 19.3159 17.1161 20.0071 16.0071 20.1678L12.4549 20.6982C12.4228 20.6982 12.3425 20.7143 12.3103 20.8268C12.2621 20.9393 12.3264 21.0036 12.3585 21.0196L14.9141 23.5271C15.7178 24.2985 16.0874 25.4237 15.8945 26.5327L15.2999 30.0686C15.2838 30.1008 15.2677 30.1812 15.3802 30.2615C15.4605 30.3259 15.541 30.2937 15.5731 30.2776L18.7395 28.6061C19.2377 28.3489 19.7841 28.2203 20.3307 28.2203C20.8772 28.2203 21.4236 28.3489 21.9219 28.6061L25.0883 30.2776C25.1203 30.2937 25.2008 30.3259 25.2811 30.2615C25.3936 30.1812 25.3776 30.1008 25.3615 30.0686L24.7668 26.5327C24.5739 25.4237 24.9436 24.2985 25.7472 23.511L28.3028 21.0196C28.3349 21.0036 28.3993 20.9393 28.351 20.8268C28.3188 20.7143 28.2385 20.6982 28.2064 20.6982Z" fill={color}/>
</svg>
  )
}

export default National


