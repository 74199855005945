import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  CandidateFlow: {
    myinfo: {
      fill: false,
      isvisible: false,
    },
    CandidateInfo: {
      fill: false,
      isvisible: false,
    },
    PoliticalAffiliation: {
      fill: false,
      isvisible: false,
    },
    CampaignInfo: {
      fill: false,
      isvisible: false,
    },
    CompanyInfo: {
      fill: false,
      isvisible: false,
    },
    BiographyStepOne: {
      fill: false,
      isvisible: false,
    },
    BiographyStepTwo: {
      fill: false,
      isvisible: false,
    },
    Education: {
      fill: false,
      isvisible: false,
    },
    Issues: {
      fill: false,
      isvisible: false,
    },
    ShowIssues: {
      fill: false,
      isvisible: false,
    },
    CreativeAssets: {
      fill: false,
      isvisible: false,
    },
    SocialMedia: {
      fill: false,
      isvisible: false,
    },
  },
  CandidateBio: {},
  Candidateissues: [],
};

export const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    // flow
    UpdateCandidateBio: (state, action: PayloadAction<string>) => {
      state.CandidateBio = action.payload;
    },
    UpdateCandidateissues: (state, action: PayloadAction<any[]>) => {
      state.Candidateissues = action.payload;
    },
    UpdateCandidateForm: (state, action: PayloadAction<string>) => {
      // Set all values to false initially
      Object.keys(state.CandidateFlow).forEach((key) => {
        // state.CommitteeFlow[key].fill = false;
        state.CandidateFlow[key].isvisible = false;
      });

      // Set the specified key values to true
      state.CandidateFlow[action.payload].fill = true;
      state.CandidateFlow[action.payload].isvisible = true;
    },
  },
});

export const {
  UpdateCandidateForm,
  UpdateCandidateBio,
  UpdateCandidateissues,
} = candidateSlice.actions;

export default candidateSlice.reducer;
