// import React, { useState } from 'react'
import RedWave from '../../../assets/images/RedWave.svg';
import Back from '../../../assets/images/Back.svg';

function Candidate() {

    return (
        <div className="flex h-auto">
            <div className="bg-[url('/src/assets/images/SidebarFlag.svg')] hidden text-white w-[443px] p-6 md:flex lg:flex flex-col justify-start items-center">
                <div className="flex items-center">
                    <img src={RedWave} alt="Logo" className="w-66 h-40 mt-24" />
                </div>

                <div className="flex flex-wrap justify-center items-center text-center mt-24 gap-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className="text-red-500">
                        <circle cx="15.5" cy="15.5" r="7.5" fill="#CB000E" />
                    </svg>
                    <p className="text-lg font-medium">MyIn</p>
                </div>
                <div className="flex items-center justify-between py gap-4 mt-96">
                    <span className='text-base font-normal'>Privacy Policy</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                        <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
                    </svg>
                    <span className='text-sm font-norma'>Terms of use</span>
                </div>
            </div>
            <div className="flex-grow px-24 pt-24">
                <p className="text-5xl font-bold mb-4">Welcome to <span className='text-red-600'>Red</span>Wave</p>
                <p className='text-xl font-normal text-[#585C7B]'>Please Provide The Details Below To Complete Your Profile</p>
                <form className='mt-14'>
                    <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="field1" className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                                Your First Name : <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">*</span>
                            </label>
                            <input
                                type="text"
                                id="field1"
                                name="field1"
                                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 sad text-[#8C8C8C] font-['Hind Guntur']"
                                placeholder='Enter your first name'
                            />
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="field2" className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                                Your Last Name : <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">*</span>
                            </label>
                            <input
                                type="text"
                                id="field2"
                                name="field2"
                                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 sad text-[#8C8C8C] font-['Hind Guntur']"
                                placeholder='Enter your last name'
                            />
                        </div>
                    </div>
                    <div className='py-2 md:py-4 lg:py-4'>
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="field2" className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                                Your functional role or job title : <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">*</span>
                            </label>
                            <input
                                type="text"
                                id="field2"
                                name="field2"
                                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 sad text-[#8C8C8C] font-['Hind Guntur']"
                                placeholder='Ex: Campaign Manager'
                            />
                        </div>
                    </div>
                    <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-4 md:py-7 lg:py-7">
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="field1" className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                                Your phone number : <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">*</span>
                            </label>
                            <input
                                type="text"
                                id="field1"
                                name="field1"
                                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 sad text-[#8C8C8C] font-['Hind Guntur']"
                                placeholder='Enter your phone number'
                            />
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="field2" className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                                Your email address : <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">*</span>
                            </label>
                            <input
                                type="text"
                                id="field2"
                                name="field2"
                                className="mt-2 w-[317px] h-[50px] bg-stone-100 rounded-[10px] p-4 sad text-[#8C8C8C] font-['Hind Guntur']"
                                placeholder='hollandmorgan@gmail.com'
                            />
                        </div>
                    </div>
                    <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-3 md:py-6 lg:py-6">
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="field1" className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
                                Do you want your information visible to others : <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">*</span>
                            </label>
                            <div className='flex flex-wrap flex-row gap-14 mt-4'>
                                <div className='flex flex-wrap gap-2'>
                                    <input type="radio" id="yes" name="yes" value="yes" />
                                    <label htmlFor="yes">Yes</label>
                                </div>

                                <div className='flex flex-wrap gap-2'>
                                    <input type="radio" id="no" name="no" value="no" />
                                    <label htmlFor="no">No</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>

                <div className='flex flex-nowrap py-6 justify-between gap-12'>
                    <div className='flex flex-nowrap gap-2 text-base font-medium cursor-pointer'>
                        <img src={Back} alt="Back" className='w-8 h-8 pt-2' />
                        <button>Back</button>
                    </div>
                    <div className='flex flex-wrap justify-between gap-8'>
                        <p className="text-zinc-800 text-[32px] font-bold font-['DM Sans'] leading-[37.18px]">My Info</p>
                        <button className="w-[183px] h-[42px] bg-red-700 rounded-[21px] text-white text-xl font-medium">Next</button>
                    </div>
                </div>
            </div >
        </div >



    )
}

export default Candidate;