import React, { useState } from "react";
import img from "../../assets/images/modalCardImg.png";
import imgTag from "../../assets/images/profileTag.png";
import heart from "../../assets/icons/heartIcon.png";
import shareProfile from "../../assets/icons/shareProfileIcon.png";
import stats from "../../assets/icons/statsIcon.png";
import contribution from "../../assets/icons/contributionIcon.png";
import activity from "../../assets/icons/activityIcon.png";
import view from "../../assets/icons/viewIcon.png";
import share from "../../assets/icons/shareProfileIcon.png";
import print from "../../assets/icons/printIcon.png";
import feature from "../../assets/icons/featureIcon.png";
import governor from "../../assets/icons/governorDarkIcon.png";
import republican from "../../assets/icons/republicanDarkIcon.png";
import northCarolina from "../../assets/icons/northDarkIcon.png";
import calendar from "../../assets/icons/calendarIcon.png";
import cake from "../../assets/icons/cakeIcon.png";
import pen from "../../assets/icons/penIcon.png";
import camera from "../../assets/icons/cameraIcon.png";
import speaker from "../../assets/icons/speakerDarkIcon.png";
import family from "../../assets/icons/familyIcon.png";
import education from "../../assets/icons/educationIcon.png";
import bag from "../../assets/icons/bagIcon.png";
import location from "../../assets/icons/locationDarkIcon.png";
import website from "../../assets/icons/websiteIcon.png";
import certificate from "../../assets/icons/certificateIcon.png";
import office from "../../assets/icons/officeDarkIcon.png";
import files from "../../assets/icons/filesIcon.png";
import book from "../../assets/icons/bookIcon.png";
import editProfile from "../../assets/icons/editProfileIcon.png";

import fire from "../../assets/icons/fireIcon.png";
import energy from "../../assets/icons/energyIcon.png";
import national_security from "../../assets/icons/securityIcon.png";
import immigration from "../../assets/icons/immigrationIcon.png";
import electorial from "../../assets/icons/electorialIcon.png";
import foreign_policy from "../../assets/icons/policyIcon.png";
import tax_reforms from "../../assets/icons/taxIcon.png";
import domestic_policy from "../../assets/icons/domesticPolicyIcon.png"
import healthcare from "../../assets/icons/healthcareIcon.png"
import economy from "../../assets/icons/economyIcon.png"
import criminal from "../../assets/icons/criminalIcon.png"
import conservation from "../../assets/icons/conservationIcon.png"
import science from "../../assets/icons/scienceIcon.png"
import housing from "../../assets/icons/housingIcon.png"
import transportation from "../../assets/icons/transportationIcon.png"

import issues from "../../assets/icons/issuesIcon.png";
import social from "../../assets/icons/socialIcon.png";
import instagram from "../../assets/icons/instaDarkIcon.png";
import telegram from "../../assets/icons/telegramDarkIcon.png";
import linkedin from "../../assets/icons/linkedinDarkIcon.png";
import facebook from "../../assets/icons/facebookDarkIcon.png";
import twitter from "../../assets/icons/twitterDarkIcon.png";
import youtube from "../../assets/icons/youtubeDarkIcon.png";
import scan from "../../assets/icons/scanIcon.png";
import contact from "../../assets/icons/contactDarkIcon.png";
import mail from "../../assets/icons/mailIcon.png";
import phone from "../../assets/icons/phoneIcon.png";
import profileTag from "../../assets/icons/profileTagIcon.png";
import profileTagSm from "../../assets/images/profileTagSm.png";
import tick from "../../assets/icons/tickDarkIcon.png";

import rightDropDown from "../../assets/icons/navbar/rightDropIcon.png";
import dropDown from "../../assets/icons/dropDownIcon.png";

import QRCode from "react-qr-code";
import ProgressBar from "@ramonak/react-progress-bar";
import { Line } from "rc-progress";
import Navbar from "../../components/DashboardNavbar/Navbar";
import Footer from "../../components/Footer";

import moment from "moment";

const iconMap: Record<string, string> = {
  energy,
  national_security,
  immigration,
  electorial,
  foreign_policy,
  tax_reforms,
  domestic_policy,
  healthcare,
  economy,
  criminal,
  conservation,
  science,
  housing,
  transportation,
  linkedin,
  facebook,
  youtube,
  instagram,
  telegram,
  
};

// Utility function to capitalize each word
const capitalizeWords = (str: string): string => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


const CandidateProfile = () => {

  const [isStats, setIsStats] = useState(false)
  const [isProfile, setIsProfile] = useState(false)

  const handleClickStats = () => {
    setIsStats(!isStats)
    setIsProfile(false)
  }

  const handleClickProfile = () => {
    setIsProfile(!isProfile)
    setIsStats(false)
  }

  const candidateObject = {
    slug: "754bb18a-e74c-410f-bba3-fcac12ea85fe",
    first_name: "Bilal",
    last_name: "Ahmed",
    email: "bilal@gmail.com",
    phone_number: "923448603334",
    address: "Lahore",
    dob: "2022-01-01T00:00:00.000Z",
    us_citizen: false,
    candidancy: false,
    political_affiliation: "Republican",
    level: "local",
    office: "US Senate",
    state: "alaska",
    district: "sitka",
    city: "juneau",
    incumbent: false,
    date_of_election: "2024-02-08T00:00:00.000Z",
    compaign_website: "https://www.google.com",
    registered_business: true,
    business_name: "Techify",
    business_type: "s-corp",
    business_tax_id: "1111111111",
    bio: "Bio",
    reason_for_running: "Reason",
    great_candidate: "Great candidate",
    family_life: "Family Life",
    current_occupation: "Software Engineer",
    still_active: true,
    past_occupation: [
      "one",
      "two",
      "three",
      "four",
      "five",
      "six"
    ],
    education: [
      {
        "degree": "BS software engineering",
        "school": "GC",
        "end_date": "2023-12-12T00:00:00.000Z"
      },
      {
        "degree": "FSC engineering",
        "school": "PGC",
        "end_date": "2023-12-12T00:00:00.000Z"
      },
      {
        "degree": "Matic Science",
        "school": "Allied",
        "end_date": "2023-12-12T00:00:00.000Z"
      }
    ],
    certifications: [
      "one",
      "two",
      "three",
      "four"
    ],
    issues: [
      "energy",
      "national_security",
      "transportation",
      "electorial",
      "foreign_policy",
      "criminal"
    ],
    issues_description: "description",
    profile_image: "https://redwave.s3.us-east-1.amazonaws.com/lb56l206ai/candidate/754bb18a-e74c-410f-bba3-fcac12ea85fe/profile_image/avatar6.png",
    logo_image: "https://redwave.s3.us-east-1.amazonaws.com/lb56l206ai/candidate/754bb18a-e74c-410f-bba3-fcac12ea85fe/logo_image/created.png",
    compaign_slogan: "Testing",
    video: "https://redwave.s3.us-east-1.amazonaws.com/lb56l206ai/candidate/754bb18a-e74c-410f-bba3-fcac12ea85fe/video/file_example_MP4_480_1_5MG.mp4",
    social_media_links: [
      {"name": "facebook", "username": "bilal"},
      {"name": "instagram", "username": "bilal"},
      {"name": "linkedin", "username": "bilal"}, 
      {"name": "other", "username": "https://www.w3schools.com/"}
  ],
    created_at: "2024-01-22T14:38:37.289Z",
    updated_at: "2024-01-22T14:40:00.197Z",
    completed: false,
    user_id: 2
  }

  return (
    <>
      <Navbar />
      <div className="flex">
        {/* left side */}
        <div className="hidden w-[33%] pl-12 pr-10 pt-10 lg:block md:block">
          <div className="">
            <img
              className=" bg-black object-cover w-[397px] h-[453px] rounded-t-[10px]"
              src={candidateObject.profile_image}
              alt=""
            />
            <img className="w-[397px] object-cover rounded-b-[10px]" src={imgTag} alt="" />
          </div>
          <div className="flex justify-between items-center mt-10">
            <div className="flex items-center gap-3">
              <img src={editProfile} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Edit Profile Info
              </div>
            </div>
            <div className="flex items-center gap-3">
              <img src={shareProfile} alt="" />
              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                Share Profile
              </div>
            </div>
          </div>
          <hr className="bg-stone-300 my-10" />
          <div>
            <div className="flex items-center gap-2">
              <img src={stats} alt="" />
              <div>
                <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                  RED
                </span>
                <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                  WAVE Statistics
                </span>
              </div>
            </div>
            <div>
              <div className="text-zinc-800 text-4xl font-semibold font-['Hind Guntur'] leading-[43.13px] py-5">
                {`${candidateObject.first_name} ${candidateObject.last_name}`}
              </div>
              <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                Republican Candidate For Governor
              </div>
              <div className="flex items-center gap-2 pb-5">
                <img src={contribution} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Contributions
                </div>
              </div>
              {/* bar code */}
              <div>
                <div>
                  {/* <ProgressBar
                  completed={3206450}
                  maxCompleted={10000000}
                  customLabel=""
                /> */}
                  <Line
                    percent={100}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                  //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $3,206,450
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Total
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={60}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                  //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $2,634,028
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      Year to Date
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <Line
                    percent={30}
                    strokeWidth={4}
                    strokeColor="#CB000E"
                    trailWidth={4}
                    trailColor="#CDCDCD"
                  //   className="bg-white rounded-[10px] border-zinc-800"
                  />

                  <div className="flex justify-between pt-2">
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      $267,230
                    </div>
                    <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                      December
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center py-5 gap-2">
                <img src={activity} alt="" />
                <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                  Profile Activity
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <img src={view} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    1,084
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={heart} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    264
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <img src={share} alt="" />
                  <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                    68
                  </div>
                </div>
              </div>
              <hr className="bg-stone-300 my-10" />
              <div>
                <div className="flex items-center gap-2 pb-5">
                  <img src={scan} alt="" />
                  <div>
                    <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                      Share{" "}
                    </span>
                    <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                      Profile
                    </span>
                  </div>
                </div>
                <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                  Share,Print or Copy Profile
                </div>
                {/* Qr Code */}
                <div className="w-[329px] h-[329px] bg-white rounded-[10px] border border-stone-300 p-5"
                  style={{ height: "auto", margin: "0 auto", maxWidth: "100%", width: "100%" }}
                >
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value="hello"
                    viewBox={`0 0 256 256`}
                  />
                </div>

                <div className="flex items-center justify-between py-10">
                  <div className="flex items-center gap-2">
                    <img src={share} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Share Profile
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={print} alt="" />
                    <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                      Print Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-l-2 border-stone-300 h-auto w-px my-10 hidden lg:block md:block"></div>
        {/* right side */}
        <div className="w-full mx-[20px] py-10 lg:w-[67%] lg:pl-12 lg:pr-24">
          <div className="flex justify-between gap-5">
            {/* left */}
            <div className="w-full lg:w-[58%] md:w-[58%]">

              {/* for mobile screen */}
              {/* flex items-center justify-between mb-10 gap-x-3 lg:hidden md:hidden */}
              <div className="flex items-center mb-10 gap-x-8 lg:hidden md:hidden">
                <div className="lg:hidden md:hidden">
                  {/* profileTagSm */}
                  <img className="w-[102px] h-[102px] rounded-[10px] border border-stone-300 bg-cover bg-center" src={candidateObject.logo_image} alt="" />
                </div>
                <div className="w-[55%]">
                  <div className=" text-zinc-800 text-2xl font-bold font-['DM Sans']">
                    {`${candidateObject.first_name} ${candidateObject.last_name}`}
                    {/* <span className="flex items-center text-red-700 text-base font-semibold font-['Hind Guntur']">
            {" "}
            <img src={feature} alt="" />
            Candidate
            </span> */}
                  </div>
                  <div className="flex justify-end gap-x-2 items-center text-red-700 text-base font-semibold font-['Hind Guntur'] py-2">
                    <img src={feature} alt="" />
                    Candidate
                  </div>
                  <div className="flex justify-between">
                    <div className="flex items-center gap-3" onClick={handleClickStats}>
                      <div className="text-slate-600 text-[15px] font-medium font-['Hind Guntur']">Statistics</div>
                      <img src={isStats ? dropDown : rightDropDown} alt="" />
                    </div>

                    <div className="flex items-center gap-3" onClick={handleClickProfile}>
                      <div className="text-slate-600 text-[15px] font-medium font-['Hind Guntur']">Share Profile</div>
                      <img src={isProfile ? dropDown : rightDropDown} alt="" />
                    </div>

                  </div>

                </div>
              </div>
              {
                isStats ?
                  <div className="mb-5">
                    <div className="flex items-center gap-2">
                      <img src={stats} alt="" />
                      <div>
                        <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                          RED
                        </span>
                        <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                          WAVE Statistics
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="text-zinc-800 text-3xl font-bold font-['Hind Guntur'] py-5">
                        {`${candidateObject.first_name} ${candidateObject.last_name}`}
                      </div>
                      <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                        Republican Candidate For Governor
                      </div>
                      <div className="flex items-center gap-2 pb-5">
                        <img src={contribution} alt="" />
                        <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                          Contributions
                        </div>
                      </div>
                      {/* bar code */}
                      <div>
                        <div>
                          {/* <ProgressBar
                  completed={3206450}
                  maxCompleted={10000000}
                  customLabel=""
                /> */}
                          <Line
                            percent={100}
                            strokeWidth={4}
                            strokeColor="#CB000E"
                            trailWidth={4}
                            trailColor="#CDCDCD"
                          //   className="bg-white rounded-[10px] border-zinc-800"
                          />

                          <div className="flex justify-between pt-2">
                            <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                              $3,206,450
                            </div>
                            <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                              Total
                            </div>
                          </div>
                        </div>
                        <div className="pt-5">
                          <Line
                            percent={60}
                            strokeWidth={4}
                            strokeColor="#CB000E"
                            trailWidth={4}
                            trailColor="#CDCDCD"
                          //   className="bg-white rounded-[10px] border-zinc-800"
                          />

                          <div className="flex justify-between pt-2">
                            <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                              $2,634,028
                            </div>
                            <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                              Year to Date
                            </div>
                          </div>
                        </div>
                        <div className="pt-5">
                          <Line
                            percent={30}
                            strokeWidth={4}
                            strokeColor="#CB000E"
                            trailWidth={4}
                            trailColor="#CDCDCD"
                          //   className="bg-white rounded-[10px] border-zinc-800"
                          />

                          <div className="flex justify-between pt-2">
                            <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                              $267,230
                            </div>
                            <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur'] leading-none">
                              December
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center py-5 gap-2">
                        <img src={activity} alt="" />
                        <div className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur']">
                          Profile Activity
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                          <img src={view} alt="" />
                          <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                            1,084
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <img src={heart} alt="" />
                          <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                            264
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <img src={share} alt="" />
                          <div className="text-zinc-800 text-lg font-normal font-['Hind Guntur']">
                            68
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : null
              }
              {
                isProfile ?
                  <div className="mb-5">
                    <div>
                      <div className="flex items-center gap-2 pb-5">
                        <img src={scan} alt="" />
                        <div>
                          <span className="text-zinc-800 text-[28px] font-semibold font-['Hind Guntur']">
                            Share{" "}
                          </span>
                          <span className="text-red-700 text-[28px] font-semibold font-['Hind Guntur']">
                            Profile
                          </span>
                        </div>
                      </div>
                      <div className="text-slate-600 text-xl font-normal font-['Hind Guntur'] leading-[30.06px] pb-5">
                        Share,Print or Copy Profile
                      </div>
                      {/* Qr Code */}
                      <div className="flex items-center gap-x-5">
                        <div className="w-[230px] h-auto bg-white rounded-[10px] border border-stone-300 p-5">
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value="hello"
                            viewBox={`0 0 256 256`}
                          />
                        </div>

                        <div className="flex flex-col items-center gap-y-5 py-10">
                          <div className="flex items-center gap-2">
                            <img src={share} alt="" />
                            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                              Share Profile
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <img src={print} alt="" />
                            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                              Print Profile
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : null
              }

              <div className="hidden text-zinc-800 text-[40px] font-bold font-['DM Sans'] lg:block md:block">
                {`${candidateObject.first_name} ${candidateObject.last_name}`}
              </div>
              <div className="hidden justify-end items-center lg:flex md:flex">
                <img src={feature} alt="" />
                <div className="text-red-700 text-xl font-semibold font-['Hind Guntur']">
                  Featured Candidate
                </div>
              </div>

{/* flex flex-wrap justify-between gap-4 lg:gap-8 md:gap-8 */}
              <div className="flex flex-wrap justify-normal gap-4">
                <div className="flex items-center  gap-5">
                  <img src={governor} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    Running For The {candidateObject.office} {candidateObject.incumbent ? "(Incumbent)" : ""}
                    {/* {candidateObject.office} */}
                  </div>
                </div>
                <div className="flex items-center gap-3 w-[50%] lg:w-[60%]">
                  <img src={republican} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    {candidateObject.political_affiliation} Party
                  </div>
                </div>
                <div className="flex items-center gap-3 ">
                  <img src={northCarolina} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    {candidateObject.state}
                  </div>
                </div>
                <div className="flex items-center gap-5 w-[50%] lg:w-[60%]">
                  <img src={calendar} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    {moment(candidateObject.date_of_election).format("MMM D, YYYY")}
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <img src={cake} alt="" />
                  <div className="text-slate-600 text-[15px] font-normal font-['Hind Guntur'] lg:text-lg md:text-lg">
                    {moment(candidateObject.dob).format("MMM D, YYYY")}
                  </div>
                </div>
              </div>
            </div>
            {/* right */}
            <div className="hidden w-[250px] h-auto bg-white rounded-[10px] border border-stone-300 lg:block md:block">
              {/* pt-14 pl-5 */}
              <img className="w-full h-full rounded-[10px] bg-cover bg-center" src={candidateObject.logo_image} alt="" />
            </div>
          </div>
          {/* for mobile screen user img */}
          <div className="mt-10 lg:hidden md:hidden">
            <div className="">
              <img
                className=" bg-black object-cover w-[397px] h-[453px] rounded-t-[10px]"
                src={candidateObject.profile_image}
                alt=""
              />
              <img className="w-full rounded-b-[10px]" src={imgTag} alt="" />
            </div>
            <div className="flex justify-between items-center mt-10">
              <div className="flex items-center gap-3">
                <img src={editProfile} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                  Edit Profile Info
                </div>
              </div>
              <div className="flex items-center gap-3">
                <img src={shareProfile} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-tight">
                  Share Profile
                </div>
              </div>
            </div>
          </div>
          <hr className=" my-10" />
          <div>
            <div className="flex items-center gap-2">
              <img src={pen} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Biography
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px] ">
              {candidateObject.bio}
            </div>
          </div>
          <hr className="my-8" />
          <div>
            <div className="flex items-center gap-2">
              <img src={camera} alt="" />
              <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Introductory Video
              </div>
            </div>
            {/* video */}
            <div className="pt-5">
              <video className="w-full rounded-[25px]" controls>
                <source src={candidateObject.video} type="video/mp4" />
              </video>
            </div>
          </div>
          <hr className="my-8" />
          <div>
            <div className="flex items-center gap-2">
              <img src={speaker} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Campaign Slogan
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 pb-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              {candidateObject.compaign_slogan}
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={family} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Family Life
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              {candidateObject.family_life}
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={education} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Education
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between py-5 lg:flex-nowrap md:flex-nowrap">
              {candidateObject.education.map((edu: any, index: number) => (
                <div key={index} className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
                  {edu.school}, {edu.degree}, {moment(edu.end_date).format("YYYY")}.
                </div>
              ))}

            </div>
          </div>

          <div className="flex flex-wrap gap-x-20 lg:gap-60">
            <div>
              <div className="flex items-center gap-2">
                <img src={bag} alt="" />
                <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                  Occupation
                </div>
              </div>
              <div className="flex py-5">
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
                  {candidateObject.current_occupation}
                </div>
                <div className="flex items-center gap-2 pl-5">
                  <img src={tick} alt="" />
                  <div className="text-slate-600 text-lg font-normal font-['Hind Guntur'] leading-[28.94px]">
                    {candidateObject.still_active === true ? "Active" : "Inactive"}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <img src={location} alt="" />
                <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                  Resides
                </div>
              </div>

              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-[28.94px] py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
                {candidateObject.address}
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={website} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Website
              </div>
            </div>
            <div className="py-5">
              <a
                className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                href={candidateObject.compaign_website}
              >
                {candidateObject.compaign_website}
              </a>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={certificate} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Certifications & Licenses
              </div>
            </div>

            {/* {candidateObject.certifications.map((certification: string, index: number) => (
             <div key={index} className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              {certification}
            </div> 
          ))} */}
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              {candidateObject.certifications.join(', ')}
            </div>


          </div>

          <hr className="my-8" />

          <div>
            <div className="flex items-center gap-2">
              <img src={office} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Why I Am Running For The Office
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              
              {candidateObject.reason_for_running}
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={files} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                What Makes Me A Great Candidate
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed py-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              {candidateObject.great_candidate}
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={book} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Past Held Positions
              </div>
            </div>
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              {candidateObject.past_occupation.join(', ')}

            </div>
          </div>

          <hr className="my-8" />
          <div>
            <div className="flex items-center gap-2 ">
              <img src={fire} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Top Issues
              </div>
            </div>
            <div className="flex flex-wrap gap-6 justify-between py-5 lg:gap-x-0 lg:gap-y-5">

              {/* <div className="flex flex-col items-center justify-center gap-5">
                <img src={energy} alt="" />
                <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                  Energy
                </div>
              </div> */}

              {candidateObject.issues.map((issue: any, index: number) => (
            <div key={index} className="relative w-[140px] flex flex-col items-center justify-center gap-5">
              
              <img src={iconMap[issue]} alt={issue.issue} />
              <div className="absolute top-0 left-0 z-10">
        <div className="w-[26px] h-[26px] text-white text-center bg-red-700 rounded-full">
          {index + 1}
        </div>
      </div>

              <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed lg:leading-[28.80px] lg:text-lg md:text-lg md:leading-[28.80px]">
                {capitalizeWords(issue)}
              </div>
            </div>
          ))}
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={issues} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Issues Explained
              </div>
            </div>
            
            <div className="text-slate-600 text-base font-normal font-['Hind Guntur'] leading-relaxed pt-5 lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]">
              {candidateObject.issues_description}
            </div>
          </div>

          <hr className="my-8" />

          <div>
            <div className="flex items-center gap-2 ">
              <img src={social} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Social Media
              </div>
            </div>
            <div className="flex gap-1 flex-wrap py-5 lg:gap-3 md:gap-3">

            {candidateObject.social_media_links.map((link: any, index: number) => (
            <div key={index} className="flex items-center">
              {/* Use the imported icon directly */}
              <img src={iconMap[link.name]} alt={link.name} />

              {/* Render link based on the name */}
              {link.name === 'other' ? (
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href={link.username}
                  target="_blank" // Open in a new tab
                  rel="noopener noreferrer" // Security best practice
                >
                  {link.username}
                </a>
              ) : (
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href={`https://${link.name}.com/${link.username}`}
                  target="_blank" // Open in a new tab
                  rel="noopener noreferrer" // Security best practice
                >
                  @{link.username}
                </a>
              )}
            </div>
          ))}

              {/* <div className="flex items-center">
                <img src={insta} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={telegram} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={linkedin} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={facebook} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={twitter} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  @HollandMorgan
                </a>
              </div>
              <div className="flex items-center">
                <img src={youtube} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  /HollandMorgan
                </a>
              </div> */}
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <img src={contact} alt="" />
              <div className="text-zinc-800 text-lg font-semibold font-['Hind Guntur'] lg:text-2xl md:text-2xl">
                Contact Us
              </div>
            </div>
            <div className="flex gap-6 flex-wrap py-5 lg:gap-14 md:gap-14">
              <div className="flex items-center gap-2">
                <img src={mail} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  {candidateObject.email}
                </a>
              </div>
              <div className="flex items-center gap-2">
                <img src={phone} alt="" />
                <a
                  className="text-red-700 text-base font-normal font-['Hind Guntur'] underline leading-relaxed lg:leading-[28.94px] lg:text-lg md:text-lg md:leading-[28.94px]"
                  href=""
                >
                  {candidateObject.phone_number}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CandidateProfile;


