import React, { useState } from "react";
import Navbar from "../../components/DashboardNavbar/Navbar";
import min from "../../assets/icons/minIcon.svg";
import max from "../../assets/icons/addIcon.svg";
import upArrow from "../../assets/icons/upArrowIcon.svg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const About = () => {
  // const [openIndex, setOpenIndex] = useState<boolean>(true);

  const [headingOne, setheadingOne] = useState<boolean>(true);
  const [headingTwo, setheadingTwo] = useState<boolean>(false);
  const [headingThree, setheadingThree] = useState<boolean>(false);
  const [headingFour, setheadingFour] = useState<boolean>(false);
  const [headingFive, setheadingFive] = useState<boolean>(false);

  const closeAllHeadingsExcept = (currentHeading: string) => {
    const headings = [
      "headingOne",
      "headingTwo",
      "headingThree",
      "headingFour",
      "headingFive",
    ];
    headings.forEach((heading) => {
      if (heading !== currentHeading) {
        // Close all headings except the current one
        eval(`set${heading}(false)`);
      }
    });
  };

  const handleClickOne = () => {
    setheadingOne(!headingOne);
    closeAllHeadingsExcept("headingOne");
  };

  const handleClickTwo = () => {
    setheadingTwo(!headingTwo);
    closeAllHeadingsExcept("headingTwo");
  };

  const handleClickThree = () => {
    setheadingThree(!headingThree);
    closeAllHeadingsExcept("headingThree");
  };

  const handleClickFour = () => {
    setheadingFour(!headingFour);
    closeAllHeadingsExcept("headingFour");
  };

  const handleClickFive = () => {
    setheadingFive(!headingFive);
    closeAllHeadingsExcept("headingFive");
  };

  // const handleToggle = () => {
  //   setOpenIndex(!openIndex);
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header zincBackground={true} />

      <div className="px-10 pb-12 mt-20 lg:px-24 md:px-24">
        {/* one */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-start flex-col gap-y-2 lg:items-center lg:flex-row lg:gap-5 md:flex-row md:items-center md:gap-5">
              <div>
                <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  About Red
                </span>
                <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Wave
                </span>
              </div>
              <div className="text-left text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:text-base lg:leading-tight  md:text-base md:leading-tight">
                The Best Campaign Management Tool
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer "
                src={headingOne ? min : max}
                alt="min icon"
                onClick={handleClickOne}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingOne ? (
            <div className="pt-1 transition-transform translate-y-px duration-1000 ease-in-out lg:pt-5 md:pt-5">
              <div>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Our Mission :
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  At RedWave, our mission is clear, to empower political
                  candidates and committees, helping them navigate the complex
                  world of campaign finance, engage with supporters, and
                  ultimately, make America the strongest and most free nation
                  possible.
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  <br />
                  Who We Are :
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  RedWave is your trusted partner, comprising a dedicated team
                  of political experts, technologists, payment specialists, and
                  entrepreneurs. We offer a comprehensive platform that enables
                  political campaigns and committees to promote their message,
                  track contributions, and cultivate ongoing support from
                  contributors and donors.
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {/* two */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-start flex-col gap-y-2 lg:items-center lg:flex-row lg:gap-5 md:flex-row md:items-center md:gap-5">
              <div className="text-center">
                <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  What{" "}
                </span>
                <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  We Do
                </span>
              </div>
              <div className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:text-base lg:leading-tight  md:text-base md:leading-tight">
                What Makes Us Different
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingTwo ? min : max}
                alt="min icon"
                onClick={handleClickTwo}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingTwo ? (
            <div className="pt-1 lg:pt-5 md:pt-5">
              <div>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Empowering Candidates:
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  Our platform is designed to empower candidates by providing
                  them with the tools and resources they need to gain exposure,
                  collect donations, and efficiently process campaign and
                  committee contributions.
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Empower Donors & Contributors:
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  Contributors come to RedWave to discover political profiles
                  and learn more about candidates, total contributions, and
                  track races at the national, state, and local levels.
                  <br />
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Efficient Fundraising:
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  RedWave provides a seamless donation collection widget that
                  can be effortlessly integrated into existing websites,
                  enabling candidates and committees to process and manage a
                  large volume of transactions efficiently.
                  <br />
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Transparent Finances:
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  With RedWave's secure payment gateway, finance teams can
                  seamlessly track, reconcile, and report monetary donations.
                  Campaigns can manage their financial transactions hassle-free,
                  ensuring transparency and accountability throughout the
                  donation process.
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {/* three */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-start flex-col gap-y-2 lg:items-center lg:flex-row lg:gap-5 md:flex-row md:items-center md:gap-5">
              <div className="text-center">
                <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Meet Our{" "}
                </span>
                <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Team
                </span>
              </div>
              <div className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:text-base lg:leading-tight  md:text-base md:leading-tight">
                Behind The Scenes
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingThree ? min : max}
                alt="min icon"
                onClick={handleClickThree}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingThree ? (
            <div className="pt-1 lg:pt-5 md:pt-5">
              <div>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  At the heart of RedWave is our diverse and dedicated team. We
                  bring together a unique blend of expertise and experience,
                  united by a common goal: to drive positive change in American
                  politics. Our team includes:
                  <br />
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Political Experts:
                </span>
                <span className="text-slate-600 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Our political experts have a deep understanding of the
                  political landscape and can provide valuable insights to help
                  campaigns thrive.
                  <br />
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Technologists:
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  Our tech-savvy team members are the backbone of our innovative
                  platform, ensuring it remains cutting-edge and user-friendly.
                  <br />
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Payment Specialists:
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  Finance is the lifeblood of any campaign, and our payment
                  specialists ensure every dollar is processed securely and
                  efficiently.
                  <br />
                  <br />
                </span>
                <span className="text-zinc-800 text-[15px] font-semibold font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  Entrepreneurs:
                </span>
                <span className="text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                  {" "}
                  Our entrepreneurial spirit drives us to continually seek new
                  and better ways to support candidates and committees.
                  <br />
                  <br />
                  With our combined efforts and expertise, we're here to help
                  you achieve your political goals.
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {/* four */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-start flex-col gap-y-2 lg:items-center lg:flex-row lg:gap-5 md:flex-row md:items-center md:gap-5">
              <div className="text-center">
                <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Why Red
                </span>
                <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Wave
                </span>
                <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  {" "}
                </span>
              </div>
              <div className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:text-base lg:leading-tight  md:text-base md:leading-tight">
                Trusted & Secure
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingFour ? min : max}
                alt="min icon"
                onClick={handleClickFour}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingFour ? (
            <div className="pt-1 lg:pt-5 md:pt-5">
              <div className=" text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed">
                RedWave's innovative platform redefines the way public donations
                are collected and tracked by political campaigns and elected
                officials. Our core principles are embodied in three simple
                words: Automated. Easy. Smart. We provide an all-encompassing
                solution for campaign management, making the journey to
                political office success smoother and more efficient.
              </div>
            </div>
          ) : null}
        </div>
        {/* five */}
        <div className="pt-10">
          <div className="flex justify-between items-center">
            <div className="flex items-start flex-col gap-y-2 lg:items-center lg:flex-row lg:gap-5 md:flex-row md:items-center md:gap-5">
              <div className="">
                <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Join Red
                </span>
                <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:leading-10 md:text-[32px] md:leading-10">
                  Wave
                </span>
                <span className="hidden text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] lg:text-[32px] lg:inline lg:leading-10 md:inline md:text-[32px] md:leading-10">
                  {" "}
                  and Revolutionize Your Campaign
                </span>
              </div>
              <div className=" text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-[18.50px] lg:text-base lg:leading-tight  md:text-base md:leading-tight">
                Sign Up Today
              </div>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={headingFive ? min : max}
                alt="min icon"
                onClick={handleClickFive}
              />
            </div>
          </div>
          <hr className="h-[2px] bg-stone-300 my-5" />
          {headingFive ? (
            <div className="pt-1 lg:pt-5 md:pt-5">
              <div className=" text-slate-600 text-[15px] font-medium font-['Hind Guntur'] leading-normal lg:text-base lg:leading-relaxed md:text-base md:leading-relaxed ">
                At RedWave, we're more than a company; we're a partner in your
                journey to political success. Join us and experience a new era
                of campaign management, where efficiency and transparency are at
                the forefront of your success.
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center gap-5 pl-10 py-10  lg:pl-24 lg:py-20 md:pl-24 md:py-20">
        <div>
          <img
            className="cursor-pointer"
            src={upArrow}
            alt=""
            onClick={scrollToTop}
          />
        </div>
        <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
          Back To Top
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default About;
