import React from 'react'

const Hand = ({color}) => {
  return (
    <svg width="145" height="112" viewBox="0 0 145 112" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M74.409 56.3682C66.0879 51.5676 53.4462 47.727 42.5648 51.5676C36.1639 53.8079 30.8832 58.2884 26.8827 65.1694C26.7227 65.3294 26.7227 65.4894 26.7227 65.8095V96.0535C26.7227 96.8536 27.6828 97.4937 28.4829 96.8536C35.6838 91.2528 46.8853 87.7324 57.7668 90.9328C67.3681 93.8132 75.3691 95.8934 85.2904 96.6935C100.492 97.9737 109.774 91.2528 119.695 84.2119C128.816 77.651 136.177 69.1699 143.538 60.5287C144.658 59.2486 144.338 57.1683 142.898 56.2082L142.258 55.7281C138.898 53.3278 134.417 53.3278 131.057 55.5681L122.735 61.0088C115.694 65.8094 107.213 68.0497 99.0523 67.5697C98.5722 67.5697 98.0921 68.0497 98.2522 68.5298C98.4122 69.97 98.2522 71.5702 97.6121 73.0104C96.3319 76.3709 93.4515 78.4511 90.2511 79.0912C88.6509 79.4113 86.8906 79.2512 85.2904 78.6111L68.1682 72.2103C67.208 71.8903 66.728 70.9301 66.5679 69.97C66.5679 69.65 66.568 69.3299 66.728 69.1699C67.208 67.8897 68.4882 67.2496 69.7684 67.7297L86.8906 74.1306C89.451 75.0907 92.1713 73.8105 93.1315 71.2502C93.7716 69.65 93.6115 67.8897 92.6514 66.4495C92.1713 65.6494 91.3712 65.0093 90.4111 64.6893C85.9305 62.9291 80.6498 59.7286 76.0092 57.1683L74.409 56.3682Z" fill={color}/>
<path d="M1.60021 111.255H19.8426C20.8028 111.255 21.4428 110.455 21.4428 109.655V61.6483C21.4428 60.6882 20.6427 60.0481 19.8426 60.0481H1.60021C0.640085 60.0481 0 60.8482 0 61.6483V109.655C0 110.615 0.800106 111.255 1.60021 111.255Z" fill={color}/>
<path d="M118.839 8.60707C118.094 6.92276 117.02 5.39646 115.677 4.11361C114.333 2.82693 112.749 1.80443 111.01 1.10169C109.207 0.370114 107.273 -0.00435119 105.32 3.81448e-05C102.581 3.81448e-05 99.9088 0.732666 97.5863 2.11652C97.0306 2.44756 96.5028 2.81116 96.0027 3.20732C95.5027 2.81116 94.9749 2.44756 94.4192 2.11652C92.0967 0.732666 89.4242 3.81448e-05 86.685 3.81448e-05C84.7126 3.81448e-05 82.8012 0.369066 80.9955 1.10169C79.2508 1.80719 77.6784 2.82201 76.3283 4.11361C74.9837 5.39502 73.9095 6.92168 73.1668 8.60707C72.3945 10.3599 72 12.2214 72 14.1371C72 15.9442 72.3778 17.8273 73.1279 19.743C73.7558 21.3439 74.6559 23.0046 75.806 24.6815C77.6284 27.3352 80.1343 30.1029 83.2457 32.9086C88.4019 37.5595 93.508 40.7722 93.7247 40.9024L95.0415 41.7273C95.6249 42.0909 96.375 42.0909 96.9584 41.7273L98.2752 40.9024C98.4919 40.7667 103.593 37.5595 108.754 32.9086C111.866 30.1029 114.372 27.3352 116.194 24.6815C117.344 23.0046 118.25 21.3439 118.872 19.743C119.622 17.8273 120 15.9442 120 14.1371C120.006 12.2214 119.611 10.3599 118.839 8.60707Z" fill={color === "#FFFFFF" ? "#FFFFFF" : "#CB000E" }/>
</svg>
  )
}

export default Hand


