export default function IconDollar({
  className='w-[1.31rem] w-[1.31rem]',
}) {
  return (
    <svg
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5C15 11.6423 11.6423 15 7.5 15C3.35775 15 0 11.6423 0 7.5C0 3.35775 3.35775 0 7.5 0C11.6423 0 15 3.35775 15 7.5ZM7.5 2.4375C7.64918 2.4375 7.79226 2.49676 7.89775 2.60225C8.00324 2.70774 8.0625 2.85082 8.0625 3V3.23775C9.285 3.45675 10.3125 4.3755 10.3125 5.625C10.3125 5.77418 10.2532 5.91726 10.1477 6.02275C10.0423 6.12824 9.89918 6.1875 9.75 6.1875C9.60082 6.1875 9.45774 6.12824 9.35225 6.02275C9.24676 5.91726 9.1875 5.77418 9.1875 5.625C9.1875 5.1165 8.7645 4.57725 8.0625 4.38525V6.98775C9.285 7.20675 10.3125 8.1255 10.3125 9.375C10.3125 10.6245 9.285 11.5433 8.0625 11.7623V12C8.0625 12.1492 8.00324 12.2923 7.89775 12.3977C7.79226 12.5032 7.64918 12.5625 7.5 12.5625C7.35082 12.5625 7.20774 12.5032 7.10225 12.3977C6.99676 12.2923 6.9375 12.1492 6.9375 12V11.7623C5.715 11.5433 4.6875 10.6245 4.6875 9.375C4.6875 9.22582 4.74676 9.08274 4.85225 8.97725C4.95774 8.87176 5.10082 8.8125 5.25 8.8125C5.39918 8.8125 5.54226 8.87176 5.64775 8.97725C5.75324 9.08274 5.8125 9.22582 5.8125 9.375C5.8125 9.8835 6.2355 10.4228 6.9375 10.614V8.01225C5.715 7.79325 4.6875 6.8745 4.6875 5.625C4.6875 4.3755 5.715 3.45675 6.9375 3.23775V3C6.9375 2.85082 6.99676 2.70774 7.10225 2.60225C7.20774 2.49676 7.35082 2.4375 7.5 2.4375Z" fill="white"/>
    </svg>
  )
}
