import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../../../assets/images/Back.svg";
import Plus from "../../../../../assets/icons/Plus.svg";
import FormFlagMobile from "../../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../../assets/images/RedDot.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  UpdateCandidateBio,
  UpdateCandidateForm,
} from "../../../../../redux/candidate/candidateSlice";

type FormValues = {
  id: string | number;
  bio: string;
  reason_for_running: string;
  great_candidate: string;
  family_life: string;
  current_occupation: string;
  still_active: string;
  past_occupation: string;
};

function BiographyInfo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authinfo = useAppSelector((state: any) => state.auth.userData);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const submitForm: SubmitHandler<FormValues> = async (data) => {
    const BioData: any = {
      bio: data.bio,
      reason_for_running: data.reason_for_running,
      great_candidate: data.great_candidate,
    };

    dispatch(UpdateCandidateBio(BioData));
    dispatch(UpdateCandidateForm("BiographyStepTwo"));
  };

  useEffect(() => {
    if (authinfo) {
      const { bio, great_candidate, reason_for_running } = authinfo;
      if (bio) {
        setValue("bio", bio);
      }
      if (great_candidate) {
        setValue("great_candidate", great_candidate);
      }
      if (reason_for_running) {
        setValue("reason_for_running", reason_for_running);
      }
    }
  }, [authinfo, setValue]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Biography Info</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Bio :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <textarea
                {...register("bio", {
                  required: "bio should not be empty",
                  maxLength: {
                    value: 200,
                    message: "bio not exceed 200 characters",
                  },
                })}
                className="mt-2 p-2 w-[320px] h-[110px] md:w-[533px] md:h-[110px] lg:w-[733px] lg:h-[110px] xl:w-[733px] xl:h-[110px] bg-stone-50 rounded-[10px] resize-none"
                placeholder="Write few sentences about yourself"
              />
              {errors.bio && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.bio.message}
                </span>
              )}
              <div className="flex flex-wrap justify-start text-slate-600">
                <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">
                  Max 200 Characters
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row mt-8">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Why I am running for the office :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <textarea
                {...register("reason_for_running", {
                  required: "reason for running should not be empty",
                  maxLength: {
                    value: 200,
                    message: "reason for running not exceed 200 characters",
                  },
                })}
                className="mt-2 p-2 w-[320px] h-[110px] md:w-[533px] md:h-[110px] lg:w-[733px] lg:h-[110px] xl:w-[733px] xl:h-[110px] bg-stone-50 rounded-[10px] resize-none"
                placeholder="Write about why you are running for the office"
              />
              {errors.reason_for_running && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.reason_for_running.message}
                </span>
              )}
              <div className="flex flex-wrap justify-start text-slate-600">
                <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">
                  Max 200 Characters
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row mt-8">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field2"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                What makes you a great candidate for public office :{" "}
                <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                  *
                </span>
              </label>
              <textarea
                {...register("great_candidate", {
                  required: "great candidate should not be empty",
                  maxLength: {
                    value: 200,
                    message: "great candidate not exceed 200 characters",
                  },
                })}
                className="mt-2 p-2 w-[320px] h-[110px] md:w-[533px] md:h-[110px] lg:w-[733px] lg:h-[110px] xl:w-[733px] xl:h-[110px] bg-stone-50 rounded-[10px] resize-none"
                placeholder="Write about what makes you a great candidate"
              />
              {errors.great_candidate && (
                <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                  {errors.great_candidate.message}
                </span>
              )}
              <div className="flex flex-wrap justify-start text-slate-600">
                <p className="text-neutral-400 text-sm font-normal font-['Hind Guntur']">
                  Max 200 Characters
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("CompanyInfo"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button
                className="hidden sm:inline-block"
                type="button"
                // onClick={() => navigate("/biography")}
              >
                Back
              </button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Biography Info
              </p>
              <button className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default BiographyInfo;
