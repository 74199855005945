import React, { useState, ChangeEvent, useEffect } from "react";
import RedWave from "../../../../assets/images/RedWave.svg";
import Back from "../../../../assets/images/Back.svg";
import Plus from "../../../../assets/icons/Plus.svg";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
import DiscordIconSocial from "../../../../assets/icons/DiscordIconSocial.svg";
import TelegramIconSocial from "../../../../assets/icons/telegramIconSocial.svg";
import FacebookIconSocial from "../../../../assets/icons/facebookIconSocial.svg";
import LinkedInIconSocial from "../../../../assets/icons/linkedInIconSocial.svg";
import XLinkIconSocial from "../../../../assets/icons/xLinkIconSocial.svg";
import RumbleIconSocial from "../../../../assets/icons/rumbleIconSocial.svg";
import InstagramIconSocial from "../../../../assets/icons/instagramIconSocial.svg";
import YoutubeIconSocial from "../../../../assets/icons/youtubeIconSocial.svg";
import WhatsapIconSocial from "../../../../assets/icons/whatsapIconSocial.svg";

import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../redux/candidate/candidateSlice";
import { useNavigate } from "react-router";
import { API_BASE_URL } from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../axios/index";
import { setAuthData } from "../../../../redux/auth/authSlice";

type FormValues = {
  id: string | number;
  social_media_links: Array<{ name: string; icon: any; username: string }>;
};
interface SocialMediaField {
  socialMedia: string;
}

function SocialMedia() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);

  const [showSocialMediaFields, setShowSocialMediaFields] = useState<
    SocialMediaField[]
  >([{ socialMedia: "" }]);

  const handleSocialMediaFields = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowSocialMediaFields([...showSocialMediaFields, { socialMedia: "" }]);
    // console.log("das");
  };

  const { control, register, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      social_media_links: [
        { name: "Discord", icon: DiscordIconSocial, username: "" },
        { name: "Telegram", icon: TelegramIconSocial, username: "" },
        { name: "Facebook", icon: FacebookIconSocial, username: "" },
        { name: "Linked", icon: LinkedInIconSocial, username: "" },
        { name: "X", icon: XLinkIconSocial, username: "" },
        { name: "Rumble", icon: RumbleIconSocial, username: "" },
        { name: "Instagram", icon: InstagramIconSocial, username: "" },
        { name: "YouTube", icon: YoutubeIconSocial, username: "" },
        { name: "WhatsApp", icon: WhatsapIconSocial, username: "" },
        { name: "Custom", username: "" },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "social_media_links",
  });

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    try {
      setloading(true);
      const res = await httpRequest.patch(
        `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/social_media`,
        data
      );

      if (res?.status === 200 || res?.status === 201) {
        const updatedAuthInfo = {
          ...authinfo,
          ...res?.data.candidate,
        };
        dispatch(UpdateCandidateForm("SocialMedia"));
        dispatch(setAuthData(updatedAuthInfo));
        // toast.success("added successfully!");
        navigate("/dashboard");
      } else {
        toast.error(res?.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toast.error(
        (error.response?.data?.message as string) || "Something went wrong"
      );
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (authinfo) {
      const { social_media_links } = authinfo;
      if (social_media_links) {
        social_media_links.forEach(
          (link: { username: string }, index: number) => {
            setValue(`social_media_links.${index}.username`, link.username);
          }
        );
      }
    }
  }, [authinfo, setValue]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          {/* Add any content you want to display on top of the background image */}
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Social Media</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-16 xl:px-16 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>

        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div>
            <p className="text-slate-600 text-xl font-normal font-['Hind Guntur']">
              Add your social media :{" "}
            </p>
          </div>
          <div className="flex flex-wrap flex-row gap-8 mt-6">
            {/* Use map to render input fields for each social media platform */}
            {fields.map((field, index) => (
              <div key={index} className="flex flex-col gap-1">
                <div className="relative">
                  <img
                    src={field.icon}
                    alt="Logo"
                    className="absolute left-0.8 top-1/2 transform -translate-y-1/2 w-[50px] h-[50px] pl-0"
                  />
                  <input
                    type="text"
                    {...register(
                      `social_media_links.${index}.username` as const
                    )}
                    className="pl-16 pr-2 w-[235px] h-[50px] bg-stone-50 rounded-[25px]"
                    placeholder={`${field.name} Link`}
                  />
                </div>
                {/* {
                                    errors.certifications?.[index] && (
                                        <span className='mt-2 flex flex-row justify-start text-start text-red-600'>
                                            {errors.certifications[index]?.message}
                                        </span>
                                    )
                                } */}
              </div>
            ))}
          </div>
          <div className="flex flex-wrap flex-row gap-8 mt-6">
            {showSocialMediaFields.map((field, index) => (
              <div className="flex flex-row gap-2" key={index}>
                <input
                  type="text"
                  {...register(`social_media_links.${index}.username` as const)}
                  className="mt-2 p-2 w-[235px] h-[50px] bg-stone-50 rounded-[25px]"
                  placeholder="Custom Link"
                />
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-8 mt-6">
            <div className="flex flex-nowrap gap-2 text-base font-medium cursor-pointer">
              <img src={Plus} alt="Plus" className="w-8 h-8" />
              <button
                className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur']"
                onClick={handleSocialMediaFields}
              >
                Add a custom social media
              </button>
            </div>
          </div>

          <div className="flex flex-row gap-4 mt-44">
            <input
              type="checkbox"
              name="checkbox"
              id="checkbox"
              className="mt-1 w-[21px] h-[21px] bg-zinc-300 rounded-[3px]"
            />
            <p className="text-neutral-400 text-lg font-normal font-['Hind Guntur']">
              By cliciking this I agree to
              <span className="text-red-700 text-lg font-normal font-['Hind Guntur']">
                {" "}
                Terms & Conditions{" "}
              </span>
              and the{" "}
              <span className="text-red-700 text-lg font-normal font-['Hind Guntur']">
                Privacy Policy
              </span>{" "}
              of the Red Wave
            </p>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("CreativeAssets"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button
                className="hidden sm:inline-block"
                // onClick={() => navigate("/creative-assets")}
              >
                Back
              </button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Social Media
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                disabled={loading}
                type="submit"
              >
                {loading ? "loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default SocialMedia;
