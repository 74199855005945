import React, { useState, useRef, useEffect } from "react";
import notification from "../../assets/icons/notificationIcon.png";
import dropDown from "../../assets/icons/dropDownIcon.svg";
import dashboard from "../../assets/icons/navbar/dashboardIcon.png";
import transaction from "../../assets/icons/navbar/transactionIcon.png";
import reporting from "../../assets/icons/navbar/reportingIcon.png";
import reportBuilder from "../../assets/icons/navbar/reportBuilderIcon.png";
import reportRecord from "../../assets/icons/navbar/reportRecordIcon.png";
import right from "../../assets/icons/navbar/rightDropIcon.png";
import rightDropDown from "../../assets/icons/rightDropIcon.svg"
import add from "../../assets/icons/addIcon.png";
import searchIcon from "../../assets/icons/navbar/searchIcon.png";
import rightArrow from "../../assets/icons/rightArrowIcon.png";
import DashboardNavbarItem from "./DashboardNavbarItem";
import donationBuilder from "../../assets/icons/navbar/donationBuilderIcon.png";
import widget from "../../assets/icons/navbar/widgetIcon.png";
import management from "../../assets/icons/navbar/userManageIcon.png";
import heart from "../../assets/icons/navbar/heartDarkIcon.png";
import profile from "../../assets/icons/navbar/profileIcon.png";
import setting from "../../assets/icons/navbar/settingIcon.png";
import payment from "../../assets/icons/navbar/paymentIcon.png";
import notificationIcon from "../../assets/icons/navbar/notificationIcon.png";
import signOut from "../../assets/icons/navbar/signoutIcon.png";
import menuIcon from "../../assets/icons/menuIcon.svg";
import notificationSm from "../../assets/icons/notificationSmIcon.svg";
import leftArrow from "../../assets/icons/leftArrowIcon.svg";
import {
  DashboardNavbarData,
  NavbarItem as DashboardNavbarType,
} from "./DashboardNavbarData";
import { Link, useNavigate,  } from "react-router-dom";

const dummyUserProfiles: UserProfile[] = [
  { id: 1, username: "john_doe", fullName: "John Doe" },
  { id: 2, username: "jane_smith", fullName: "Jane Smith" },
  { id: 3, username: "bob_jackson", fullName: "Bob Jackson" },
  { id: 4, username: "bob_smith", fullName: "Bob Smith" },
];

// ...

interface UserProfile {
  id: number;
  username: string;
  fullName: string;
}

interface Notification {
  id: number,
  alert: string,
  notification: string,
  status: "read" | "unread"

}

const dummyNotifications: Notification[] = [
  { id: 1, alert: "$5k Donation Alert", notification: "Holland donated $500 to your campaign",status:"unread" },
  { id: 2, alert: "$1.5k Donation Alert", notification: "Holland donated $500 to your campaign",status:"unread" },
  { id: 3, alert: "$2.5k Donation Alert", notification: "Holland donated $500 to your campaign",status:"unread" },
  { id: 4, alert: "$10k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 5, alert: "$5k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 6, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 7, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 8, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 9, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 10, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 11, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 12, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 13, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 14, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 15, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 16, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 17, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 18, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 19, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 20, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },
  { id: 21, alert: "$8k Donation Alert", notification: "Holland donated $500 to your campaign",status:"read" },

];

// code for right sidebar (handling multiple user profiles)
interface UserRightSideProfile {
  id: number;
  name: string;
  role: string;
  imageUrl: string;
  committees : {id: number;
  name: string;}[]
}

// above interface profile data

const profiles: UserRightSideProfile = {
  
    id: 1,
    name: 'Holland Morgan',
    role: 'Candidate Account',
    imageUrl: 'https://via.placeholder.com/47x47',
    committees:[
    {
      id: 2,
    name: 'committee one',
    },
    {
      id: 2,
    name: 'committee two',
    },
    {
      id: 2,
    name: 'committee three',
    },
    {
      id: 2,
    name: 'committee four',
    },
    {
      id: 2,
    name: 'committee five',
    },
  ]
}

// custom checkbox for profiles
interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange }) => {
  return (
    <div
    className={`w-4 h-4 rounded-full border-2 border-red-700 cursor-pointer transition-colors ${
      checked ? 'bg-red-700 border-red-700' : ''
    }`}
    onClick={onChange}
    ></div>
  );
};

const Navbar = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showUserDropDown, setShowUserDropDown] = useState(false);
  const [showUserSubmenu, setShowUserSubmenu] = useState(false);
  const [currentUsername, setCurrentUsername] = useState("");
  // for mobile left menu
  const [isOpenLeft, setIsOpenLeft] = useState(false);
  const [isReporting, setIsReporting] = useState(false)
  const [isDashboard, setIsDashboard] = useState(true)
  // for mobile notifications
const [isNotifications,setIsNotifications] = useState(false)

  // for mobile right menu
  const [isOpenRight, setIsOpenRight] = useState(false)
  const [isViewMoreProfiles, setIsViewMoreProfiles] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState<UserRightSideProfile>(profiles);
  const [selectedCommittee, setSelectedCommittee] = useState<number | null>(null);

  const navigate = useNavigate();

  // for mobile left menu
  const toggleMenuLeft = () => {
    setIsOpenLeft(!isOpenLeft);
  };
  const handleReporting = () => {
    setIsReporting(!isReporting)
  }
  const handleDashboard = () => {
    setIsDashboard(!isDashboard)
  }

  // for mobile notification
  const toggleNotifications= () => {
    setIsNotifications(!isNotifications)
  }

  // for mobile right menu
  const toggleMenuRight = () => {
    setIsOpenRight(!isOpenRight);
  };
  const handleViewMoreProfiles = () => {
    setIsViewMoreProfiles(!isViewMoreProfiles)
  }

  // const handleProfileClick = (profile: UserRightSideProfile) => {
  //   setSelectedProfile(profile);
  //   // Navigate to the profile's route using React Router
  //   navigate(`/profile/${profile.id}`)
  // };

  const handleProfileClick = (profile: UserRightSideProfile) => {
    setSelectedProfile(profile);
    // Clear selected committee when a new profile is selected
    setSelectedCommittee(null);
  };

  const handleCommitteeClick = (committee: any) => {
    // Handle the click on a specific committee
    setSelectedCommittee(committee);
    setSelectedProfile(committee)
  };


  // 
  const [userProfiles, setUserProfiles] =
    useState<UserProfile[]>(dummyUserProfiles);

  const handleClick = () => {
    setShowDropdown(false);
    setShowSubmenu(false);
    setShowUserDropDown(false);
    setShowUserSubmenu(false);
  };

  // document.addEventListener("click", handleClick);

  const handleItemClick = (item: any) => {
    setActiveItem(item);
    setShowDropdown(false);
    setShowSubmenu(false);
    setShowUserDropDown(false);
    setShowUserSubmenu(false);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSubmenuToggle = () => {
    setShowSubmenu(!showSubmenu);
  };

  const handleUserDropDownToggle = () => {
    setShowUserDropDown(!showUserDropDown);
  };

  const handleUserSubmenuToggle = () => {
    setShowUserSubmenu(!showUserSubmenu);
  };

  return (
    <nav className=" h-[90px] bg-white py-2 flex justify-between items-center shadow px-8 md:h-[115px] md:px-12">
      {/* for large screens navbar */}
      {/* Left side */}
      <div className="hidden items-center gap-5 w-[35%] md:flex">
        <div>
          <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] md:text-4xl">
            Red
          </span>
          <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] md:text-4xl">
            Wave
          </span>
        </div>

        <div className="hidden items-center justify-between gap-5 md:flex">
          <div className="relative group ">
            <div className="flex items-center gap-3">
              <div
                className={`text-zinc-800 text-sm font-medium font-['DM Sans'] cursor-pointer ${
                  showDropdown ? "text-zinc-800" : "text-zinc-800"
                }`}
                onClick={handleDropdownToggle}
              >
                Dashboard
              </div>
              <img src={dropDown} alt="" />
            </div>

            {showDropdown && (
              <div className="absolute w-[238px] h-[330px] bg-white rounded-bl-[10px] rounded-br-[10px] p-5 shadow-2xl z-10">
                <div className="flex items-center gap-5 py-2">
                  <div>
                    <img src={dashboard} alt="" />
                  </div>
                  <Link
                    to="/dashboard"
                    className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                    onClick={() => handleItemClick("option1")}
                  >
                    Dashboard
                  </Link>
                </div>

                <Link
                  to="/transactions-history"
                  className="flex items-center gap-5 py-3"
                >
                  <div>
                    <img src={transaction} alt="" />
                  </div>
                  <div
                    className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                    onClick={() => handleItemClick("option2")}
                  >
                    Transactions
                  </div>
                </Link>

                <div className="flex items-center justify-between py-3">
                  <div className="flex items-center gap-5 ">
                    <div>
                      <img src={reporting} alt="" />
                    </div>
                    <div
                      className="text-slate-600 text-sm font-medium font-['Hind Guntur']  cursor-pointer group"
                      onClick={() => handleSubmenuToggle()}
                    >
                      Reporting
                      {showSubmenu && (
                        <div className="absolute left-full ml-1 w-[238px] h-28 bg-white rounded-bl-[10px] rounded-br-[10px] p-5 shadow-2xl">
                          <Link
                            to="report-builder"
                            className="flex items-center gap-5 py-2"
                          >
                            <div>
                              <img src={reportBuilder} alt="" />
                            </div>
                            <div
                              className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                              onClick={() => handleItemClick("submenu1")}
                            >
                              Report Builder
                            </div>
                          </Link>

                          <Link
                            to="/report-records"
                            className="flex items-center gap-5 py-2"
                          >
                            <div>
                              <img src={reportRecord} alt="" />
                            </div>
                            <div
                              className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                              onClick={() => handleItemClick("submenu2")}
                            >
                              Report Records
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <img src={right} alt="right drop down" />
                  </div>
                </div>

                <Link
                  to="/dnations-builder"
                  className="flex items-center gap-5 py-3"
                >
                  <div>
                    <img src={donationBuilder} alt="" />
                  </div>
                  <div
                    className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                    onClick={() => handleItemClick("option2")}
                  >
                    Donations Builder
                  </div>
                </Link>

                <Link
                  to="/manage-widgets"
                  className="flex items-center gap-5 py-3"
                >
                  <div>
                    <img src={widget} alt="" />
                  </div>
                  <div
                    className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                    onClick={() => handleItemClick("option2")}
                  >
                    Manage Widgets
                  </div>
                </Link>

                <Link
                  to="/favourite-profiles"
                  className="flex items-center gap-5 py-3"
                >
                  <div>
                    <img src={heart} alt="" />
                  </div>
                  <div
                    className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                    onClick={() => handleItemClick("option2")}
                  >
                    Favourite Profiles
                  </div>
                </Link>

                <Link
                  to="/user-management"
                  className="flex items-center gap-5 py-3"
                >
                  <div>
                    <img src={management} alt="" />
                  </div>
                  <div
                    className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                    onClick={() => handleItemClick("option2")}
                  >
                    User Management
                  </div>
                </Link>
              </div>
            )}
          </div>
          <Link
            to="/about"
            className="text-zinc-800 text-sm font-medium font-['DM Sans']"
          >
            About
          </Link>
          <Link
            to="/contact-us"
            className="text-zinc-800 text-sm font-medium font-['DM Sans']"
          >
            Contact Us
          </Link>
        </div>
      </div>

      {/* Center */}
      <div className="w-[30%] hidden justify-center md:flex">
        <div className="flex items-center p-1 bg-stone-500 bg-opacity-5 rounded-[20.50px]">
          <img className="h-[18px] w-[18px]" src={searchIcon} alt="" />
          <input
            className="<w-[268px] h-[35px] bg-white bg-opacity-0 rounded-[17.50px]  text-black outline-none pl-2"
            type="text"
            placeholder="Search For Anything"
          />
          <img src={rightArrow} alt="" />
        </div>
      </div>

      {/* Right side */}
      <div className=" hidden items-center w-[35%] justify-end gap-5 md:flex">
        <div>
          <img src={notification} alt="" />
        </div>

        <div className="relative group">
          <div
            className=" bg-white rounded-[30px] justify-end flex items-center cursor-pointer md: w-[238px] md:h-[62px]"
            onClick={handleUserDropDownToggle}
          >
            <img
              className="w-[47px] h-[47px] rounded-[64.50px]"
              src="https://via.placeholder.com/47x47"
              alt="user"
            />
            <div className="border-l-2 hidden border-stone-300 h-full w-[2px] mx-5 md:block"></div>
            <div className=" hidden items-center gap-3 md:flex">
              <div>
                <div className="text-zinc-800 text-sm font-medium font-['DM Sans']">
                  Holland Morgan
                </div>
                <div className="text-slate-600 text-sm font-normal font-['Hind Guntur']">
                  Candidate Account
                </div>
              </div>
              <img src={dropDown} alt="" />
            </div>
          </div>

          {showUserDropDown && (
            <div
              // ref={userDropDownRef}
              className="hidden absolute w-[238px] h-[522px]  bg-white rounded-bl-[10px] rounded-br-[10px] p-5 shadow-2xl z-10 md:inline-block"
            >
              <div className="flex items-center gap-5 py-3">
                <img src={dashboard} alt="" />
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu1")}
                >
                  Dashboard
                </div>
              </div>

              <hr className="bg-stone-300 h-[2px]" />

              <div className="flex items-center justify-between">
                <Link
                  to="/candidate-profile"
                  className=" text-sm font-medium font-['Hind Guntur'] py-3 cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu2")}
                >
                  Holland's Morgan
                </Link>
                <div>
                  <div className="w-[13px] h-[13px] relative">
                    <div className="w-[13px] h-[13px] left-0 top-0 absolute bg-red-700 rounded-full"></div>
                    <div className="w-[7px] h-[7px] left-[3px] top-[3px] absolute bg-white rounded-full"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <Link
                  to="/committee-profile"
                  className=" text-slate-600 text-sm font-medium py-3 cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu2")}
                >
                  {dummyUserProfiles[0].username}
                </Link>
                <div>
                  <div className="w-[13px] h-[13px] relative">
                    <div className="w-[13px] h-[13px] left-0 top-0 absolute bg-red-700 rounded-full"></div>
                    <div className="w-[7px] h-[7px] left-[3px] top-[3px] absolute bg-white rounded-full"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] py-3 cursor-pointer"
                  onClick={handleUserSubmenuToggle}
                >
                  View more
                  {showUserSubmenu && (
                    <div className="absolute right-full mr-1 w-[238px] h-auto  bg-white rounded-bl-[10px] rounded-br-[10px] p-5 shadow-2xl">
                      {userProfiles.slice(1).map((profile) => (
                        <div
                          key={profile.id}
                          className="flex items-center justify-between"
                        >
                          <div
                            className=" text-sm font-medium font-['Hind Guntur'] py-3 cursor-pointer"
                            onClick={() => handleItemClick("userSubmenu2")}
                          >
                            {profile.username}
                          </div>
                          <div>
                            <div className="w-[13px] h-[13px] relative">
                              <div className="w-[13px] h-[13px] left-0 top-0 absolute bg-red-700 rounded-full"></div>
                              <div className="w-[7px] h-[7px] left-[3px] top-[3px] absolute bg-white rounded-full"></div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="flex items-center gap-5 py-3">
                        <div>
                          <img className="h-[21px] w-[21px]" src={add} alt="" />
                        </div>
                        <div
                          className="text-slate-600 text-sm font-medium font-['Hind Guntur'] py-3 cursor-pointer"
                          onClick={() => handleItemClick("userSubmenu2")}
                        >
                          Create New
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <img src={right} alt="" />
                </div>
              </div>

              <div className="flex items-center gap-5 py-3">
                <div>
                  <img className="h-[21px] w-[21px]" src={add} alt="" />
                </div>
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] py-3 cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu2")}
                >
                  Create New
                </div>
              </div>

              <hr className="bg-stone-300 h-[2px]" />

              <Link
                to="/candidate-profile"
                className="flex items-center gap-5 py-3"
              >
                <img src={profile} alt="" />
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu1")}
                >
                  Profile
                </div>
              </Link>

              <Link to="/settings" className="flex items-center gap-5 py-3">
                <img src={setting} alt="" />
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu1")}
                >
                  Settings
                </div>
              </Link>

              <Link to="/payments" className="flex items-center gap-5 py-3">
                <img src={payment} alt="" />
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu1")}
                >
                  Payments
                </div>
              </Link>

              <Link
                to="/notifictaions"
                className="flex items-center gap-5 py-3"
              >
                <img src={notificationIcon} alt="" />
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu1")}
                >
                  Notifications
                </div>
              </Link>

              <hr className="bg-stone-300 h-[2px]" />

              <Link to="/" className="flex items-center gap-5 py-4">
                <img src={signOut} alt="" />
                <div
                  className="text-slate-600 text-sm font-medium font-['Hind Guntur'] cursor-pointer"
                  onClick={() => handleItemClick("userSubmenu1")}
                >
                  Sign Out
                </div>
              </Link>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>

      {/* mobile responsive navbar code */}
      {/* left side */}
      <div className=" flex items-center gap-x-5 md:hidden">
        <img src={menuIcon} alt="" onClick={toggleMenuLeft} />
        <div>
          <span className="text-zinc-800 text-2xl font-semibold font-['Hind Guntur'] ">
            Red
          </span>
          <span className="text-red-700 text-2xl font-semibold font-['Hind Guntur'] ">
            Wave
          </span>
        </div>
      </div>

      {/* right side */}
      <div className="flex items-center gap-x-5 md:hidden">
        <div onClick={toggleNotifications}>
          <img src={notificationSm} alt="" />
        </div>
        <div >
          <img
            className="w-[41px] h-[41px] rounded-[64.50px]"
            src="https://via.placeholder.com/41x41"
            alt="user"
            onClick={toggleMenuRight}
          />
        </div>
      </div>

      {/* left side menu sidebar */}

      <div
        className={` bg-white text-black z-50 w-64 fixed inset-y-0 left-0 transform ${
          isOpenLeft ? "translate-x-0" : "-translate-x-full"
        } transition-transform ease-in-out duration-300`}
      >
        {/* menu items  */}
        <div className="flex flex-col gap-y-8 pl-5 pr-10 pt-10">
          <div className="flex items-center gap-x-3" onClick={toggleMenuLeft}>
            <img src={leftArrow} alt="" />
            <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
              Back
            </div>
          </div>

          <div className="py-5">
            <span className="text-zinc-800 text-4xl font-semibold font-['Hind Guntur']">
              Red
            </span>
            <span className="text-red-700 text-4xl font-semibold font-['Hind Guntur']">
              Wave
            </span>
          </div>

          <div className="flex items-center gap-20">
            <div className="flex items-center gap-x-3" onClick={handleDashboard}>
              {/* <img src={dashboard} alt="" /> */}
              
                <div className="text-zinc-800 text-sm font-medium font-['DM Sans']">
                  Dashboard
                </div>
              
            </div>
            <img src={isDashboard ? dropDown : rightDropDown} alt="" />
          </div>
          {/* close dashboard dropdown */}

          {
            isDashboard ? 
            <>
              <div className="flex items-center gap-x-3" onClick={handleDashboard}>
              <img src={dashboard} alt="" />
              <Link to="/dashboard">
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  Dashboard
                </div>
              </Link>
            </div>

          <div className="flex items-center gap-x-3">
            <img src={transaction} alt="" />
            <Link to="/transactions-history">
              <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                Transactions
              </div>
            </Link>
          </div>

          <div className="flex items-center  gap-20" onClick={handleReporting}>
            <div className="flex items-center gap-x-3">
              <img src={reporting} alt="" />
              <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                Reporting
              </div>
            </div>
            <img className="" src={isReporting ? dropDown : rightDropDown} alt="right drop down" />
          </div>
          {/* reporting drop downs */}
          {
            isReporting ?
            <>

            <div className="flex items-center gap-x-3">
            <img src={reportBuilder} alt="" />
            <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
              Report Builder
            </div>
          </div>
          <div className="flex items-center gap-x-3">
          <img src={reportRecord} alt="" />
          <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
            Report Records
          </div>
        </div>
        </>
        : null

          }


          <div className="flex items-center gap-x-3">
            <img src={donationBuilder} alt="" />
            <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
              Donations Builder
            </div>
          </div>

          <div className="flex items-center gap-x-3">
            <img src={widget} alt="" />
            <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
              Manage Widgets
            </div>
          </div>

          <div className="flex items-center gap-x-3">
            <img src={profile} alt="" />
            <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
              Favourite Profiles
            </div>
          </div>

          <div className="flex items-center gap-x-3">
            <img src={management} alt="" />
            <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
              User Management
            </div>
          </div>
            </> : null
          }

          

          <Link to="/about">
            <div className="text-zinc-800 text-sm font-medium font-['DM Sans']">
              About
            </div>
          </Link>

          <div className="text-zinc-800 text-sm font-medium font-['DM Sans']">
            Contact Us
          </div>

          <div className="justify-end items-end">
            <button className="w-[155px] h-[33px] bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-base font-semibold">
              Sign up
            </button>
          </div>
        </div>
      </div>

      {/* left side notifications */}

      <div
        className={` bg-white text-black z-50 w-full h-screen fixed inset-y-0 left-0 transform ${
          isNotifications ? "translate-x-0" : "-translate-x-full"
        } transition-transform ease-in-out duration-300`}
      >
        {/* menu items  */}
        <div className=" px-5 pt-10">

          <div className="flex justify-between items-end">

          <div className="flex items-center gap-x-5">
          <div className="flex items-center gap-x-3" onClick={toggleNotifications}>
            <img src={leftArrow} alt="" />
            <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
              Back
            </div>
          </div>
          <div className="text-zinc-800 text-xl font-semibold font-['Hind Guntur']">
              Notifications
          </div>
          </div>

          <div className="text-slate-600 text-xs font-normal font-['Hind Guntur']">Read All</div>

          </div>
          
          <hr className="stone-300 my-5" />
          
          <div className="h-[80vh] overflow-scroll">
          {dummyNotifications.map((notification) => (
        // className={notification.status === 'read' ? 'read' : 'unread'}
        <div key={notification.id} className="flex items-center gap-x-5 mb-5">
          <div>
            <img className="w-[47px] h-[47px] rounded-[64.50px]" src="https://via.placeholder.com/47x47" alt="" />
          </div>
          <div>
            <div className="flex gap-2 pb-2">
            <div className={` text-lg font-semibold font-['Hind Guntur'] ${notification.status == "unread" ? "text-zinc-800" : "text-slate-600"}`}>{notification.alert}</div>
            {notification.status == "unread" &&
            <div className="w-2 h-2 bg-red-700 rounded-full" />
            }
            </div>
          <div className="text-slate-600 text-sm font-normal font-['Hind Guntur'] leading-tight">{notification.notification}</div>
          </div>
          
        </div>
      ))}
          </div>
        </div>
      </div>

      {/* right side menu sidebar */}
      <div
        className={`bg-white text-black z-50 w-64 fixed inset-y-0 right-0 transform ${
          isOpenRight ? "translate-x-0" : "translate-x-full"
        } transition-transform ease-in-out duration-300`}
      >
        {/* menu items  */}
        <div className="flex flex-col gap-y-8 pl-5 pr-5 pt-10">
          <div className="flex items-center justify-end gap-x-3" onClick={toggleMenuRight}>
            <div className="text-slate-600 text-base font-medium font-['Hind Guntur'] leading-tight">
              Back
            </div>
            <img src={rightArrow} alt="" />
          </div>

          <div className="pt-5">
          <div
            className=" bg-white rounded-[30px] justify-end flex items-center cursor-pointer"
          >
            <img
              className="w-[47px] h-[47px] rounded-[64.50px]"
              src="https://via.placeholder.com/47x47"
              alt="user"
            />
            <div className="border-l-2  border-stone-300 h-[50px] w-[2px] mx-5"></div>
            <div className=" items-center gap-3">
              <div>
                <div className="text-zinc-800 text-sm font-medium font-['DM Sans']">
                  Holland Morgan
                </div>
                <div className="text-slate-600 text-sm font-normal font-['Hind Guntur']">
                  Candidate Account
                </div>
              </div>
            </div>
          </div>
          </div>

          <hr className="stone-300 " />

          <div className="flex items-center gap-x-3">
              <img src={dashboard} alt="" />
              
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  Dashboard
                </div>
              
            </div>

          <hr className="stone-300 " />

          {/* <div>Profiles</div> */}
          {!isViewMoreProfiles && 
          <>
        <div
          className={`flex items-center gap-x-3 cursor-pointer ${
            selectedProfile.id === selectedProfile.id ? 'bg-gray-200' : ''
          }`}
          onClick={() => handleProfileClick(selectedProfile)}
        >
          <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
            {selectedProfile.name}
          </div>
          <Checkbox
            checked={selectedProfile.id === selectedProfile.id}
            onChange={() => handleProfileClick(selectedProfile)}
          />
        </div>

        <div
          className={`flex items-center gap-x-3 cursor-pointer ${
            selectedProfile.committees[0].id === selectedProfile.id ? 'bg-gray-200' : ''
          }`}
          onClick={() => handleProfileClick(selectedProfile)}
        >
          <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
            {selectedProfile.committees[0].name}
          </div>
          <Checkbox
            checked={selectedProfile.committees[0].id === selectedProfile.id}
            onChange={() => handleProfileClick(selectedProfile)}
          />
        </div>
          </>
          }
            <div className="flex items-center gap-20">
            <div className="flex items-center gap-x-3" onClick={handleViewMoreProfiles}>
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  View More
                </div>
            </div>
            <img src={isViewMoreProfiles ? dropDown : rightDropDown} alt="" />
            </div>
            
            {isViewMoreProfiles ?
            <>
            <div
          // key={profile.id}
          className={`flex items-center gap-x-3 cursor-pointer ${
            selectedProfile.id === selectedProfile.id ? 'bg-gray-200' : ''
          }`}
          onClick={() => handleProfileClick(selectedProfile)}
        >
          <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
            {selectedProfile.name}
          </div>
          <Checkbox
            checked={selectedProfile.id === selectedProfile.id}
            onChange={() => handleProfileClick(selectedProfile)}
          />
        </div>
            {profiles.committees.map((profile) => (
              <div
                key={profile.id}
                className={`flex items-center gap-x-3 cursor-pointer ${
                  profile.id === selectedProfile.id ? 'bg-gray-200' : ''
                }`}
                // onClick={() => handleProfileClick(profile.id)}
              >
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  {profile.name}
                </div>
                <Checkbox
                  checked={profile.id === selectedProfile.id}
                  onChange={() => handleCommitteeClick(profile)}
                />
              </div>
            ))} </> : null
          }

          <div className="flex items-center gap-5">
                        <div>
                          <img className="h-[21px] w-[21px]" src={add} alt="" />
                        </div>
                        <div
                          className="text-slate-600 text-sm font-medium font-['Hind Guntur'] py-3 cursor-pointer"
                          onClick={() => handleItemClick("userSubmenu2")}
                        >
                          Create New
                        </div>
          </div>

          <hr className="stone-300 " />

          <div className="flex items-center gap-x-3">
              <img src={profile} alt="" />
              
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  Profile
                </div>
              
            </div>

            <div className="flex items-center gap-x-3">
              <img src={setting} alt="" />
              
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  Settings
                </div>
              
            </div>

            <div className="flex items-center gap-x-3">
              <img src={payment} alt="" />
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  Payments
                </div>
            </div>

            <div className="flex items-center gap-x-3">
              <img src={notification} alt="" />
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  Notifications
                </div>
            </div>

          <hr className="stone-300 " />
          
          <div className="flex items-center gap-x-3">
              <img src={signOut} alt="" />
                <div className="text-slate-600 text-sm font-medium font-['Hind Guntur']">
                  Sign out
                </div>
            </div>

        </div>
      </div>

    </nav>
  );
};

export default Navbar;
