import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
const initialState: any = {
  CommitteeFlow: {
    myinfo: {
      fill: false,
      isvisible: true,
    },
    CommitteeInfo: {
      fill: false,
      isvisible: false,
    },
    PoliticalAffiliation: {
      fill: false,
      isvisible: false,
    },
    CompanyInfo: {
      fill: false,
      isvisible: false,
    },
    Biography: {
      fill: false,
      isvisible: false,
    },
    CreativeAssets: {
      fill: false,
      isvisible: false,
    },
    Issues: {
      fill: false,
      isvisible: false,
    },
    SocialMedia: {
      fill: false,
      isvisible: false,
    },
  },
};

export const CommitteeNewSlice = createSlice({
  name: "CommitteeNewSlice",
  initialState,
  reducers: {
    UpdateCommitteeForm: (state, action: PayloadAction<string>) => {
      // Set all values to false initially
      Object.keys(state.CommitteeFlow).forEach((key) => {
        // state.CommitteeFlow[key].fill = false;
        state.CommitteeFlow[key].isvisible = false;
      });

      // Set the specified key values to true
      state.CommitteeFlow[action.payload].fill = true;
      state.CommitteeFlow[action.payload].isvisible = true;
    },
  },
});
export const { UpdateCommitteeForm } = CommitteeNewSlice.actions;

export default CommitteeNewSlice.reducer;
