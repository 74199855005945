import React, { useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RedWave from "../../../../assets/images/RedWave.svg";
import Back from "../../../../assets/images/Back.svg";
import Plus from "../../../../assets/icons/Plus.svg";
import FormFlagMobile from "../../../../assets/images/FormFlagMobile.svg";
import MobileFormLogo from "../../../../assets/images/MobileFormLogo.svg";
import RedDot from "../../../../assets/images/RedDot.svg";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UpdateCandidateForm } from "../../../../redux/candidate/candidateSlice";

import { API_BASE_URL } from "../../../../constants/apiEndPoints";
import { toast } from "react-hot-toast";
import httpRequest from "../../../../axios/index";
import { setAuthData } from "../../../../redux/auth/authSlice";

type FormValues = {
  id: string | number;
  education: Array<{
    // formattedDate: any;
    school: string;
    degree: string;
    end_date: string;
  }>;
  certifications: Array<{ certification: string }>;
};

interface DegreeFields {
  school: string;
  degree: string;
  end_date: string;
}

interface CertificationField {
  certification: string[];
}

function Education() {
  const dispatch = useAppDispatch();
  const authinfo = useAppSelector((state: any) => state.auth.userData);
  const [loading, setloading] = useState<boolean>(false);

  const [showDegreeFields, setShowDegreeFields] = useState<DegreeFields[]>([
    { school: "", degree: "", end_date: "" },
  ]);
  const [showCertificationField, setShowCertificationField] = useState<
    CertificationField[]
  >([{ certification: [] }]);

  const handleDegreeFields = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Check if the number of existing input fields is less than 3
    if (showDegreeFields.length < 3) {
      setShowDegreeFields([
        ...showDegreeFields,
        { school: "", degree: "", end_date: "" },
      ]);
    }
  };

  const handleCertificationField = () => {
    if (showCertificationField.length < 6) {
      setShowCertificationField([
        ...showCertificationField,
        { certification: [] },
      ]);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      education: [{ school: "", degree: "", end_date: "" }],
    },
  });

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "education",
    });

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    if (data) {
      try {
        setloading(true);
        const res = await httpRequest.patch(
          `${API_BASE_URL}/onboarding/candidate/${authinfo.id}/education`,
          {
            education: data.education,
            certifications: data.certifications,
          }
        );

        if (res?.status === 200 || res?.status === 201) {
          const updatedAuthInfo = {
            ...authinfo,
            ...res?.data.candidate,
          };
          dispatch(UpdateCandidateForm("Issues"));
          dispatch(setAuthData(updatedAuthInfo));
          // toast.success("added successfully!");
        } else {
          toast.error(res?.data?.message || "Something went wrong");
        }
      } catch (error: any) {
        toast.error(
          (error.response?.data?.message as string) || "Something went wrong"
        );
      } finally {
        setloading(false);
      }
    }
  };

  useEffect(() => {
    if (authinfo) {
      const { education, certifications } = authinfo;

      if (education && education.length > 0) {
        // Loop through the education array and set values for each field
        education.forEach((edu: any, index: number) => {
          setValue(`education.${index}.school`, edu.school);
          setValue(`education.${index}.degree`, edu.degree);
          // Parse and format the date
          const formattedDate = new Date(edu.end_date)
            .toISOString()
            .split("T")[0];
          setValue(`education.${index}.end_date`, formattedDate);
        });

        setShowDegreeFields(
          education.map((edu: any) => ({
            school: edu.school,
            degree: edu.degree,
            end_date: new Date(edu.end_date).toISOString().split("T")[0],
          }))
        );
      }

      if (certifications) {
        certifications.forEach((certification: any, index: number) => {
          setValue(`certifications.${index}`, certification);
        });
        setShowCertificationField(
          certifications.map((certification: any) => ({ certification }))
        );
      }
    }
  }, [authinfo, setValue]);

  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-24 block md:block lg:block xl:hidden"
        style={{ backgroundImage: `url(${FormFlagMobile})` }}
      >
        <div className="flex items-center justify-between h-full px-12  ">
          <img
            src={MobileFormLogo}
            alt="Mobile Header"
            className="max-h-full"
          />
          <div className="flex flex-row gap-4">
            <img src={RedDot} alt="RedDot" />
            <p className="text-white">Education</p>
          </div>
        </div>
      </div>

      <div className="px-12 md:px-24 lg:px-24 xl:px-24 pt-24 md:justify-center lg:justify-center">
        <p className="text-3xl text-center md:text-center lg:text-start xl:text-start font-bold mb-4 md:text-4xl lg:text-5xl xl:text-5xl">
          Welcome to <span className="text-red-600">Red</span>Wave
        </p>
        <p className="text-xs text-center font-normal md:text-lg lg:text-start xl:text-start xl:text-xl text-[#585C7B]">
          Please Provide The Details Below To Complete Your Profile
        </p>
        <form onSubmit={handleSubmit(submitForm)} className="mt-14">
          <div>
            {showDegreeFields.map((field, index) => (
              <div
                className="flex flex-col flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row py-2"
                key={index}
              >
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="field1"
                    className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                  >
                    School :{" "}
                    <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                      *
                    </span>
                  </label>
                  <input
                    {...register(`education.${index}.school` as const, {
                      required: "School should not be empty",
                    })}
                    className="mt-2 w-[275px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                    placeholder="Ex: Oxford University"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="field2"
                    className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                  >
                    Degree :{" "}
                    <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    {...register(`education.${index}.degree` as const, {
                      required: "Degree should not be empty",
                    })}
                    className="mt-2 w-[275px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                    placeholder="Ex: Master’s Degree"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="field2"
                    className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
                  >
                    End Date :{" "}
                    <span className="text-red-700 text-xl font-normal font-['Hind Guntur']">
                      *
                    </span>
                  </label>
                  <input
                    type="date"
                    {...register(`education.${index}.end_date` as const, {
                      required: "End Date should not be empty",
                    })}
                    className="mt-2 w-[275px] h-[50px] bg-stone-100 rounded-[10px] p-4 text-[#8C8C8C] font-['Hind Guntur']"
                    placeholder="DD/MM/YY"
                  />
                </div>
              </div>
            ))}

            <div className="flex flex-wrap py-5 px-2 justify-between gap-72">
              <button
                className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
                onClick={handleDegreeFields}
                type="button"
              >
                <img src={Plus} alt="Plus" className="w-8 h-8" />
                <span className="pt-1 text-slate-600 font-medium font-[' Hind Guntur']">
                  Add an additional degree
                </span>
              </button>
            </div>
          </div>

          <div className="flex flex-row flex-nowrap gap-6 md:gap-8 lg:gap-12 md:flex-col lg:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="field1"
                className="text-slate-600 text-xl font-normal font-['Hind Guntur']"
              >
                Past held positions :
              </label>
              <div className="flex flex-wrap flex-row gap-8 mt-2">
                {showCertificationField.map((field, index) => (
                  <div className="flex flex-row gap-2" key={index}>
                    <input
                      {...register(`certifications.${index}` as const, {
                        required: "Certification should not be empty",
                      })}
                      type="text"
                      className="mt-2 p-2 w-[317px] h-[50px] bg-stone-50 rounded-[10px]"
                      placeholder="Enter any Certifications or Licences "
                    />
                    {errors.certifications?.[index] && (
                      <span className="mt-2 flex flex-row justify-start text-start text-red-600">
                        {errors.certifications[index]?.message}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-8 mt-6">
            <div
              className="flex flex-nowrap gap-2 text-base font-medium cursor-pointer"
              onClick={handleCertificationField}
            >
              <img src={Plus} alt="Plus" className="w-8 h-8" />
              <button
                className="text-center text-slate-600 text-[15px] font-medium font-['Hind Guntur']"
                type="button"
              >
                Add additional past held position
              </button>
            </div>
          </div>

          <div className="flex flex-wrap py-14 justify-between gap-4 sm:gap-2">
            <div
              className="flex flex-wrap gap-2 text-base font-medium cursor-pointer"
              onClick={() => dispatch(UpdateCandidateForm("BiographyStepTwo"))}
            >
              <img
                src={Back}
                alt="Back"
                className="w-8 h-8 pt-2 hidden sm:inline-block"
              />
              <button className="hidden sm:inline-block" type="button">
                Back
              </button>
            </div>
            <div className="flex flex-wrap justify-between gap-4 sm:gap-8 items-center">
              <p className="text-zinc-800 text-[16px] sm:text-[32px] font-bold font-['DM Sans'] leading-[27.18px] sm:leading-[37.18px]">
                Education
              </p>
              <button
                className="w-[140px] h-[32px] sm:w-[183px] sm:h-[42px] rounded-[16px] sm:rounded-[21px] bg-red-700 text-white text-sm sm:text-xl font-medium"
                type="submit"
              >
                {loading ? "loading..." : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Education;
