// Modal.tsx
import React, { useState } from "react";
import leftArrow from "../assets/icons/leftArrowIcon.png";
import donationlg from "../assets/icons/donationIconlg.png";
import IconKey from "./icons/landing_page/IconKey";
import IconShare from "./icons/landing_page/IconShare";
import IconDonationDollar from "./icons/landing_page/IconDonationDollar";
import IconSearch from "./icons/landing_page/IconSearch";
import IconLike from "./icons/landing_page/IconLike";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Features {
  content: {
    icon: any,
    text: string;
    description: string;
  };
}

// Contributors and Donors features/description

const features2: Features[] = [
  {
    content: {
      text: "Access to Candidate/Committee Profiles",
      icon: IconKey,
      description: "Gain unparalleled access to committee and candidate profiles ad analysis that meet your local, state, and national needs as a voter and campaign contributor.  Stay informed, and get involved.",
    },
  },
  {
    content: {
      text: "Share, Favorite, and View Trending Profiles",
      icon: IconShare,
      description: "RedWave offers the ability to share campaigns and committees of interest, pin and favorite, and see suggested and trending profiles. Stay on top and informed of notable trends and analysis in real time.",
    },
  },
  {
    content: {
      text: "Contribution Going Directly to Your Cause ",
      icon: IconDonationDollar,
      description: "Nothing is worse than finding out your $100 contribution was eaten away by subscription fees, processing, taxes, and other hands in the donation jar. RedWave makes certain 100 percent (%) of your donation goes directly to the causes and candidates you support.",
    },
  },
  {
    content: {
      text: "Search by Different Filters  ",
      icon: IconSearch,
      description: "RedWave search intelligence enables you to identify and filter committees and candidates to ensure your issues and party alignment exactly matches yours before you choose to support a specific candidate or initiative.",
    },
  },
  {
    content: {
      text: "Gain Confidence Knowing Top Candidates",
      icon: IconLike,
      description: "Contribution transparency and understanding who is trending in total donations, donations year to date, and contributions taken this month is key to overall donors awareness when deciding who is going to be their go to candidate for change.",
    },
  },
];

const FeatureModal2: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [selectedFeature, setSelectedFeature] = useState<Features>(features2[0]);
  // const [isMobile, setIsMobile] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  //choose the screen size
  // const handleResize = () => {
  //   if (window.innerWidth < 720) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // };

  // create an event listener
  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  // });

  const handleFeatureClick = (feature: Features) => {
    setSelectedFeature(feature);
  };

  const handleClick = (index: number) => {
    if (selectedOption === index) {
      // Close the accordion if the same option is clicked again
      setSelectedOption(null);
    } else {
      // Open the accordion for the selected option
      setSelectedOption(index);
    }
  };

  return (
    <>
      {/* fixed bg-opacity-75 mix-blend bg-black*/}
      {/* inset-0 bg-gradient-to-b from-black to-black */}
      {isOpen && (
        <div className=" bg-opacity-75 mix-blend bg-black rounded-[25px] w-full h-[578px] visible xl:mx-[100px] xl:fixed xl:w-auto">
          {/* {isMobile ? ( */}
          {/* w-[358px] */}
          <div className="block w-full h-[578px] lg:w-[366px] lg:h-[578px] xl:hidden">
            <div>
              <div
                className="flex items-center text-white gap-4 p-5 cursor-pointer"
                onClick={onClose}
              >
                <img src={`${leftArrow}`} alt="" />
                <span>Back</span>
              </div>
              <div className="flex flex-col items-center text-5xl text-white gap-4 p-5">
                <img
                  className="w-[83px] h-[64px]"
                  src={`${donationlg}`}
                  alt=""
                />
                <div className="text-center py-[20px] text-white text-[22px] font-bold font-['DM Sans'] tracking-wide">
                  Contributors & Donors
                </div>
              </div>
            </div>
            <div className="max-w-lg w-[90%] mx-auto pb-5">
              {features2.map((feature, index) => (
                <div
                  key={index}
                  className={` ${
                    selectedOption !== null && selectedOption !== index
                      ? "hidden"
                      : ""
                  }`}
                >
                  <button
                    className={` bg-black-500 text-white p-2 text-left ${
                      selectedOption === index ? "rounded-t-md" : ""
                    }`}
                    onClick={() => handleClick(index)}
                  >
                    <div className="flex items-center gap-x-5">
                      <feature.content.icon className="w-[15px]"/>

                      {feature.content.text}
                    </div>
                  </button>
                  {selectedOption === index && (
                    <div className="bg-red-700 text-white p-4 h-[100%] rounded-[25px]">
                      {features2[index].content.description}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* ) : (
            <> */}
          <div className="hidden w-[1216px] h-[578px] xl:block">
            <div className="flex items-center justify-between px-8">
              <div
                className="flex items-center text-white gap-4 p-5 cursor-pointer"
                onClick={onClose}
              >
                <img src={`${leftArrow}`} alt="" />
                <span>Back</span>
              </div>
              <div className="flex items-center text-5xl text-white font-['DM Sans'] tracking-wide  gap-4 p-5">
                <img
                  className="w-[60px] h-[50px]"
                  src={`${donationlg}`}
                  alt=""
                />
                <div>Contributors & Donors</div>
              </div>
            </div>

            <div className="flex items-center justify-center text-white px-8 py-12 max-w-fit w-full">
              <div className="flex justify-center gap-10">
                <div className="bg-opacity-60 mix-blend bg-black rounded-[25px] w-[50%] ">
                  <div className="p-[15px]">
                    {features2.map((feature, index) => (
                      <div
                        key={index}
                        className={`cursor-pointer py-2 pl-4 ${
                          selectedFeature.content.text === feature.content.text
                            ? "bg-red-700 rounded-[21px]"
                            : ""
                        }`}
                        onClick={() => handleFeatureClick(feature)}
                      >
                        <div className="flex items-center gap-4">
                          <feature.content.icon/>
                          <div className="text-xl font-medium font-['Hind Guntur'] leading-relaxed">
                            {feature.content.text}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-red-700 rounded-[25px]  p-[15px] w-[50%]">
                  <div>
                    {selectedFeature && (
                      <>
                        <div className="flex items-center mb-2">
                          <selectedFeature.content.icon />
                          <div className="ml-2 text-[22px] font-semibold font-['Hind Guntur'] leading-7 py-2">
                            {selectedFeature.content.text}
                          </div>
                        </div>
                        <div className="text-lg font-medium font-['Hind Guntur'] leading-[28.89px]">
                          {selectedFeature.content.description}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </> */}
          {/* )} */}
        </div>
      )}
    </>
  );
};

export default FeatureModal2;
