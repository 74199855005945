import React from 'react'
import redWave from "../../assets/images/redWaveIcon.svg"
import connectBank from "../../assets/icons/connectBankIcon.svg"
import donations from "../../assets/icons/donationsIcon.svg"
import { Link } from 'react-router-dom'
const WelcomeProfile = () => {
  return (
    <div className="bg-cover bg-center relative bg-[url('/src/assets/images/whitehouseSideView.svg')] lg:h-full">
    <div className="absolute inset-0 bg-gradient-to-r from-black to-black opacity-80"></div>

    <div className='z-10 relative px-8 py-8 lg:px-20 lg:py-20'>


        <div className='flex justify-between items-center '>
        <div className="  lg:w-[702px]"><span className="text-white text-[28px] font-bold font-['DM Sans'] leading-loose lg:text-[64px] lg:leading-[74.37px]">Welcome, Holland Morgan </span><span className="text-red-700 text-[28px] font-bold font-['DM Sans'] leading-loose lg:text-[64px] lg:leading-[74.37px]">Reagan</span><span className="text-white text-[28px] font-bold font-['DM Sans'] leading-loose lg:text-[64px] lg:leading-[74.37px]">  </span></div>
        <img className='w-[92px] h-[67px] lg:w-[312px] lg:h-[227px]' src={redWave} alt="" />
        </div>
            {/* w-[370px] */}
        <div className="w-full text-white text-[15px] font-medium font-['Hind Guntur'] leading-snug tracking-tight pt-5 lg-pt-0 lg:text-xl lg:leading-[30.06px] lg:w-[558px]">Thank You for choosing RedWave. Your profile has been published. There are few more steps to complete your Withdrawal method, Setup Bank account, Customize your donation amounts and your Donation Widget settings. <br/><br/>Once you finish these steps you will gain access to your Payment, Campaign, and Compliance management portal.</div>

        <div className="text-white text-base font-bold font-['DM Sans'] leading-[18.59px] py-8 lg:py-10 lg:text-2xl lg:leading-7">Next steps to complete :</div>

        <div className='flex gap-x-10 py-5 lg:py-10'>
            <div className='flex items-center flex-col gap-y-5'>
                <img className='w-[45px] h-[45.61px] lg:w-[55px] lg:h-[55.74px]' src={connectBank} alt="" />
                <div className="text-white text-[15px] font-medium font-['Hind Guntur'] leading-[17.43px] lg:text-[19px] lg:leading-snug ">Connect Bank</div>
            </div>

            <div className='flex items-center flex-col gap-y-5'>
                <img className='w-[58.33px] h-[45px] lg:w-[71.29px] lg:h-[55px]' src={donations} alt="" />
                <div className="text-white text-[15px] font-medium font-['Hind Guntur'] leading-[17.43px] lg:text-[19px] lg:leading-snug">Customize Donations</div>
            </div>
        </div>

        <div className='flex justify-between items-center pt-5'>
        <div className="text-white text-xl font-medium font-['Hind Guntur'] leading-[30.10px]">Let’s  connect with the people who share same beliefs</div>
        <Link to="/customize-donations">
        <button className="w-[183px] h-[42px] bg-red-700 flex-row justify-center items-center rounded-[21px] text-white text-base font-semibold hidden lg:flex">Next</button>

        </Link>
        </div>

        
    
    </div>
    <div className='flex justify-end px-8 py-32 relative z-10 lg:hidden'>
       <Link to="/customize-donations">
       <div className="w-[183px] h-[42px] bg-red-700 flex flex-row justify-center items-center rounded-[21px] text-white text-base font-semibold ">Next</div>
        </Link> 
        </div>
</div>

  )
}

export default WelcomeProfile