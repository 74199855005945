export default function IconLike({
  className='w-[1.31rem] w-[1.31rem]',
}) {
  return (
    <svg
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path d="M3.00855 6.5625H0.694281C0.310835 6.5625 0 6.8773 0 7.26563V14.2969C0 14.6852 0.310835 15 0.694281 15H3.00855C3.392 15 3.70283 14.6852 3.70283 14.2969V7.26563C3.70283 6.8773 3.392 6.5625 3.00855 6.5625ZM1.85142 13.8281C1.46797 13.8281 1.15714 13.5133 1.15714 13.125C1.15714 12.7367 1.46797 12.4219 1.85142 12.4219C2.23486 12.4219 2.5457 12.7367 2.5457 13.125C2.5457 13.5133 2.23486 13.8281 1.85142 13.8281ZM11.1085 2.38629C11.1085 3.62895 10.3572 4.32598 10.1458 5.15625H13.0885C14.0547 5.15625 14.8068 5.96913 14.8113 6.85835C14.8137 7.38387 14.593 7.94963 14.249 8.29966L14.2458 8.30289C14.5303 8.98659 14.4841 9.9446 13.9765 10.6311C14.2276 11.3897 13.9745 12.3216 13.5026 12.8212C13.6269 13.3368 13.5675 13.7756 13.3248 14.1288C12.7343 14.9879 11.271 15 10.0335 15L9.95119 15C8.55432 14.9995 7.4111 14.4844 6.49251 14.0705C6.0309 13.8626 5.42734 13.6051 4.9694 13.5966C4.78021 13.5931 4.62854 13.4367 4.62854 13.2451V6.98227C4.62854 6.88852 4.66563 6.79855 4.73147 6.73263C5.87744 5.58583 6.3702 4.37168 7.30948 3.41883C7.73773 2.9843 7.89348 2.32793 8.04406 1.69318C8.1727 1.15116 8.44179 0 9.02565 0C9.71994 0 11.1085 0.234375 11.1085 2.38629Z" fill="white"/>
    </svg>
  )
}
