export default function IconDonationDollar({
  className='w-[1.31rem] w-[1.31rem]',
}) {
  return (
    <svg
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
    >
      <path d="M10.3125 1.46191C10.0785 1.52591 9.9375 1.70566 9.9375 1.87516C9.9375 2.04466 10.0785 2.22441 10.3125 2.28816V1.46191ZM10.6875 2.71216V3.53816C10.9215 3.47441 11.0625 3.29466 11.0625 3.12516C11.0625 2.95566 10.9215 2.77591 10.6875 2.71216Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13 2.5C13 3.88075 11.8807 5 10.5 5C9.11925 5 8 3.88075 8 2.5C8 1.11925 9.11925 0 10.5 0C11.8807 0 13 1.11925 13 2.5ZM10.5 0.8125C10.5497 0.8125 10.5974 0.832254 10.6326 0.867417C10.6677 0.90258 10.6875 0.950272 10.6875 1V1.07925C11.095 1.15225 11.4375 1.4585 11.4375 1.875C11.4375 1.92473 11.4177 1.97242 11.3826 2.00758C11.3474 2.04275 11.2997 2.0625 11.25 2.0625C11.2003 2.0625 11.1526 2.04275 11.1174 2.00758C11.0823 1.97242 11.0625 1.92473 11.0625 1.875C11.0625 1.7055 10.9215 1.52575 10.6875 1.46175V2.32925C11.095 2.40225 11.4375 2.7085 11.4375 3.125C11.4375 3.5415 11.095 3.84775 10.6875 3.92075V4C10.6875 4.04973 10.6677 4.09742 10.6326 4.13258C10.5974 4.16775 10.5497 4.1875 10.5 4.1875C10.4503 4.1875 10.4026 4.16775 10.3674 4.13258C10.3323 4.09742 10.3125 4.04973 10.3125 4V3.92075C9.905 3.84775 9.5625 3.5415 9.5625 3.125C9.5625 3.07527 9.58225 3.02758 9.61742 2.99242C9.65258 2.95725 9.70027 2.9375 9.75 2.9375C9.79973 2.9375 9.84742 2.95725 9.88258 2.99242C9.91775 3.02758 9.9375 3.07527 9.9375 3.125C9.9375 3.2945 10.0785 3.47425 10.3125 3.538V2.67075C9.905 2.59775 9.5625 2.2915 9.5625 1.875C9.5625 1.4585 9.905 1.15225 10.3125 1.07925V1C10.3125 0.950272 10.3323 0.90258 10.3674 0.867417C10.4026 0.832254 10.4503 0.8125 10.5 0.8125Z" fill="white"/>
      <path d="M7.74128 6.8616C6.87577 6.36227 5.56087 5.9628 4.42905 6.36227C3.76327 6.59529 3.214 7.06133 2.79789 7.77704C2.78125 7.79369 2.78125 7.81033 2.78125 7.84362V10.9894C2.78125 11.0726 2.88112 11.1392 2.96434 11.0726C3.71334 10.4901 4.87844 10.1239 6.01026 10.4568C7.00893 10.7564 7.84115 10.9728 8.8731 11.056C10.4543 11.1891 11.4197 10.4901 12.4516 9.75773C13.4004 9.0753 14.166 8.19315 14.9317 7.29435C15.0482 7.1612 15.0149 6.94482 14.8651 6.84495L14.7985 6.79502C14.449 6.54535 13.9829 6.54535 13.6334 6.77838L12.7679 7.34429C12.0355 7.84362 11.1534 8.07664 10.3045 8.02671C10.2546 8.02671 10.2047 8.07664 10.2213 8.12657C10.2379 8.27637 10.2213 8.44282 10.1547 8.59262C10.0216 8.94215 9.72197 9.15853 9.38908 9.2251C9.22263 9.25839 9.03955 9.24175 8.8731 9.17517L7.09215 8.50939C6.99228 8.47611 6.94235 8.37624 6.92571 8.27637C6.92571 8.24308 6.92571 8.2098 6.94235 8.19315C6.99228 8.06 7.12544 7.99342 7.25859 8.04335L9.03955 8.70913C9.30586 8.80899 9.58881 8.67584 9.68868 8.40953C9.75526 8.24308 9.73861 8.06 9.63874 7.9102C9.58881 7.82697 9.50559 7.7604 9.40572 7.72711C8.93968 7.54402 8.39041 7.21113 7.90773 6.94482L7.74128 6.8616Z" fill="white"/>
      <path d="M0.166444 12.5706H2.06391C2.16377 12.5706 2.23035 12.4874 2.23035 12.4042V7.41083C2.23035 7.31096 2.14713 7.24438 2.06391 7.24438H0.166444C0.0665776 7.24438 0 7.32761 0 7.41083V12.4042C0 12.504 0.083222 12.5706 0.166444 12.5706Z" fill="white"/>
    </svg>
  )
}
